import React, { Component } from 'react';

import { connect } from 'react-redux';
import { _refreshToken } from '../../Refresh/Refresh';
import dispatch from '../../../../store/dispatch.js';

import api from '../../../../api';

import './PassOpenItem.css';

class PassOpenItem extends Component {

    componentWillMount() {
        // console.log('this.props.passItemId: ', this.props.passItemId);
        
        
    }

    backTo(value) {
        switch (value) {
            case "pass":
                if (this.props.stateWindowOpenPassItem === true) {
                    this.props.changeStateWindowOpenPassItem(!this.props.stateWindowOpenPassItem);
                    return;
                } else {
                    this.props.changeMain("home");
                }
                break;

            default:
                break;
        }
    }

    changeStatusPass(status) {
        this.props.changePassItemDateStatus(status);
        this.props.changeStatePassPage(1);
        
    }

    saveStatus() {
        api.editPassRequest(this.props.LOGIN_TYPE_USER, this.props.passItemId, this.props.passItemEtag, this.props.access_token, this.props.passItemStatus )
        .then(
            (req) => {
                // console.log(req);
                api.getPassRequests(this.props.LOGIN_TYPE_USER, this.props.access_token)
            .then(
                (req) => {
                    // console.log(req);
                    if (req.data !== undefined && req.data !== null && req.data !== "" && req.data._items !== undefined) {
                        this.props.changePassData(req.data._items);
                        this.backTo(this.props.mainName);
                        return;
                    }
                }
            )
            .catch(
                (err) => {
                    console.log(err);
                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                        _refreshToken(
                            this.props.client_id,
                            this.props.LOGIN,
                            this.props.PASSWORD,
                            this.props.changeAccessToken,
                            this.saveStatus.bind(this)
                        )
                    }
                }
            )
                
            }
        )
        .catch(
            (err) => {
                console.log(err);
                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                    _refreshToken(
                        this.props.client_id,
                        this.props.LOGIN,
                        this.props.PASSWORD,
                        this.props.changeAccessToken,
                        this.saveStatus.bind(this)
                    )
                }
            }
        )
        
    }

    render() {

        const types = [
            "new",
            "accepted",
            "cancelled",
            "completed"
        ]

        return (
            <div className="pass-open-item padding25 w100 flex">
                <div className="w50">
                    <div className="w100">
                        <p className="text_blue margin-bottom30">{this.props.passItemDateUpdate}</p>
                    </div>
                    <div className="w100">
                        <p className="label">Ф. И. О.</p>
                        <p className="text_white margin-bottom30">{this.props.passItemUser}</p>
                    </div>
                    <div className="w100">
                        <p className="label">Номер апартамента</p>
                        <p className="text_white margin-bottom30">{this.props.passItemApartNum}</p>
                    </div>
                    <div className="w100">
                        <p className="label">Номер автомобиля</p>
                        <p className="text_white margin-bottom30">{this.props.passItemPlate}</p>
                    </div>
                    <div className="w100">
                        <p className="label">Дата прибытия</p>
                        <p className="text_white margin-bottom30">{this.props.passItemDateComming}</p>
                    </div>
                </div>
                <div className="w50">
                    <p className="label">Статус заявки</p>
                    <nav className="select_nav">
                        <ul className="user_type_ul">
                            <li className="user_type_li select_li">
                                <a className="user_type_a min-width300 target text_white h43px">
                                    {this.props.passItemStatus === undefined ? "Нет данных" : this.props.passItemStatus[0] === "new" ? "Новая" : this.props.passItemStatus[0] === "completed" ? "Выполнено" :
                                    this.props.passItemStatus[0] === "accepted" ? "Принято" :
                                    this.props.passItemStatus[0] === "cancelled" ? "Отменено" :
                                    "Нет данных" }
                                </a>
                                <ul>
                                    {types.map(typeItem => <li className="user_type_li select_li" key={typeItem} ><a className="min-width300 w100 h43px" onClick={() => this.changeStatusPass([typeItem])} >{ typeItem === "new" ? "Новая" : typeItem === "completed" ? "Выполнено" : typeItem === "accepted" ? "Принято" : typeItem === "cancelled" ? "Отменено" : "Нет данных" }</a></li>)}
                                </ul>
                            </li>
                        </ul>
                    </nav>
                    <a className="button_account_save flex center text_white margin-top20" onClick={() => this.saveStatus()}>сохранить статус</a>
                </div>
            </div>
        );
    };
};

export default connect(dispatch.mapStateToProps, dispatch.mapDispatchToProps)(PassOpenItem);