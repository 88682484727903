
import api from '../../../api';
 
import cookieAction from '../../../cookieAction/';
export const _refreshToken = async (client_id, loginEmail, loginPassword, changeAccessToken, func) => {
    await api
      .refreshToken(
        client_id,
        loginEmail,
        loginPassword
      )

      .then(req => {
        // console.log('refreshToken',req);
        changeAccessToken(req.data.access_token);
        cookieAction.setCookie("AT", req.data.access_token);
        func();
        return
      })
      
      .catch(err => {
        console.log("err refreshToken", err);
        console.log("err.response refreshToken", err.response);

      })
};