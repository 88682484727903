import React from 'react';

import DefaultInput from '../../../DumbComponents/DefaultInput';

// import './styles.css';

export default props => {
  return(
    <div>
      <DefaultInput
        value={props.searchValue}
        onChange={props.onChange}
        className='aparts__search'
        placeholder='Поиск апартаментов'
      />
    </div>
  );
}