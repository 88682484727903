import React, { Component } from 'react';

import { connect } from 'react-redux';
import { _refreshToken } from './Refresh/Refresh';
import dispath from '../../store/dispatch.js';

import './App_Main.css';

import Home from '../App_Main/Home/Home.js';
import Requests from './Requests/Requests.js';
import Accounts from '../App_Main/Accounts/Accounts.js';
import Mailing from './Mailing/Mailing.js';
import Pass from './Pass/Pass.js';
import Lk from './Lk/Lk.js';
import Services from './Services/Services';
import PreLoader from '../App_Main/PreLoader/PreLoader.js';
import Aparts from './Aparts';


class App_Main extends Component {

    mainDispatcher = () => {
        switch (this.props.mainName) {
            case "home":
                return <Home />;
            case "request":
                return <Requests />;
            case "apart":
                return <Accounts />;
            case "notification":
                return <Mailing />;
            case "pass":
                return <Pass />;
            case "lk":
                return <Lk />;
            case "loftec_services":
                return <Services />;
            case "аparts":
                const {
                    client_id,
                    username,
                    user_id,
                    access_token,
                    LOGIN,
                    PASSWORD,
                    changeAccessToken,
                    ulData,
                    changeMain
                } = this.props;

                return <Aparts
                    client_id={client_id}
                    user_id={user_id}
                    username={username}
                    access_token={access_token}
                    login={LOGIN}
                    password={PASSWORD}
                    changeAccessToken={changeAccessToken}
                    ulData={ulData}
                    changeMain={changeMain}
                />;
            default:
                return this.props.mainName;
        }
    }

    render() {
        return (
            <div className="app_main">
                { this.mainDispatcher() }
            </div>
        );
    };
};

export default connect(dispath.mapStateToProps, dispath.mapDispatchToProps)(App_Main);