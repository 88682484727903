import React, { Component } from 'react';

import { connect } from 'react-redux';

import dispatch from '../../../../store/dispatch.js';

import api from '../../../../api';

import './Service.css';

import empty_avatar from './empty_avatar.jpg';

class Service extends Component {
    componentWillMount() {
        if (this.props.services_photo_for_save !== undefined) {
            this.getImageApi();
        }
    }
    getImageApi = () => {
        // console.log(this.props.services_photo_for_save);
        api.getImage(this.props.services_photo_for_save, this.props.access_token)
            .then(request => {
                // console.log(request);

                let img_file = `data:${request.data.image.content_type};base64, ${request.data.image.file}`
                // console.log(img_file);

                this.props.changeStateCreateServicePhoto({ imagePreviewUrl: img_file });
            })
            .catch(
                error => {
                    console.log(error);
                    if (error.response.status === 401 && error.response.data._error.message === 'token expired' || error.response.status === 401 && error.response.data._error.message === 'user not found') {

                        _refreshToken(
                            this.props.client_id,
                            this.props.LOGIN,
                            this.props.PASSWORD,
                            this.props.changeAccessToken,
                            this.getImageApi.bind(this)
                        )
                    }
                }
            )
    }
    componentWillUnmount() {
        this.props.changeStateCreateServicePhoto(undefined)
    }
    render() {
        return (
            <div className="account flex service">
                <div className="col-lg3 flex_column_full">
                    <div className="account_photo">
                        <img className="account_photo_img" src={this.props.state_services_photo === undefined ? empty_avatar : this.props.state_services_photo.imagePreviewUrl} alt="Фото" onClick={() => this.props.state_services_photo !== undefined ? this.props.changeImgOpen(!this.props.imgOpen, this.props.state_services_photo.imagePreviewUrl) : alert("Нет фото")}></img>
                    </div>
                    <div className="account_back_button">
                        <a className="account_back_button_a flex center text_white" onClick={() => this.props.changeStateWindowServices(!this.props.stateWindowServices.servicesOpen)}>к списку</a>
                    </div>
                </div>
                <div className="col-lg6 flex_column_full">
                    {this.props.stateDataForOpenItem !== undefined ?
                        <div className="">
                            <span className="label">Название</span>
                            <p className="text_white max-w-250px user-select-text margin-top10">{this.props.stateDataForOpenItem.data_item.title}</p>
                        </div> : null}
                    {this.props.stateDataForOpenItem !== undefined ?
                        <div className="margin-top20">
                            <span className="label">Категория</span>
                            <p className="text_white max-w-250px user-select-text margin-top10">{this.props.stateDataForOpenItem.data_item_category.title}</p>
                        </div> : null}
                    {this.props.state_services_phone !== undefined ?
                        <div className="margin-top20">
                            <span className="label">Телефон</span>
                            <p className="text_white max-w-250px user-select-text margin-top10">{this.props.state_services_phone}</p>
                        </div> : null}
                    {this.props.state_services_email !== undefined ?
                        <div className="margin-top20">
                            <span className="label">E-mail</span>
                            <p className="text_white max-w-250px user-select-text margin-top10">{this.props.state_services_email}</p>
                        </div> : null}
                    {this.props.stateDataForOpenItem !== undefined ?
                        <div className="margin-top20">
                            <span className="label">Услуги</span>
                            <p className="text_white services-description user-select-text margin-top10">{this.props.stateDataForOpenItem.data_item.description}</p>
                        </div> : null}
                    {this.props.state_services_order !== undefined ?
                        <div className="margin-top20">
                            <span className="label">Заказ от</span>
                            <p className="text_white max-w-250px user-select-text margin-top10">{this.props.state_services_order}</p>
                        </div> : null}
                    {this.props.state_services_delivery !== undefined ?
                        <div className="margin-top20">
                            <span className="label">Доставка</span>
                            <p className="text_white max-w-250px user-select-text margin-top10">{this.props.state_services_delivery}</p>
                        </div> : null}
                    {this.props.state_services_pay !== undefined ?
                        <div className="margin-top20">
                            <span className="label">Оплата</span>
                            <p className="text_white max-w-250px user-select-text margin-top10">{this.props.state_services_pay}</p>
                        </div> : null}
                </div>
            </div>
        );
    };
}

export default connect(dispatch.mapStateToProps, dispatch.mapDispatchToProps)(Service);