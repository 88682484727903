import React from 'react';

import DefaultInput from '../../../../../../../DumbComponents/DefaultInput';

// import './styles.css';

export default props => {
  const path = ['rooms', props.roomIndex, 'devicesEndpoints', 'light', props.itemIndex];

  return (
    <React.Fragment>

      <div className='params-item__field'>
        <span className='params-field__title'>Топик:</span>
        <DefaultInput
          name='topic_value'
          value={props.item.topic_value}
          onChange={props.handlerChange(...path)}
          placeholder="topic_value"
          required={!props.item.topic_value}
        />
      </div>
      <div className='params-item__field'>
        <span className='params-field__title'>Фидбэк:</span>
        <DefaultInput
          name='topic_value_fb'
          value={props.item.feedbacks.topic_value_fb}
          onChange={props.handlerChange(...path, 'feedbacks')}
          placeholder="topic_value_fb"
          required={!props.item.feedbacks.topic_value_fb}
        />
      </div>
      <div className='params-item__field'>
        <span className='params-field__title'>Макс. значение диммера:</span>
        <DefaultInput
          name='dimmer_max_value'
          value={props.item.dimmer_max_value}
          onChange={props.handlerChange(...path)}
          placeholder="dimmer_max_value"
          required={!props.item.dimmer_max_value}
        />
      </div>
    </React.Fragment>
  );
}