import React, { Component } from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2'
import './OpenGraphic.css';

class openGraphic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartData: {
                labels: ["Да", "Нет", "Не знаю", "Воздержусь"],
                datasets: [
                    {
                        label: 'Ответы',
                        data: [
                            13,
                            19,
                            6,
                            5
                        ],
                        backgroundColor: [
                            'rgba(153, 102, 255, 0.6)',
                            'rgba(255, 99, 132, 0.6)',
                            'rgba(54, 162, 235, 0.6)',
                            'rgba(75, 192, 192, 0.6)',
                            'rgba(255, 206, 86, 0.6)'
                        ]
                    }
                ]
            }
        }
    }
    componentWillMount() {
        let labelsArray = [];
        let datasetsArray = [];
        for (let i = 0; i < this.props.chartData.length; i++) {

            labelsArray.push(this.props.chartData[i].title);
            datasetsArray.push(this.props.chartData[i].quantity);

            if (i + 1 === this.props.chartData.length) {
                // console.log(labelsArray);
                // console.log(datasetsArray);
                this.setState({
                    chartData: { ...this.state.chartData, labels: labelsArray ,  datasets: [{
                        label: 'Ответы',
                        data: datasetsArray,
                        backgroundColor: [
                            'rgba(153, 102, 255, 0.6)',
                            'rgba(255, 99, 132, 0.6)',
                            'rgba(54, 162, 235, 0.6)',
                            'rgba(75, 192, 192, 0.6)',
                            'rgba(255, 206, 86, 0.6)'
                        ]
                    }]},
               
                })

            }
        }
    }
    render() {
        // console.log('fffffffffffffssssssssssssssssssssssssssssssssssssssssss');
        // console.log(this.state.chartData);
        // console.log(this.state.chartData.labels);
        // console.log(this.state.chartData.datasets[0].data);



        return (
            <div className="graphic" onClick={() => this.props.changeChartDataGraphicOpen(false)}>
                {/* <Pie
                    data={this.props.chartData}
                    width={100}
                    height={50}
                    options={{ maintainAspectRatio: false }}
                /> */}

                <Pie
                    data={this.state.chartData}
                    options={{
                        title: {
                            display: true,
                            fontSize: 25
                        },
                        legend: {
                            display: true,
                            labels: {
                                fontColor: '#ffffff'
                            }
                        }
                    }}
                />
            </div>




        );
    };
}

export default openGraphic;