import React from 'react';

import DefaultInput from '../../../../../../DumbComponents/DefaultInput';

import './styles.css';

export default props => {
  const path = ['rooms', props.roomIndex, 'devicesEndpoints', 'security_sensors', props.itemIndex];

  return (
    <div className='params__item params__item_security-sensors'>
      <div className='params-item__field'>
        <span className='params-field__title'>Название:</span>
        <DefaultInput
          name='title'
          value={props.item.title}
          onChange={props.handlerChange(...path)}
          placeholder='title'
          required={!props.item.title}
        />
      </div>

      <div className='params-item__field'>
        <span className='params-field__title'>Датчик</span>
        <select
          value={
            (() => {return props.item.topic_type[0]})()
          }
          onChange={(e) => props.handlerChange(...path, 'topic_type')(null, 0, e.target.value)}
          required={!props.item.topic_type[0]}
        >
          <option value=''>Выберите датчик</option>
          <option value='door'>Дверь</option>
          <option value='window'>Окно</option>
          <option value='leakage'>Протечки</option>
        </select>
      </div>

      <div className='params-item__field'>
        <span className='params-field__title'>Фидбэк</span>
        <DefaultInput
          name='topic_feedback'
          value={props.item.topic_feedback}
          onChange={props.handlerChange(...path)}
          placeholder='topic_feedback'
          required={!props.item.topic_feedback}
        />
      </div>
    </div>
  );
}