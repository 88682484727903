import React from 'react';

import './styles.css';

export default props => {
  const backArrow = (<svg
      width="9px"
      height="16px">
      <path
        fillRule="evenodd"
        fill="rgb(255, 255, 255)"
        d="M7.986,0.155 C8.102,0.051 8.247,-0.000 8.407,-0.000 C8.566,-0.000 8.711,0.051 8.827,0.155 C9.058,0.362 9.058,0.698 8.827,0.905 L1.438,7.501 L8.827,14.096 C9.058,14.303 9.058,14.640 8.827,14.846 C8.595,15.053 8.218,15.053 7.986,14.846 L0.178,7.876 C-0.054,7.669 -0.054,7.333 0.178,7.126 L7.986,0.155 Z"
      />
    </svg>);
  const buttonIcon = (<svg
      width="20px"
      height="20px">
      <path
        fillRule="evenodd"
        fill="rgb(255, 255, 255)"
        d="M10.000,20.000 C4.486,20.000 -0.000,15.514 -0.000,10.000 C-0.000,4.486 4.486,-0.000 10.000,-0.000 C15.514,-0.000 20.000,4.486 20.000,10.000 C20.000,15.514 15.514,20.000 10.000,20.000 ZM10.000,0.769 C4.910,0.769 0.769,4.910 0.769,10.000 C0.769,15.090 4.910,19.231 10.000,19.231 C15.090,19.231 19.231,15.090 19.231,10.000 C19.231,4.910 15.090,0.769 10.000,0.769 ZM14.808,10.385 L10.385,10.385 L10.385,15.000 C10.385,15.213 10.212,15.385 10.000,15.385 C9.788,15.385 9.615,15.213 9.615,15.000 L9.615,10.385 L5.192,10.385 C4.980,10.385 4.808,10.213 4.808,10.000 C4.808,9.787 4.980,9.615 5.192,9.615 L9.615,9.615 L9.615,5.385 C9.615,5.172 9.788,5.000 10.000,5.000 C10.212,5.000 10.385,5.172 10.385,5.385 L10.385,9.615 L14.808,9.615 C15.020,9.615 15.192,9.787 15.192,10.000 C15.192,10.213 15.020,10.385 14.808,10.385 Z"
      />
    </svg>);

  return(
    <div className="aparts__header">
      <div className="aparts-header__icon">
        {props.ulData.find(item => item.name === 'аparts').svg}
      </div>
      <div className="aparts-header__arrow-back" onClick={() => {
        props.editApart === undefined ? props.handlerReturnToHome() :
          props.handlerReturnFromEdit();
      }}>
        {backArrow}
      </div>
      <div className="aparts-header__button" onClick={() => {
        props.editApart === undefined ? props.handlerAddApart(null) :
          props.handlerReturnFromEdit();
      }}>
        <span className="button__container">{props.editApart === undefined ? buttonIcon : ''}<a className="button__title">{props.editApart === undefined ? "Добавить апартамент" : "Скрыть меню"}</a></span>
      </div>
    </div>
  );
}