import React, { Component } from 'react';

import { connect } from 'react-redux';
import { _refreshToken } from '../../Refresh/Refresh';

import Services_item from '../Services_item/Services_item.js';

import api from '../../../../api';

import dispath from '../../../../store/dispatch.js';
import PreLoader from '../../PreLoader/PreLoader.js';

import './Services_main.css';

class Services_main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoad: false,
            where: undefined,
            search: false
        };
    }


    getOnlineServicesApi = async () => {
        await api.getOnlineServices(this.props.access_token, this.props.LOGIN_TYPE_USER, 1, this.state.where, this.state.search)
            .then(
                (req) => {
                    // console.log(req);

                    api.getOnlineAllCategoryServices(this.props.access_token, this.props.LOGIN_TYPE_USER)
                        .then(
                            (req2) => {
                                // this.props.changeOnlineServicesData(req.data._items);
                                this.props.changeStateEditServicesId(req.data._items._id);
                                this.props.changeStateEditServicesEtag(req.data._items._etag);
                                this.props.changeStateEditServicesTitle(req.data._items.title);
                                this.props.changeStateCreateServicesCategoryId(req.data._items.category_id);
                                this.props.changeStateCreateServicesDescription(req.data._items.description);
                                this.props.changeStateCreateServicesShortinfo(req.data._items.shortinfo);
                                this.props.changeStateCreateServicesPhone(req.data._items.phone);
                                this.props.changeStateCreateServicesLinkCite(req.data._items.link_url);
                                this.props.changeStateCreateServicesEmail(req.data._items.email);
                                this.props.changeStateCreateServicesOrder(req.data._items.order);
                                this.props.changeStateCreateServicesDelivery(req.data._items.delivery);
                                this.props.changeStateCreateServicesPay(req.data._items.pay);

                                // console.log('changeOnlineAllServicesCategoryData', req2);
                                // console.log(req2.data);


                                this.props.changeOnlineAllServicesCategoryData(req2.data);

                                let items = req.data._items;
                                let categories = req2.data._items;
                                if (items.length === 0) {
                                    this.props.changeOnlineServicesData([]);
                                    this.setState({
                                        isLoad: true
                                    });
                                    return
                                }
                                items.forEach((item, i) => {
                                    categories.forEach((category) => {
                                        // console.log('******************* i', i, items.length);

                                        if (item.category_id === category._id) {
                                            item.category_name = category.title;
                                            // console.log(item);
                                        }
                                    });
                                    if (i === items.length - 1) {
                                        // console.log('******************* i === item.length', i === items.length-1);
                                        this.props.changeOnlineServicesData(items);
                                        this.setState({
                                            isLoad: true
                                        });
                                    }
                                });



                            }
                        )
                        .catch(
                            (err) => {
                                console.log(err);
                                console.log(err.response);
                                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                                    _refreshToken(
                                        this.props.client_id,
                                        this.props.LOGIN,
                                        this.props.PASSWORD,
                                        this.props.changeAccessToken,
                                        this.getOnlineServicesApi.bind(this)
                                    )
                                }
                            }
                        )
                }
            )
            .catch(
                (err) => {
                    console.log(err.response);
                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                        _refreshToken(
                            this.props.client_id,
                            this.props.LOGIN,
                            this.props.PASSWORD,
                            this.props.changeAccessToken,
                            this.getOnlineServicesApi.bind(this)
                        )
                    }
                }
            )
    }
    // getThisCategoryServices = async (categories) => {
    //     // console.log(this.props.onlineServicesData);
    //     // console.log(this.props.onlineServicesCategoryData._items);
    //     let items = this.props.onlineServicesData;
    //     // let categories = this.props.onlineServicesCategoryData._items;


    //     await items.forEach((item) => {
    //         categories.forEach((category) => {
    //             if (item.category_id === category._id) {
    //                 item.category_name = category.title;
    //                 // console.log(item);
    //             }
    //         });
    //     });
    //     await this.props.changeOnlineServicesData(items);


    // }
    onScrollList(event) {
        const scrollBottom = event.target.scrollTop +
            event.target.offsetHeight == event.target.scrollHeight;

        if (scrollBottom) {
            this.loadContent(); //API method
        }
    }
    changeStateSearchServices = async (text) => {

        await this.setState({
            where: text,
            search: true
        })
        // console.log(this.state.where);
        this.props.changeStateSearchServices(text);
        this.getOnlineServicesApi();
    }
    loadContent() {
        api.getOnlineServices(this.props.access_token, this.props.LOGIN, this.props.PASSWORD, 1)
            .then(
                (response) => {
                    // console.log(this.props.state_services_page);

                    if (this.props.state_services_page === Math.ceil(response.data._meta.total / 25)) {
                        return;
                    } else if (response.data._meta.total > 25 && this.props.state_services_page !== Math.ceil(response.data._meta.total / 25)) {

                        this.props.changeStateServicesPage(this.props.state_services_page + 1);
                        api.getServices(this.props.access_token, this.props.LOGIN_TYPE_USER, this.props.mainName, this.props.state_services_page)
                            .then(
                                (res) => {
                                    let items = req.data._items;
                                    let categories = req2.data._items;

                                    items.forEach((item, i) => {
                                        categories.forEach((category) => {
                                            // console.log('******************* i', i, items.length);

                                            if (item.category_id === category._id) {
                                                item.category_name = category.title;
                                                // console.log(item);
                                            }
                                        });
                                        if (i === items.length - 1) {
                                            // console.log('******************* i === item.length', i === items.length-1);
                                            this.props.changeOnlineServicesData(this.props.onlineServicesData.concat(items));

                                            this.setState({
                                                isLoad: true
                                            });
                                        }
                                    });

                                }
                            )
                            .catch((err) => {
                                console.log(err);
                                console.log(err.response);

                                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                    _refreshToken(
                                        this.props.client_id,
                                        this.props.LOGIN,
                                        this.props.PASSWORD,
                                        this.props.changeAccessToken,
                                        this.loadContent.bind(this)
                                    )

                                }
                            });
                    }
                    // this.props.changeOnlineServicesData(req.data._items);
                    // this.getThisCategoryServices();
                    // this.props.changeStateEditServicesId(req.data._items._id);
                    // this.props.changeStateEditServicesEtag(req.data._items._etag);
                    // this.props.changeStateEditServicesTitle(req.data._items.title);
                    // this.props.changeStateCreateServicesCategoryId(req.data._items.category_id);
                    // this.props.changeStateCreateServicesDescription(req.data._items.description);
                    // this.props.changeStateCreateServicesShortinfo(req.data._items.shortinfo);
                    // this.props.changeStateCreateServicesPhone(req.data._items.phone);
                    // this.props.changeStateCreateServicesLinkCite(req.data._items.link_url);
                    // this.props.changeStateCreateServicesEmail(req.data._items.email);
                    // this.props.changeStateCreateServicesOrder(req.data._items.order);
                    // this.props.changeStateCreateServicesDelivery(req.data._items.delivery);
                    // this.props.changeStateCreateServicesPay(req.data._items.pay);

                }
            )
            .catch(
                (err) => {
                    console.log(err);
                    console.log(err.response);
                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                        _refreshToken(
                            this.props.client_id,
                            this.props.LOGIN,
                            this.props.PASSWORD,
                            this.props.changeAccessToken,
                            this.loadContent.bind(this)
                        )
                    }
                }
            )

    }

    render() {
        if (!this.state.isLoad) {
            return (
                <PreLoader onLoad={this.getOnlineServicesApi.bind(this)} />
            )
        }
        return (
            <div className="accounts_main">
                <div className="accounts_main_search_container">
                    <input
                        className="input_user_edit w50"
                        value={this.props.state_search_services === undefined ? "" : this.props.state_search_services}
                        type="text"
                        placeholder="Поиск по сервисам"
                        onChange={(event) => {
                            this.changeStateSearchServices(event.target.value);
                        }}

                    />
                </div>
                <div className="accounts_main_titles">
                    <ul className="accounts_main_titles_ul flex label">
                        <li className="accounts_main_titles_li w100">Название</li>
                        <li className="accounts_main_titles_li w100">Категория</li>
                        <li className="accounts_main_titles_li w100">Телефон</li>
                        <li className="accounts_main_titles_li w50"></li>
                    </ul>
                </div>
                <div
                    className="scroll h90"
                    onScroll={event => this.onScrollList(event)}
                >
                    {
                        this.props.onlineServicesData !== undefined
                            ?
                            this.props.onlineServicesData.map(
                                data_items => <Services_item key={data_items.category_id + data_items._id} data_items={data_items} />
                            )

                            :
                            null
                    }
                </div>
            </div>
        );
    };
};

export default connect(dispath.mapStateToProps, dispath.mapDispatchToProps)(Services_main);