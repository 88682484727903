import React, { Component } from 'react';

import { connect } from 'react-redux';

import api from '../../api';
import cookieAction from '../../cookieAction';
import dispath from '../../store/dispatch.js';

import './Login.css';

import loginbackground from '../App/img/login-background.jpg';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginButtonBlock: false
        }
    }

    // componentWillMount() {

    //     if (cookieAction.getCookie("AT") !== undefined) { //&& cookieAction.getCookie("AT") !== 'None'
    //         console.log('cookieAction.getCookie("AT"): ', cookieAction.getCookie("AT"));
    //         this.props.changeStatusLogin(true);
    //         this.props.changeAccessToken(cookieAction.getCookie("AT"));
    //         this.props.changeAccountId(cookieAction.getCookie("AI"));
    //         this.props.changeClientId(cookieAction.getCookie("CI"));
    //         this.props.changeExpires(cookieAction.getCookie("E"));
    //         this.props.changeRefreshToken(cookieAction.getCookie("RT"));
    //         this.props.changeUserName(cookieAction.getCookie("UN"));
    //         this.props.changeUserId(cookieAction.getCookie("UI"));
    //         return
    //     } else {
    //         this.props.changeStatusLogin(false);
    //         return;
    //     }
    // }

    getUserData(account_id, access_token) {
        api.getAccount(account_id, access_token, this.props.LOGIN_TYPE_USER)
            .then(
                req => {
                    // console.log(req.data);
                    // console.log(req);

                    this.props.changeUserData(req.data);
                    cookieAction.setCookie("ROLE", req.data.role[0]);
                    this.props.changeLoginTypeUser(req.data.role[0]);
                }
            )
            .catch(
                err => {
                    console.log(err.response);
                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                        api.refreshToken(this.props.client_id, this.props.LOGIN, this.props.PASSWORD)
                            .then((res) => {
                                this.props.changeAccessToken(res.data.access_token);
                                api.getAccount(res.data.account_id, res.data.access_token).then(
                                    req => {
                                        // console.log(req.data);
                                        // console.log(req);

                                        this.props.changeUserData(req.data);
                                        cookieAction.setCookie("ROLE", req.data.role[0]);
                                        this.props.changeLoginTypeUser(req.data.role[0]);
                                    }
                                );
                                return;
                            })
                            .catch(
                                (err) => {
                                    console.log(err);
                                    console.log(err.response);
                                    
                                }
                            )
                    }
                 }
            )
    }

    logIn() {
        this.setState({
            loginButtonBlock: true
        })
        // Пример генерации токенов с использованием refresh токена:
        // $ curl -X POST -d "client_id=111&grant_type=refresh_token&refresh_token=YYYyyyYYYyyyYYY" http://localhost:5000/oauth/token
        if (this.props.LOGIN === undefined) {
            this.props.changeStateDangerInputLogin(true);
        } if (this.props.PASSWORD === undefined) {
            this.props.changeStateDangerInputPassword(true);
        } else {
            cookieAction.setCookie("U", this.props.LOGIN);
            cookieAction.setCookie("W", this.props.PASSWORD);
            api.login(this.props.LOGIN, this.props.PASSWORD)
                .then(
                    (req) => {
                        console.log('====================================')
                        console.log('req.data', req.data)
                        console.log('====================================')
                        this.setState({
                            loginButtonBlock: false
                        })

                        this.props.changeClientId(req.data.client_id);
                        this.props.changeAccessToken(req.data.access_token);
                        this.props.changeRefreshToken(req.data.refresh_token);
                        if (req.data.token_active === "False") {
                            // console.log(req.data);

                            api.refreshToken(req.data.client_id, this.props.LOGIN, this.props.PASSWORD)
                                .then((req2) => {

                                    this.props.changeAccessToken(req2.data.access_token);
                                    this.logIn();
                                    return
                                })
                                .catch(
                                    (err) => {
                                        console.log(err);
                                        console.log(err.response);
                                
                                    }
                                )
                        }
                        else {
                            // console.log('access_token: ', req.data.access_token);

                            this.getUserData(req.data.account_id, req.data.access_token);


                            const cookies = [
                                {
                                    name: "AT", //access_token
                                    value: req.data.access_token
                                },
                                {
                                    name: "AI", //account_id
                                    value: req.data.account_id
                                },
                                {
                                    name: "CI", //client_id
                                    value: req.data.client_id
                                },
                                {
                                    name: "E", //expires
                                    value: req.data.expires
                                },
                                {
                                    name: "RT", //refresh_token
                                    value: req.data.refresh_token
                                },
                                {
                                    name: "UN", //username
                                    value: req.data.username
                                },
                                {
                                    name: "UI", //user_id
                                    value: req.data.user_id
                                }
                            ]
                            const date = new Date;
                            date.setDate(date.getDate() + 30);
                            const optionsCookie = {
                                expires: date.toUTCString()
                            }
                            cookies.map(itemCookie => {
                                cookieAction.setCookie(itemCookie.name, itemCookie.value, optionsCookie)
                            });
                            // console.log(optionsCookie);
                            
                            this.props.changeStatusLogin(true);
                        }

                    }
                )
                .catch(
                    (err) => {
                        this.setState({
                            loginButtonBlock: false
                        })
                        // console.log(err.name);
                        console.log(err);
                        console.log(err.response.status);
                        if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                            api.refreshToken(this.props.client_id, this.props.LOGIN, this.props.PASSWORD)
                                .then((req) => {
                                    this.props.changeAccessToken(req.data.access_token);
                                    this.logIn();
                                    return
                                })
                                .catch(
                                    (err) => {
                                        console.log(err);
                                        console.log(err.response);
                                    }
                                )
                        }
                        switch (err.response.status) {
                            case 401:
                                alert('Данные не верны!')
                                break;
                            case 500:
                                alert('Внутренняя ошибка сервера!')
                                break;
                            default:
                                break;
                        }
                    }
                )
        }
    }
    render() {
        return (
            <div className="login flex center" style={{ "background": `url(${loginbackground}) 100% 100% no-repeat` }}>
                <div className="login-widget backcolor">
                    <div className="widget-box">
                        <h1 className="widget-name">Добро пожаловать!</h1>
                        <input
                            className="login-input input"
                            value={this.props.LOGIN === undefined ? "" : this.props.LOGIN}
                            type="text"
                            placeholder="имя пользователя"
                            onChange={(event) => {
                                this.props.changeLogin(event.target.value);
                                this.props.changeStateDangerInputLogin(false);
                            }}
                            onBlur={(event) => {
                                this.props.changeLogin(event.target.value);
                                this.props.changeStateDangerInputLogin(false);
                            }}
                        />
                        <input
                            className="login-input input"
                            value={this.props.PASSWORD === undefined ? "" : this.props.PASSWORD}
                            type="password"
                            placeholder="пароль"
                            onChange={(event) => {
                                this.props.changePassword(event.target.value);
                                this.props.changeStateDangerInputPassword(false);
                            }}
                            onBlur={(event) => {
                                this.props.changePassword(event.target.value);
                                this.props.changeStateDangerInputPassword(false);
                            }}
                        />
                        <div className="button-login-box">
                            <button className="w100 button_cancel transparent flex center text_white button-login" disabled={this.state.loginButtonBlock} onClick={() => this.logIn()}>Вход</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

export default connect(dispath.mapStateToProps, dispath.mapDispatchToProps)(Login);