import React, { Component } from 'react';

import './App_right-column_cards_container.css';

import App_right_column_card from '../App_right-column_card/App_right-column_card.js';

class App_right_column_cards_container extends Component {
    render() {
        const dataCard = [
            {
                time: "11:01",
                address: "Подъезд 3, кв. 145",
                text: "Камера 3 неисправна. Проверьте, пожалуйста.",
                id: 1,
                status: 2, //выполнено
                first: true
            },
            {
                time: "12:10",
                address: "Подъезд 4, кв. 220",
                text: "Течёт кран, срочно нужен сантехник.",
                id: 2,
                status: 1, //выполняется
                first: false
            },
            {
                time: "14:22",
                address: "Подъезд 1, кв. 10",
                text: "Необходимо оформить пропуск на парковку.",
                id: 3,
                status: 0, //к выполнению
                first: false
            }
        ]
        // console.log(dataCard);
        
        return (
            <div className="app_right_column_cards_container">
                <div className="app_right_column_card_date header_box_p">22.03.19</div>
                { dataCard.map( data => <App_right_column_card first={data.first} status={data.status} time={data.time} address={data.address} text={data.text} key={data.id}/> ).reverse() }
            </div>
        );
    };
}

export default App_right_column_cards_container;