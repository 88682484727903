import React, { useState } from 'react';

import './styles.css';

export default props => {
  const [ displayedJSON, switchDisplayedJSON ] = useState(false);

  delete props.data.deletedItems;

  return (
    <div className='apart-configure__json'>
      {
        !displayedJSON ?
          <div className='json__button json__button_open' onClick={() => switchDisplayedJSON(true)} >Посмотреть JSON</div> :
          <React.Fragment>
            <textarea className='json__textarea' value={JSON.stringify(props.data, null, '\t')} disabled />
            <div className='json__button json__button_close' onClick={() => switchDisplayedJSON(false)} >Закрыть JSON</div>
          </React.Fragment>
      }
    </div>
  );
}