import React, { Component } from 'react';

import './App_right-column_card.css';

class App_right_column_card extends Component {

    render() {
        const svg = [
            {
                toFulfill:
                    <svg
                        width="23px"
                        height="23px"
                    >
                        <path
                            stroke="rgb(219, 55, 53)"
                            fill="rgb(15, 16, 18)"
                            strokeWidth="2px"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            d="M11.500,6.500 C14.261,6.500 16.500,8.739 16.500,11.500 C16.500,14.261 14.261,16.500 11.500,16.500 C8.739,16.500 6.500,14.261 6.500,11.500 C6.500,8.739 8.739,6.500 11.500,6.500 Z"
                        />
                    </svg>
            },
            {
                performed:
                    <svg
                        width="23px"
                        height="23px"
                    >
                        <path
                            fillRule="evenodd"
                            fill="rgb(219, 55, 53)"
                            d="M11.000,6.000 C13.761,6.000 16.000,8.239 16.000,11.000 C16.000,13.761 13.761,16.000 11.000,16.000 C8.239,16.000 6.000,13.761 6.000,11.000 C6.000,8.239 8.239,6.000 11.000,6.000 Z"
                        />
                    </svg>
            },

            {
                done:
                    <svg
                        width="23px"
                        height="23px">
                        <path fillRule="evenodd"
                            opacity="0.141"
                            fill="rgb(218, 58, 55)"
                            d="M11.000,6.000 C13.761,6.000 16.000,8.239 16.000,11.000 C16.000,13.761 13.761,16.000 11.000,16.000 C8.239,16.000 6.000,13.761 6.000,11.000 C6.000,8.239 8.239,6.000 11.000,6.000 Z"
                        />
                    </svg>
            }
        ]

        return (
            <div className="app_right_column_card" style={ this.props.first === true ? { borderLeft: 0+'px' }: { borderLeft: 1+"px solid #707070"  }}>
                <div className="app_right_column_card_header"  >
                    <div className="app_right_column_card_svg_box">
                        {this.props.status === 0 ? svg[2].done : this.props.status === 1 ? svg[1].performed : svg[0].toFulfill }
                    </div>
                    <div className="card_header_box">
                        <p className="header_box_p time">
                            {this.props.time}
                        </p>
                        <p className="header_box_p address">
                            {this.props.address}
                        </p>
                    </div>
                    <div className="card_text_box">
                        <p className="header_box_p text">
                            {this.props.text}
                        </p>
                    </div>
                </div>
            </div>
        );
    };
}

export default App_right_column_card;