import React, { Component } from 'react';

import api from '../../../../api';

import { connect } from 'react-redux';
import { _refreshToken } from '../../Refresh/Refresh';
import dispatch from '../../../../store/dispatch.js';
import imageCompression from 'browser-image-compression';

import './Account_Add.css';

import empty_avatar from './empty_avatar.jpg';

class Account_Add extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            newPhoto: undefined,
            saveNewPhoto: undefined,
            user_id: undefined,
            sendPasswordToAdmin: false,
            isLoad: false
        }
    }
    componentWillMount() {
        if (this.props.user_photo_for_save !== undefined) {
            this.getImageApi();
        }
    }

    getImageApi = () => {
        // console.log(this.props.user_photo_for_save);
        api.getImage(this.props.user_photo_for_save, this.props.access_token)
            .then(request => {
                // console.log(request);

                let img_file = `data:${request.data.image.content_type};base64, ${request.data.image.file}`
                // console.log(img_file);

                this.props.changeStateCreateUserPhoto({ imagePreviewUrl: img_file });
            })
            .catch(
                error => {
                    console.log(error);
                    if (error.response.status === 401 && error.response.data._error.message === 'token expired' || error.response.status === 401 && error.response.data._error.message === 'user not found') {

                        _refreshToken(
                            this.props.client_id,
                            this.props.LOGIN,
                            this.props.PASSWORD,
                            this.props.changeAccessToken,
                            this.getImageApi.bind(this)
                        )
                    }
                }
            )
    }


    backTo(value) {
        switch (value) {

            case "apart":
                if (this.props.stateWindowOpenNewOperator === true) {
                    this.props.changeMain("lk");
                    this.props.changeStateWindowAccounts(false);
                    this.props.changeStateWindowAccountEdit(false);
                } else {
                    if (this.props.stateWindowAccounts.accountOpen === true && this.props.stateWindowAccounts.accountEdit === true) {
                        this.props.changeStateWindowAccounts(!this.props.stateWindowAccounts.accountOpen);
                        this.props.changeStateWindowAccountEdit(!this.props.stateWindowAccounts.accountEdit);
                        return;
                    } else if (this.props.stateWindowAccounts.accountOpen === true) {
                        this.props.changeStateWindowAccounts(!this.props.stateWindowAccounts.accountOpen)
                        return;
                    } else if (this.props.stateWindowAccounts.accountEdit === true) {
                        this.props.changeStateWindowAccountEdit(!this.props.stateWindowAccounts.accountEdit);
                        return;
                    } else {
                        this.props.changeMain("home");
                    }
                    break;
                }
            default:
                break;
        }
    }

    /*     _handleImgPhotoUserChange(e) {
            e.preventDefault();
    
            let reader = new FileReader();
            let file = e.target.files[0];
            if (file !== undefined) {
                reader.onloadend = () => {
                    console.log(file);
                    this.props.changeStateCreateUserPhoto({
                        file: file,
                        imagePreviewUrl: reader.result
                    });
                    // this.props.changeUserImg(reader.result);
                }
      
                reader.readAsDataURL(file)
            }
        } */
    async _handleImgPhotoUserChange(e) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        if (file !== undefined) {
            // you should provide one of maxSizeMB, maxWidthOrHeight in the options
            const options = {
                maxSizeMB: 0.4,
                maxWidthOrHeight: 1024,
                useWebWorker: true
            }
            try {
                const compressedFile = await imageCompression(file, options);
                // console.log('File', file);
                // console.log(`compressedFile`, compressedFile);

                await reader.readAsDataURL(compressedFile);
                this.props.changeStateCreateUserPhoto({
                    file: compressedFile
                });
                this.setState({
                    newPhoto: compressedFile,
                    user_id: this.props.user_id
                })
            } catch (error) {
                console.log(error);
            }
            reader.onloadend = () => {
                this.props.changeStateCreateUserPhoto({
                    imagePreviewUrl: reader.result
                });

            }


        }

    }
    _handleImgPassportChange(e) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.props.changeStateCreateUserImgPassport({
                file: file,
                imagePreviewUrl: reader.result
            })
        }

        reader.readAsDataURL(file)
    }
    saveOnServerIf() {
        this.props.changeStateCreateUserTypeUser("user");
        if (this.state.newPhoto !== undefined) {
            // console.log(this.state.newPhoto);
            const Newdata = new FormData();
            Newdata.append("image", this.state.newPhoto);
            Newdata.append("owner_id", this.state.user_id);
            console.log('====================================')
            console.log('Newdata', Newdata)
            console.log('====================================')
            api.createImageId(Newdata, this.props.access_token, this.props.LOGIN_TYPE_USER)
                .then(
                    req => {
                        // console.log(req);
                        // console.log(req.data._id);

                        this.props.changeUserPhotoForSave(req.data._id);
                        this.setState({
                            saveNewPhoto: req.data._id
                        })
                        this.saveOnServer();

                    })
                .catch(
                    err => {
                        console.log(err);
                        console.log(err.response);
                        if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                            _refreshToken(
                                this.props.client_id,
                                this.props.LOGIN,
                                this.props.PASSWORD,
                                this.props.changeAccessToken,
                                this.saveOnServerIf.bind(this)
                            )
                        }
                    }
                )
        } else {
            
            this.setState({
                saveNewPhoto: this.props.user_photo_for_save
            })
            this.saveOnServer();
        }
    }
    saveOnServer() {
        if (this.props.state_create_user_firstName === "" || this.props.state_create_user_firstName === null || this.props.state_create_user_firstName === undefined) {
            this.props.changeStateDangerInputCreateUserFirstName(true);
        } if (this.props.state_create_user_secondName === "" || this.props.state_create_user_secondName === null || this.props.state_create_user_secondName === undefined) {
            this.props.changeStateDangerInputCreateUserSecondName(true);
        } if (this.props.state_create_user_thirdName === "" || this.props.state_create_user_thirdName === null, this.props.state_create_user_thirdName === undefined) {
            this.props.changeStateDangerInputCreateUserThirdName(true);
        } if (this.props.state_create_user_tel === "" || this.props.state_create_user_tel === null, this.props.state_create_user_tel === undefined) {
            this.props.changeStateDangerInputCreateUserTel(true);
        } if (this.props.state_create_user_email === "" || this.props.state_create_user_email === null || this.props.state_create_user_email === undefined) {
            this.props.changeStateDangerInputCreateUserUserEmail(true);
        } if (this.props.state_create_user_numApart === "" || this.props.state_create_user_numApart === null || this.props.state_create_user_numApart === undefined) {
            this.props.changeStateDangerInputCreateUserNumApart(true);
        } if (this.props.state_create_user_sizeApart === "" || this.props.state_create_user_sizeApart === null || this.props.state_create_user_sizeApart === undefined) {
            this.props.changeStateDangerInputCreateUserSizeApart(true);
        } if (this.props.state_create_user_typeUser === "" || this.props.state_create_user_typeUser === null || this.props.state_create_user_typeUser === undefined) {
            this.props.changeStateDangerInputCreateUserTypeUser(true);
            console.log('***********');

        } if (this.props.state_create_user_firstName !== undefined &&
            this.props.state_create_user_secondName !== undefined &&
                this.props.state_create_user_thirdName !== undefined &&
                    this.props.state_create_user_tel !== undefined &&
                        this.props.state_create_user_email !== undefined &&
                            (this.props.state_create_user_numApart !== undefined || this.props.state_create_user_typeUser === "admin" || this.props.state_create_user_typeUser === "operator" || this.props.state_create_user_typeUser[0] === "admin" || this.props.state_create_user_typeUser[0] === "operator") &&
                                this.props.state_create_user_typeUser !== undefined) {

            api.getRealClientId(this.props.client_id, this.props.access_token, this.props.LOGIN_TYPE_USER)
                .then(
                    (req) => {
                        // console.log('====================================')
                        // console.log('********* req ', req)
                        // console.log('********* req.data._id ', req.data._id)
                        // console.log('====================================')
                        this.props.changeRealClientId(req.data._id);
                        

                        if (this.props.statusSendDataAccountOnServer === "create") {
                            const dataForCreateUser = {
                                username: this.props.state_create_user_email,
                                hashpw: undefined
                            }
                            api.createUser(this.props.LOGIN_TYPE_USER, this.props.access_token, this.state.sendPasswordToAdmin, dataForCreateUser) //typeuser, access_token, data
                                .then(
                                    (req) => {


                                        // console.log(req);
                                        const dataOnServerCreate = {
                                            user: req.data._id, //
                                            // client: this.props.real_client_id, //
                                            firstname: this.props.state_create_user_firstName,
                                            lastname: this.props.state_create_user_secondName,
                                            middlename: this.props.state_create_user_thirdName,
                                            phone: this.props.state_create_user_tel,
                                            email: this.props.state_create_user_email,
                                            image: this.state.saveNewPhoto,
                                            role: Array.isArray(this.props.state_create_user_typeUser) === true ? this.props.state_create_user_typeUser : [this.props.state_create_user_typeUser],
                                            // active: true,
                                            // multifactor: true,
                                            apartment_id: this.props.state_create_user_typeUser[0] === "operator" ||
                                                this.props.state_create_user_typeUser[0] === "admin" ||
                                                this.props.state_create_user_typeUser === "admin" ||
                                                this.props.state_create_user_typeUser === "operator" ? undefined : this.getApartmentId(this.props.state_create_user_numApart) //
                                        }
                                        // console.log('***************** create', dataOnServerCreate);

                                        api.createAccount(dataOnServerCreate, this.props.LOGIN_TYPE_USER, this.props.access_token)
                                            .then((response) => {
                                                api.getAccounts(this.props.access_token, this.props.LOGIN_TYPE_USER, this.props.mainName, 1)
                                                    .then((req) => {
                                                        this.props.changeAccountData(req.data._items);
                                                        this.props.changeStateAccountPage(1);
                                                        // console.log(req);
                                                    })
                                                    .catch(
                                                        (err) => {
                                                            console.log(err.response);
                                                            if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                                                _refreshToken(
                                                                    this.props.client_id,
                                                                    this.props.LOGIN,
                                                                    this.props.PASSWORD,
                                                                    this.props.changeAccessToken,
                                                                    this.saveOnServer.bind(this)
                                                                )

                                                            }
                                                        }
                                                    );
                                                api.getAccounts(this.props.access_token, this.props.LOGIN_TYPE_USER, "lk", 1)
                                                    .then(
                                                        req => {
                                                            this.props.changedDataOperators(req.data._items);
                                                            // console.log(req);
                                                        }
                                                    )
                                                    .catch(
                                                        err => {
                                                            console.log(err.response);
                                                            if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                                                                _refreshToken(
                                                                    this.props.client_id,
                                                                    this.props.LOGIN,
                                                                    this.props.PASSWORD,
                                                                    this.props.changeAccessToken,
                                                                    this.saveOnServer.bind(this)
                                                                )
                                                            }
                                                        }
                                                    )
                                                // console.log(response);
                                            })
                                            .catch((err) => {
                                                console.log(err.response);
                                                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                                    _refreshToken(
                                                        this.props.client_id,
                                                        this.props.LOGIN,
                                                        this.props.PASSWORD,
                                                        this.props.changeAccessToken,
                                                        this.saveOnServer.bind(this)
                                                    )
                                                }
                                            });
                                        // this.props.getInitialState();
                                        this.backTo(this.props.mainName);
                                        return;
                                    }
                                )
                                .catch(
                                    (err) => {
                                        console.log(err);
                                        console.log(err.response);
                                        console.log(err.response.status);
                                        if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                            _refreshToken(
                                                this.props.client_id,
                                                this.props.LOGIN,
                                                this.props.PASSWORD,
                                                this.props.changeAccessToken,
                                                this.saveOnServer.bind(this)
                                            )
                                        } else {


                                            switch (err.response.status) {
                                                case 401:
                                                    alert('Данные не верны!')
                                                    break;
                                                case 500:
                                                    alert('Внутренняя ошибка сервера!')
                                                    break;
                                                case 422:
                                                    alert('Такой e-mail уже используется!')
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }
                                    }
                                )

                        } else {

                            // console.log('this.props.saveNewPhoto', this.state.saveNewPhoto);
                            const dataOnServerEdit = {
                                // user: this.props.user_id, //
                                // client: this.props.real_client_id, //
                                firstname: this.props.state_create_user_firstName,
                                lastname: this.props.state_create_user_secondName,
                                middlename: this.props.state_create_user_thirdName,
                                phone: this.props.state_create_user_tel,
                                email: this.props.state_create_user_email,
                                image: this.state.saveNewPhoto,
                                role: Array.isArray(this.props.state_create_user_typeUser) === true ? this.props.state_create_user_typeUser : [this.props.state_create_user_typeUser],
                                // active: true,
                                // multifactor: true,
                                apartment_id: this.props.state_create_user_typeUser[0] === "operator" ||
                                    this.props.state_create_user_typeUser[0] === "admin" ||
                                    this.props.state_create_user_typeUser === "admin" ||
                                    this.props.state_create_user_typeUser === "operator" ? undefined : this.getApartmentId(this.props.state_create_user_numApart)

                            }
                            // console.log('edit', this.props.state_create_user_typeUser[0] === "operator" ||
                            //     this.props.state_create_user_typeUser[0] === "admin" ||
                            //     this.props.state_create_user_typeUser === "admin" ||
                            //     this.props.state_create_user_typeUser === "operator");
                            // console.log('edit', dataOnServerEdit);
                            api.getAccount(this.props.stateEditAccountId, this.props.access_token, this.props.LOGIN_TYPE_USER)
                                .then(
                                    res => {
                                        // console.log(this.props.stateEditAccountId);
                                        // console.log(res.data._etag);

                                        api.editAccount(this.props.stateEditAccountId, res.data._etag, this.props.LOGIN_TYPE_USER, dataOnServerEdit, this.props.access_token)
                                            .then((response) => {
                                                // console.log(response);

                                                api.getAccounts(this.props.access_token, this.props.LOGIN_TYPE_USER, this.props.mainName, 1)
                                                    .then((req) => {
                                                        this.props.changeAccountData(req.data._items);
                                                        // console.log('====================================');
                                                        // console.log('add: ', req);
                                                        // console.log('====================================');
                                                        this.props.changeStateAccountPage(1);
                                                        this.backTo(this.props.mainName);
                                                    })
                                                    .catch(
                                                        (err) => {
                                                            console.log(err.response);
                                                            if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                                                _refreshToken(
                                                                    this.props.client_id,
                                                                    this.props.LOGIN,
                                                                    this.props.PASSWORD,
                                                                    this.props.changeAccessToken,
                                                                    this.saveOnServer.bind(this)
                                                                )
                                                            }
                                                        }
                                                    );
                                                api.getAccounts(this.props.access_token, this.props.LOGIN_TYPE_USER, "lk", 1)
                                                    .then(
                                                        req => {
                                                            this.props.changedDataOperators(req.data._items);
                                                            // console.log(req);
                                                        }
                                                    )
                                                    .catch(
                                                        err => {
                                                            console.log(err.response);
                                                            if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                                                _refreshToken(
                                                                    this.props.client_id,
                                                                    this.props.LOGIN,
                                                                    this.props.PASSWORD,
                                                                    this.props.changeAccessToken,
                                                                    this.saveOnServer.bind(this)
                                                                )
                                                            }
                                                        }
                                                    )
                                                // console.log(response);
                                            })
                                            .catch((err) => {
                                                // console.log(err.response);
                                                // console.log('this.props.apartmentsData', this.props.apartmentsData);
                                                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                                    _refreshToken(
                                                        this.props.client_id,
                                                        this.props.LOGIN,
                                                        this.props.PASSWORD,
                                                        this.props.changeAccessToken,
                                                        this.saveOnServer.bind(this)
                                                    )
                                                }
                                            });
                                        // this.backTo(this.props.mainName);
                                        // return;
                                    }
                                )
                                .catch(
                                    err => {

                                    }
                                )

                        }
                    }
                )
                .catch((err) => {
                    console.log(err.response);
                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                        _refreshToken(
                            this.props.client_id,
                            this.props.LOGIN,
                            this.props.PASSWORD,
                            this.props.changeAccessToken,
                            this.saveOnServer.bind(this)
                        )
                    }
                });

        } else {
            alert("Поля не заполнены")
            // console.log('Поля не заполнены');
            return;
        }

    }

    getApartmentId(num) {
        debugger;

        if (this.props.apartmentsData !== undefined) {
            for (let i = 0; i < this.props.apartmentsData._items.length; i++) {
                // console.log("aptnumber: ", this.props.apartmentsData._items[i].aptnumber);

                if (this.props.apartmentsData._items[i].aptnumber === num) {
                    // console.log("getApartmentId: ", this.props.apartmentsData._items[i]._id);

                    return this.props.apartmentsData._items[i]._id;
                }
            }
        }

    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }



    changeNumApart(event) {
        // console.log("event: ", event, " ", this.props.apartmentsData._items.length);

        this.props.changeStateCreateUserNumApart(event);
        this.props.changeStateDangerInputCreateUserNumApart(false);

        if (this.props.apartmentsData !== undefined) {
            for (let i = 0; i < this.props.accountData.length; i++) {
                // console.log("this.props.accountData[i].aptnumber: ", this.props.apartmentsData._items[i].aptnumber);

                if (this.props.apartmentsData._items[i].aptnumber === event) {
                    this.props.changeStateCreateUserSizeApart(this.props.apartmentsData._items[i].area);
                    return;
                }
            }
        }
    }

    render() {

        // console.log('this.props.apartmentsData', this.props.apartmentsData);
        const types = [
            'user',
            'admin',
            'operator'
        ]
        const svg = [
            <svg
                width="52px"
                height="52px"
            >
                <path
                    fillRule="evenodd"
                    fill="rgb(194, 194, 194)"
                    d="M26.000,52.000 C11.664,52.000 -0.000,40.337 -0.000,26.000 C-0.000,11.663 11.664,-0.000 26.000,-0.000 C40.336,-0.000 52.000,11.663 52.000,26.000 C52.000,40.337 40.336,52.000 26.000,52.000 ZM26.000,2.000 C12.767,2.000 2.000,12.767 2.000,26.000 C2.000,39.233 12.767,50.000 26.000,50.000 C39.233,50.000 50.000,39.233 50.000,26.000 C50.000,12.767 39.233,2.000 26.000,2.000 ZM38.500,27.000 L27.000,27.000 L27.000,39.000 C27.000,39.553 26.552,40.000 26.000,40.000 C25.448,40.000 25.000,39.553 25.000,39.000 L25.000,27.000 L13.500,27.000 C12.948,27.000 12.500,26.553 12.500,26.000 C12.500,25.447 12.948,25.000 13.500,25.000 L25.000,25.000 L25.000,14.000 C25.000,13.447 25.448,13.000 26.000,13.000 C26.552,13.000 27.000,13.447 27.000,14.000 L27.000,25.000 L38.500,25.000 C39.052,25.000 39.500,25.447 39.500,26.000 C39.500,26.553 39.052,27.000 38.500,27.000 Z"
                />
            </svg>,
            <svg
                width="16px"
                height="13px">
                <image x="0px" y="0px" width="16px" height="13px" href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAANCAQAAAAKsiavAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfiDBQSJQ0EM+akAAAAmklEQVQY032QXRmDQAwE53BwFrCAhbNQC1g4DZUAEmrhkFAkFAlFwvSBn7ZfofuYbCbJBk7khQa4nrWjGYx21RmBHsLMxD+ZbSprs/Gj2Jj3K+YwYtKtBGDR1dgCVMBIuzGsd+eNaDZjsph3bGdaCG9kshi9L/M+4NtQAYSZwRZoD4IxWZbZnfNLgDAx0NEfhZEs6/7n8ss34QVoKVga5iI1pgAAAABJRU5ErkJggg==" />
            </svg>
        ]
        // console.log('this.props.state_create_user_photo', this.props.state_create_user_photo);
        return (
            <div className="account flex flex_flow_column" >
                <div className="account flex">
                    <div className="col-lg3">
                        <div>
                            <span className="label">Фото</span><br />
                            <label htmlFor="account_photo_img_input">
                                <div className="account_photo">
                                    <img className="account_photo_img" src={this.props.state_create_user_photo !== undefined ? this.props.state_create_user_photo.imagePreviewUrl !== undefined ? this.props.state_create_user_photo.imagePreviewUrl : empty_avatar : empty_avatar} alt="Фото"></img>
                                    <input
                                        name="account_photo_img_input"
                                        id="account_photo_img_input"
                                        className="inputFile"
                                        type="file"
                                        onChange={(e) => this._handleImgPhotoUserChange(e)}
                                    />
                                </div>
                                <a className="button_account_add flex center text_white margin-top20">загрузить фото</a>
                            </label>

                        </div>
                    </div>
                    <div className="col-lg3">
                        <div>
                            <span className="label">Имя</span><br />
                            <input
                                className={this.props.state_danger_input_user_firstName === true ? "input_user_edit danger_input w100" : "input_user_edit w100"}
                                value={this.props.state_create_user_firstName === undefined ? "" : this.props.state_create_user_firstName}
                                type="text"
                                placeholder="Имя"
                                onChange={(event) => {
                                    this.props.changeStateCreateUserFirstName(event.target.value);
                                    this.props.changeStateDangerInputCreateUserFirstName(false);
                                }
                                }
                                onBlur={(event) => {
                                    this.props.changeStateCreateUserFirstName(event.target.value);
                                    this.props.changeStateDangerInputCreateUserFirstName(false);
                                }
                                }
                            />
                        </div>
                        <div className="margin-top24">
                            <span className="label">Отчество</span><br />
                            <input
                                className={this.props.state_danger_input_user_thirdName === true ? "input_user_edit danger_input w100" : "input_user_edit w100"}
                                value={this.props.state_create_user_thirdName === undefined ? "" : this.props.state_create_user_thirdName}
                                type="text"
                                placeholder="Отчество"
                                onChange={(event) => {
                                    this.props.changeStateCreateUserThirdName(event.target.value);
                                    this.props.changeStateDangerInputCreateUserThirdName(false);
                                }}
                                onBlur={(event) => {
                                    this.props.changeStateCreateUserThirdName(event.target.value);
                                    this.props.changeStateDangerInputCreateUserThirdName(false);
                                }}
                            />
                        </div>
                        {
                            this.props.LOGIN_TYPE_USER === "admin"
                                ?
                                <div className="margin-top24">
                                    <span className="label">Тип пользователя</span><br />
                                    <nav className="select_nav">
                                        <ul className="user_type_ul">
                                            <li className="user_type_li select_li"><a className={this.props.state_danger_input_user_typeUser === true ? "user_type_a_danger target text_white h43px" : "user_type_a target text_white h43px"}>{this.props.state_create_user_typeUser === undefined ? "Тип пользователя" : this.props.state_create_user_typeUser}</a>
                                                <ul>
                                                    {types.map(typeUser => <li className="user_type_li select_li" key={typeUser} ><a className="user_type_a_bottom220 w100 minWidth0 h43px" onClick={() => { this.props.changeStateDangerInputCreateUserTypeUser(false); this.props.changeStateCreateUserTypeUser(typeUser) }} >{typeUser}</a></li>)}
                                                </ul>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                :
                                <div onLoad={() => this.props.changeStateCreateUserTypeUser("user")}></div>
                        }
                        {
                            this.props.state_create_user_typeUser === "admin" || this.props.state_create_user_typeUser === "operator" ?
                                null :
                                <div className="flex w100 align-flex-end margin-top24">
                                    <div className="w50">
                                        <span className="label padding-right10">Номер апартамента</span><br />

                                        <nav className="select_nav w100">
                                            <ul className="user_type_ul user_type_a_bottom120">
                                                <li className="user_type_li select_li"><a className={this.props.state_danger_input_user_numApart === true ? "user_type_a_danger target text_white h43px" : "user_type_a target text_white h43px"}>{this.props.state_create_user_numApart === undefined ? "" : this.props.state_create_user_numApart}</a>
                                                    <ul>
                                                        {this.props.apartmentsData !== undefined ? this.props.apartmentsData._items.map(apart => <li className="user_type_li select_li" key={apart._id} ><a className="user_type_a_bottom120 h43px" onClick={() => { this.changeNumApart(apart.aptnumber) }} >{apart.aptnumber}</a></li>) : ""}
                                                    </ul>
                                                </li>
                                            </ul>
                                        </nav>

                                        {/* <input
                            className={this.props.state_danger_input_user_numApart === true ? "input_user_edit danger_input w100" : "input_user_edit w100"}
                            value={ this.props.state_create_user_numApart === undefined ? "" : this.props.state_create_user_numApart }
                                type="text"
                                placeholder="№"
                                onChange={(event) => {
                                    this.changeNumApart(event);
                                    
                                }}
                                onBlur={(event) => {
                                    this.changeNumApart(event);
                                }}
                            /> */}


                                    </div>
                                </div>
                        }
                        {/* <div className="margin-top24">
                        <span className="label">Кадастровый статус</span><br />
                        <a className="button_account_add w100 flex center text_white" onClick={() => console.log('загрузить файл')}>загрузить файл</a>

                    </div> */}
                    </div>
                    <div className="col-lg3 padding0">
                        <div>
                            <span className="label">Фамилия</span><br />
                            <input
                                className={this.props.state_danger_input_user_secondName === true ? "input_user_edit danger_input w100" : "input_user_edit w100"}
                                value={this.props.state_create_user_secondName === undefined ? "" : this.props.state_create_user_secondName}
                                type="text"
                                placeholder="Фамилия"
                                onChange={(event) => {
                                    this.props.changeStateCreateUserSecondName(event.target.value);
                                    this.props.changeStateDangerInputCreateUserSecondName(false);
                                }}
                                onBlur={(event) => {
                                    this.props.changeStateCreateUserSecondName(event.target.value);
                                    this.props.changeStateDangerInputCreateUserSecondName(false);
                                }}
                            />
                        </div>
                        <div className="margin-top24">
                            <span className="label">Телефон</span><br />
                            <input
                                className={this.props.state_danger_input_user_tel === true ? "input_user_edit danger_input w100" : "input_user_edit w100"}
                                value={this.props.state_create_user_tel === undefined ? "" : this.props.state_create_user_tel}
                                type="text"
                                placeholder="Телефон"
                                onChange={(event) => {
                                    this.props.changeStateCreateUserTel(event.target.value);
                                    this.props.changeStateDangerInputCreateUserTel(false);
                                }}
                                onBlur={(event) => {
                                    this.props.changeStateCreateUserTel(event.target.value);
                                    this.props.changeStateDangerInputCreateUserTel(false);
                                }}
                            />
                        </div>
                        <div className="margin-top24">
                            <span className="label">E-mail</span><br />
                            <input
                                className={this.props.state_danger_input_user_email === true ? "input_user_edit danger_input w100" : "input_user_edit w100"}
                                value={this.props.state_create_user_email === undefined ? "" : this.props.state_create_user_email}
                                type="text"
                                placeholder="E-mail"
                                onChange={(event) => {
                                    this.props.changeStateCreateUserUserEmail(event.target.value);
                                    this.props.changeStateDangerInputCreateUserUserEmail(false);
                                }}
                            /*        onBlur={(event) => {
                                       this.props.changeStateCreateUserUserEmail(event.target.value);
                                       this.props.changeStateDangerInputCreateUserUserEmail(false);
                                   }} */
                            />
                        </div>
                        {
                            this.props.LOGIN_TYPE_USER === "admin" || this.props.LOGIN_TYPE_USER === "operator"
                                ?
                                null
                                :
                                <div className="margin-top24">
                                    <span className="label">Кадастровый паспорт</span><br />
                                    <label htmlFor="passport_img_input">
                                        <div className="passport_img">
                                            <div className="svg_box">{svg[0]}</div>
                                            {this.props.state_create_user_imgPassport === undefined ? null : <img className="passport_img_item" src={this.props.state_create_user_imgPassport} alt="Паспорт" onClick={() => this.props.state_create_user_imgPassport !== undefined ? this.props.changeImgOpen(!this.props.imgOpen, this.props.state_create_user_imgPassport) : null}></img>}
                                            <input
                                                name="passport_img_input"
                                                id="passport_img_input"
                                                type="file"
                                                onChange={(e) => this._handleImgPassportChange(e)}
                                            />
                                        </div>
                                    </label>
                                </div>
                        }
                        <div className="margin-top24">
                            <label className='save__send-pass-to-admin'>
                                <input
                                  type="checkbox"
                                  onChange={() => this.setState(prevState => ({ ...prevState, sendPasswordToAdmin: !prevState.sendPasswordToAdmin }))}
                                  checked={this.state.sendPasswordToAdmin}
                                />
                                Отправить пароль администратору
                            </label>
                            <a className="button_account_save flex center text_white" onClick={() => this.saveOnServerIf()}>{this.props.statusSendDataAccountOnServer === "edit" ? "сохранить изменения" : "сохранить"}</a>
                        </div>
                    </div>
                </div>
                <div className="flex center flex1">
                    <a className="button_account_cancel flex center text_white" onClick={() => this.props.changeStateWindowAccountEdit(!this.props.stateWindowAccounts.accountEdit)}>отмена</a>
                </div>
            </div>
        );
    };
};

export default connect(dispatch.mapStateToProps, dispatch.mapDispatchToProps)(Account_Add);