import React from 'react';

import './styles.css';

const deleteIcon = <svg
    id='deleteSvg'
    width='11px'
    height='11px'>
    <path
      id='deletePath'
      fillRule='evenodd'
      fill='rgb(92, 92, 92)'
      d='M10.718,1.260 C10.348,0.891 9.749,0.891 9.379,1.260 L5.989,4.648 L2.600,1.260 C2.230,0.891 1.630,0.891 1.260,1.260 C0.890,1.630 0.890,2.230 1.260,2.599 L4.649,5.987 L1.260,9.375 C0.890,9.744 0.890,10.344 1.260,10.714 C1.445,10.899 1.687,10.991 1.930,10.991 C2.172,10.991 2.414,10.899 2.599,10.714 L5.989,7.326 L9.379,10.714 C9.564,10.899 9.806,10.991 10.048,10.991 C10.291,10.991 10.533,10.899 10.718,10.714 C11.088,10.344 11.088,9.745 10.718,9.375 L7.329,5.987 L10.718,2.599 C11.088,2.230 11.088,1.630 10.718,1.260 Z'
    />
  </svg>;

const editIcon = <svg
    id='editSvg'
    width='12px'
    height='12px'>
    <path
      id='editPath'
      fillRule='evenodd'
      fill='rgb(92, 92, 92)'
      d='M11.582,12.000 L0.428,12.000 C0.204,12.000 0.010,11.803 0.010,11.574 C0.010,11.346 0.194,11.149 0.428,11.149 L11.572,11.149 C11.806,11.149 12.000,11.346 12.000,11.574 C12.000,11.803 11.816,12.000 11.582,12.000 ZM2.689,9.706 C2.608,9.789 2.506,9.830 2.394,9.830 L0.418,9.841 C0.306,9.841 0.204,9.799 0.122,9.716 C0.041,9.633 0.000,9.519 0.000,9.405 L0.010,7.380 C0.010,7.266 0.061,7.162 0.132,7.079 L5.592,1.525 L6.957,0.124 C7.131,-0.042 7.395,-0.042 7.558,0.124 L9.514,2.138 C9.677,2.314 9.677,2.584 9.514,2.750 L2.689,9.706 ZM0.856,7.567 L0.845,8.969 L2.231,8.958 L7.263,3.840 L5.888,2.439 L0.856,7.567 ZM7.263,1.037 L6.489,1.826 L7.864,3.228 L8.638,2.439 L7.263,1.037 Z'
    />
  </svg>;

export default props => {
  const renderFloor = id => {
    let returnedFloor = '-';
    props.floors && props.floors.forEach(item => { if (item._id === id) returnedFloor = item.flrnumber });

    return returnedFloor;
  }

  const renderApart = (item, i) => {
    return (
      <div
        key={i}
        className='list-container__item'
        onClick={() => props.handlerChangeApart(item._id)}
      >
        <span className={`container-item__value container-item__value_aptnumber`}>{item.aptnumber ? item.aptnumber : '-'}</span>
        <span className={`container-item__value container-item__value_floor-id`}>{renderFloor(item.floor_id)}</span>
        <div className='container-item__buttons'>
          <span className='container-item__buttons-element' onClick={e => {e.stopPropagation(); props.handlerDeleteApart(item, i)}} >{deleteIcon}</span>
        </div>
      </div>
    )
  }

  const onScrollList = event => {
    const scrollBottom = event.target.scrollTop +
      event.target.offsetHeight == event.target.scrollHeight;

    if (scrollBottom) {
      props.scrolledToBottom(); //API method
    }
  }

  return(
    <div className='aparts__list' onScroll={onScrollList}>
      {
        props.aparts !== null ? <React.Fragment>
          <div className='aparts-list__titles'>
            <span className='list-titles__element'>№</span>
            <span className='list-titles__element'>Этаж</span>
            <span className='list-titles__element list-titles__element_empty'></span>
          </div>
          <div className='aparts-list__container'>
            { props.aparts.map(renderApart) }
          </div>
        </React.Fragment>
          : <div className='aparts-list__empty-list'>Добавьте апартаменты</div>
      }
    </div>
  );
}