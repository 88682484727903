export default {

    ACTION_CHANGE_USER_IMG: 'ACTION_CHANGE_USER_IMG',
    ACTION_CHANGE_LEFT_COLUMN_IMG: 'ACTION_CHANGE_LEFT_COLUMN_IMG',
    ACTION_CHANGE_LK_USER_IMG_FILE: 'ACTION_CHANGE_LK_USER_IMG_FILE',
    ACTION_CHANGE_USER_DATA: 'ACTION_CHANGE_USER_DATA',
    ACTION_CHANGE_USER_MENU_OPEN_STATE: 'ACTION_CHANGE_USER_MENU_OPEN_STATE',

    ACTION_CHANGE_LOGIN: 'ACTION_CHANGE_LOGIN',
    ACTION_CHANGE_PASSWORD: 'ACTION_CHANGE_PASSWORD',
    ACTION_CHANGE_STATE_DANGER_INPUT_LOGIN: 'ACTION_CHANGE_STATE_DANGER_INPUT_LOGIN',
    ACTION_CHANGE_STATE_DANGER_INPUT_PASSWORD: 'ACTION_CHANGE_STATE_DANGER_INPUT_PASSWORD',

    ACTION_CHANGE_STATUS_LOGIN: 'ACTION_CHANGE_STATUS_LOGIN',

    ACTION_CHANGE_ACCESS_TOKEN: 'ACTION_CHANGE_ACCESS_TOKEN',
    ACTION_CHANGE_ACCOUNT_ID: 'ACTION_CHANGE_ACCOUNT_ID',
    ACTION_CHANGE_CLIENT_ID: 'ACTION_CHANGE_CLIENT_ID',
    ACTION_CHANGE_REAL_CLIENT_ID: 'ACTION_CHANGE_REAL_CLIENT_ID',
    ACTION_CHANGE_STATE_SEARCH_ACCOUNTS: 'ACTION_CHANGE_STATE_SEARCH_ACCOUNTS',
    ACTION_CHANGE_STATE_SEARCH_MAILING: 'ACTION_CHANGE_STATE_SEARCH_MAILING',
    ACTION_CHANGE_STATE_SEARCH_SERVICES: 'ACTION_CHANGE_STATE_SEARCH_SERVICES',
    ACTION_CHANGE_EXPIRES: 'ACTION_CHANGE_EXPIRES',
    ACTION_CHANGE_REFRESH_TOKEN: 'ACTION_CHANGE_REFRESH_TOKEN',
    ACTION_CHANGE_USER_NAME: 'ACTION_CHANGE_USER_NAME',
    ACTION_CHANGE_USER_ID: 'ACTION_CHANGE_USER_ID',

    ACTION_LOGIN_TYPE_USER: 'ACTION_LOGIN_TYPE_USER',
    ACTION_CHANGE_MAIN: 'ACTION_CHANGE_MAIN',
    ACTION_CHANGE_IMG_OPEN: 'ACTION_CHANGE_IMG_OPEN',
    ACTION_GET_INITIAL_STATE: 'ACTION_GET_INITIAL_STATE',
    ACTION_CHANGE_SERVICE_REQUESTS: 'ACTION_CHANGE_SERVICE_REQUESTS',
    ACTION_CHANGE_STATE_REQUEST_PAGE: 'ACTION_CHANGE_STATE_REQUEST_PAGE',
    ACTION_CHANGE_SERVICE_REQUEST_DATA: 'ACTION_CHANGE_SERVICE_REQUEST_DATA',
    ACTION_CHANGE_STATE_WINDOW_SERVICE_REQUEST: 'ACTION_CHANGE_STATE_WINDOW_SERVICE_REQUEST',
    ACTION_CHANGE_STATE_WINDOW_ACCOUNTS: 'ACTION_CHANGE_STATE_WINDOW_ACCOUNTS',
    ACTION_CHANGE_ACCOUNT_DATA: 'ACTION_CHANGE_ACCOUNT_DATA',
    ACTION_CHANGE_STATE_WINDOW_ACCOUNT_EDIT: 'ACTION_CHANGE_STATE_WINDOW_ACCOUNT_EDIT',


    //
    ACTION_CHANGE_STATE_ACCOUNT_PAGE: 'ACTION_CHANGE_STATE_ACCOUNT_PAGE', //
    ACTION_CHANGE_STATE_ACCOUNT_NAME: 'ACTION_CHANGE_STATE_ACCOUNT_NAME',
    ACTION_CHANGE_STATE_ACCOUNT_TYPE: 'ACTION_CHANGE_STATE_ACCOUNT_TYPE',
    ACTION_CHANGE_STATE_ACCOUNT_TEL: 'ACTION_CHANGE_STATE_ACCOUNT_TEL',
    ACTION_CHANGE_STATE_ACCOUNT_EMAIL: 'ACTION_CHANGE_STATE_ACCOUNT_EMAIL',
    ACTION_CHANGE_STATE_ACCOUNT_NUM_APART: 'ACTION_CHANGE_STATE_ACCOUNT_NUM_APART',
    ACTION_CHANGE_STATE_ACCOUNT_SIZE_APART: 'ACTION_CHANGE_STATE_ACCOUNT_SIZE_APART',
    ACTION_CHANGE_STATE_ACCOUNT_USER_PHOTO: 'ACTION_CHANGE_STATE_ACCOUNT_USER_PHOTO',
    ACTION_CHANGE_STATE_ACCOUNT_PASSPORT_APART: 'ACTION_CHANGE_STATE_ACCOUNT_PASSPORT_APART',
    //

    ACTION_CHANGE_STATUS_SEND_ACCOUNT_ON_SERVER: 'ACTION_CHANGE_STATUS_SEND_ACCOUNT_ON_SERVER',
    ACTION_CHANGE_STATUS_SEND_SERVICES_ON_SERVER: 'ACTION_CHANGE_STATUS_SEND_SERVICES_ON_SERVER',
    ACTION_CHANGE_STATE_EDIT_ACCOUNT_ID: 'ACTION_CHANGE_STATE_EDIT_ACCOUNT_ID',
    ACTION_CHANGE_STATE_EDIT_ACCOUNT_ETAG: 'ACTION_CHANGE_STATE_EDIT_ACCOUNT_ETAG',

    ACTION_CHANGE_STATE_CREATE_USER_FIRSTNAME: 'ACTION_CHANGE_STATE_CREATE_USER_FIRSTNAME',
    ACTION_CHANGE_STATE_CREATE_USER_SECONDNAME: 'ACTION_CHANGE_STATE_CREATE_USER_SECONDNAME',
    ACTION_CHANGE_STATE_CREATE_USER_THIRDNAME: 'ACTION_CHANGE_STATE_CREATE_USER_THIRDNAME',
    ACTION_CHANGE_STATE_CREATE_USER_TEL: 'ACTION_CHANGE_STATE_CREATE_USER_TEL',
    ACTION_CHANGE_STATE_CREATE_USER_EMAIL: 'ACTION_CHANGE_STATE_CREATE_USER_EMAIL',
    ACTION_CHANGE_STATE_CREATE_USER_PHOTO: 'ACTION_CHANGE_STATE_CREATE_USER_PHOTO',
    ACTION_CHANGE_STATE_CREATE_USER_TYPE_USER: 'ACTION_CHANGE_STATE_CREATE_USER_TYPE_USER',
    ACTION_CHANGE_STATE_CREATE_USER_IMG_PASSPORT: 'ACTION_CHANGE_STATE_CREATE_USER_IMG_PASSPORT',
    ACTION_CHANGE_STATE_CREATE_USER_NUM_APART: 'ACTION_CHANGE_STATE_CREATE_USER_NUM_APART',
    ACTION_CHANGE_STATE_CREATE_USER_SIZE_APART: 'ACTION_CHANGE_STATE_CREATE_USER_SIZE_APART',


    ACTION_CHANGE_APARTMENT_NUM: 'ACTION_CHANGE_APARTMENT_NUM',
    ACTION_CHANGE_APARTMENT_SIZE: 'ACTION_CHANGE_APARTMENT_SIZE',
    ACTION_CHANGE_APARTMENT_PASPORT: 'ACTION_CHANGE_APARTMENT_PASPORT',
    ACTION_CHANGE_USER_PHOTO: 'ACTION_CHANGE_USER_PHOTO',
    ACTION_CHANGE_USER_PHOTO_FOR_SAVE: 'ACTION_CHANGE_USER_PHOTO_FOR_SAVE',
    ACTION_CHANGE_SERVICES_PHOTO_FOR_SAVE: 'ACTION_CHANGE_SERVICES_PHOTO_FOR_SAVE',

    ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_FIRSTNAME: 'ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_FIRSTNAME',
    ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_SECONDNAME: 'ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_SECONDNAME',
    ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_THIRDNAME: 'ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_THIRDNAME',
    ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_TEL: 'ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_TEL',
    ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_TYPE_USER: 'ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_TYPE_USER',
    ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_EMAIL: 'ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_EMAIL',
    ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_PHOTO: 'ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_PHOTO',
    ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_IMG_PASSPORT: 'ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_IMG_PASSPORT',
    ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_NUM_APART: 'ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_NUM_APART',
    ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_SIZE_APART: 'ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_SIZE_APART',

    ACTION_CHANGE_ADD_TYPE_NOTIFICATION_STATUS: 'ACTION_CHANGE_ADD_TYPE_NOTIFICATION_STATUS',
    ACTION_CHANGE_STATE_ADD_TYPE_NOTIFICATION: 'ACTION_CHANGE_STATE_ADD_TYPE_NOTIFICATION',
    ACTION_CHANGE_STATE_EDIT_NOTIFICATION_CATEGORY: 'ACTION_CHANGE_STATE_EDIT_NOTIFICATION_CATEGORY',
    ACTION_CHANGE_STATE_STATUS_EDIT_NOTIFICATION_CATEGORY: 'ACTION_CHANGE_STATE_STATUS_EDIT_NOTIFICATION_CATEGORY',
    ACTION_CHANGE_STATE_EDIT_NOTIFICATION_CATEGORY_TITLE: 'ACTION_CHANGE_STATE_EDIT_NOTIFICATION_CATEGORY_TITLE',
    ACTION_CHANGE_STATE_EDIT_NOTIFICATION_CATEGORY_LIST: 'ACTION_CHANGE_STATE_EDIT_NOTIFICATION_CATEGORY_LIST',
    ACTION_CHANGE_DATA_GRAPHIC: 'ACTION_CHANGE_DATA_GRAPHIC',
    ACTION_CHANGE_DATA_GRAPHIC_OPEN: 'ACTION_CHANGE_DATA_GRAPHIC_OPEN',
    ACTION_CHANGE_STATE_DANGER_INPUT_ADD_TYPE_NOTIFICATION: 'ACTION_CHANGE_STATE_DANGER_INPUT_ADD_TYPE_NOTIFICATION',
    ACTION_CHANGE_MAILING_DATA: 'ACTION_CHANGE_MAILING_DATA',
    ACTION_CHANGE_MAILING_DATA_ACTIVE: 'ACTION_CHANGE_MAILING_DATA_ACTIVE',
    ACTION_CHANGE_MAILING_DATA_NOT_ACTIVE: 'ACTION_CHANGE_MAILING_DATA_NOT_ACTIVE',
    ACTION_CHANGE_QUESTIONS_DATA_ACTIVE: 'ACTION_CHANGE_QUESTIONS_DATA_ACTIVE',
    ACTION_CHANGE_QUESTIONS_DATA_NOT_ACTIVE: 'ACTION_CHANGE_QUESTIONS_DATA_NOT_ACTIVE',
    ACTION_CHANGE_STATE_WINDOW_OPEN_MAILING: 'ACTION_CHANGE_STATE_WINDOW_OPEN_MAILING',
    ACTION_CHANGE_STATE_WINDOW_OPEN_NEW_MAILING: 'ACTION_CHANGE_STATE_WINDOW_OPEN_NEW_MAILING',
    ACTION_CHANGE_STATE_WINDOW_OPEN_QUESTION: 'ACTION_CHANGE_STATE_WINDOW_OPEN_QUESTION',
    ACTION_CHANGE_NOTIFICATION_CATERIES_LIST: 'ACTION_CHANGE_NOTIFICATION_CATERIES_LIST',
    ACTION_CHANGE_STATE_OPEN_NOTIFICATION_STATUS_NOTIFICATION: 'ACTION_CHANGE_STATE_OPEN_NOTIFICATION_STATUS_NOTIFICATION',
    ACTION_CHANGE_STATE_OPEN_NOTIFICATION_STATUS_QUESTIONS: 'ACTION_CHANGE_STATE_OPEN_NOTIFICATION_STATUS_QUESTIONS',
    ACTION_CHANGE_STATE_OPEN_NOTIFICATION_STATUS_QUESTIONS_END: 'ACTION_CHANGE_STATE_OPEN_NOTIFICATION_STATUS_QUESTIONS_END',
    ACTION_CHANGE_STATE_STATUS_CUSTOM_ANSWERS: 'ACTION_CHANGE_STATE_STATUS_CUSTOM_ANSWERS',

    ACTION_CHANGE_ACTIVE_COLOR_TYPE_OF_NOTIFICATION: 'ACTION_CHANGE_ACTIVE_COLOR_TYPE_OF_NOTIFICATION',
    ACTION_CHANGE_STATE_CREATE_NOTIFICATION_TYPES: 'ACTION_CHANGE_STATE_CREATE_NOTIFICATION_TYPES',
    ACTION_CHANGE_STATE_CREATE_NOTIFICATION_DATE: 'ACTION_CHANGE_STATE_CREATE_NOTIFICATION_DATE',
    ACTION_CHANGE_STATE_CREATE_NOTIFICATION_TIME: 'ACTION_CHANGE_STATE_CREATE_NOTIFICATION_TIME',
    ACTION_CHANGE_STATE_CREATE_NOTIFICATION_DATE_END: 'ACTION_CHANGE_STATE_CREATE_NOTIFICATION_DATE_END',
    ACTION_CHANGE_STATE_CREATE_NOTIFICATION_TIME_END: 'ACTION_CHANGE_STATE_CREATE_NOTIFICATION_TIME_END',
    ACTION_CHANGE_STATE_CREATE_NOTIFICATION_MESSAGE: 'ACTION_CHANGE_STATE_CREATE_NOTIFICATION_MESSAGE',
    ACTION_CHANGE_STATE_CREATE_QUESTION_TITLE: 'ACTION_CHANGE_STATE_CREATE_QUESTION_TITLE',
    ACTION_CHANGE_STATE_CREATE_QUESTION_ANSWER_OPTIONS: 'ACTION_CHANGE_STATE_CREATE_QUESTION_ANSWER_OPTIONS',
    ACTION_CHANGE_STATE_CREATE_QUESTION_ANSWER_OPTION: 'ACTION_CHANGE_STATE_CREATE_QUESTION_ANSWER_OPTION',
    ACTION_CHANGE_STATE_CREATE_QUESTION_STATISTICS: 'ACTION_CHANGE_STATE_CREATE_QUESTION_STATISTICS',
    ACTION_CHANGE_STATE_CREATE_QUESTION_ANSWER_TOTAL: 'ACTION_CHANGE_STATE_CREATE_QUESTION_ANSWER_TOTAL',
    ACTION_CHANGE_STATE_CREATE_QUESTION_MULTIPLE_ANSWERS: 'ACTION_CHANGE_STATE_CREATE_QUESTION_MULTIPLE_ANSWERS',
    ACTION_CHANGE_STATE_CREATE_QUESTION_ACTIVE_TILL: 'ACTION_CHANGE_STATE_CREATE_QUESTION_ACTIVE_TILL',
    ACTION_CHANGE_STATE_CREATE_NOTIFICATION_STATUS_NOTIFICATION: 'ACTION_CHANGE_STATE_CREATE_NOTIFICATION_STATUS_NOTIFICATION',
    ACTION_CHANGE_STATE_OPEN_NOTIFICATION_STATUS_PRIVATE_PUBLICATION: 'ACTION_CHANGE_STATE_OPEN_NOTIFICATION_STATUS_PRIVATE_PUBLICATION',

    ACTION_CHANGE_STATE_OPEN_NOTIFICATION_LIST_USERS_ON_SEARCH: 'ACTION_CHANGE_STATE_OPEN_NOTIFICATION_LIST_USERS_ON_SEARCH',

    ACTION_CHANGE_STATUS_SEND_DATA_NOTIFICATION_ON_SERVER: 'ACTION_CHANGE_STATUS_SEND_DATA_NOTIFICATION_ON_SERVER',

    ACTION_CHANGE_STATE_EDIT_NOTIFICATION_ID: 'ACTION_CHANGE_STATE_EDIT_NOTIFICATION_ID',
    ACTION_CHANGE_STATE_EDIT_NOTIFICATION_ETAG: 'ACTION_CHANGE_STATE_EDIT_NOTIFICATION_ETAG',

    ACTION_CHANGE_STATE_EDIT_QUESTIONS_ID: 'ACTION_CHANGE_STATE_EDIT_QUESTIONS_ID',
    ACTION_CHANGE_STATE_EDIT_QUESTIONS_ETAG: 'ACTION_CHANGE_STATE_EDIT_QUESTIONS_ETAG',

    // Подсветка заполнения инпутов в Рассылке
    ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_NOTIFICATION_MESSAGE: 'ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_NOTIFICATION_MESSAGE',
    ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_NOTIFICATION_TYPE_NOTIFICATION: 'ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_NOTIFICATION_TYPE_NOTIFICATION',
    ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_NOTIFICATION_DATE: 'ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_NOTIFICATION_DATE',
    ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_NOTIFICATION_TIME: 'ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_NOTIFICATION_TIME',

    // Апартаменты
    ACTION_CHANGE_APARTMENTS_DATA: 'ACTION_CHANGE_APARTMENTS_DATA',

    //Пропуск

    ACTION_CHANGE_STATE_WINDOW_OPEN_PASS_ITEM: 'ACTION_CHANGE_STATE_WINDOW_OPEN_PASS_ITEM',
    ACTION_CHANGE_PASS_DATA: 'ACTION_CHANGE_PASS_DATA',

    ACTION_CHANGE_PASS_ITEM_ID: "ACTION_CHANGE_PASS_ITEM_ID",
    ACTION_CHANGE_PASS_ITEM_ETAG: "ACTION_CHANGE_PASS_ITEM_ETAG",

    ACTION_CHANGE_PASS_ITEM_USER: 'ACTION_CHANGE_PASS_ITEM_USER',
    ACTION_CHANGE_PASS_ITEM_DATA_UPDATE: 'ACTION_CHANGE_PASS_ITEM_DATA_UPDATE',
    ACTION_CHANGE_PASS_ITEM_STATUS: 'ACTION_CHANGE_PASS_ITEM_STATUS',
    ACTION_CHANGE_PASS_ITEM_PLATE: 'ACTION_CHANGE_PASS_ITEM_PLATE',
    ACTION_CHANGE_PASS_ITEM_DATA_COMMING: 'ACTION_CHANGE_PASS_ITEM_DATA_COMMING',
    ACTION_CHANGE_PASS_ITEM_APART_ID: 'ACTION_CHANGE_PASS_ITEM_APART_ID',
    ACTION_CHANGE_PASS_ITEM_APART_NUM: 'ACTION_CHANGE_PASS_ITEM_APART_NUM',

    // Сервисы

    ACTION_CHANGE_SERVICES: 'ACTION_CHANGE_SERVICES',
    ACTION_CHANGE_SERVICES_DATA: 'ACTION_CHANGE_SERVICES_DATA',
    ACTION_CHANGE_ONLINE_SERVICES_DATA: 'ACTION_CHANGE_ONLINE_SERVICES_DATA',
    ACTION_CHANGE_ONLINE_SERVICES_CATEGORY: 'ACTION_CHANGE_ONLINE_SERVICES_CATEGORY',
    ACTION_CHANGE_ONLINE_SERVICES_CATEGORY_DATA: 'ACTION_CHANGE_ONLINE_SERVICES_CATEGORY_DATA',
    ACTION_CHANGE_ONLINE_SERVICES_DATA_FOR_OPEN: 'ACTION_CHANGE_ONLINE_SERVICES_DATA_FOR_OPEN',
    ACTION_CHANGE_ONLINE_SERVICES_CATEGORY_DATA_FOR_OPEN: 'ACTION_CHANGE_ONLINE_SERVICES_CATEGORY_DATA_FOR_OPEN',
    ACTION_CHANGE_STATE_WINDOW_SERVICES: 'ACTION_CHANGE_STATE_WINDOW_SERVICES',
    ACTION_CHANGE_STATE_ONLINE_SERVICES_PHOTO: 'ACTION_CHANGE_STATE_ONLINE_SERVICES_PHOTO',
    ACTION_CHANGE_STATE_SERVICES_PAGE: 'ACTION_CHANGE_STATE_SERVICES_PAGE', //
    ACTION_CHANGE_STATE_SERVICES_CATEGORY_PAGE: 'ACTION_CHANGE_STATE_SERVICES_CATEGORY_PAGE', //
    ACTION_CHANGE_STATE_CREATE_SEVRICE_PHOTO: 'ACTION_CHANGE_STATE_CREATE_SEVRICE_PHOTO',
    ACTION_CHANGE_STATE_CREATE_SEVRICE_CATEGORY_PHOTO: 'ACTION_CHANGE_STATE_CREATE_SEVRICE_CATEGORY_PHOTO',
    ACTION_CHANGE_STATE_WINDOW_SERVICES_EDIT: 'ACTION_CHANGE_STATE_WINDOW_SERVICES_EDIT',
    ACTION_CHANGE_STATE_WINDOW_SERVICES_CATEGORY: 'ACTION_CHANGE_STATE_WINDOW_SERVICES_CATEGORY',
    ACTION_CHANGE_STATE_WINDOW_SERVICES_CATEGORY_EDIT: 'ACTION_CHANGE_STATE_WINDOW_SERVICES_CATEGORY_EDIT',
    ACTION_CHANGE_STATE_EDIT_SEVRICE_TITLE: 'ACTION_CHANGE_STATE_EDIT_SEVRICE_TITLE',
    ACTION_CHANGE_STATE_EDIT_SEVRICE_CATEGORY_TITLE: 'ACTION_CHANGE_STATE_EDIT_SEVRICE_CATEGORY_TITLE',
    ACTION_CHANGE_STATE_EDIT_SEVRICE_CATEGORY_ACTIVE: 'ACTION_CHANGE_STATE_EDIT_SEVRICE_CATEGORY_ACTIVE',
    ACTION_CHANGE_STATE_EDIT_SEVRICE_ID: 'ACTION_CHANGE_STATE_EDIT_SEVRICE_ID',
    ACTION_CHANGE_STATE_EDIT_SEVRICE_ETAG: 'ACTION_CHANGE_STATE_EDIT_SEVRICE_ETAG',
    ACTION_CHANGE_STATE_SEVRICE_CATEGORY_ETAG: 'ACTION_CHANGE_STATE_SEVRICE_CATEGORY_ETAG',
    ACTION_CHANGE_STATE_CREATE_SEVRICE_CATEGORY_ID: 'ACTION_CHANGE_STATE_CREATE_SEVRICE_CATEGORY_ID',
    ACTION_CHANGE_STATE_CREATE_SEVRICE_CATEGORY_NAME: 'ACTION_CHANGE_STATE_CREATE_SEVRICE_CATEGORY_NAME',
    ACTION_CHANGE_STATE_CREATE_SEVRICE_DESCRIPTION: 'ACTION_CHANGE_STATE_CREATE_SEVRICE_DESCRIPTION',
    ACTION_CHANGE_STATE_CREATE_SEVRICE_SHORTINFO: 'ACTION_CHANGE_STATE_CREATE_SEVRICE_SHORTINFO',
    ACTION_CHANGE_STATE_CREATE_SEVRICE_PHONE: 'ACTION_CHANGE_STATE_CREATE_SEVRICE_PHONE',
    ACTION_CHANGE_STATE_CREATE_SEVRICE_LINK: 'ACTION_CHANGE_STATE_CREATE_SEVRICE_LINK',
    ACTION_CHANGE_STATE_CREATE_SEVRICE_EMAIL: 'ACTION_CHANGE_STATE_CREATE_SEVRICE_EMAIL',
    ACTION_CHANGE_STATE_CREATE_SEVRICE_ORDER: 'ACTION_CHANGE_STATE_CREATE_SEVRICE_ORDER',
    ACTION_CHANGE_STATE_CREATE_SEVRICE_DELIVERY: 'ACTION_CHANGE_STATE_CREATE_SEVRICE_DELIVERY',
    ACTION_CHANGE_STATE_CREATE_SEVRICE_PAY: 'ACTION_CHANGE_STATE_CREATE_SEVRICE_PAY',
    //
    ACTION_CHANGE_SERVICES_CATEGORY_PHOTO_FOR_SAVE: 'ACTION_CHANGE_SERVICES_CATEGORY_PHOTO_FOR_SAVE',
    ACTION_CHANGE_ACCOUNTS_MAIN_STATE_HIDEN: 'ACTION_CHANGE_ACCOUNTS_MAIN_STATE_HIDEN',

    // Личный кабинет

    ACTION_CHANGE_STATE_WINDOW_OPEN_NEW_OPERATOR: 'ACTION_CHANGE_STATE_WINDOW_OPEN_NEW_OPERATOR',
    ACTION_CHANGE_STATE_WINDOW_EDIT_OPERATOR: 'ACTION_CHANGE_STATE_WINDOW_EDIT_OPERATOR',
    ACTION_CHANGE_STATE_LK_NAMEUSER: 'ACTION_CHANGE_STATE_LK_NAMEUSER',
    ACTION_CHANGE_STATE_LK_EMAILUSER: 'ACTION_CHANGE_STATE_LK_EMAILUSER',
    ACTION_CHANGE_STATE_LK_IMAGEUSER: 'ACTION_CHANGE_STATE_LK_IMAGEUSER',
    ACTION_CHANGE_STATE_LK_EMAILUSER_CHANGE_STATUS: 'ACTION_CHANGE_STATE_LK_EMAILUSER_CHANGE_STATUS',
    ACTION_CHANGE_STATE_LK_LISTOPERATORS_OPEN: 'ACTION_CHANGE_STATE_LK_LISTOPERATORS_OPEN',
    ACTION_CHANGE_STATE_LK_PHOTOUSER: 'ACTION_CHANGE_STATE_LK_PHOTOUSER',
    ACTION_CHANGE_DATA_OPERATORS: 'ACTION_CHANGE_DATA_OPERATORS',
    ACTION_CHANGE_STATE_PASS_PAGE: 'ACTION_CHANGE_STATE_PASS_PAGE',
    ACTION_CHANGE_STATE_DANGER_INPUT_EMAILUSER_CHANGE_STATUS: 'ACTION_CHANGE_STATE_DANGER_INPUT_EMAILUSER_CHANGE_STATUS',

    ACTION_CHANGE_IS_LOAD_MAILING_MAIN: "ACTION_CHANGE_IS_LOAD_MAILING_MAIN",
    ACTION_CHANGE_IS_LOAD_MAILING_MENU: "ACTION_CHANGE_IS_LOAD_MAILING_MENU",
}