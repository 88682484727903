import React, { Component } from 'react';


import './PreLoader.css';

export default class PreLoader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _onLoadState: this.props._onLoadState ? true : false
        }
    }
    _onLoad = () => {
        !this.state._onLoadState ? (this.props.onLoad() && this.setState({ _onLoadState: true })) : null
    }

    componentDidMount() {
        this._onLoad();
    }

    render() {
        return (
            <div className="flex flex1 relative">
                <div className="PreLoader-absolute">
                    <div className="PreLoader-absolute__box"></div>
                    <div className="PreLoader-absolute__box"></div>
                    <div className="PreLoader-absolute__box"></div>
                    <div className="PreLoader-absolute__box"></div>
                    <div className="PreLoader-absolute__box"></div>
                    <div className="PreLoader-absolute__box"></div>
                    <div className="PreLoader-absolute__box"></div>
                    <div className="PreLoader-absolute__box"></div>
                    <div className="PreLoader-absolute__box"></div>
                </div>
            </div>
        )
    }
}