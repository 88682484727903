import React from 'react';

import VentilationParams from './VentilationParams';

import './styles.css';

const ventiltaionInitState = {
  title: '',
  name: '',
  topic_switch: '',
  feedbacks: { topic_switch_fb: '' },
};

export default props => {
  const selected = !!props.items.ventiltaion;
  const path = ['rooms', props.roomIndex, 'devicesEndpoints', 'climate'];

  const handlerClick = () => {
    if (!Array.isArray(props.items.ventiltaion)) {
      !selected && props.handlerChange(...path)(null, 'ventiltaion', [ { ...ventiltaionInitState } ]);
      return;
    }

    !selected && props.handlerChange(...path)(null, 'ventiltaion', ventiltaionInitState );
    selected && props.handlerChange(...path)(null, 'ventiltaion', { clear: true });

  }

  const handlerAddGroup = () => {
    selected && props.handlerChange(...path)(null, 'ventiltaion', ventiltaionInitState );
  }

  return (
    <div className='params-item__ventilation'>
      <div className='room__checkbox room__checkbox_scenes'>
        <label><input type='checkbox' onChange={() => handlerClick()} checked={selected} />Вентиляция</label>
      </div>
      { selected && 
        <div>
          { props.items.ventiltaion.map((item, i) =>
            <VentilationParams
              key={i}
              roomIndex={props.roomIndex}
              itemIndex={i}
              item={item}
              itemsLength={props.items.ventiltaion.length}
              handlerChange={props.handlerChange}
            />)}
          <div
            className='room__button_add'
            onClick={() => handlerAddGroup()}
          >Добавить вентилятор</div>
        </div>
      }
    </div>
  );
}