import React, { Component } from 'react';

import { connect } from 'react-redux';

import dispath from '../../../store/dispatch.js';

import './Home.css';

class Home extends Component {
    render() {
        return (
            <div className="home">
                {
                    this.props.ulData.map(
                        data =>
                            <div className="col-lg" key={ data.id }>
                                <div className="home_item backcolor" key={data.id} onClick={()=>this.props.changeMain(data.name)}>
                                    <div className="home_img">
                                        { data.svg }
                                    </div>
                                    <div className="home_text">
                                        <p className="home_p uppercase">{ data.text }</p>
                                    </div>
                                </div>
                            </div>
                    )
                }
            </div>
        );
    };
};

export default connect(dispath.mapStateToProps, dispath.mapDispatchToProps)(Home);