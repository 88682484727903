import React, { Component } from 'react';

import DefaultInput from '../../../../../DumbComponents/DefaultInput';

import './styles.css';

export default props => {
  const renderFloorItems = () => {
    const returnedFloors = [];

    returnedFloors.push(<option key={0} value=''>Выберите этаж</option>);

    props.floorsList.map((item, i) => {
      returnedFloors.push(<option key={i + 1} value={item._id} >{item.flrnumber}</option>)
    })

    return returnedFloors;
  }

  return (
    <div className='apart__info'>
      <div className='apart-info__item'>
        <div className='apart-info__title'>Апартаменты:</div>
        <DefaultInput
          name='aptnumber'
          placeholder='aptnumber'
          onChange={props.handlerChange('apart')}
          value={props.apart.aptnumber}
          required={!props.apart.aptnumber}
        />
      </div>

      <div className='apart-info__item'>
        <div className='apart-info__title'>Этаж:</div>
          {
            props.floorList !== null &&
              <select
                onChange={(e) => props.handlerChange('apart')(null, 'floor_id', e.target.value)}
                value={props.apart.floor_id}
                required={!props.apart.floor_id}
              >
                {
                  props.floorsList !== null && renderFloorItems()
                }
              </select>
          }
      </div>
    </div>
  );
}