import React, { Component } from 'react';

import './Img.css';

class Img extends Component {
    render() {
        return (
            <div className="img" onClick={ () => this.props.changeImgOpen(!this.props.imgOpen, undefined) }>
                <img className="img_img" src={this.props.imgSrc} alt="Прикрепленный файл"></img>
            </div>
        );
    };
}

export default Img;