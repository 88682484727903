import React, { Component } from 'react';

import { connect } from 'react-redux';
import { _refreshToken } from '../../Refresh/Refresh';

import Services_Category_item from '../Services_Category_item/Services_Category_item.js';

import api from '../../../../api';

import dispath from '../../../../store/dispatch.js';

// import './Services_main.css';

class Services_Category extends Component {
    constructor(props) {
        super(props);

    }
    componentWillMount() {
        // console.log('this.props.access_token: ', this.props.access_token);
        this.getOnlineServicesApi();
        this.getOnlineAllCategoryServices();

    }

    getOnlineServicesApi = () => {
        api.getOnlineServices(this.props.access_token, this.props.LOGIN_TYPE_USER)
            .then(
                (req) => {

                    // console.log(req);
                }
            )
            .catch(
                (err) => {
                    console.log(err.response);
                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                        _refreshToken(
                            this.props.client_id,
                            this.props.LOGIN,
                            this.props.PASSWORD,
                            this.props.changeAccessToken,
                            this.getOnlineServicesApi.bind(this)
                        )
                    }
                }
            )
    }
    getOnlineAllCategoryServices = () => {
        api.getOnlineAllCategoryServices(this.props.access_token, this.props.LOGIN_TYPE_USER)
            .then(
                (req) => {
                    // console.log('changeOnlineAllServicesCategoryData', req);
                    this.props.changeOnlineAllServicesCategoryData(req.data);
              

                }
            )
            .catch(
                (err) => {
                    console.log(err);
                    console.log(err.response);
                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                        _refreshToken(
                            this.props.client_id,
                            this.props.LOGIN,
                            this.props.PASSWORD,
                            this.props.changeAccessToken,
                            this.getOnlineServicesApi.bind(this)
                        )
                    }
                }
            )
    }
    onScrollList(event) {
        const scrollBottom = event.target.scrollTop +
            event.target.offsetHeight == event.target.scrollHeight;

        if (scrollBottom) {
            this.loadContent(); //API method
        }
    }

    loadContent() {
        api.getOnlineServices(this.props.access_token, this.props.LOGIN, this.props.PASSWORD)
            .then(
                (req) => {

                    // console.log(req);
                }
            )
            .catch(
                (err) => {
                    console.log(err);
                    console.log(err.response);
                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                        _refreshToken(
                            this.props.client_id,
                            this.props.LOGIN,
                            this.props.PASSWORD,
                            this.props.changeAccessToken,
                            this.loadContent.bind(this)
                        )
                    }
                }
            )

    }

    render() {
        return (
            <div className="accounts_main">
                <div className="accounts_main_titles">
                    <ul className="accounts_main_titles_ul flex label">
                        <li className="accounts_main_titles_li w100">Название</li>
                        <li className="accounts_main_titles_li w100">Активность</li>
                        <li className="accounts_main_titles_li w50"></li>
                    </ul>
                </div>
                <div
                    className="scroll h90"
                    onScroll={event => this.onScrollList(event)}
                >
                    {
                        this.props.onlineServicesCategoryData !== undefined
                            ?
                            this.props.onlineServicesCategoryData._items.map(
                                data_items => <Services_Category_item key={data_items._id + data_items.category_id + data_items._id} data_items={data_items} />
                            )
                            :
                            null
                    }
                </div>
            </div>
        );
    };
};

export default connect(dispath.mapStateToProps, dispath.mapDispatchToProps)(Services_Category);