import React, { Component } from 'react';

import api from '../../../../api';

import { connect } from 'react-redux';
import { _refreshToken } from '../../Refresh/Refresh';
import dispatch from '../../../../store/dispatch.js';
import imageCompression from 'browser-image-compression';

import empty_avatar from './empty_avatar.jpg';
class Services_Category_Add extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            newPhoto: undefined,
            saveNewPhoto: undefined,
            user_id: undefined
        }
    }
    componentWillMount() {
        if (this.props.services_category_photo_for_save !== undefined) {
            this.getImageApi();
        }
    }
    getImageApi = () => {
        // console.log(this.props.services_category_photo_for_save);
        api.getImage(this.props.services_category_photo_for_save, this.props.access_token)
            .then(request => {
                // console.log(request);

                let img_file = `data:${request.data.image.content_type};base64, ${request.data.image.file}`
                // console.log(img_file);

                this.props.changeStateCreateServiceCategoryPhoto({ imagePreviewUrl: img_file });
            })
            .catch(
                error => {
                    console.log(error);
                    if (error.response.status === 401 && error.response.data._error.message === 'token expired' || error.response.status === 401 && error.response.data._error.message === 'user not found') {

                        _refreshToken(
                            this.props.client_id,
                            this.props.LOGIN,
                            this.props.PASSWORD,
                            this.props.changeAccessToken,
                            this.getImageApi.bind(this)
                        )
                    }
                }
            )
    }
    saveOnServerIf() {
        // console.log(this.state.newPhoto);
        if (this.state.newPhoto !== undefined) {

            const Newdata = new FormData();
            Newdata.append("image", this.state.newPhoto);
            Newdata.append("owner_id", this.state.user_id);

            api.createImageId(Newdata, this.props.access_token, this.props.LOGIN_TYPE_USER)
                .then(
                    req => {
                        // console.log(req);
                        // console.log(req.data._id);

                        this.props.changeServicesCategoryPhotoForSave(req.data._id);
                        this.setState({
                            saveNewPhoto: req.data._id
                        })
                        this.saveOnServer();

                    })
                .catch(
                    err => {
                        console.log(err);
                        console.log(err.response);
                        if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                            _refreshToken(
                                this.props.client_id,
                                this.props.LOGIN,
                                this.props.PASSWORD,
                                this.props.changeAccessToken,
                                this.saveOnServerIf.bind(this)
                            )
                        }
                    }
                )
        } else {
            this.setState({
                saveNewPhoto: this.props.services_category_photo_for_save
            })
            this.saveOnServer();
        }
    }
    async _handleImgPhotoServicesCategoryChange(e) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        if (file !== undefined) {
            // you should provide one of maxSizeMB, maxWidthOrHeight in the options
            const options = {
                maxSizeMB: 0.2,
                maxWidthOrHeight: 320,
                useWebWorker: true
            }
            try {
                const compressedFile = await imageCompression(file, options);
                // console.log('File', file);
                // console.log(`compressedFile`, compressedFile);

                await reader.readAsDataURL(compressedFile);
                await this.props.changeStateCreateServiceCategoryPhoto({
                    file: compressedFile
                });
                this.setState({
                    newPhoto: compressedFile,
                    user_id: this.props.user_id
                })
            } catch (error) {
                console.log(error);
            }
            reader.onloadend = () => {
                this.props.changeStateCreateServiceCategoryPhoto({
                    imagePreviewUrl: reader.result
                });

            }


        }

    }
    backTo(value) {
        switch (value) {
            case "loftec_services":

                if (this.props.stateWindowServices.servicesOpen === true && this.props.stateWindowServices.servicesEdit === true) {
                    this.props.changeStateWindowServices(!this.props.stateWindowServices.servicesOpen);
                    this.props.changeStateWindowServicesEdit(!this.props.stateWindowServices.servicesEdit);
                    return;
                } else if (this.props.stateWindowServices.servicesOpen === true) {
                    this.props.changeStateWindowServices(!this.props.stateWindowServices.servicesOpen)
                    return;
                } else if (this.props.stateWindowServices.servicesEdit === true) {
                    this.props.changeStateWindowServicesEdit(!this.props.stateWindowServices.servicesEdit);
                    return;
                } else if (this.props.stateWindowServices.servicesCategoryEdit === true) {
                    this.props.changeStateWindowServicesCategoryEdit(!this.props.stateWindowServices.servicesCategoryEdit);
                    return;
                } else if (this.props.stateWindowServices.servicesCategory === true) {
                    this.props.changeStateWindowServicesCategory(!this.props.stateWindowServices.servicesCategory);
                    return;
                } else {
                    this.props.changeMain("home");
                }
                break;

            default:
                break;
        }
    }
    saveOnServer = () => {
        const dataOnServerCreate = {
            image: this.state.saveNewPhoto,
            title: this.props.state_services_category_title,
            active: this.props.state_services_category_active,
        }
        // console.log('***************** create Service', dataOnServerCreate);
        if (this.props.statusSendDataServicesOnServer === "servicesCreate") {
            api.createOnlineServicesCategory(dataOnServerCreate, this.props.LOGIN_TYPE_USER, this.props.access_token)
                .then((response) => {
                    api.getOnlineAllCategoryServices(this.props.access_token, this.props.LOGIN_TYPE_USER)
                        .then((req) => {
                            this.props.changeOnlineAllServicesCategoryData(req.data);
                            this.props.changeStateServicesCategoryPage(1);
                            // console.log(req);
                        })
                        .catch(
                            (err) => {
                                console.log(err);
                                console.log(err.response);
                                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                    _refreshToken(
                                        this.props.client_id,
                                        this.props.LOGIN,
                                        this.props.PASSWORD,
                                        this.props.changeAccessToken,
                                        this.saveOnServer.bind(this)
                                    )

                                }
                            }
                        );

                    // console.log(response);
                })
                .catch((err) => {
                    console.log(err.response);
                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                        _refreshToken(
                            this.props.client_id,
                            this.props.LOGIN,
                            this.props.PASSWORD,
                            this.props.changeAccessToken,
                            this.saveOnServer.bind(this)
                        )
                    }
                });
        } else {
            // console.log(this.props.state_services_category_id);
            api.editOnlineServicesCategory(this.props.state_services_category_id, this.props.state_services_category_etag, this.props.LOGIN_TYPE_USER, dataOnServerCreate, this.props.access_token)
                .then((response) => {
                    api.getOnlineAllCategoryServices(this.props.access_token, this.props.LOGIN_TYPE_USER)
                        .then((req) => {
                            this.props.changeOnlineAllServicesCategoryData(req.data);
                            this.props.changeStateServicesCategoryPage(1);
                            // console.log(req);
                        })
                        .catch(
                            (err) => {
                                console.log(err);
                                console.log(err.response);
                                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                    _refreshToken(
                                        this.props.client_id,
                                        this.props.LOGIN,
                                        this.props.PASSWORD,
                                        this.props.changeAccessToken,
                                        this.saveOnServer.bind(this)
                                    )

                                }
                            }
                        );

                    // console.log(response);
                })
                .catch((err) => {
                    console.log(err.response);
                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                        _refreshToken(
                            this.props.client_id,
                            this.props.LOGIN,
                            this.props.PASSWORD,
                            this.props.changeAccessToken,
                            this.saveOnServer.bind(this)
                        )
                    }
                });
        }

        // this.props.getInitialState();
        this.backTo(this.props.mainName);
        return;
    }
    render() {
        // console.log(this.props.state_services_category_title);
        const svg = [
            <svg
                width="52px"
                height="52px"
            >
                <path
                    fillRule="evenodd"
                    fill="rgb(194, 194, 194)"
                    d="M26.000,52.000 C11.664,52.000 -0.000,40.337 -0.000,26.000 C-0.000,11.663 11.664,-0.000 26.000,-0.000 C40.336,-0.000 52.000,11.663 52.000,26.000 C52.000,40.337 40.336,52.000 26.000,52.000 ZM26.000,2.000 C12.767,2.000 2.000,12.767 2.000,26.000 C2.000,39.233 12.767,50.000 26.000,50.000 C39.233,50.000 50.000,39.233 50.000,26.000 C50.000,12.767 39.233,2.000 26.000,2.000 ZM38.500,27.000 L27.000,27.000 L27.000,39.000 C27.000,39.553 26.552,40.000 26.000,40.000 C25.448,40.000 25.000,39.553 25.000,39.000 L25.000,27.000 L13.500,27.000 C12.948,27.000 12.500,26.553 12.500,26.000 C12.500,25.447 12.948,25.000 13.500,25.000 L25.000,25.000 L25.000,14.000 C25.000,13.447 25.448,13.000 26.000,13.000 C26.552,13.000 27.000,13.447 27.000,14.000 L27.000,25.000 L38.500,25.000 C39.052,25.000 39.500,25.447 39.500,26.000 C39.500,26.553 39.052,27.000 38.500,27.000 Z"
                />
            </svg>,
            <svg
                width="16px"
                height="13px">
                <image x="0px" y="0px" width="16px" height="13px" href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAANCAQAAAAKsiavAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfiDBQSJQ0EM+akAAAAmklEQVQY032QXRmDQAwE53BwFrCAhbNQC1g4DZUAEmrhkFAkFAlFwvSBn7ZfofuYbCbJBk7khQa4nrWjGYx21RmBHsLMxD+ZbSprs/Gj2Jj3K+YwYtKtBGDR1dgCVMBIuzGsd+eNaDZjsph3bGdaCG9kshi9L/M+4NtQAYSZwRZoD4IxWZbZnfNLgDAx0NEfhZEs6/7n8ss34QVoKVga5iI1pgAAAABJRU5ErkJggg==" />
            </svg>
        ]
        return (
            <div className="account flex" >
                <div className="col-lg3">
                    <div>
                        <span className="label">Фото</span><br />
                        <label htmlFor="account_photo_img_input">
                            <div className="account_photo">
                                <img className="account_photo_img" src={this.props.state_services_category_photo === undefined ? empty_avatar : this.props.state_services_category_photo.imagePreviewUrl} alt="Фото"></img>
                                <input
                                    name="account_photo_img_input"
                                    id="account_photo_img_input"
                                    className="inputFile"
                                    type="file"
                                    onChange={(e) => this._handleImgPhotoServicesCategoryChange(e)}
                                />
                            </div>
                            <a className="button_account_add flex center text_white margin-top20">загрузить фото</a>
                        </label>
                    </div>
                </div>
     
                <div className="col-lg9 padding0 ">
                <div>
                        <span className="label">Название</span><br />
                        <input
                            className="input_user_edit w100"
                            value={this.props.state_services_category_title === undefined ? "" : this.props.state_services_category_title}
                            type="text"
                            placeholder="Название"
                            onChange={(event) => {
                                this.props.changeStateEditServicesCategoryTitle(event.target.value);
                            }
                            }
                            onBlur={(event) => {
                                this.props.changeStateEditServicesCategoryTitle(event.target.value);
                            }
                            }
                        />
                    </div>
                    <div className="margin-bottom100">

                        <label className="container-checkbox margin-top20">
                            <span className="checkbox-text">Активность</span>
                            <input type="checkbox"
                                defaultChecked={this.props.state_services_category_active === true ? "checked" : ""}
                                onChange={(event) => {
                                    this.props.changeStateEditServicesCategoryActive(event.target.checked);
                                }
                                }></input>
                            <span className="checkmark-checkbox"></span>
                        </label>
                    </div>
                    <div className="flex align-flex-end row">
                        <div className="col-lg6">
                            <a className="button_account_cancel flex center text_white" onClick={() => this.props.changeStateWindowServicesCategoryEdit(!this.props.stateWindowServices.servicesCategoryEdit)}>отмена</a>
                        </div>

                        <div className="col-lg6 padding0">
                            <a className="button_account_save flex center text_white" onClick={() => this.saveOnServerIf()}>{this.props.statusSendDataServicesOnServer === "servicesEdit" ? "сохранить изменения" : "сохранить"}</a>
                        </div>
                    </div>
                </div>

            </div>
        );
    };
};

export default connect(dispatch.mapStateToProps, dispatch.mapDispatchToProps)(Services_Category_Add);