import React, { Component } from 'react';

import { connect } from 'react-redux';
import { _refreshToken } from '../Refresh/Refresh';
import Accounts_main from './Accounts_main/Accounts_main.js';
import Account from './Account/Account.js';
import Account_Add from './Account_Add/Account_Add.js';

import api from '../../../api';

import dispath from '../../../store/dispatch.js';

import PreLoader from '../PreLoader/PreLoader.js';
import './Accounts.css';

class Accounts extends Component {


    backTo(value) {
        this.props.changeStateSearchAccounts(undefined);
        switch (value) {
            case "apart":
                if (this.props.stateWindowOpenNewOperator === true) {
                    this.props.changeMain("lk");
                    this.props.changeStateWindowAccounts(false);
                    this.props.changeStateWindowAccountEdit(false);
                } else {
                    if (this.props.stateWindowAccounts.accountOpen === true && this.props.stateWindowAccounts.accountEdit === true) {
                        this.props.changeStateWindowAccounts(!this.props.stateWindowAccounts.accountOpen);
                        this.props.changeStateWindowAccountEdit(!this.props.stateWindowAccounts.accountEdit);
                        return;
                    } else if (this.props.stateWindowAccounts.accountOpen === true) {
                        this.props.changeStateWindowAccounts(!this.props.stateWindowAccounts.accountOpen)
                        return;
                    } else if (this.props.stateWindowAccounts.accountEdit === true) {
                        this.props.changeStateWindowAccountEdit(!this.props.stateWindowAccounts.accountEdit);
                        return;
                    } else {
                        this.props.changeStateAccountPage(1);
                        this.props.changeMain("home");
                    }
                    break;
                }


            default:
                break;
        }
    }

    createAccount() {
        this.props.changesStateWindowOpenNewOperator(false);
        this.props.changeStatusSendDataAccountOnServer('create');
        this.props.changeStateWindowAccountEdit(!this.props.stateWindowAccounts.accountEdit);

        this.props.changeStateEditAccountId(undefined);
        this.props.changeStateEditAccountEtag(undefined);

        this.props.changeStateCreateUserFirstName(undefined);
        this.props.changeStateCreateUserSecondName(undefined);
        this.props.changeStateCreateUserThirdName(undefined);
        this.props.changeStateCreateUserTel(undefined);
        this.props.changeStateCreateUserTypeUser(undefined);
        this.props.changeStateCreateUserUserEmail(undefined);
        this.props.changeStateCreateUserPhoto(undefined);
        this.props.changeUserPhotoForSave(undefined);
        // this.props.changeStateCreateUserImgPassport(undefined);
        this.props.changeStateCreateUserNumApart(undefined);
        this.props.changeStateCreateUserSizeApart(undefined);
    }

    render() {

        const button_icon =
            <svg
                width="20px"
                height="20px">
                <path
                    fillRule="evenodd"
                    fill="rgb(255, 255, 255)"
                    d="M10.000,20.000 C4.486,20.000 -0.000,15.514 -0.000,10.000 C-0.000,4.486 4.486,-0.000 10.000,-0.000 C15.514,-0.000 20.000,4.486 20.000,10.000 C20.000,15.514 15.514,20.000 10.000,20.000 ZM10.000,0.769 C4.910,0.769 0.769,4.910 0.769,10.000 C0.769,15.090 4.910,19.231 10.000,19.231 C15.090,19.231 19.231,15.090 19.231,10.000 C19.231,4.910 15.090,0.769 10.000,0.769 ZM14.808,10.385 L10.385,10.385 L10.385,15.000 C10.385,15.213 10.212,15.385 10.000,15.385 C9.788,15.385 9.615,15.213 9.615,15.000 L9.615,10.385 L5.192,10.385 C4.980,10.385 4.808,10.213 4.808,10.000 C4.808,9.787 4.980,9.615 5.192,9.615 L9.615,9.615 L9.615,5.385 C9.615,5.172 9.788,5.000 10.000,5.000 C10.212,5.000 10.385,5.172 10.385,5.385 L10.385,9.615 L14.808,9.615 C15.020,9.615 15.192,9.787 15.192,10.000 C15.192,10.213 15.020,10.385 14.808,10.385 Z"
                />
            </svg>

        const svg = [
            <svg
                width="20px"
                height="25px">
                <path
                    fillRule="evenodd"
                    fill="rgb(112, 112, 112)"
                    d="M18.461,25.000 L0.537,25.000 C0.240,25.000 -0.000,24.756 -0.000,24.456 L-0.000,2.576 C-0.000,2.275 0.240,2.032 0.537,2.032 L11.016,2.032 C11.312,2.032 11.553,2.275 11.553,2.576 C11.553,2.876 11.312,3.119 11.016,3.119 L1.074,3.119 L1.074,23.912 L17.924,23.912 L17.924,6.437 C17.924,6.137 18.164,5.893 18.461,5.893 C18.757,5.893 18.998,6.137 18.998,6.437 L18.998,24.456 C18.998,24.756 18.757,25.000 18.461,25.000 ZM17.126,4.775 C17.126,4.775 17.125,4.775 17.125,4.776 C17.125,4.776 17.125,4.776 17.125,4.776 L8.608,13.400 C8.540,13.468 8.455,13.517 8.363,13.542 L5.547,14.282 C5.503,14.294 5.457,14.299 5.412,14.299 C5.272,14.299 5.135,14.243 5.033,14.140 C4.898,14.004 4.845,13.806 4.893,13.619 L5.624,10.768 C5.648,10.674 5.696,10.589 5.764,10.520 L14.281,1.896 C14.281,1.896 14.282,1.896 14.282,1.896 C14.282,1.896 14.282,1.896 14.282,1.895 L15.997,0.159 C16.097,0.057 16.234,-0.000 16.376,-0.000 C16.519,-0.000 16.655,0.057 16.756,0.159 L18.840,2.270 C19.050,2.482 19.050,2.827 18.840,3.039 L17.126,4.775 ZM6.627,11.185 L6.162,12.997 L7.952,12.526 L15.986,4.391 L14.661,3.049 L6.627,11.185 ZM16.376,1.313 L15.421,2.280 L16.746,3.622 L17.701,2.654 L16.376,1.313 Z"
                />
            </svg>,
            <svg
                width="9px"
                height="16px">
                <path
                    fillRule="evenodd"
                    fill="rgb(255, 255, 255)"
                    d="M7.986,0.155 C8.102,0.051 8.247,-0.000 8.407,-0.000 C8.566,-0.000 8.711,0.051 8.827,0.155 C9.058,0.362 9.058,0.698 8.827,0.905 L1.438,7.501 L8.827,14.096 C9.058,14.303 9.058,14.640 8.827,14.846 C8.595,15.053 8.218,15.053 7.986,14.846 L0.178,7.876 C-0.054,7.669 -0.054,7.333 0.178,7.126 L7.986,0.155 Z"
                />
            </svg>,
            <svg
                width="25px"
                height="25px">
                <path
                    fillRule="evenodd"
                    fill="rgb(255, 255, 255)"
                    d="M21.337,21.337 C18.926,23.747 15.723,25.000 12.488,25.000 C10.474,25.000 8.444,24.514 6.589,23.515 C4.923,24.715 3.295,24.974 2.190,24.974 C1.778,24.974 1.440,24.937 1.202,24.900 C0.695,24.820 0.303,24.424 0.224,23.916 C0.145,23.409 0.404,22.918 0.864,22.690 C1.820,22.220 2.465,21.279 2.867,20.470 C-1.230,15.539 -0.912,8.229 3.655,3.663 C6.018,1.300 9.152,-0.000 12.493,-0.000 C15.834,-0.000 18.969,1.300 21.331,3.663 C23.694,6.025 25.000,9.160 25.000,12.500 C25.000,15.840 23.700,18.975 21.337,21.337 ZM20.316,4.672 C16.003,0.354 8.978,0.354 4.659,4.672 C0.525,8.805 0.325,15.465 4.194,19.836 C4.294,19.952 4.352,20.095 4.368,20.238 C4.405,20.386 4.395,20.544 4.331,20.698 C3.935,21.601 3.258,22.764 2.185,23.546 L2.190,23.546 C3.121,23.546 4.527,23.314 5.959,22.204 C5.975,22.188 5.996,22.172 6.018,22.162 C6.245,21.934 6.599,21.882 6.885,22.051 C11.230,24.604 16.754,23.895 20.322,20.328 C24.641,16.009 24.641,8.990 20.316,4.672 ZM17.018,13.377 C16.533,13.377 16.140,12.985 16.140,12.500 C16.140,12.015 16.533,11.623 17.018,11.623 C17.503,11.623 17.895,12.015 17.895,12.500 C17.895,12.985 17.503,13.377 17.018,13.377 ZM12.493,13.377 C12.008,13.377 11.616,12.985 11.616,12.500 C11.616,12.015 12.008,11.623 12.493,11.623 C12.978,11.623 13.371,12.015 13.371,12.500 C13.371,12.985 12.978,13.377 12.493,13.377 ZM7.968,13.377 C7.484,13.377 7.091,12.985 7.091,12.500 C7.091,12.015 7.484,11.623 7.968,11.623 C8.453,11.623 8.846,12.015 8.846,12.500 C8.846,12.985 8.453,13.377 7.968,13.377 Z"
                />
            </svg>,
            <svg
                width="21px"
                height="21px"
            >
                <path
                    d="M20.344,-0.000 L17.503,-0.000 C17.348,-0.000 17.199,0.055 17.081,0.155 L7.787,8.002 C7.359,7.917 6.956,7.875 6.562,7.875 C2.944,7.875 -0.000,10.819 -0.000,14.437 C-0.000,18.056 2.944,21.000 6.562,21.000 C10.181,21.000 13.125,18.056 13.125,14.437 C13.125,13.748 13.015,13.065 12.797,12.403 L14.793,11.105 C14.981,10.984 15.094,10.777 15.094,10.555 L15.094,8.170 L17.587,8.039 C17.913,8.022 18.177,7.769 18.207,7.444 L18.447,4.917 L20.626,3.874 C20.857,3.764 21.000,3.533 21.000,3.281 L21.000,0.656 C21.000,0.294 20.705,-0.000 20.344,-0.000 ZM3.281,16.406 C3.281,15.683 3.871,15.094 4.594,15.094 C5.100,15.094 5.566,15.392 5.783,15.848 C5.864,16.023 5.906,16.211 5.906,16.406 C5.906,17.129 5.317,17.719 4.594,17.719 C3.871,17.719 3.281,17.129 3.281,16.406 ZM19.687,2.868 L17.545,3.892 C17.338,3.991 17.195,4.192 17.175,4.422 L16.955,6.757 L14.405,6.891 C14.054,6.910 13.781,7.198 13.781,7.547 L13.781,10.198 L11.639,11.591 C11.373,11.764 11.268,12.105 11.392,12.396 C11.672,13.053 11.812,13.739 11.812,14.437 C11.812,17.333 9.458,19.687 6.562,19.687 C5.569,19.687 4.649,19.395 3.855,18.913 C4.091,18.983 4.335,19.031 4.594,19.031 C6.041,19.031 7.219,17.855 7.219,16.406 C7.219,16.016 7.135,15.640 6.969,15.288 C6.539,14.373 5.606,13.781 4.594,13.781 C3.146,13.781 1.969,14.957 1.969,16.406 C1.969,16.665 2.017,16.909 2.087,17.145 C1.605,16.352 1.312,15.431 1.312,14.437 C1.312,11.542 3.667,9.187 6.562,9.187 C6.950,9.187 7.356,9.241 7.807,9.350 C8.010,9.400 8.225,9.350 8.385,9.214 L17.744,1.312 L19.687,1.312 L19.687,2.868 Z"
                />
            </svg>
        ]
    
        return (
            <div className="accounts container_main backcolor">
                <div className="container_header header_h">
                    <div className="svg_box fill-gray-svg">
                        {/* {svg[3]} */}
                        {this.props.ulData[0].svg}
                    </div>
                    <div className="svg_box margin-left hover_svg" onClick={() => this.backTo(this.props.mainName)}>
                        {svg[1]}
                    </div>
                </div>
                <div className="accounts_header flex flex-end">

                    <div className="accounts_button flex center" onClick={() => this.createAccount()}>
                        <span className="flex center">{this.props.stateWindowAccounts.accountEdit === false ? button_icon : null}<a className="accounts_button_a">{this.props.stateWindowAccounts.accountEdit === false ? "Добавить жильца" : "Скрыть меню"}</a></span>
                    </div>

                </div>
                {this.props.stateWindowAccounts.accountOpen === false && this.props.stateWindowAccounts.accountEdit === false ?
                    <Accounts_main />
                    :
                    this.props.stateWindowAccounts.accountEdit === true ?
                        <Account_Add />
                        :
                        <Account />
                }
            </div>
        );
    };
};

export default connect(dispath.mapStateToProps, dispath.mapDispatchToProps)(Accounts);