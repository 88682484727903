import React from 'react';

import DefaultInput from '../../../../../../DumbComponents/DefaultInput';

import './styles.css';

export default props => {
  const path = ['rooms', props.roomIndex, 'devicesEndpoints', 'light_scenes', props.itemIndex];

  return (
    <div className='params__item params__item_scenes'>
      <div className='params-item__field'>
        <span className='params-field__title'>Название</span>
        <DefaultInput
          name='title'
          value={props.item.title}
          onChange={props.handlerChange(...path)}
          placeholder='title'
          required={!props.item.title}
        />
      </div>

      <div className='params-item__field'>
        <span className='params-field__title'>Команда вызова</span>
        <DefaultInput
          name='topic_call'
          value={props.item.topic_call}
          onChange={props.handlerChange(...path)}
          placeholder='topic_call'
          required={!props.item.topic_call}
        />
      </div>
      <div className='params-item__field'>
        <span className='params-field__title'>Команда сохранить</span>
        <DefaultInput
          name='topic_save'
          value={props.item.topic_save}
          onChange={props.handlerChange(...path)}
          placeholder='topic_save'
        />
      </div>
      <div className='params-item__field'>
        <span className='params-field__title'>Фидбэк</span>
        <DefaultInput
          name='topic_fb'
          value={props.item.feedbacks.topic_fb}
          onChange={props.handlerChange(...path, 'feedbacks')}
          placeholder='topic_fb'
        />
      </div>
    </div>
  );
}