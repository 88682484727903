import React, { Component } from "react";

import { connect } from "react-redux";
import { _refreshToken } from "../App_Main/Refresh/Refresh";
import dispatch from "../../store/dispatch.js";

import cookieAction from "../../cookieAction";

import "./App.css";

import Login from "../Login/Login.js";
import MainComponent from "../MainComponent";

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoadPhoto: false
		};
	}
	componentWillMount() {
		// console.log(window.navigator);
		if (!navigator.cookieEnabled) {
			alert("Включите cookie для работы с этим сайтом");
		}
		window.navigator.geolocation.getCurrentPosition(position => {
			// console.log(position);
		});
		// this.getApartments();
		let cookieActionAT = cookieAction.getCookie("AT");
		let log = cookieAction.getCookie("U");
		if (cookieActionAT !== undefined && log !== undefined) {
			this.props.changeStatusLogin(true);
			this.props.changeAccessToken(cookieActionAT);
			this.props.changeAccountId(cookieAction.getCookie("AI"));
			this.props.changeClientId(cookieAction.getCookie("CI"));
			this.props.changeExpires(cookieAction.getCookie("E"));
			this.props.changeRefreshToken(cookieAction.getCookie("RT"));
			this.props.changeUserName(cookieAction.getCookie("UN"));
			if (cookieAction.getCookie("U") !== undefined) {
				this.props.changeLogin(cookieAction.getCookie("U").replace(/%40/,'@'));
			};
			this.props.changePassword(cookieAction.getCookie("W"));
			this.props.changeUserId(cookieAction.getCookie("UI"));
			this.props.changeLoginTypeUser(cookieAction.getCookie("ROLE"));
		} else {
			this.props.changeStatusLogin(false);
			return;
		}
	}

	render() {
		return (
			<div className="App">
				{this.props.STATUS_LOGIN !== true ? (
					<Login />
				) : (
						<MainComponent />
					)}
				{/* <div className="flex-container w100">
                    { this.props.stateWindowServicerequests.imgOpen === true ? <Img imgOpen={this.props.stateWindowServicerequests.imgOpen} imgSrc={this.props.stateWindowServicerequests.imgSrc} changeImgOpen={this.props.changeImgOpen}/> : null }
                    <App_left_column />
                    <App_Main />
                    <App_right_column />
                </div> */}
			</div>
		);
	}
}

export default connect(
	dispatch.mapStateToProps,
	dispatch.mapDispatchToProps
)(App);
