import React, { Component } from 'react';

import { connect } from 'react-redux';
import { _refreshToken } from '../Refresh/Refresh';
import dispath from '../../../store/dispatch.js';
import PreLoader from '../PreLoader/PreLoader.js';
import './Requests.css';

import Request_item from './Request_item/Request_item.js';
import Request from './Request/Request.js'
import api from '../../../api/index.js';

class Requests extends Component {

    constructor(props) {
        super(props);
        this.props.changeStateRequestPage(1);
        // this.getServicerequestsApi();

        this.state = {
            isLoad: false
        };

    }

    getServicerequestsApi = async () => {

        await api.getServicerequests(this.props.LOGIN_TYPE_USER, this.props.access_token, 1)
            .then(
                (response) => {
                    console.log(response);
                    this.props.changeServicerequests(response.data._items);
                    api.getApartments(this.props.access_token, this.props.LOGIN_TYPE_USER)
                        .then(
                            (req) => {
                                // console.log('getAccountsApi getApartmentsApi');
                                // console.log('req.data', req.data);

                                if (req.data._items !== undefined, req.data._items !== 0, req.data._items !== null, req.data._items !== "") {
                                    this.props.changeApartmentsData(req.data);
                                    let items = response.data._items;
                                    let apartmentsInfo = req.data._items;



                                    if (items.length === 0) {
                                        this.props.changeServicerequests([]);
                                        this.setState({
                                            isLoad: true
                                        });
                                        return
                                    }
                                    items.forEach((item, i) => {

                                        apartmentsInfo.forEach((apartment) => {

                                            // console.log(apartment.aptnumber);
                                            if (item.apartment_id === apartment._id) {
                                                item.apartment_num = apartment.aptnumber;
                                                item.apartment_size = apartment.area;

                                            }
                                        });
                                        if (i === items.length - 1) {
                                            // console.log('items', items);

                                            this.props.changeServicerequests(items);
                                            this.setState({
                                                isLoad: true
                                            });

                                        }
                                    });

                                } else {
                                    return;
                                }

                            }
                        )
                        .catch(
                            (err) => {
                                console.log(err.response);
                                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                                    _refreshToken(
                                        this.props.client_id,
                                        this.props.LOGIN,
                                        this.props.PASSWORD,
                                        this.props.changeAccessToken,
                                        this.getServicerequestsApi.bind(this)
                                    )
                                }
                            }
                        )
                    // this.props.changeAccountData(response.data._items);

                })
            .catch(
                (err) => {
                    console.log(err.response);
                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                        _refreshToken(
                            this.props.client_id,
                            this.props.LOGIN,
                            this.props.PASSWORD,
                            this.props.changeAccessToken,
                            this.getServicerequestsApi.bind(this)
                        )
                    }
                }
            )

        await api.getServices(this.props.LOGIN_TYPE_USER, this.props.access_token)
            .then(
                (req) => {
                    this.props.changeServices(req.data._items);
                    // console.log(req);
                }
            )
            .catch(
                (err) => {
                    console.log(err.response);
                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                        _refreshToken(
                            this.props.client_id,
                            this.props.LOGIN,
                            this.props.PASSWORD,
                            this.props.changeAccessToken,
                            this.getServicerequestsApi.bind(this)
                        )
                    }
                }
            )


    }


    componentWillMount() {
        // console.log('****************** servicerequests', this.props.servicerequests);

    }

    getStatus(value) {
        switch (value) {
            case "new":
                return "Новая"
            case "accepted":
                return "Принято"
            case "cancelled":
                return "Отменено"
            case "completed":
                return "Выполнено"
            default:
                break;
        }
    }

    getDayText(dayInfo) {
        switch (dayInfo) {
            case 0:
                return "Воскресенье";
            case 1:
                return "Понедельник";
            case 2:
                return "Вторник";
            case 3:
                return "Среда";
            case 4:
                return "Четверг";
            case 5:
                return "Пятница";
            case 6:
                return "Суббота";
        }
    }

    getMonthText(monthInfo) {
        switch (monthInfo) {
            case 0:
                return "Января";
            case 1:
                return "Февраля";
            case 2:
                return "Марта";
            case 3:
                return "Апреля";
            case 4:
                return "Мая";
            case 5:
                return "Июня";
            case 6:
                return "Июля";
            case 7:
                return "Августа";
            case 8:
                return "Сентября";
            case 9:
                return "Октября";
            case 10:
                return "Ноября";
            case 11:
                return "Декабря";
        }
    }

    backTo(value) {
        // console.log('this.props.stateWindowServicerequests: ', this.props.stateWindowServicerequests);

        switch (value) {
            case "request":
                if (this.props.stateWindowServicerequests.requestOpen === true) {
                    this.props.changeStateWindowServicerequests(!this.props.stateWindowServicerequests.requestOpen)
                    return;
                } else {
                    this.props.changeMain("home");
                }
                break;

            default:
                break;
        }
    }
    //this.props.mainName

    onScrollList(event) {
        const scrollBottom = event.target.scrollTop +
            event.target.offsetHeight == event.target.scrollHeight;

        if (scrollBottom) {
            this.loadContent(); //API method
        }
    }

    loadContent() {
        api.getServicerequests(this.props.LOGIN_TYPE_USER, this.props.access_token, 1)
            .then(
                (response) => {
                    if (this.props.state_request_page === Math.ceil(response.data._meta.total / 25)) {
                        return;
                    } else if (response.data._meta.total > 25 && this.props.state_request_page !== Math.ceil(response.data._meta.total / 25)) {
                        this.props.changeStateRequestPage(this.props.state_request_page + 1);

                        api.getServicerequests(this.props.LOGIN_TYPE_USER, this.props.access_token, this.props.state_request_page)
                            .then(
                                (res) => {
                                    this.props.changeServicerequests(this.props.servicerequests.concat(res.data._items));
                                }
                            )
                            .catch(
                                (err) => {
                                    console.log(err.response);
                                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                                        _refreshToken(
                                            this.props.client_id,
                                            this.props.LOGIN,
                                            this.props.PASSWORD,
                                            this.props.changeAccessToken,
                                            this.loadContent.bind(this)
                                        )
                                    }
                                }
                            )
                    }

                })
            .catch((err) => {
                console.log(err.response);
                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                    _refreshToken(
                        this.props.client_id,
                        this.props.LOGIN,
                        this.props.PASSWORD,
                        this.props.changeAccessToken,
                        this.loadContent.bind(this)
                    )
                }
            });


    }

    render() {
        // console.log('this.props.servicerequests: ', this.props.servicerequests);
        if (!this.state.isLoad) {
            return (
                <PreLoader onLoad={this.getServicerequestsApi.bind(this)} />
            )
        }
        const svg = [
            <svg
                width="20px"
                height="25px">
                <path
                    fillRule="evenodd"
                    fill="rgb(112, 112, 112)"
                    d="M18.461,25.000 L0.537,25.000 C0.240,25.000 -0.000,24.756 -0.000,24.456 L-0.000,2.576 C-0.000,2.275 0.240,2.032 0.537,2.032 L11.016,2.032 C11.312,2.032 11.553,2.275 11.553,2.576 C11.553,2.876 11.312,3.119 11.016,3.119 L1.074,3.119 L1.074,23.912 L17.924,23.912 L17.924,6.437 C17.924,6.137 18.164,5.893 18.461,5.893 C18.757,5.893 18.998,6.137 18.998,6.437 L18.998,24.456 C18.998,24.756 18.757,25.000 18.461,25.000 ZM17.126,4.775 C17.126,4.775 17.125,4.775 17.125,4.776 C17.125,4.776 17.125,4.776 17.125,4.776 L8.608,13.400 C8.540,13.468 8.455,13.517 8.363,13.542 L5.547,14.282 C5.503,14.294 5.457,14.299 5.412,14.299 C5.272,14.299 5.135,14.243 5.033,14.140 C4.898,14.004 4.845,13.806 4.893,13.619 L5.624,10.768 C5.648,10.674 5.696,10.589 5.764,10.520 L14.281,1.896 C14.281,1.896 14.282,1.896 14.282,1.896 C14.282,1.896 14.282,1.896 14.282,1.895 L15.997,0.159 C16.097,0.057 16.234,-0.000 16.376,-0.000 C16.519,-0.000 16.655,0.057 16.756,0.159 L18.840,2.270 C19.050,2.482 19.050,2.827 18.840,3.039 L17.126,4.775 ZM6.627,11.185 L6.162,12.997 L7.952,12.526 L15.986,4.391 L14.661,3.049 L6.627,11.185 ZM16.376,1.313 L15.421,2.280 L16.746,3.622 L17.701,2.654 L16.376,1.313 Z"
                />
            </svg>,
            <svg
                width="9px"
                height="16px">
                <path
                    fillRule="evenodd"
                    fill="rgb(255, 255, 255)"
                    d="M7.986,0.155 C8.102,0.051 8.247,-0.000 8.407,-0.000 C8.566,-0.000 8.711,0.051 8.827,0.155 C9.058,0.362 9.058,0.698 8.827,0.905 L1.438,7.501 L8.827,14.096 C9.058,14.303 9.058,14.640 8.827,14.846 C8.595,15.053 8.218,15.053 7.986,14.846 L0.178,7.876 C-0.054,7.669 -0.054,7.333 0.178,7.126 L7.986,0.155 Z"
                />
            </svg>,
            <svg
                width="25px"
                height="25px">
                <path
                    fillRule="evenodd"
                    fill="rgb(255, 255, 255)"
                    d="M21.337,21.337 C18.926,23.747 15.723,25.000 12.488,25.000 C10.474,25.000 8.444,24.514 6.589,23.515 C4.923,24.715 3.295,24.974 2.190,24.974 C1.778,24.974 1.440,24.937 1.202,24.900 C0.695,24.820 0.303,24.424 0.224,23.916 C0.145,23.409 0.404,22.918 0.864,22.690 C1.820,22.220 2.465,21.279 2.867,20.470 C-1.230,15.539 -0.912,8.229 3.655,3.663 C6.018,1.300 9.152,-0.000 12.493,-0.000 C15.834,-0.000 18.969,1.300 21.331,3.663 C23.694,6.025 25.000,9.160 25.000,12.500 C25.000,15.840 23.700,18.975 21.337,21.337 ZM20.316,4.672 C16.003,0.354 8.978,0.354 4.659,4.672 C0.525,8.805 0.325,15.465 4.194,19.836 C4.294,19.952 4.352,20.095 4.368,20.238 C4.405,20.386 4.395,20.544 4.331,20.698 C3.935,21.601 3.258,22.764 2.185,23.546 L2.190,23.546 C3.121,23.546 4.527,23.314 5.959,22.204 C5.975,22.188 5.996,22.172 6.018,22.162 C6.245,21.934 6.599,21.882 6.885,22.051 C11.230,24.604 16.754,23.895 20.322,20.328 C24.641,16.009 24.641,8.990 20.316,4.672 ZM17.018,13.377 C16.533,13.377 16.140,12.985 16.140,12.500 C16.140,12.015 16.533,11.623 17.018,11.623 C17.503,11.623 17.895,12.015 17.895,12.500 C17.895,12.985 17.503,13.377 17.018,13.377 ZM12.493,13.377 C12.008,13.377 11.616,12.985 11.616,12.500 C11.616,12.015 12.008,11.623 12.493,11.623 C12.978,11.623 13.371,12.015 13.371,12.500 C13.371,12.985 12.978,13.377 12.493,13.377 ZM7.968,13.377 C7.484,13.377 7.091,12.985 7.091,12.500 C7.091,12.015 7.484,11.623 7.968,11.623 C8.453,11.623 8.846,12.015 8.846,12.500 C8.846,12.985 8.453,13.377 7.968,13.377 Z"
                />
            </svg>
        ]

        return (
            <div className="request container_main backcolor">
                <div className="container_header header_h">
                    <div className="svg_box">
                        {svg[0]}
                    </div>
                    <div className="svg_box margin-left hover_svg" onClick={() => this.backTo(this.props.mainName)}>
                        {svg[1]}
                    </div>
                </div>

                {
                    this.props.stateWindowServicerequests.requestOpen === false
                        ?
                        <div
                            className="requests_list"
                            onScroll={event => this.onScrollList(event)}
                        >
                            {this.props.servicerequests !== undefined ? this.props.servicerequests.map(
                                servicerequestItem =>
                                    <Request_item servicerequestItem={servicerequestItem} key={servicerequestItem._id} />
                            ) : null}
                        </div>
                        :
                        <Request />
                }

            </div>
        );
    };
};

export default connect(dispath.mapStateToProps, dispath.mapDispatchToProps)(Requests);