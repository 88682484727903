import React, { Component } from 'react';

import RoomInfo from './RoomInfo';
import Climate from './Climate';
import Curtains from './Curtains';
import Lights from './Lights';
import Sockets from './Sockets';
import Windows from './Windows';
import Scenes from './Scenes';
import SecuritySensors from './SecuritySensors';
import EndpointInfo from './EndpointInfo';

import './styles.css';

class Room extends Component {
  render() {
    const endpointInfo = {
      short_sn: this.props.roomInfo.devicesEndpoints.short_sn,
      device_url: this.props.roomInfo.devicesEndpoints.device_url,
      global_endpoint: this.props.roomInfo.devicesEndpoints.global_endpoint,
      master_off: this.props.roomInfo.devicesEndpoints.master_off,
    };

    return (
      <div className={`apart__room${this.props.index === 0 ? ' apart__room_global' : ''}`}>
        { this.props.index !== 0 && <RoomInfo
            roomIndex={this.props.index}
            rooms={this.props.rooms}
            roomInfo={this.props.roomInfo}
            handlerRemove={this.props.handlerRemove}
            handlerChange={this.props.handlerChange}
            handlerChangeRoomPosition={this.props.handlerChangeRoomPosition}
          />}

        <EndpointInfo
          roomIndex={this.props.index}
          info={endpointInfo}
          handlerChange={this.props.handlerChange}
        />

        <Scenes
          roomIndex={this.props.index}
          items={this.props.roomInfo.devicesEndpoints.light_scenes}
          handlerAddGroup={this.props.handlerAddGroup(this.props.index)}
          handlerRemoveGroup={this.props.handlerRemoveGroup(this.props.index)}
          handlerChange={this.props.handlerChange}
        />

        { this.props.index !== 0 && <Climate
            roomIndex={this.props.index}
            items={this.props.roomInfo.devicesEndpoints.climate}
            handlerAddGroup={this.props.handlerAddGroup(this.props.index)}
            handlerRemoveGroup={this.props.handlerRemoveGroup(this.props.index)}
            handlerChange={this.props.handlerChange}
          />}

        { this.props.index !== 0 && <Sockets
            roomIndex={this.props.index}
            items={this.props.roomInfo.devicesEndpoints.sockets}
            handlerAddGroup={this.props.handlerAddGroup(this.props.index)}
            handlerRemoveGroup={this.props.handlerRemoveGroup(this.props.index)}
            handlerChange={this.props.handlerChange}
          />}

        { this.props.index !== 0 && <Curtains
            roomIndex={this.props.index}
            items={this.props.roomInfo.devicesEndpoints.blinds}
            handlerAddGroup={this.props.handlerAddGroup(this.props.index)}
            handlerRemoveGroup={this.props.handlerRemoveGroup(this.props.index)}
            handlerChange={this.props.handlerChange}
          />}

        { this.props.index !== 0 && <Lights
            roomIndex={this.props.index}
            items={this.props.roomInfo.devicesEndpoints.light}
            handlerAddGroup={this.props.handlerAddGroup(this.props.index)}
            handlerRemoveGroup={this.props.handlerRemoveGroup(this.props.index)}
            handlerChange={this.props.handlerChange}
          />}

        <SecuritySensors
          roomIndex={this.props.index}
          items={this.props.roomInfo.devicesEndpoints.security_sensors}
          handlerAddGroup={this.props.handlerAddGroup(this.props.index)}
          handlerRemoveGroup={this.props.handlerRemoveGroup(this.props.index)}
          handlerChange={this.props.handlerChange}
        />

        { this.props.index !== 0 && <Windows
            roomIndex={this.props.index}
            items={this.props.roomInfo.devicesEndpoints.windows}
            handlerAddGroup={this.props.handlerAddGroup(this.props.index)}
            handlerRemoveGroup={this.props.handlerRemoveGroup(this.props.index)}
            handlerChange={this.props.handlerChange}
          />}
      </div>
    );
  }
}

export default Room;