import React, { Component } from 'react';

import { connect } from 'react-redux';
var moment = require('moment');
moment.locale("ru");

import dispath from '../../../../../store/dispatch.js';

import './Message.css';

class Message extends Component {

    getNotifacationDate(date) {
        return ('0' + new Date(date).getDate()).slice(-2) + '.' + ('0' + (new Date(date).getMonth() + 1)).slice(-2) + '.' + new Date(date).getUTCFullYear();
    }

    getNotifacationTime(date) {
        return ('0' + (new Date(date).getHours())).slice(-2) + ':' + ('0' + (new Date(date).getMinutes())).slice(-2);
    }

    render() {

        const svg = [
            <svg
                width="25px"
                height="25px">
                <path
                    fillRule="evenodd"
                    fill="rgb(255, 255, 255)"
                    d="M21.337,21.337 C18.926,23.747 15.723,25.000 12.488,25.000 C10.474,25.000 8.444,24.514 6.589,23.515 C4.923,24.715 3.295,24.974 2.190,24.974 C1.778,24.974 1.440,24.937 1.202,24.900 C0.695,24.820 0.303,24.424 0.224,23.916 C0.145,23.409 0.404,22.918 0.864,22.690 C1.820,22.220 2.465,21.279 2.867,20.470 C-1.230,15.539 -0.912,8.229 3.655,3.663 C6.018,1.300 9.152,-0.000 12.493,-0.000 C15.834,-0.000 18.969,1.300 21.331,3.663 C23.694,6.025 25.000,9.160 25.000,12.500 C25.000,15.840 23.700,18.975 21.337,21.337 ZM20.316,4.672 C16.003,0.354 8.978,0.354 4.659,4.672 C0.525,8.805 0.325,15.465 4.194,19.836 C4.294,19.952 4.352,20.095 4.368,20.238 C4.405,20.386 4.395,20.544 4.331,20.698 C3.935,21.601 3.258,22.764 2.185,23.546 L2.190,23.546 C3.121,23.546 4.527,23.314 5.959,22.204 C5.975,22.188 5.996,22.172 6.018,22.162 C6.245,21.934 6.599,21.882 6.885,22.051 C11.230,24.604 16.754,23.895 20.322,20.328 C24.641,16.009 24.641,8.990 20.316,4.672 ZM17.018,13.377 C16.533,13.377 16.140,12.985 16.140,12.500 C16.140,12.015 16.533,11.623 17.018,11.623 C17.503,11.623 17.895,12.015 17.895,12.500 C17.895,12.985 17.503,13.377 17.018,13.377 ZM12.493,13.377 C12.008,13.377 11.616,12.985 11.616,12.500 C11.616,12.015 12.008,11.623 12.493,11.623 C12.978,11.623 13.371,12.015 13.371,12.500 C13.371,12.985 12.978,13.377 12.493,13.377 ZM7.968,13.377 C7.484,13.377 7.091,12.985 7.091,12.500 C7.091,12.015 7.484,11.623 7.968,11.623 C8.453,11.623 8.846,12.015 8.846,12.500 C8.846,12.985 8.453,13.377 7.968,13.377 Z"
                />
            </svg>
        ]

        return (
            <div className="message">

                <div className="chat_message">
                    <div>
                        <p className="chat_date label">{moment(this.props.servicerequestData._created).format('L')}</p>
                        <p className="message_time label">
                            {moment(this.props.servicerequestData._created).format('LT')}
                        </p>
                    </div>
                    <div className="message_text">
                        <p className="message_text_p">
                            {this.props.servicerequestData.description}
                        </p>
                    </div>
                    <div className="message_icon svg_box">
                        {svg[0]}
                    </div>
                </div>
                {this.props.attachment === undefined || null || "" || 0 ? null : <div className="chat_attachment">
                    <p className="label">Прикрепленный файл</p>
                    <img className="chat_attachment_img" src={this.props.attachment} alt="Прикрепленный файл" onClick={() => this.props.changeImgOpen(!this.props.imgOpen, this.props.attachment)}></img>
                </div>}
            </div>
        );
    };
}

export default connect(dispath.mapStateToProps, dispath.mapDispatchToProps)(Message);