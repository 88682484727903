import React, { Component } from 'react';

import { connect } from 'react-redux';

import dispath from '../../../../store/dispatch.js';

import './Request_item.css';
import api from '../../../../api/index.js';
var moment = require('moment');
moment.locale("ru"); 
class Request_item extends Component {

    constructor(prop) {
        super(prop);
    }

    componentWillMount() {

    }

    getStatus(value) {
        switch (value) {
            case "new":
                return "Новая"
            case "accepted":
                return "Принято"
            case "cancelled":
                return "Отменено"
            case "completed":
                return "Выполнено"
            default:
                break;
        }
    }

    getDateNum(value, type) {
        let month = ('0' + (new Date(value).getMonth() + 1)).slice(-2);
        let date = ('0' + new Date(value).getDate()).slice(-2);
        let shortDate = date + '.' + month + '.' + (new Date(value)).getFullYear();
        let fullDate = date + '.' + month;
        if (type === "short") {
            return shortDate;
        } else if (type === "full") {
            return fullDate;
        }



    }

    getNotifacationDate(date) {
        return new Date(date).getUTCFullYear() + '-' + ('0' + (new Date(date).getMonth() + 1)).slice(-2) + '-' + ('0' + new Date(date).getDate()).slice(-2);
    }

    getNotifacationTime(date) {
        return ('0' + (new Date(date).getHours())).slice(-2) + ':' + ('0' + (new Date(date).getMinutes())).slice(-2);
    }

    changeServisDataAndState(data, state) {
        // console.log('changeServisDataAndState data: ', data, '; ', 'state: ', state);

        this.props.changeServicerequestData(data);
        this.props.changeStateWindowServicerequests(state);
    }

    getServiceTitle(service_id) {
        if (this.props.services !== undefined) {
            for (let i = 0; i < this.props.services.length; i++) {
                if (this.props.services[i]._id === service_id) {
                    // console.log(this.props.services[i].title);
                    return this.props.services[i].title;
                } else {
                    // console.log(this.props.services[i]._id);
                }
            }
        } else {
            return "Нет данных"
        }
    }

    render() {
        // console.log("servicerequestItem", this.props.servicerequestItem);


        const blue_elips =
            <svg
                width="7px"
                height="7px">
                <path
                    fillRule="evenodd"
                    fill="rgb(25, 176, 226)"
                    d="M3.500,-0.000 C5.433,-0.000 7.000,1.567 7.000,3.500 C7.000,5.433 5.433,7.000 3.500,7.000 C1.567,7.000 -0.000,5.433 -0.000,3.500 C-0.000,1.567 1.567,-0.000 3.500,-0.000 Z"
                />
            </svg>

        return (


            this.props.servicerequestItem !== undefined ? <div className="request_item" onClick={() => { this.props.changeServicerequestData(this.props.servicerequestItem); this.props.changeStateWindowServicerequests(!this.props.stateWindowServicerequests.requestOpen) }} >
                {
                    // console.log(this.props.servicerequestItem)
                    }
                <div className="request_item_title">
                    <p className="text_white text_margin">{this.getServiceTitle(this.props.servicerequestItem.service_id)}</p>
                    <div className="flex">
                        <p className="date_blue">{moment(this.props.servicerequestItem.datetime).format('LT')}</p>
                        <p className="date_blue marginleft10">{moment(this.props.servicerequestItem.datetime).format('L')}</p>
                    </div>
                </div>
                <div className="request_item_main">
                    <div className="item_main_data text_margin">
                        <p className="w100 text_white">{this.props.servicerequestItem.apartment_num !== undefined ? `апартаменты ${this.props.servicerequestItem.apartment_num}` : null}</p>
                        <div className="w100 flex div_item_main flex-end">
                            <p className="text_gray30">{moment(this.props.servicerequestItem._created).format('LT')}</p>
                            <p className="text_gray30 marginleft10">{moment(this.props.servicerequestItem._created).format('L')}</p>
                        </div>
                        <p className="w100 text_white text-right marginRight15">{this.getStatus(this.props.servicerequestItem.status)}
                            <span className="marginleft10">
                                <svg
                                    width="7px"
                                    height="7px">
                                    <path
                                        fillRule="evenodd"
                                        fill={this.props.servicerequestItem.status[0] === "new" ? "rgb(25, 176, 226)" : this.props.servicerequestItem.status[0] === "accepted" ? "rgb(239, 183, 63)" : this.props.servicerequestItem.status[0] === "cancelled" ? "rgb(191, 191, 191)" : this.props.servicerequestItem.status[0] === "completed" ? "rgb(62, 137, 39)" : null}
                                        d="M3.500,-0.000 C5.433,-0.000 7.000,1.567 7.000,3.500 C7.000,5.433 5.433,7.000 3.500,7.000 C1.567,7.000 -0.000,5.433 -0.000,3.500 C-0.000,1.567 1.567,-0.000 3.500,-0.000 Z"
                                    />
                                </svg>
                            </span>
                        </p>
                    </div>
                    <p className="request_item_message text_gray50">{this.props.servicerequestItem.description}</p>
                </div>
            </div >
                :
                null
        );
    };
}

export default connect(dispath.mapStateToProps, dispath.mapDispatchToProps)(Request_item);