import React from 'react';

import ScenesParams from './ScenesParams';

// import './styles.css';

const light_scenesInitState = {
  title: '',
  name: '',
  topic_call: '',
  topic_save: '',
  feedbacks: {
    topic_fb: ''
  }
}

export default props => {
  const selected = props.items.length !== 0;
  const path = ['rooms', props.roomIndex, 'devicesEndpoints'];

  const handlerClick = () => {
    !selected && props.handlerChange(...path)(null, 'light_scenes', JSON.parse(JSON.stringify(light_scenesInitState)));
    selected && props.handlerChange(...path)(null, 'light_scenes', { clear: true });
  }

  return (
    <div className='room__block'>
      <div className='room__checkbox room__checkbox_scenes'>
        <label><input onChange={() => handlerClick()} type='checkbox' checked={selected} />Сценарии</label>
      </div>
      { selected &&
          (
            <div className='params params_scenes'>
              { props.items.map((item, i) =>
                <div key={i} >
                  <ScenesParams key={i} roomIndex={props.roomIndex} itemIndex={i} item={item} handlerChange={props.handlerChange} />
                  { props.items.length > 1 && <div
                      className='params__button-remove'
                      onClick={() => props.handlerRemoveGroup('light_scenes', i)}>Удалить сценарий</div> }
                </div>) }
              <div
                className='room__button_add'
                onClick={() => props.handlerAddGroup('light_scenes', light_scenesInitState)}
              >Добавить сценарий</div>
            </div>
          )
      }
    </div>
  );
}