const checkValid = ( obj, depth ) => {
  let flag = true;

  if (depth.length === 0 || !flag) return flag;

  if (Array.isArray(depth)) {

    depth.forEach(item => {
      if (typeof item === 'object' && !Array.isArray(item)) {
        for (let key in item) {
          if(Array.isArray(obj)) obj.forEach(arrayItem => flag = checkValid(arrayItem[key], item[key]));
            else checkValid(obj[key], item[key]);
        }
      }

      if (!flag) return flag;

      obj[item] === '' ? flag = false : flag;
    });
  }

  return flag;
}

const checkFields = (obj, fields) => {
  let flag = true;

  if (obj === undefined) return flag;

  fields.forEach(field => {
    if(!flag) return flag;

    !!obj[field] ? flag : flag = false;
  });

  return flag;
}

const checkValidApart = apartFields => {
  const requiredFields = [ "aptnumber", "floor_id" ];
  let flag = true;

  flag = !!flag && checkFields(apartFields, requiredFields);

  return flag;
}

const checkValidRooms = rooms => {
  const requiredFields = [ "title" ];
  let flag = true;

  const checkWindows = windows => {
    const requiredFields = [ "title", "topic_open", "topic_close" ];
    let flag = true;

    if (windows === undefined) return flag;

    windows.forEach(item => !!flag && (flag = checkFields(item, requiredFields)));

    return flag;
  }

  const checkSecurity = security => {
    const requiredFields = [ "title", "topic_type", "topic_feedback" ];
    let flag = true;

    if (security === undefined) return flag;

    security.forEach(item => {
      requiredFields.forEach(field => {
        if(field === 'topic_type' && item[field][0] === '') return flag = false;
        if(field === 'topic_type' && item[field][0] !== '') return flag;

        flag = (item[field] !== '') ? flag : false;
      })
    })

    return flag;
  }

  const checkScenes = scenes => {
    const requiredFields = [ "title", "topic_call"];
    let flag = true;

    if (scenes === undefined) return flag;

    scenes.forEach(item => !!flag && (flag = checkFields(item, requiredFields)));

    return flag;
  }

  const checkBlinds = blinds => {
    const requiredFields = [ "title", "topic_open", "topic_close", "topic_stop" ];
    let flag = true;

    if (blinds === undefined) return flag;

    blinds.forEach(item => !!flag && (flag = checkFields(item, requiredFields)));

    return flag;
  }

  const checkSockets = sockets => {
    const requiredFields = [ "title", "topic_switch", { "feedbacks": "topic_switch_fb" } ];
    let flag = true;

    if (sockets === undefined) return flag;

    sockets.forEach(item => {
      requiredFields.forEach(field => {
        if (typeof field === 'object') flag = item.feedbacks[field.feedbacks] !== '' ? flag : false;

        flag = (item[field] !== '') ? flag : false;
      });
    });

    return flag;
  }

  const checkClimateFields = climate => {
    const requiredFields = [
      { "feedbacks": [ "topic_setpoint_fb", "topic_temperature_fb", "topic_power_fb", "topic_manual_auto_fb", "topic_fan_auto_fb", "topic_fan_min_fb", "topic_fan_med_fb", "topic_fan_max_fb", "topic_mode_heat_fb", "topic_mode_cool_fb" ]},

      { "temperature": [ "topic_setpoint_up", "topic_setpoint_down", "topic_setpoint" ]},

      {
        "conditioner":
          [
            { "power": [ "topic_power" ] },
            { "fan": [ "topic_fan_auto", "topic_fan_min", "topic_fan_med", "topic_fan_max" ] },
            { "operating_mode": [ "topic_mode_heat", "topic_mode_cool" ] }
          ]
      },

      { "automation_mode": [ "topic_manual_auto" ] },

      { "heated_floors": [ "title", "topic_switch", { "feedbacks": "topic_switch_fb" } ]},

      { "ventiltaion": [ "title", "topic_switch", { "feedbacks": "topic_switch_fb" } ]}
    ];
    let flag = true;

     if (climate === undefined) return flag;

    const feedbackCheck = field => {
      field += '_fb';
      if (climate.feedbacks[field] === undefined) return;

      flag = climate.feedbacks[field] !== '' ? flag : false;
    }

    const tempCheck = (value, fields) => {
      fields.forEach(field => {
        if (field === 'topic_setpoint' &&
          (!!value[field] && (!value['topic_setpoint_up']
            && !value['topic_setpoint_down']))) {

          return feedbackCheck(field);
        } else if (field === 'topic_setpoint_up' &&
          (!!value[field] && (!value['topic_setpoint'] && !!value['topic_setpoint_down']))) {

          return feedbackCheck('topic_temperature');
        } else if (field === 'topic_setpoint_down' &&
          (!!value[field] && (!value['topic_setpoint'] && !!value['topic_setpoint_up']))) {

          return feedbackCheck('topic_temperature');
        } else if (!!value['topic_setpoint'] && (!!value['topic_setpoint_up'] && !!value['topic_setpoint_down'])) {
          feedbackCheck('topic_setpoint');
          feedbackCheck('topic_temperature');
        } else if ((!value['topic_setpoint'] && (!value['topic_setpoint_up'] || !value['topic_setpoint_down']))
          || (!!value['topic_setpoint'] && (!!value['topic_setpoint_up'] || !!value['topic_setpoint_down']))) {
          flag = false;
        }
      });
    }

    const fnClimateFields = (item, fields) => {
      if (item === null) return;

      if (Array.isArray(fields)) {
        fields.forEach(reqField => {
          if (typeof reqField === 'object') {

            for (let key in reqField) {
              if (item[key] === undefined) return;

              switch (key) {
                case 'feedbacks': return;
                case 'temperature': {
                  tempCheck(item[key], reqField[key]);
                  return;
                }
                case 'heated_floors':
                case 'ventiltaion':
                  climate[key].forEach(item => {
                    reqField[key].forEach(field => {
                      if (typeof field === 'object')
                        return flag = item.feedbacks[field.feedbacks] !== '' ? flag : false;

                      flag = (item[field] !== '') ? flag : false;
                    });
                  });
                  break;
                default: flag;
              }

              fnClimateFields(item[key], reqField[key]);
            }
          }

          if (typeof reqField === 'string') {
            flag = item[reqField] !== '' ? flag : false;
          }
        })
      }
    }

    fnClimateFields(climate, requiredFields);

    return flag;
  }

  const checkLights = lights => {
    const requiredFields = [ "title", "dimmable", "topic_value", "dimmer_max_value", "topic_switch", { "feedbacks": [ "topic_switch_fb", "topic_value_fb" ] } ];
    let flag = true;

    if(lights === undefined) return flag;

    lights.forEach(item => {
      requiredFields.forEach(field => {
        if (typeof field === 'object') field.feedbacks.forEach(field_2 => {
          if((item.dimmable && item.feedbacks[field_2] !== '') || (!item.dimmable && item.feedbacks['topic_switch_fb'] !== '')) return flag;
          flag = false
        });

        if(item.dimmable) flag = (item[field] !== '') ? flag : false;
          else {
            if ((field === 'dimmer_max_value') || (field === 'topic_value')) return flag;
            flag = (item[field] !== '') ? flag : false;
          }
      })
    });

    return flag;
  }

  const checkEndpoints = endpoints => {
    const endpointsFields = [ "short_sn", "device_url" ];

    const checkMasterOff = () => {
      if (endpoints.master_off === undefined || endpoints.master_off === null) return true;

      return !!endpoints.master_off.topic_call;
    }

    return checkMasterOff() && checkFields(endpoints, endpointsFields) &&
      checkLights(endpoints['light']) && checkScenes(endpoints['light_scenes']) && checkClimateFields(endpoints['climate'])
        && checkSockets(endpoints['sockets']) && checkBlinds(endpoints['blinds'])
          && checkSecurity(endpoints['security_sensors']) && checkWindows(endpoints['windows']);
  }

  rooms.forEach(room => {
    if (room['title'] === '' && !room.devicesEndpoints.global_endpoint)
      flag = false;

    if (!flag) return;

    flag = checkEndpoints(room['devicesEndpoints']);
  });

  return flag;
}

export const validationFields = obj => {
  return checkValidApart(obj['apart']) && checkValidRooms(obj['rooms']);
}