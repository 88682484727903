import { bindActionCreators } from 'redux';
import actions from './actions';

export default {
    mapStateToProps(state) {
        // console.log('mapStateToProps - state, который пришел в mapStateToProps: ', state);
        return {

            userImg: state.userImg,
            userLeftColumnImg: state.userLeftColumnImg,
            userData: state.userData,
            userMenuOpenState: state.userMenuOpenState,

            LOGIN: state.LOGIN,
            PASSWORD: state.PASSWORD,
            state_danger_input_login: state.state_danger_input_login,
            state_danger_input_password: state.state_danger_input_password,
            STATUS_LOGIN: state.STATUS_LOGIN,
            access_token: state.access_token,

            state_search_mailing: state.state_search_mailing,
            state_search_accounts: state.state_search_accounts,
            state_search_services: state.state_search_services,
            real_client_id: state.real_client_id,
            client_id: state.client_id,
            expires: state.expires,
            refresh_token: state.refresh_token,
            username: state.username,
            user_id: state.user_id,

            LOGIN_TYPE_USER: state.LOGIN_TYPE_USER,

            ulData: state.ulData,
            mainName: state.mainName,
            stateWindowServicerequests: state.stateWindowServicerequests,
         
            servicerequests: state.servicerequests,
            servicerequestData: state.servicerequestData,

            //
            state_account_page: state.state_account_page,
            state_account_name: state.state_account_name,
            state_account_type: state.state_account_type,
            state_account_tel: state.state_account_tel,
            state_account_email: state.state_account_email,
            state_account_numApart: state.state_account_numApart,
            state_account_sizeApart: state.state_account_sizeApart,
            state_account_user_photo: state.state_account_user_photo,
            
            state_account_passportApart: state.state_account_passportApart,
            //

            stateWindowAccounts: state.stateWindowAccounts,
            accountData: state.accountData,

            statusSendDataAccountOnServer: state.statusSendDataAccountOnServer,
            statusSendDataServicesOnServer: state.statusSendDataServicesOnServer,
            stateEditAccountId: state.stateEditAccountId,
            stateEditAccountEtag: state.stateEditAccountEtag,

            apartment_num: state.apartment_num,
            apartment_size: state.apartment_size,
            apartment_pasport: state.apartment_pasport,
            user_photo: state.user_photo,
            user_photo_for_save: state.user_photo_for_save,

            state_create_user_firstName: state.state_create_user_firstName,
            state_create_user_secondName: state.state_create_user_secondName,
            state_create_user_thirdName: state.state_create_user_thirdName,
            state_create_user_tel: state.state_create_user_tel,
            state_create_user_typeUser: state.state_create_user_typeUser,
            state_create_user_email: state.state_create_user_email,
            state_create_user_photo: state.state_create_user_photo,
            state_create_user_imgPassport: state.state_create_user_imgPassport,
            state_create_user_numApart: state.state_create_user_numApart,
            state_create_user_sizeApart: state.state_create_user_sizeApart,

            state_danger_input_user_firstName: state.state_danger_input_user_firstName,
            state_danger_input_user_secondName: state.state_danger_input_user_secondName,
            state_danger_input_user_thirdName: state.state_danger_input_user_thirdName,
            state_danger_input_user_tel: state.state_danger_input_user_tel,
            state_danger_input_user_typeUser: state.state_danger_input_user_typeUser,
            state_danger_input_user_email: state.state_danger_input_user_email,
            state_danger_input_user_photo: state.state_danger_input_user_photo,
            state_danger_input_user_imgPassport: state.state_danger_input_user_imgPassport,
            state_danger_input_user_numApart: state.state_danger_input_user_numApart,
            state_danger_input_user_sizeApart: state.state_danger_input_user_sizeApart,

            addTypeNotificationStatus: state.addTypeNotificationStatus,
            state_add_type_notifacation: state.state_add_type_notifacation,
            state_edit_notification_category: state.state_edit_notification_category,
            state_status_edit_notification_category: state.state_status_edit_notification_category,
            state_edit_notification_category_title: state.state_edit_notification_category_title,
            state_edit_notification_category_list: state.state_edit_notification_category_list,
            chartDataGraphic: state.chartDataGraphic,
            chartDataGraphicOpen: state.chartDataGraphicOpen,
            state_danger_input_add_type_notifacation: state.state_danger_input_add_type_notifacation,
            stateWindowOpenMailing: state.stateWindowOpenMailing,
            stateWindowOpenNewMailing: state.stateWindowOpenNewMailing,
            stateWindowOpenQuestion: state.stateWindowOpenQuestion,
            mailingData: state.mailingData,
            mailingDataActive: state.mailingDataActive,
            mailingDataNotActive: state.mailingDataNotActive,
            questionsDataActive: state.questionsDataActive,
            questionsDataNotActive: state.questionsDataNotActive,
            notificationCategoriesList: state.notificationCategoriesList,
            state_active_color_type_of_notifacation: state.state_active_color_type_of_notifacation,
            state_create_notifacation_types: state.state_create_notifacation_types,
            state_create_notifacation_date: state.state_create_notifacation_date,
            state_create_notifacation_time: state.state_create_notifacation_time,
            state_create_notifacation_date_end: state.state_create_notifacation_date_end,
            state_create_notifacation_time_end: state.state_create_notifacation_time_end,
            state_create_notifacation_message: state.state_create_notifacation_message,
            state_create_questions_title: state.state_create_questions_title,
            state_create_questions_answer_options: state.state_create_questions_answer_options,
            state_create_questions_answer_option: state.state_create_questions_answer_option,
            state_create_questions_statistics: state.state_create_questions_statistics,
            state_create_questions_answer_total: state.state_create_questions_answer_total,
            state_create_questions_multiple_answers: state.state_create_questions_multiple_answers,
            state_create_questions_active_till: state.state_create_questions_active_till,
            state_create_notifacation_status_notifacation: state.state_create_notifacation_status_notifacation,
            state_status_questions_end: state.state_status_questions_end,
            state_status_custom_answers: state.state_status_custom_answers,
            state_open_notifacation_status_notifacation: state.state_open_notifacation_status_notifacation,
            state_open_notifacation_status_questions: state.state_open_notifacation_status_questions,
            state_open_notifacation_status_questions_end: state.state_open_notifacation_status_questions_end,
            state_create_notifacation_status_private_publication: state.state_create_notifacation_status_private_publication,
            state_create_notifacation_list_users_on_search: state.state_create_notifacation_list_users_on_search,

            statusSendDataNotificationOnServer: state.statusSendDataNotificationOnServer,

            state_edit_notifacation_id: state.state_edit_notifacation_id,
            state_edit_notifacation_etag: state.state_edit_notifacation_etag,

            state_edit_questions_id: state.state_edit_questions_id,
            state_edit_questions_etag: state.state_edit_questions_etag,

            // Подсветка заполнения инпутов в Рассылке
            stateDangerInputCreateNotificationMessage: state.stateDangerInputCreateNotificationMessage,
            stateDangerInputCreateNotificationTypeNotification: state.stateDangerInputCreateNotificationTypeNotification,
            stateDangerInputCreateNotificationDate: state.stateDangerInputCreateNotificationDate,
            stateDangerInputCreateNotificationTime: state.stateDangerInputCreateNotificationTime,

            //Апартаменты
            apartmentsData: state.apartmentsData,

            // Пропуск

            stateWindowOpenPassItem: state.stateWindowOpenPassItem,
            passData: state.passData,
            passItemUser: state.passItemUser,
            passItemDateComming: state.passItemDateComming,
            passItemStatus: state.passItemStatus,
            passItemPlate: state.passItemPlate,
            passItemDateUpdate: state.passItemDateUpdate,
            passItemApartId: state.passItemApartId,
            passItemApartNum: state.passItemApartNum,

            passItemId: state.passItemId,
            passItemEtag: state.passItemEtag,

            // Сервисы

            services: state.services,
            servicesData: state.servicesData,
            onlineServicesData: state.onlineServicesData,
            onlineServicesCategory: state.onlineServicesCategory,
            onlineServicesCategoryData: state.onlineServicesCategoryData,
            stateWindowServices: state.stateWindowServices,
            state_services_page: state.state_services_page,
            state_services_category_page: state.state_services_category_page,
            state_services_photo: state.state_services_photo,
            state_services_category_photo: state.state_services_category_photo,
            stateDataForOpenItem: state.stateDataForOpenItem,
            state_online_services_photo: state.state_online_services_photo,
            
            services_photo_for_save: state.services_photo_for_save,
            services_category_photo_for_save: state.services_category_photo_for_save,
            // 
            accounts_main_state_hiden: state.accounts_main_state_hiden,

            state_request_page: state.state_request_page,

            state_services_title: state.state_services_title,
            state_services_category_title: state.state_services_category_title,
            state_services_category_active: state.state_services_category_active,
            state_services_id: state.state_services_id,
            state_services_etag: state.state_services_etag,
            state_services_category_etag: state.state_services_category_etag,
            state_services_category_id: state.state_services_category_id,
            state_services_category_name: state.state_services_category_name,
            state_services_description: state.state_services_description,
            state_services_shortinfo: state.state_services_shortinfo,
            state_services_phone: state.state_services_phone,
            state_services_link: state.state_services_link,
            state_services_email: state.state_services_email,
            state_services_order: state.state_services_order,
            state_services_delivery: state.state_services_delivery,
            state_services_pay: state.state_services_pay,

            // Личный кабинет

            stateWindowOpenNewOperator: state.stateWindowOpenNewOperator,
            stateWindowOpenEditOperator: state.stateWindowOpenEditOperator,
            state_lk_nameuser: state.state_lk_nameuser,
            state_lk_emailuser: state.state_lk_emailuser,
            state_lk_imageuser: state.state_lk_imageuser,
            state_lk_emailuser_change_status: state.state_lk_emailuser_change_status,
            state_lk_listoperators_open: state.state_lk_listoperators_open,
            state_lk_photouser: state.state_lk_photouser,
            dataOperators: state.dataOperators,
            state_pass_page: state.state_pass_page,
            isLoadMailingMain: state.isLoadMailingMain,
            isLoadMailingMenu: state.isLoadMailingMenu,
        }
    },
    mapDispatchToProps(dispatch) {
        return {
            changeUserImg: bindActionCreators(actions.changeUserImg, dispatch),
            changeLeftColumnImg: bindActionCreators(actions.changeLeftColumnImg, dispatch),
            changeLkImageluserFile: bindActionCreators(actions.changeLkImageluserFile, dispatch),
            changeUserData: bindActionCreators(actions.changeUserData, dispatch),
            changeUserMenuOpenState: bindActionCreators(actions.changeUserMenuOpenState, dispatch),

            changeLogin: bindActionCreators(actions.changeLogin, dispatch),
            changePassword: bindActionCreators(actions.changePassword, dispatch),
            changeStateDangerInputLogin: bindActionCreators(actions.changeStateDangerInputLogin, dispatch),
            changeStateDangerInputPassword: bindActionCreators(actions.changeStateDangerInputPassword, dispatch),
            changeStatusLogin: bindActionCreators(actions.changeStatusLogin, dispatch),
            changeAccessToken: bindActionCreators(actions.changeAccessToken, dispatch),
            changeAccountId: bindActionCreators(actions.changeAccountId, dispatch),
            changeClientId: bindActionCreators(actions.changeClientId, dispatch),
            changeRealClientId: bindActionCreators(actions.changeRealClientId, dispatch),
            changeStateSearchAccounts: bindActionCreators(actions.changeStateSearchAccounts, dispatch),
            changeStateSearchMailing: bindActionCreators(actions.changeStateSearchMailing, dispatch),
            changeStateSearchServices: bindActionCreators(actions.changeStateSearchServices, dispatch),
            changeExpires: bindActionCreators(actions.changeExpires, dispatch),
            changeRefreshToken: bindActionCreators(actions.changeRefreshToken, dispatch),
            changeUserName: bindActionCreators(actions.changeUserName, dispatch),
            changeUserId: bindActionCreators(actions.changeUserId, dispatch),

            changeLoginTypeUser: bindActionCreators(actions.changeLoginTypeUser, dispatch),
            changeMain: bindActionCreators(actions.changeMain, dispatch),
            getInitialState: bindActionCreators(actions.getInitialState, dispatch),
            changeImgOpen: bindActionCreators(actions.changeImgOpen, dispatch),
            changeServicerequests: bindActionCreators(actions.changeServicerequests, dispatch),
            changeServicerequestData: bindActionCreators(actions.changeServicerequestData, dispatch),
            changeStateWindowServicerequests: bindActionCreators(actions.changeStateWindowServicerequests, dispatch),

            //
            changeStateAccountPage: bindActionCreators(actions.changeStateAccountPage, dispatch),
            changeStateAccountName: bindActionCreators(actions.changeStateAccountName, dispatch),
            changeStateAccountType: bindActionCreators(actions.changeStateAccountType, dispatch),
            changeStateAccountTel: bindActionCreators(actions.changeStateAccountTel, dispatch),
            changeStateAccountEmail: bindActionCreators(actions.changeStateAccountEmail, dispatch),
            changeStateAccountNumApart: bindActionCreators(actions.changeStateAccountNumApart, dispatch),
            changeStateAccountSizeApart: bindActionCreators(actions.changeStateAccountSizeApart, dispatch),
            changeStateAccountUserPhoto: bindActionCreators(actions.changeStateAccountUserPhoto, dispatch),
            changeStateAccountPassportApart: bindActionCreators(actions.changeStateAccountPassportApart, dispatch),
            //

            changeAccountData: bindActionCreators(actions.changeAccountData, dispatch),
            changeStateWindowAccounts: bindActionCreators(actions.changeStateWindowAccounts, dispatch),

            changeStateWindowAccountEdit: bindActionCreators(actions.changeStateWindowAccountEdit, dispatch),

            changeStatusSendDataAccountOnServer: bindActionCreators(actions.changeStatusSendDataAccountOnServer, dispatch), //
            changeStateEditAccountId: bindActionCreators(actions.changeStateEditAccountId, dispatch), //
            changeStateEditAccountEtag: bindActionCreators(actions.changeStateEditAccountEtag, dispatch), //

            changeStateCreateUserFirstName: bindActionCreators(actions.changeStateCreateUserFirstName, dispatch),
            changeStateCreateUserSecondName: bindActionCreators(actions.changeStateCreateUserSecondName, dispatch),
            changeStateCreateUserThirdName: bindActionCreators(actions.changeStateCreateUserThirdName, dispatch),
            changeStateCreateUserTel: bindActionCreators(actions.changeStateCreateUserTel, dispatch),
            changeStateCreateUserTypeUser: bindActionCreators(actions.changeStateCreateUserTypeUser, dispatch),
            changeStateCreateUserUserEmail: bindActionCreators(actions.changeStateCreateUserUserEmail, dispatch),
            changeStateCreateUserPhoto: bindActionCreators(actions.changeStateCreateUserPhoto, dispatch),
            changeStateCreateUserImgPassport: bindActionCreators(actions.changeStateCreateUserImgPassport, dispatch),
            changeStateCreateUserNumApart: bindActionCreators(actions.changeStateCreateUserNumApart, dispatch),
            changeStateCreateUserSizeApart: bindActionCreators(actions.changeStateCreateUserSizeApart, dispatch),

            changeApartmentNum: bindActionCreators(actions.changeApartmentNum, dispatch),
            changeApartmentSize: bindActionCreators(actions.changeApartmentSize, dispatch),
            changeApartmentPasport: bindActionCreators(actions.changeApartmentPasport, dispatch),
            changeUserPhoto: bindActionCreators(actions.changeUserPhoto, dispatch),
            changeUserPhotoForSave: bindActionCreators(actions.changeUserPhotoForSave, dispatch),

            changeStateDangerInputCreateUserFirstName: bindActionCreators(actions.changeStateDangerInputCreateUserFirstName, dispatch),
            changeStateDangerInputCreateUserSecondName: bindActionCreators(actions.changeStateDangerInputCreateUserSecondName, dispatch),
            changeStateDangerInputCreateUserThirdName: bindActionCreators(actions.changeStateDangerInputCreateUserThirdName, dispatch),
            changeStateDangerInputCreateUserTel: bindActionCreators(actions.changeStateDangerInputCreateUserTel, dispatch),
            changeStateDangerInputCreateUserTypeUser: bindActionCreators(actions.changeStateDangerInputCreateUserTypeUser, dispatch),
            changeStateDangerInputCreateUserUserEmail: bindActionCreators(actions.changeStateDangerInputCreateUserUserEmail, dispatch),
            changeStateDangerInputCreateUserPhoto: bindActionCreators(actions.changeStateDangerInputCreateUserPhoto, dispatch),
            changeStateDangerInputCreateUserImgPassport: bindActionCreators(actions.changeStateDangerInputCreateUserImgPassport, dispatch),
            changeStateDangerInputCreateUserNumApart: bindActionCreators(actions.changeStateDangerInputCreateUserNumApart, dispatch),
            changeStateDangerInputCreateUserSizeApart: bindActionCreators(actions.changeStateDangerInputCreateUserSizeApart, dispatch),

            changeStateWindowAccountEdit: bindActionCreators(actions.changeStateWindowAccountEdit, dispatch),

            changeStateWindowOpenMailing: bindActionCreators(actions.changeStateWindowOpenMailing, dispatch),
            changeStateWindowOpenNewMailing: bindActionCreators(actions.changeStateWindowOpenNewMailing, dispatch),
            changeStateWindowOpenQuestion: bindActionCreators(actions.changeStateWindowOpenQuestion, dispatch),

            changeMailingData: bindActionCreators(actions.changeMailingData, dispatch),
            changeMailingDataActive: bindActionCreators(actions.changeMailingDataActive, dispatch),
            changeMailingDataNotActive: bindActionCreators(actions.changeMailingDataNotActive, dispatch),
            changeQuestionsDataActive: bindActionCreators(actions.changeQuestionsDataActive, dispatch),
            changeQuestionsDataNotActive: bindActionCreators(actions.changeQuestionsDataNotActive, dispatch),
            changeNotificationCategoriesList: bindActionCreators(actions.changeNotificationCategoriesList, dispatch),

            changeAddTypeNotificationStatus: bindActionCreators(actions.changeAddTypeNotificationStatus, dispatch),
            changeChartDataGraphic: bindActionCreators(actions.changeChartDataGraphic, dispatch),
            changeChartDataGraphicOpen: bindActionCreators(actions.changeChartDataGraphicOpen, dispatch),
            changeStateAddTypeNotification: bindActionCreators(actions.changeStateAddTypeNotification, dispatch),
            changeStateEditNotificationCategory: bindActionCreators(actions.changeStateEditNotificationCategory, dispatch),
            changeStateStatusEditNotificationCategory: bindActionCreators(actions.changeStateStatusEditNotificationCategory, dispatch),
            changeStateEditNotificationCategoryTitle: bindActionCreators(actions.changeStateEditNotificationCategoryTitle, dispatch),
            changeStateEditNotificationCategoryList: bindActionCreators(actions.changeStateEditNotificationCategoryList, dispatch),
            changeStateDangerInputAddTypeNotification: bindActionCreators(actions.changeStateDangerInputAddTypeNotification, dispatch),
            changeActiveColorTypeOfNotification: bindActionCreators(actions.changeActiveColorTypeOfNotification, dispatch),
            changeStateCreateNotifacationTypes: bindActionCreators(actions.changeStateCreateNotifacationTypes, dispatch),
            changeStateCreateNotifacationDate: bindActionCreators(actions.changeStateCreateNotifacationDate, dispatch),
            changeStateCreateNotifacationTime: bindActionCreators(actions.changeStateCreateNotifacationTime, dispatch),
            changeStateCreateNotifacationDateEnd: bindActionCreators(actions.changeStateCreateNotifacationDateEnd, dispatch),
            changeStateCreateNotifacationTimeEnd: bindActionCreators(actions.changeStateCreateNotifacationTimeEnd, dispatch),
            changeStateCreateNotifacationMessage: bindActionCreators(actions.changeStateCreateNotifacationMessage, dispatch),
            changeStateCreateQuestionsTitle: bindActionCreators(actions.changeStateCreateQuestionsTitle, dispatch),
            changeStateCreateQuestionsAnswerOptions: bindActionCreators(actions.changeStateCreateQuestionsAnswerOptions, dispatch),
            changeStateCreateQuestionsAnswerOption: bindActionCreators(actions.changeStateCreateQuestionsAnswerOption, dispatch),
            changeStateCreateQuestionsStatistics: bindActionCreators(actions.changeStateCreateQuestionsStatistics, dispatch),
            changeStateCreateQuestionsAnswersTotal: bindActionCreators(actions.changeStateCreateQuestionsAnswersTotal, dispatch),
            changeStateCreateQuestionsMultipleAnswers: bindActionCreators(actions.changeStateCreateQuestionsMultipleAnswers, dispatch),
            changeStateCreateQuestionsActiveTill: bindActionCreators(actions.changeStateCreateQuestionsActiveTill, dispatch),
            changeStateCreateNotifacationSatusNotification: bindActionCreators(actions.changeStateCreateNotifacationSatusNotification, dispatch),

            changeStateOpenNotifacationSatusNotification: bindActionCreators(actions.changeStateOpenNotifacationSatusNotification, dispatch),
            changeStateOpenNotifacationSatusQuestions: bindActionCreators(actions.changeStateOpenNotifacationSatusQuestions, dispatch),
            changeStateStatusQuestionsEnd: bindActionCreators(actions.changeStateStatusQuestionsEnd, dispatch),
            changeStateStatusCustomAnswers: bindActionCreators(actions.changeStateStatusCustomAnswers, dispatch),
            changeStateOpenNotifacationStatusPrivatePublication: bindActionCreators(actions.changeStateOpenNotifacationStatusPrivatePublication, dispatch),
            changeStateCreateNotifacationListUsersOnSearch: bindActionCreators(actions.changeStateCreateNotifacationListUsersOnSearch, dispatch),

            changeStatusSendDataNotificationOnServer: bindActionCreators(actions.changeStatusSendDataNotificationOnServer, dispatch),

            changeStateEditNotifacationId: bindActionCreators(actions.changeStateEditNotifacationId, dispatch),
            changeStateEditNotifacationEtag: bindActionCreators(actions.changeStateEditNotifacationEtag, dispatch),

            changeStateEditQuestionsId: bindActionCreators(actions.changeStateEditQuestionsId, dispatch),
            changeStateEditQuestionsEtag: bindActionCreators(actions.changeStateEditQuestionsEtag, dispatch),

            // Подсветка заполнения инпутов в Рассылке
            changeStateDangerInputCreateNotificationMessage: bindActionCreators(actions.changeStateDangerInputCreateNotificationMessage, dispatch),
            changeStateDangerInputCreateNotificationTypeNotification: bindActionCreators(actions.changeStateDangerInputCreateNotificationTypeNotification, dispatch),
            changeStateDangerInputCreateNotificationDate: bindActionCreators(actions.changeStateDangerInputCreateNotificationDate, dispatch),
            changeStateDangerInputCreateNotificationTime: bindActionCreators(actions.changeStateDangerInputCreateNotificationTime, dispatch),

            // Апартаменты

            changeApartmentsData: bindActionCreators(actions.changeApartmentsData, dispatch),

            // Пропуск

            changeStateWindowOpenPassItem: bindActionCreators(actions.changeStateWindowOpenPassItem, dispatch),
            changePassData: bindActionCreators(actions.changePassData, dispatch),
            changePassItemUser: bindActionCreators(actions.changePassItemUser, dispatch),
            changePassItemDateComming: bindActionCreators(actions.changePassItemDateComming, dispatch),
            changePassItemDateUpdate: bindActionCreators(actions.changePassItemDateUpdate, dispatch),
            changePassItemPlate: bindActionCreators(actions.changePassItemPlate, dispatch),
            changePassItemDateStatus: bindActionCreators(actions.changePassItemDateStatus, dispatch),
            changePassItemApartId: bindActionCreators(actions.changePassItemApartId, dispatch),
            changePassItemApartNum: bindActionCreators(actions.changePassItemApartNum, dispatch),

            changePassItemId: bindActionCreators(actions.changePassItemId, dispatch),
            changePassItemEtag: bindActionCreators(actions.changePassItemEtag, dispatch),
            // Сервисы
            changeServices: bindActionCreators(actions.changeServices, dispatch),
            changeServicesData: bindActionCreators(actions.changeServicesData, dispatch),
            changeOnlineServicesData: bindActionCreators(actions.changeOnlineServicesData, dispatch),
            changeOnlineServicesCategory: bindActionCreators(actions.changeOnlineServicesCategory, dispatch),
            changeOnlineAllServicesCategoryData: bindActionCreators(actions.changeOnlineAllServicesCategoryData, dispatch),
            changeServicesCategoryForOpenItem: bindActionCreators(actions.changeServicesCategoryForOpenItem, dispatch),
            changeOnlineServicesDataForOpenItem: bindActionCreators(actions.changeOnlineServicesDataForOpenItem, dispatch),
            changeServicesPhotoForSave: bindActionCreators(actions.changeServicesPhotoForSave, dispatch),
            changeServicesCategoryPhotoForSave: bindActionCreators(actions.changeServicesCategoryPhotoForSave, dispatch),
            // changeStateWindowServicesCategoryForOpenItem: bindActionCreators(actions.changeStateWindowServicesCategoryForOpenItem, dispatch),
            changeStateWindowServices: bindActionCreators(actions.changeStateWindowServices, dispatch),
            changeStateServicesPage: bindActionCreators(actions.changeStateServicesPage, dispatch),
            changeStateServicesCategoryPage: bindActionCreators(actions.changeStateServicesCategoryPage, dispatch),
            changeStateWindowServicesEdit: bindActionCreators(actions.changeStateWindowServicesEdit, dispatch),
            changeStateWindowServicesCategory: bindActionCreators(actions.changeStateWindowServicesCategory, dispatch),
            changeStateWindowServicesCategoryEdit: bindActionCreators(actions.changeStateWindowServicesCategoryEdit, dispatch), //

            
            changeStatusSendDataServicesOnServer: bindActionCreators(actions.changeStatusSendDataServicesOnServer, dispatch), //
            changeStateEditServicesTitle: bindActionCreators(actions.changeStateEditServicesTitle, dispatch),
            changeStateEditServicesCategoryTitle: bindActionCreators(actions.changeStateEditServicesCategoryTitle, dispatch),
            changeStateEditServicesCategoryActive: bindActionCreators(actions.changeStateEditServicesCategoryActive, dispatch),
            changeStateCreateServicePhoto: bindActionCreators(actions.changeStateCreateServicePhoto, dispatch),
            changeStateCreateServiceCategoryPhoto: bindActionCreators(actions.changeStateCreateServiceCategoryPhoto, dispatch),
            changeStateEditServicesId: bindActionCreators(actions.changeStateEditServicesId, dispatch),
            changeStateEditServicesEtag: bindActionCreators(actions.changeStateEditServicesEtag, dispatch),
            changeStateServicesCategoryEtag: bindActionCreators(actions.changeStateServicesCategoryEtag, dispatch),
            changeStateCreateServicesCategoryId: bindActionCreators(actions.changeStateCreateServicesCategoryId, dispatch),
            changeStateCreateServicesCategoryName: bindActionCreators(actions.changeStateCreateServicesCategoryName, dispatch),
            changeStateCreateServicesDescription: bindActionCreators(actions.changeStateCreateServicesDescription, dispatch),
            changeStateCreateServicesShortinfo: bindActionCreators(actions.changeStateCreateServicesShortinfo, dispatch),
            changeStateCreateServicesPhone: bindActionCreators(actions.changeStateCreateServicesPhone, dispatch),
            changeStateCreateServicesLinkCite: bindActionCreators(actions.changeStateCreateServicesLinkCite, dispatch),
            changeStateCreateServicesEmail: bindActionCreators(actions.changeStateCreateServicesEmail, dispatch),
            changeStateCreateServicesOrder: bindActionCreators(actions.changeStateCreateServicesOrder, dispatch),
            changeStateCreateServicesDelivery: bindActionCreators(actions.changeStateCreateServicesDelivery, dispatch),
            changeStateCreateServicesPay: bindActionCreators(actions.changeStateCreateServicesPay, dispatch),

            //
            changeAccountsMainStateHiden: bindActionCreators(actions.changeAccountsMainStateHiden, dispatch),

            changeStateRequestPage: bindActionCreators(actions.changeStateRequestPage, dispatch),

            changesStateWindowOpenNewOperator: bindActionCreators(actions.changesStateWindowOpenNewOperator, dispatch),
            changesStateWindowOpenEditOperator: bindActionCreators(actions.changesStateWindowOpenEditOperator, dispatch),
            changesStateLkNameuser: bindActionCreators(actions.changesStateLkNameuser, dispatch),
            changesStateLkEmailuser: bindActionCreators(actions.changesStateLkEmailuser, dispatch),
            changesStateLkImageluser: bindActionCreators(actions.changesStateLkImageluser, dispatch),
            changesStateLkEmailuserChangeStatus: bindActionCreators(actions.changesStateLkEmailuserChangeStatus, dispatch),
            changesStateLkListoperatorsOpen: bindActionCreators(actions.changesStateLkListoperatorsOpen, dispatch),
            changesStateLkPhotouser: bindActionCreators(actions.changesStateLkPhotouser, dispatch),
            changedDataOperators: bindActionCreators(actions.changedDataOperators, dispatch),
            changeStatePassPage: bindActionCreators(actions.changeStatePassPage, dispatch),
            changeStateDangerInputEmailuserChangeStatus: bindActionCreators(actions.changeStateDangerInputEmailuserChangeStatus, dispatch),
            changeIsLoadMailingMain: bindActionCreators(actions.changeIsLoadMailingMain, dispatch),
            changeIsLoadMailingMenu: bindActionCreators(actions.changeIsLoadMailingMenu, dispatch),
        }
    }

}