import React from 'react';

import WarmFloorParams from './WarmFloorParams';

import './styles.css';

const floorsInitState = {
  title: '',
  name: '',
  topic_switch: '',
  feedbacks: { topic_switch_fb: '' },
};

export default props => {
  const selected = !!props.items.heated_floors;
  const path = ['rooms', props.roomIndex, 'devicesEndpoints', 'climate'];

  const handlerClick = () => {
    if (!Array.isArray(props.items.heated_floors)) {
      !selected && props.handlerChange(...path)(null, 'heated_floors', [ { ...floorsInitState }] );
      return;
    }

    !selected && props.handlerChange(...path)(null, 'heated_floors', floorsInitState );
    selected && props.handlerChange(...path)(null, 'heated_floors', { clear: true });

  }

  const handlerAddGroup = () => {
    selected && props.handlerChange(...path)(null, 'heated_floors', floorsInitState );
  }

  return (
    <div className='params-item_heated-floors'>
      <div className='room__checkbox room__checkbox_scenes'>
        <label><input type='checkbox' onChange={() => handlerClick()} checked={selected} />Тёплые полы</label>
      </div>
      { selected && 
        <div>
          { props.items.heated_floors.map((item, i) =>
            <WarmFloorParams
              key={i}
              roomIndex={props.roomIndex}
              itemIndex={i}
              item={item}
              itemsLength={props.items.heated_floors.length}
              handlerChange={props.handlerChange}
            />)}
          <div
            className='room__button_add'
            onClick={() => handlerAddGroup()}
          >Добавить контур</div>
        </div>
      }
    </div>
  );
}