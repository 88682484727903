import React, { Component } from 'react';

import { connect } from 'react-redux';
var moment = require('moment');
moment.locale("ru");

import { _refreshToken } from '../../Refresh/Refresh';
import dispath from '../../../../store/dispatch.js';
import PreLoader from '../../PreLoader/PreLoader.js';

import api from '../../../../api';

import './Request.css';



import Message from './Message/Message.js'

class Request extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: undefined,
            userName: undefined,
            status: this.props.servicerequestData.status,
            _onLoadState: false,
            isLoad: false,
            apartNum: undefined
        };
        // console.log('this.props.servicerequestData.apartment_id: ', this.props.servicerequestData.apartment_id);
        // console.log('this.props.servicerequestData.owner_id: ', this.props.servicerequestData.owner_id);

        // if (this.props.servicerequestData.apartment_id !== undefined) {
        //     this.getAptnumberApi();
        // } else {
        //     return;
        // }


    }
    getAptnumberApi = async () => {
        // if (this.props.servicerequestData.apartment_id !== undefined) {
        //     await api.getAptnumber(this.props.servicerequestData.apartment_id, this.props.LOGIN_TYPE_USER, this.props.access_token)
        //         .then(
        //             (req) => {
        //                 console.log('*** ', req);
        //                 this.setState({
        //                     apartNum: req.data.aptnumber,
        //                     isLoad: true
        //                 });
        //             }
        //         )
        //         .catch(
        //             err => {
        //                 console.log(err.response);
        //                 if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
        //                     _refreshToken(
        //                         this.props.client_id,
        //                         this.props.LOGIN,
        //                         this.props.PASSWORD,
        //                         this.props.changeAccessToken,
        //                         this.getAptnumberApi.bind(this)
        //                     )

        //                 }
        //             }
        //         )
        // } else {
        //     this.setState({
        //         apartNum: "Отсутствует",
        //         isLoad: true
        //     });
        //     return
        // }




        // if (this.props.servicerequestData.owner_id !== undefined) {
        //     console.log('*** ', this.props.servicerequestData.owner_id);
        //     // console.log('*** ', '5c41a428766c81000a49c6e1');
        //     await api.getAccount('5c41a428766c81000a49c6e1', this.props.access_token, this.props.LOGIN_TYPE_USER)
        //         .then(
        //             (req) => {
        //                 console.log('*** ', req);
        //                 console.log('*** ', req.data.firstname);
        //                 this.setState({
        //                     userName: req.data.firstname + ' ' + req.data.lastname + ' ' + req.data.middlename,
        //                     isLoad: true
        //                 });
        //             }
        //         )
        //         .catch(
        //             err => {
        //                 console.log(err.response);
        //                 if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
        //                     _refreshToken(
        //                         this.props.client_id,
        //                         this.props.LOGIN,
        //                         this.props.PASSWORD,
        //                         this.props.changeAccessToken,
        //                         this.getAptnumberApi.bind(this)
        //                     )

        //                 }
        //             }
        //         )
        // } else {
        //     this.setState({
        //         isLoad: true
        //     });
        //     return
        // }
    }

    componentWillMount() {
        if (this.props.services !== undefined) {
            for (let i = 0; i < this.props.services.length; i++) {
                if (this.props.services[i]._id === this.props.servicerequestData.service_id) {
                    // console.log('************', this.props.services[i].title);
                    this.setState({ title: this.props.services[i]._id });
                    return;
                } else {
                    // console.log('************', this.props.services[i]._id);
                }
            }
        } else {
            // console.log('************');
            return "Нет данных"
        }
    }

    getStatus(value) {
        switch (value[0]) {
            case "new":
                return "Новая"
            case "accepted":
                return "Принято"
            case "cancelled":
                return "Отменено"
            case "completed":
                return "Выполнено"
            default:
                return value[0];
        }
    }

    getDayText(dayInfo) {
        switch (dayInfo) {
            case 0:
                return "Воскресенье";
            case 1:
                return "Понедельник";
            case 2:
                return "Вторник";
            case 3:
                return "Среда";
            case 4:
                return "Четверг";
            case 5:
                return "Пятница";
            case 6:
                return "Суббота";
        }
    }

    getMonthText(monthInfo) {
        switch (monthInfo) {
            case 0:
                return "Января";
            case 1:
                return "Февраля";
            case 2:
                return "Марта";
            case 3:
                return "Апреля";
            case 4:
                return "Мая";
            case 5:
                return "Июня";
            case 6:
                return "Июля";
            case 7:
                return "Августа";
            case 8:
                return "Сентября";
            case 9:
                return "Октября";
            case 10:
                return "Ноября";
            case 11:
                return "Декабря";
        }
    }

    changeTitle = async (title) => {
        await this.setState({ title: title });
        //отправить в базу
        this.props.changeStateRequestPage(1);
        const dataToSend = {
            service_id: title
        }
        await this.editServicerequestApi();
    }

    changeStatus = async (status) => {
        await this.setState({ status: status });
        //отправить в базу
        const dataToSend = {
            status: status
        }
        this.props.changeStateRequestPage(1);
        await this.editServicerequestApi();
    }

    editServicerequestApi = () => {


        let dataToSend = {}
        if (this.state.status !== undefined && this.state.title !== undefined) {
            dataToSend = {
                status: this.state.status,
                service_id: this.state.title
            }
        } else if (this.state.status !== undefined) {
            dataToSend = {
                status: this.state.status
            }
        } else if (this.state.title !== undefined) {
            dataToSend = {
                service_id: this.state.title
            }
        }
        // console.log(dataToSend);
        api.editServicerequest(this.props.servicerequestData._id, this.props.servicerequestData._etag, this.props.LOGIN_TYPE_USER, dataToSend, this.props.access_token)
            .then(
                req => {
                    api.getServicerequest(req.data._id, this.props.LOGIN_TYPE_USER, this.props.access_token)
                        .then(
                            req => {
                                this.props.changeServicerequestData(req.data);

                                api.getServicerequests(this.props.LOGIN_TYPE_USER, this.props.access_token, 1)
                                    .then(
                                        (req) => {
                                            this.props.changeServicerequests(req.data._items);
                                            // console.log(req);
                                        }
                                    )
                                    .catch(
                                        (err) => {
                                            console.log(err.response);
                                            if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                                _refreshToken(
                                                    this.props.client_id,
                                                    this.props.LOGIN,
                                                    this.props.PASSWORD,
                                                    this.props.changeAccessToken,
                                                    this.editServicerequestApi.bind(this)
                                                )
                                            }
                                        }
                                    )
                                // console.log(req);
                            }
                        )
                        .catch(
                            err => {
                                console.log(err.response);
                                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                    _refreshToken(
                                        this.props.client_id,
                                        this.props.LOGIN,
                                        this.props.PASSWORD,
                                        this.props.changeAccessToken,
                                        this.editServicerequestApi.bind(this)
                                    )
                                }
                            }
                        )
                    // console.log(req);
                }
            )
            .catch(
                err => {
                    console.log(err.response);
                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                        _refreshToken(
                            this.props.client_id,
                            this.props.LOGIN,
                            this.props.PASSWORD,
                            this.props.changeAccessToken,
                            this.editServicerequestApi.bind(this)
                        )
                    }
                }
            )
    }

    getServiceTitle(title) {
        if (this.props.services !== undefined) {
            for (let i = 0; i < this.props.services.length; i++) {
                if (this.props.services[i]._id === title) {
                    return this.props.services[i].title;
                }
            }
        } else {
            return "Нет данных"
        }
    }

    render() {
        // console.log('====================================')
        // console.log('this.props.servicerequestData: ', this.props.servicerequestData)
        // console.log('====================================')

        const status = [["new"], ["accepted"], ["cancelled"], ["completed"]]
        // if (!this.state.isLoad) {
        //     return (
        //         <PreLoader onLoad={this.getAptnumberApi.bind(this)} _onLoadState={this.state._onLoadState} />
        //     )
        // }
        return (
            this.props.servicerequestData
                ?
                <div >
                    <div className="request_menu">
                        <div className="select">
                            <p className="label">Тип заявки</p>
                            <nav className="select_nav">
                                <ul>
                                    <li className="user_type_li select_li relative minWidth300px flex flex_flow_row flex1"><a className="target flex1"><i className="fa fa-cogs padding_right_10"></i>{this.getServiceTitle(this.state.title)}</a>
                                        <ul className="right0">
                                            {this.props.services.map(service => <li className="select_li minWidth300px " key={service._id} ><a onClick={() => this.changeTitle(service._id)} >{this.getServiceTitle(service._id)}</a></li>)}
                                        </ul>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="select">
                            <p className="label">Статус заявки</p>
                            <nav className="select_nav">
                                <ul >
                                    <li className="user_type_li select_li minWidth300px relative flex flex_flow_row flex1"><a className="target flex1"><i className="fa fa-cogs padding_right_10"></i>{this.getStatus(this.state.status)}</a>
                                        <ul className="right0">
                                            {status.map(status => <li className="select_li minWidth300px" key={status} ><a onClick={() => this.changeStatus(status)} >{this.getStatus(status)}</a></li>)}
                                        </ul>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="request_menu">
                        <div className="select">
                            <p className="label">Удобное время</p>
                            <div className="select_data">
                                <p className="label label_date">{moment(this.props.servicerequestData.datetime).format('llll')}</p>
                            </div>
                        </div>
                        <div className="select">
                            <p className="label">Номер апартамента</p>
                            <div className="select_data">
                                <p className="label label_number">{this.props.servicerequestData.apartment_num !== undefined ? this.props.servicerequestData.apartment_num : "Нет данных"}</p>
                            </div>
                        </div>
                    </div >
                    <div className="request_menu">
                        <div className="select">
                            <p className="label">ФИО клиента</p>
                            <div className="select_data">
                                <p className="label label_number">{this.props.servicerequestData.account_data !== undefined ? this.props.servicerequestData.account_data.firstname + " " + this.props.servicerequestData.account_data.lastname + " " + this.props.servicerequestData.account_data.middlename : "Нет данных"}</p>
                            </div>
                        </div>
                        <div className="select">
                            <p className="label">Кто то будет дома</p>
                            <div className="select_data">
                                <p className="label label_number">{this.props.servicerequestData.anybody_home !== undefined ? this.props.servicerequestData.anybody_home === true ? "Да" : "Нет" : "Неизвестно"}</p>
                            </div>
                        </div>
                    </div >
                    <div className="request_chat">
                        <Message />
                    </div>
                    <div className="request_response">
                        <a className="response_button">ответить</a>
                    </div>
                </div >
                :
                null
        );
    };
}

export default connect(dispath.mapStateToProps, dispath.mapDispatchToProps)(Request);