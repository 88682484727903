import React, { Component } from 'react';

import { connect } from 'react-redux';
import { _refreshToken } from '../Refresh/Refresh';
import swal from 'sweetalert';
import FormData from 'form-data';
import dispath from '../../../store/dispatch.js';

import PreLoader from '../PreLoader/PreLoader.js';
import './Lk.css';
import imageCompression from 'browser-image-compression';
import imgUrl from '../../App/img/user.jpg';
import empty_avatar from '../Accounts/Account_Add/empty_avatar.jpg';

import api from '../../../api/index.js';

import LkOperatorsList from './LkOperatorsList/LkOperatorsList.js';

class Lk extends Component {

    constructor(props) {
        super(props);
        // console.log('====================================');
        // console.log('this.props.account_id: ', this.props.account_id);
        // console.log('this.props.userData: ', this.props.userData);
        // console.log('====================================');

        this.state = {
            changePhoto: false,
            newPhoto: undefined,
            user_id: undefined,
            _onLoadState: false,
            isLoad: false
        }
    }



    getAccountApi = () => {
        api.getAccount(this.props.userData._id, this.props.access_token, this.props.LOGIN_TYPE_USER)
            .then(
                req => {
                    // console.log(req);
                    this.setState({
                        isLoad: true
                    })
                    let lastname = req.data.lastname !== undefined ? req.data.lastname : null
                    let firstnameCharat = req.data.firstname !== undefined ? req.data.firstname.charAt(0) : ""
                    let middlenameCharat = req.data.middlename !== undefined ? req.data.middlename.charAt(0) : ""
                    this.props.changesStateLkNameuser(`${lastname} ${firstnameCharat}. ${middlenameCharat}. `);
                    this.props.changesStateLkEmailuser(req.data.email);
                    // console.log(req);


                    api.getImage(req.data.image, this.props.access_token)
                        .then(request => {
                            // console.log(request);
                            this.setState({
                                isLoad: true
                            })
                            let img_file = `data:${request.data.image.content_type};base64, ${request.data.image.file}`
                            // console.log(img_file);

                            this.props.changeLeftColumnImg(img_file);
                        })
                        .catch(
                            error => {
                                console.log(error);
                                if (error.response.status === 401 && error.response.data._error.message === 'token expired' || error.response.status === 401 && error.response.data._error.message === 'user not found') {

                                    _refreshToken(
                                        this.props.client_id,
                                        this.props.LOGIN,
                                        this.props.PASSWORD,
                                        this.props.changeAccessToken,
                                        this.getAccountApi.bind(this)
                                    )
                                }
                            }
                        )

                    api.getAccounts(this.props.access_token, this.props.LOGIN_TYPE_USER, this.props.mainName, 1)
                        .then(
                            req => {
                                this.props.changedDataOperators(req.data._items);
                                // console.log(req);
                            }
                        )
                        .catch(
                            err => {
                                console.log(err.response);
                                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                    _refreshToken(
                                        this.props.client_id,
                                        this.props.LOGIN,
                                        this.props.PASSWORD,
                                        this.props.changeAccessToken,
                                        this.getAccountApi.bind(this)
                                    )
                                }
                            }
                        )
                }
            )

            .catch(
                err => {
                    console.log(err);
                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                        _refreshToken(
                            this.props.client_id,
                            this.props.LOGIN,
                            this.props.PASSWORD,
                            this.props.changeAccessToken,
                            this.getAccountApi.bind(this)
                        )
                    }
                }
            )
    }
    backTo(value) {
        switch (value) {
            case "lk":
                if (this.props.stateWindowOpenNewOperatorAdd === true) {
                    this.props.changeStateWindowOpenNewOperatorAdd(!this.props.stateWindowOpenNewOperatorAdd);
                    return;
                } else {
                    this.props.changeMain("home");
                }
                break;

            default:
                break;
        }
    }

    // _handleImgPhotoUserChange(e) {
    //     e.preventDefault();

    //     let reader = new FileReader();
    //     let file = e.target.files[0];
    //     if (file !== undefined) {
    //         reader.onloadend = () => {
    //             // console.log(file);

    //             this.props.changeLkImageluserFile(file);
    //             this.props.changeLeftColumnImg(reader.result);
    //         }
    //         this.setState({
    //             changePhoto: true
    //         })
    //         reader.readAsDataURL(file)
    //     }

    // }
    async _handleImgPhotoUserChange(e) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        if (file !== undefined) {
            // you should provide one of maxSizeMB, maxWidthOrHeight in the options
            const options = {
                maxSizeMB: 0.2,
                maxWidthOrHeight: 320,
                useWebWorker: true
            }
            try {
                const compressedFile = await imageCompression(file, options);
                // console.log('File', file);
                // console.log(`compressedFile`, compressedFile);

                await reader.readAsDataURL(compressedFile);
                await this.props.changeLkImageluserFile(compressedFile);
                this.setState({
                    newPhoto: compressedFile,
                    user_id: this.props.user_id
                })
            } catch (error) {
                console.log(error);
            }
            reader.onloadend = () => {
                this.props.changeLeftColumnImg(reader.result);

            }
            this.setState({
                changePhoto: true
            })

        }

    }
    _handleImgPhotoUserSave() {
 
        const Newdata = new FormData();
        Newdata.append("image", this.state.newPhoto);
        Newdata.append("owner_id", this.state.user_id);

        api.createImageId(Newdata, this.props.access_token, this.props.LOGIN_TYPE_USER)
            .then(
                req => {
                    // console.log(req);
                    // console.log(req.data._id);
                    this.setState({
                        changePhoto: false
                    })
                    this.props.changesStateLkImageluser(req.data._id);
                    this.changePhoto();

                })
            .catch(
                err => {
                    console.log(err);
                    console.log(err.response);
                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                        _refreshToken(
                            this.props.client_id,
                            this.props.LOGIN,
                            this.props.PASSWORD,
                            this.props.changeAccessToken,
                            this._handleImgPhotoUserSave.bind(this)
                        )
                    }
                }
            )

    }

    resetPass() {
        // console.log('click');
    }

    handleClickButtonEmail() {
        if (this.props.state_lk_emailuser_change_status === false) {
            this.props.changesStateLkEmailuserChangeStatus(!this.props.state_lk_emailuser_change_status);
        } else {
            this.props.changesStateLkEmailuserChangeStatus(!this.props.state_lk_emailuser_change_status);
            swal("Действительно хотите изменить E-mail?", {
                dangerMode: true,
                buttons: true,
            }).catch(swal.noop).then((result) => {
                this.saveEmailToBase(result);
            });
        }

    }

    saveEmailToBase(result) {
        if (result === true) {
            if (this.props.state_lk_emailuser !== undefined && this.props.state_lk_emailuser !== "" && this.props.state_lk_emailuser !== null) {
                this.getUserApi();
            } else {
                this.props.changeStateDangerInputEmailuserChangeStatus(true);
                alert('E-mail не должен быть пустым');

            }
        } else {
            return;
        }
    }
    changePhoto = () => {

        api.getAccount(this.props.userData._id, this.props.access_token, this.props.LOGIN_TYPE_USER)
            .then(
                req => {
                    const dataToSend = {
                        image: this.props.state_lk_imageuser
                    }
                    api.editAccount(this.props.userData._id, req.data._etag, this.props.LOGIN_TYPE_USER, dataToSend, this.props.access_token)
                        .then(
                            req => {
                                api.getAccount(this.props.userData._id, this.props.access_token, this.props.LOGIN_TYPE_USER)
                                    .then(
                                        req => {
                                            this.props.changeUserData(req.data);
                                        }
                                    )
                                    .catch(
                                        err => {
                                            console.log(err.response);
                                            if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                                _refreshToken(
                                                    this.props.client_id,
                                                    this.props.LOGIN,
                                                    this.props.PASSWORD,
                                                    this.props.changeAccessToken,
                                                    this.changePhoto.bind(this)
                                                )

                                            }
                                        }
                                    )
                            }
                        )
                        .catch(
                            err => {
                                console.log(err.response);
                                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                    _refreshToken(
                                        this.props.client_id,
                                        this.props.LOGIN,
                                        this.props.PASSWORD,
                                        this.props.changeAccessToken,
                                        this.changePhoto.bind(this)
                                    )
                                }
                            }
                        )
                }
            )
            .catch(err => {
                console.log(err.response);
                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                    _refreshToken(
                        this.props.client_id,
                        this.props.LOGIN,
                        this.props.PASSWORD,
                        this.props.changeAccessToken,
                        this.changePhoto.bind(this)
                    )
                }
            })
    }
    getUserApi = () => {

        api.getUser(this.props.LOGIN_TYPE_USER, this.props.userData.user, this.props.access_token)
            .then(
                req => {
                    // console.log(req);
                    const dataUserToSend = {
                        username: this.props.state_lk_emailuser
                    }
                    api.editUser(this.props.userData.user, this.props.LOGIN_TYPE_USER, req.data._etag, this.props.access_token, dataUserToSend)
                        .then(
                            req => {
                                // console.log(req);

                                api.getAccount(this.props.userData._id, this.props.access_token, this.props.LOGIN_TYPE_USER)
                                    .then(
                                        req => {
                                            // console.log(req);

                                            const dataToSend = {
                                                email: this.props.state_lk_emailuser
                                            }
                                            api.editAccount(this.props.userData._id, req.data._etag, this.props.LOGIN_TYPE_USER, dataToSend, this.props.access_token)
                                                .then(
                                                    req => {
                                                        // console.log(req);
                                                        api.getAccount(this.props.userData._id, this.props.access_token, this.props.LOGIN_TYPE_USER)
                                                            .then(
                                                                req => {
                                                                    // console.log(req);
                                                                    this.props.changeUserData(req.data);
                                                                }
                                                            )
                                                            .catch(
                                                                err => {
                                                                    console.log(err.response);
                                                                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                                                        _refreshToken(
                                                                            this.props.client_id,
                                                                            this.props.LOGIN,
                                                                            this.props.PASSWORD,
                                                                            this.props.changeAccessToken,
                                                                            this.getUserApi.bind(this)
                                                                        )

                                                                    }
                                                                }
                                                            )
                                                    }
                                                )
                                                .catch(
                                                    err => {
                                                        console.log(err.response);
                                                        if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                                            _refreshToken(
                                                                this.props.client_id,
                                                                this.props.LOGIN,
                                                                this.props.PASSWORD,
                                                                this.props.changeAccessToken,
                                                                this.getUserApi.bind(this)
                                                            )
                                                        }
                                                    }
                                                )
                                        }
                                    )
                                    .catch(
                                        err => {
                                            console.log(err.response);
                                            if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                                                _refreshToken(
                                                    this.props.client_id,
                                                    this.props.LOGIN,
                                                    this.props.PASSWORD,
                                                    this.props.changeAccessToken,
                                                    this.getUserApi.bind(this)
                                                )
                                            }
                                        }
                                    )

                            }
                        )
                        .catch(
                            err => {
                                console.log(err.response);
                                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                                    _refreshToken(
                                        this.props.client_id,
                                        this.props.LOGIN,
                                        this.props.PASSWORD,
                                        this.props.changeAccessToken,
                                        this.getUserApi.bind(this)
                                    )
                                }

                            }
                        )
                }
            )
            .catch(
                err => {
                    console.log(err);
                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                        _refreshToken(
                            this.props.client_id,
                            this.props.LOGIN,
                            this.props.PASSWORD,
                            this.props.changeAccessToken,
                            this.getUserApi.bind(this)
                        )
                    }
                }
            )
    }
    createAccount() {

        this.props.changesStateWindowOpenNewOperator(true);
        this.props.changeStatusSendDataAccountOnServer('create');
        this.props.changeMain("apart");
        this.props.changeStateWindowAccountEdit(true);

        this.props.changeStateEditAccountId(undefined);
        this.props.changeStateEditAccountEtag(undefined);

        this.props.changeStateCreateUserFirstName(undefined);
        this.props.changeStateCreateUserSecondName(undefined);
        this.props.changeStateCreateUserThirdName(undefined);
        this.props.changeStateCreateUserTel(undefined);
        this.props.changeStateCreateUserTypeUser("operator");
        this.props.changeStateCreateUserUserEmail(undefined);
        this.props.changeStateCreateUserPhoto(undefined);
        this.props.changeUserPhotoForSave(undefined);
        // this.props.changeStateCreateUserImgPassport(undefined);
        this.props.changeStateCreateUserNumApart(undefined);
        this.props.changeStateCreateUserSizeApart(undefined);

    }

    render() {

        const svg = [
            <svg
                width="20px"
                height="25px">
                <path
                    fillRule="evenodd"
                    fill="rgb(112, 112, 112)"
                    d="M18.461,25.000 L0.537,25.000 C0.240,25.000 -0.000,24.756 -0.000,24.456 L-0.000,2.576 C-0.000,2.275 0.240,2.032 0.537,2.032 L11.016,2.032 C11.312,2.032 11.553,2.275 11.553,2.576 C11.553,2.876 11.312,3.119 11.016,3.119 L1.074,3.119 L1.074,23.912 L17.924,23.912 L17.924,6.437 C17.924,6.137 18.164,5.893 18.461,5.893 C18.757,5.893 18.998,6.137 18.998,6.437 L18.998,24.456 C18.998,24.756 18.757,25.000 18.461,25.000 ZM17.126,4.775 C17.126,4.775 17.125,4.775 17.125,4.776 C17.125,4.776 17.125,4.776 17.125,4.776 L8.608,13.400 C8.540,13.468 8.455,13.517 8.363,13.542 L5.547,14.282 C5.503,14.294 5.457,14.299 5.412,14.299 C5.272,14.299 5.135,14.243 5.033,14.140 C4.898,14.004 4.845,13.806 4.893,13.619 L5.624,10.768 C5.648,10.674 5.696,10.589 5.764,10.520 L14.281,1.896 C14.281,1.896 14.282,1.896 14.282,1.896 C14.282,1.896 14.282,1.896 14.282,1.895 L15.997,0.159 C16.097,0.057 16.234,-0.000 16.376,-0.000 C16.519,-0.000 16.655,0.057 16.756,0.159 L18.840,2.270 C19.050,2.482 19.050,2.827 18.840,3.039 L17.126,4.775 ZM6.627,11.185 L6.162,12.997 L7.952,12.526 L15.986,4.391 L14.661,3.049 L6.627,11.185 ZM16.376,1.313 L15.421,2.280 L16.746,3.622 L17.701,2.654 L16.376,1.313 Z"
                />
            </svg>,
            <svg
                width="9px"
                height="16px">
                <path
                    fillRule="evenodd"
                    fill="rgb(255, 255, 255)"
                    d="M7.986,0.155 C8.102,0.051 8.247,-0.000 8.407,-0.000 C8.566,-0.000 8.711,0.051 8.827,0.155 C9.058,0.362 9.058,0.698 8.827,0.905 L1.438,7.501 L8.827,14.096 C9.058,14.303 9.058,14.640 8.827,14.846 C8.595,15.053 8.218,15.053 7.986,14.846 L0.178,7.876 C-0.054,7.669 -0.054,7.333 0.178,7.126 L7.986,0.155 Z"
                />
            </svg>,
            <svg
                width="25px"
                height="25px">
                <path
                    fillRule="evenodd"
                    fill="rgb(255, 255, 255)"
                    d="M21.337,21.337 C18.926,23.747 15.723,25.000 12.488,25.000 C10.474,25.000 8.444,24.514 6.589,23.515 C4.923,24.715 3.295,24.974 2.190,24.974 C1.778,24.974 1.440,24.937 1.202,24.900 C0.695,24.820 0.303,24.424 0.224,23.916 C0.145,23.409 0.404,22.918 0.864,22.690 C1.820,22.220 2.465,21.279 2.867,20.470 C-1.230,15.539 -0.912,8.229 3.655,3.663 C6.018,1.300 9.152,-0.000 12.493,-0.000 C15.834,-0.000 18.969,1.300 21.331,3.663 C23.694,6.025 25.000,9.160 25.000,12.500 C25.000,15.840 23.700,18.975 21.337,21.337 ZM20.316,4.672 C16.003,0.354 8.978,0.354 4.659,4.672 C0.525,8.805 0.325,15.465 4.194,19.836 C4.294,19.952 4.352,20.095 4.368,20.238 C4.405,20.386 4.395,20.544 4.331,20.698 C3.935,21.601 3.258,22.764 2.185,23.546 L2.190,23.546 C3.121,23.546 4.527,23.314 5.959,22.204 C5.975,22.188 5.996,22.172 6.018,22.162 C6.245,21.934 6.599,21.882 6.885,22.051 C11.230,24.604 16.754,23.895 20.322,20.328 C24.641,16.009 24.641,8.990 20.316,4.672 ZM17.018,13.377 C16.533,13.377 16.140,12.985 16.140,12.500 C16.140,12.015 16.533,11.623 17.018,11.623 C17.503,11.623 17.895,12.015 17.895,12.500 C17.895,12.985 17.503,13.377 17.018,13.377 ZM12.493,13.377 C12.008,13.377 11.616,12.985 11.616,12.500 C11.616,12.015 12.008,11.623 12.493,11.623 C12.978,11.623 13.371,12.015 13.371,12.500 C13.371,12.985 12.978,13.377 12.493,13.377 ZM7.968,13.377 C7.484,13.377 7.091,12.985 7.091,12.500 C7.091,12.015 7.484,11.623 7.968,11.623 C8.453,11.623 8.846,12.015 8.846,12.500 C8.846,12.985 8.453,13.377 7.968,13.377 Z"
                />
            </svg>
        ]

        const button_icon =
            <svg
                width="20px"
                height="20px">
                <path
                    fillRule="evenodd"
                    fill="rgb(255, 255, 255)"
                    d="M10.000,20.000 C4.486,20.000 -0.000,15.514 -0.000,10.000 C-0.000,4.486 4.486,-0.000 10.000,-0.000 C15.514,-0.000 20.000,4.486 20.000,10.000 C20.000,15.514 15.514,20.000 10.000,20.000 ZM10.000,0.769 C4.910,0.769 0.769,4.910 0.769,10.000 C0.769,15.090 4.910,19.231 10.000,19.231 C15.090,19.231 19.231,15.090 19.231,10.000 C19.231,4.910 15.090,0.769 10.000,0.769 ZM14.808,10.385 L10.385,10.385 L10.385,15.000 C10.385,15.213 10.212,15.385 10.000,15.385 C9.788,15.385 9.615,15.213 9.615,15.000 L9.615,10.385 L5.192,10.385 C4.980,10.385 4.808,10.213 4.808,10.000 C4.808,9.787 4.980,9.615 5.192,9.615 L9.615,9.615 L9.615,5.385 C9.615,5.172 9.788,5.000 10.000,5.000 C10.212,5.000 10.385,5.172 10.385,5.385 L10.385,9.615 L14.808,9.615 C15.020,9.615 15.192,9.787 15.192,10.000 C15.192,10.213 15.020,10.385 14.808,10.385 Z"
                />
            </svg>

        if (!this.state.isLoad) {
            return (
                <PreLoader onLoad={this.getAccountApi.bind(this)} _onLoadState={this.state._onLoadState} />
            )
        }
        return (
            <div className="lk container_main backcolor">
                <div className="container_header header_h">
                    <div className="svg_box">
                        <div className="userAvatar flex center" >
                            <div className="userImgBox iconBox" onClick={() => this.props.changeMain("lk")}>
                                <img className="userImg" src={this.props.userLeftColumnImg !== undefined ? this.props.userLeftColumnImg : imgUrl} alt="альтернативный текст" />
                            </div>
                        </div>
                    </div>
                    <div className="svg_box margin-left hover_svg" onClick={() => this.backTo(this.props.mainName)}>
                        {svg[1]}
                    </div>
                </div>
                <div className="flex margin-top30">
                    <div className="col-lg3">
                        <div>
                            <span className="label">Фото</span><br />
                            <label htmlFor="account_photo_img_input">
                                <div className="account_photo">
                                    <img className="account_photo_img" src={this.props.userLeftColumnImg === undefined ? empty_avatar : this.props.userLeftColumnImg} alt="Фото"></img>
                                    <input
                                        name="account_photo_img_input"
                                        id="account_photo_img_input"
                                        className="inputFile"
                                        type="file"
                                        onChange={(e) => this._handleImgPhotoUserChange(e)}
                                    />
                                </div>
                                {this.state.changePhoto === false ? <a className="button_account_add flex center text_white margin-top20">выбрать фото</a> : ""}

                            </label>
                            {this.state.changePhoto === false ? "" : <a className="button_account_add flex center text_white margin-top20" onClick={() => this._handleImgPhotoUserSave()}>сохранить фото</a>}

                        </div>
                    </div>
                    <div className="col-lg3">
                        <div>
                            <span className="label">Ф. И. О.</span><br />
                            <p className="text_white max-w-250px user-select-text margin-top10">{this.props.state_lk_nameuser !== undefined ? this.props.state_lk_nameuser : "Нет данных"}</p>
                        </div>
                        <div className="margin-top10">
                            <span className="label">E-mail</span><br />
                            <div className="flex">
                                {
                                    this.props.state_lk_emailuser_change_status === false
                                        ?

                                        <p
                                            onDoubleClick={() => this.props.changesStateLkEmailuserChangeStatus(!this.props.state_lk_emailuser_change_status)}
                                            className="text-email-input-lk text_white max-w-250px flex align-flex-center user-select-text h43px margin-top10"
                                        >
                                            {this.props.state_lk_emailuser}
                                        </p>
                                        :
                                        <input
                                            className={this.props.state_danger_input_lk_email === true ? "input_user_edit danger_input w100 margin-top10 input-lk" : "input_user_edit w100 margin-top10 input-lk"}
                                            value={this.props.state_lk_emailuser === undefined ? "Нет данных" : this.props.state_lk_emailuser}
                                            type="text"
                                            placeholder="E-mail"
                                            autoFocus

                                            onChange={(event) => {
                                                this.props.changesStateLkEmailuser(event.target.value);
                                                this.props.changeStateDangerInputEmailuserChangeStatus(false);
                                            }
                                            }
                                        // onBlur={(event) => {
                                        //     this.props.changesStateLkEmailuser(event.target.value);
                                        //     this.props.changeStateDangerInputEmailuserChangeStatus(false);
                                        // }
                                        // }
                                        />

                                }
                            </div>
                            <a className="button_account_add flex center text_white margin-top10" onClick={() => this.handleClickButtonEmail()}>{this.props.state_lk_emailuser_change_status === false ? "изменить E-mail" : "сохранить E-mail"}</a>

                        </div>
                        <div className="margin-top20">
                            <span className="label">Пароль</span><br />
                            <a className="button_account_add flex center text_white margin-top10" onClick={() => this.resetPass()}>сбросить пароль</a>
                        </div>
                    </div>
                    <div className="col-lg3 padding0">
                        {
                            this.props.LOGIN_TYPE_USER === "admin"
                                ?
                                <a className="button_account_add flex center text_white margin-top10 list-button" onClick={() => this.props.changesStateLkListoperatorsOpen(!this.props.state_lk_listoperators_open)}>список операторов</a>
                                :
                                null
                        }
                    </div>
                </div>

                {
                    this.props.LOGIN_TYPE_USER === "admin" && this.props.state_lk_listoperators_open === true ?
                        <div className="margin-top10 flex_column_full webkit_fill_available">
                            <LkOperatorsList />
                            <div className="flex flex-end margin-top10">
                                <div className="accounts_button flex center" onClick={() => this.createAccount()}>
                                    <span className="flex center">{button_icon}<a className="accounts_button_a">Добавить оператора</a></span>
                                </div>
                            </div>
                        </div>

                        : null

                }
            </div>
        );
    };
};

export default connect(dispath.mapStateToProps, dispath.mapDispatchToProps)(Lk);