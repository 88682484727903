import React, { Component } from 'react';
import logo from "../App/img/liis_logo.png";
import imgUrl from '../App/img/user.jpg';

import { connect } from 'react-redux';

import dispatch from '../../store/dispatch.js';

import cookieAction from '../../cookieAction';

import api from '../../api';

import './App_left_column.css';

import App_left_column_element from '../App_left-column_element/App_left-column_element.js';

class App_left_column extends Component {

    logOut() {
        api.logOut(this.props.LOGIN, this.props.PASSWORD);
        cookieAction.deleteAllCookies();
        this.props.getInitialState();
    }

    openLK() {
        // console.log('====================================')
        // console.log("click")
        // console.log('====================================')
        this.props.changeUserMenuOpenState(false);
    }


    render() {
        const ulData = this.props.ulData;

        const bottomSvg =
            <svg
                width="12px"
                height="7px">
                <path
                    fillRule="evenodd"
                    fill="rgb(255, 255, 255)"
                    d="M0.125,0.760 C0.042,0.670 0.001,0.557 0.001,0.432 C0.001,0.307 0.042,0.194 0.125,0.104 C0.291,-0.077 0.560,-0.077 0.725,0.104 L6.000,5.876 L11.275,0.104 C11.441,-0.077 11.710,-0.077 11.875,0.104 C12.041,0.285 12.041,0.579 11.875,0.760 L6.300,6.861 C6.135,7.042 5.866,7.042 5.700,6.861 L0.125,0.760 Z"
                />
            </svg>

        return (
            <div className="app_left_column backcolor">
                <div className="app_left_logo" onClick={() => this.props.changeMain("home")}>
                    <img className="imgLogo" src={logo} alt="Logo" />
                </div>
                <div className="app_left_navbar" >
                    <ul className="app_left_navbar-nav">
                        <li className="app_left_column_name" >
                            <div className="userAvatar flex center" >
                                <div key={this.props.userLeftColumnImg} className="userImgBox" onClick={() => this.props.changeMain("lk")}>
                                    <img className="userImg" src={this.props.userLeftColumnImg !== undefined ? this.props.userLeftColumnImg : imgUrl} alt="альтернативный текст" />
                                </div>
                            </div>
                            <div className="left_column_element_text flex center">
                                <p>
                                    {
                                        this.props.userData !== undefined
                                            ?
                                            this.props.userData.firstname.substring(0, 10) + ' ' + this.props.userData.lastname.charAt(0) + "."
                                            :
                                            "Нет данных"
                                    }
                                </p>
                                <div className="svg_box margin-left15 hover_svg_not_z"
                                    onClick={() => this.props.changeUserMenuOpenState(!this.props.userMenuOpenState)}
                                >
                                    {bottomSvg}
                                </div>
                            </div>
                            <ul className={this.props.userMenuOpenState ? "menu-user app_left_navbar-nav" : "menu-user-hiden"} >
                                <li className="app_left_column_name bottom-horizontal-border" onClick={() => this.openLK()}>
                                    <p className="text_white14 hover-text text-align-start padding-left10" onClick={() => this.props.changeMain("lk")}>Личный кабинет</p>
                                </li >
                                <li className="app_left_column_name bottom-horizontal-border" onClick={() => this.logOut()}>
                                    <p className="text_white14 hover-text text-align-start padding-left10" onClick={() => this.logOut()}>Выйти</p>
                                </li >
                            </ul>
                        </li >
                    </ul>
                </div>
                <div className="app_left_navbar">
                    <ul className="app_left_navbar-nav">
                        {ulData.map(data => <App_left_column_element mainName={this.props.mainName} changeMain={this.props.changeMain} ulData={this.props.ulData} name={data.name} svg={data.svg} text={data.text} id={data.id} key={data.id} />)}

                    </ul>
                </div>
                {/* <div className="app_left_help">
                    <div className="app_left_help_elem">
                        <svg
                            width="19px" height="18px">
                            <path fill="rgb(112, 112, 112)"
                                d="M9.191,18.000 C4.115,18.000 -0.000,13.970 -0.000,9.000 C-0.000,4.030 4.115,-0.000 9.191,-0.000 C14.267,-0.000 18.382,4.030 18.382,9.000 C18.382,13.970 14.267,18.000 9.191,18.000 ZM9.191,0.818 C4.576,0.818 0.836,4.481 0.836,9.000 C0.836,13.519 4.576,17.182 9.191,17.182 C13.806,17.182 17.546,13.519 17.546,9.000 C17.546,4.481 13.806,0.818 9.191,0.818 ZM10.296,9.132 C9.905,9.505 9.651,9.819 9.534,10.077 C9.418,10.334 9.360,10.715 9.360,11.218 L8.270,11.218 C8.270,10.647 8.340,10.186 8.478,9.837 C8.617,9.487 8.920,9.087 9.390,8.635 L9.879,8.161 C10.027,8.026 10.144,7.883 10.234,7.736 C10.397,7.476 10.479,7.207 10.479,6.927 C10.479,6.536 10.360,6.196 10.121,5.908 C9.883,5.620 9.488,5.476 8.937,5.476 C8.255,5.476 7.784,5.724 7.523,6.219 C7.377,6.495 7.293,6.892 7.273,7.412 L6.183,7.412 C6.183,6.549 6.432,5.855 6.930,5.330 C6.858,5.406 6.811,5.455 6.776,5.492 C6.841,5.423 6.929,5.331 6.930,5.330 C6.930,5.330 6.930,5.330 6.930,5.330 C7.428,4.804 8.111,4.542 8.980,4.542 C9.784,4.542 10.428,4.766 10.911,5.216 C11.395,5.666 11.637,6.240 11.637,6.939 C11.637,7.363 11.547,7.706 11.370,7.970 C11.193,8.233 10.835,8.621 10.296,9.132 ZM9.463,13.458 L8.245,13.458 L8.245,12.212 L9.463,12.212 L9.463,13.458 Z" />
                        </svg>
                        <p className="app_left_help_elem_text" >Помощь</p>
                    </div>
                </div> */}
            </div>
        );
    };
};

export default connect(dispatch.mapStateToProps, dispatch.mapDispatchToProps)(App_left_column);