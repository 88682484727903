import React, { Component } from 'react';

import './App_right_column.css';

import App_right_column_cards_container from '../App_right-column_cards_container/App_right-column_cards_container.js';

class App_right_column extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rigthColomnHeader: {
                notifications: 2,
                request: 2
            }
        };
    }

    render() {
        const headerItem = [
            {
                svg:
                    <svg
                        width="19px"
                        height="22px"
                    >
                        <path
                            d="M17.934,18.556 L16.388,15.933 C15.675,14.722 15.298,13.336 15.298,11.923 L15.298,9.625 C15.298,6.724 13.398,4.267 10.799,3.476 L10.799,1.833 C10.799,0.822 9.992,-0.000 8.999,-0.000 C8.007,-0.000 7.199,0.822 7.199,1.833 L7.199,3.476 C4.601,4.267 2.700,6.724 2.700,9.625 L2.700,11.923 C2.700,13.336 2.323,14.722 1.610,15.933 L0.064,18.556 C-0.019,18.697 -0.021,18.874 0.058,19.017 C0.138,19.161 0.288,19.250 0.450,19.250 L6.795,19.250 C6.765,19.400 6.750,19.554 6.750,19.708 C6.750,20.972 7.758,22.000 8.999,22.000 C10.240,22.000 11.249,20.972 11.251,19.708 C11.251,19.554 11.235,19.400 11.205,19.250 L17.548,19.250 C17.710,19.250 17.860,19.161 17.940,19.017 C18.020,18.874 18.018,18.697 17.934,18.556 ZM8.099,1.833 C8.099,1.327 8.502,0.917 8.999,0.917 C9.496,0.917 9.899,1.327 9.899,1.833 L9.899,3.274 C9.605,3.231 9.305,3.208 8.999,3.208 C8.694,3.208 8.393,3.231 8.099,3.274 L8.099,1.833 ZM10.350,19.708 C10.350,20.466 9.744,21.083 9.000,21.083 C8.256,21.083 7.650,20.466 7.650,19.708 C7.650,19.553 7.678,19.396 7.729,19.250 L10.272,19.250 C10.323,19.396 10.350,19.553 10.350,19.708 ZM1.245,18.333 L2.382,16.404 C3.179,15.051 3.600,13.502 3.600,11.923 L3.600,9.625 C3.600,6.592 6.022,4.125 8.999,4.125 C11.977,4.125 14.399,6.592 14.399,9.625 L14.399,11.923 C14.399,13.502 14.820,15.051 15.616,16.404 L16.753,18.333 L1.245,18.333 Z"
                        />
                    </svg>,
                text: "уведомления"
            },
            {
                svg:
                    <svg

                        width="17px" height="22px">
                        <path
                            d="M16.518,22.000 L0.481,22.000 C0.215,22.000 -0.000,21.786 -0.000,21.521 L-0.000,2.266 C-0.000,2.002 0.215,1.788 0.481,1.788 L9.856,1.788 C10.121,1.788 10.337,2.002 10.337,2.266 C10.337,2.531 10.121,2.745 9.856,2.745 L0.961,2.745 L0.961,21.043 L16.037,21.043 L16.037,5.665 C16.037,5.400 16.252,5.186 16.518,5.186 C16.783,5.186 16.998,5.400 16.998,5.665 L16.998,21.521 C16.998,21.786 16.783,22.000 16.518,22.000 ZM15.323,4.202 C15.323,4.202 15.323,4.202 15.323,4.202 C15.323,4.203 15.322,4.203 15.322,4.203 L7.701,11.792 C7.641,11.852 7.565,11.895 7.483,11.917 L4.963,12.568 C4.923,12.579 4.883,12.584 4.843,12.584 C4.717,12.584 4.594,12.534 4.503,12.443 C4.382,12.323 4.335,12.149 4.378,11.985 L5.032,9.476 C5.053,9.394 5.096,9.318 5.157,9.258 L12.778,1.669 C12.778,1.669 12.778,1.668 12.778,1.668 C12.778,1.668 12.778,1.668 12.779,1.668 L14.313,0.140 C14.403,0.050 14.525,-0.000 14.652,-0.000 C14.780,-0.000 14.902,0.050 14.992,0.140 L16.857,1.998 C17.045,2.184 17.045,2.487 16.857,2.674 L15.323,4.202 ZM5.929,9.843 L5.513,11.437 L7.114,11.023 L14.303,3.864 L13.118,2.683 L5.929,9.843 ZM14.652,1.155 L13.798,2.007 L14.983,3.187 L15.838,2.336 L14.652,1.155 Z" />
                    </svg>,
                text: "заявки"
            }
        ]
        return (
            <div className="app_right_column backcolor">
                <div className="app_right_column_header">
                    <ul className="app_right_column_header_ul">
                        <li className="app_right_column_header_li">
                            <div className="header_li_svg_box">
                                {headerItem[0].svg}
                                <p className="right_column_header_li_num">{this.state.rigthColomnHeader.notifications}</p>
                            </div>
                            <p className="header_li_text_box color-not-active">
                                {headerItem[0].text}
                            </p>
                        </li>
                        <li className="app_right_column_header_li">
                            <div className="header_li_svg_box">
                                {headerItem[1].svg}
                                <p className="right_column_header_li_num">{this.state.rigthColomnHeader.request}</p>
                            </div>
                            <p className="header_li_text_box color-not-active">
                                {headerItem[1].text}
                            </p>
                        </li>
                    </ul>

                </div>
                <App_right_column_cards_container />
            </div>
        );
    };
}

export default App_right_column;