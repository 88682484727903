import React, { Component } from 'react';

import './App_left-column_element.css';

class App_left_column_element extends Component {
    render() {
        return (
            <li className="app_left_column_element" key={this.props.id.id} onClick={() => this.props.changeMain(this.props.name)}>
                <div className={this.props.name === this.props.mainName ? "app_left_column_element_for_svg checked" : "app_left_column_element_for_svg"}>
                    <div className="svg_box">
                        {
                            this.props.svg
                        }
                    </div>
                </div>
                <div className={this.props.name === this.props.mainName ? "left_column_element_text checked" : "left_column_element_text"}>
                    <p>
                        {
                            this.props.text
                        }
                    </p>
                </div>
            </li >
        );
    };
}

export default App_left_column_element;