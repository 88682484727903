import React, { useState } from 'react';

import DefaultInput from '../../../../../../../../DumbComponents/DefaultInput';

import './styles.css';

const operatingInitState = {
  topic_mode_heat: '',
  topic_mode_cool: '',
  topic_mode_fan: '',
  topic_mode_dry: '',
};

export default props => {
  const selected = !!props.item.conditioner.operating_mode;
  const path = ['rooms', props.roomIndex, 'devicesEndpoints', 'climate'];

  const handlerClick = () => {
    !selected && props.handlerChange(...path, 'conditioner')(null, 'operating_mode', operatingInitState );
    selected && props.handlerChange(...path, 'conditioner')(null, 'operating_mode', { clear: true });
  }

  return (
    <div className='params-item_operation-mode'>
      <div className='room__checkbox room__checkbox_scenes'>
        <label><input type='checkbox' onChange={() => handlerClick()} checked={selected} />Режим работы</label>
      </div>
      {selected &&
        <div>
          <div className='params-item__field'>
            <span className='params-field__title'>Охлаждение</span>
            <DefaultInput
              name='topic_mode_cool'
              value={props.item.conditioner.operating_mode.topic_mode_cool}
              onChange={props.handlerChange(...path, 'conditioner', 'operating_mode')}
              placeholder='topic_mode_cool'
              required={!props.item.conditioner.operating_mode.topic_mode_cool}
            />
          </div>
          <div className='params-item__field'>
            <span className='params-field__title'>Фидбэк охлаждения</span>
            <DefaultInput
              name='topic_mode_cool_fb'
              value={props.item.feedbacks.topic_mode_cool_fb}
              onChange={props.handlerChange(...path, 'feedbacks')}
              placeholder='topic_mode_cool_fb'
              required={!props.item.feedbacks.topic_mode_cool_fb}
            />
          </div>
          <div className='params-item__field'>
            <span className='params-field__title'>Отопление</span>
            <DefaultInput
              name='topic_mode_heat'
              value={props.item.conditioner.operating_mode.topic_mode_heat}
              onChange={props.handlerChange(...path, 'conditioner', 'operating_mode')}
              placeholder='topic_mode_heat'
              required={!props.item.conditioner.operating_mode.topic_mode_heat}
            />
          </div>
          <div className='params-item__field'>
            <span className='params-field__title'>Фидбэк отопления</span>
            <DefaultInput
              name='topic_mode_heat_fb'
              value={props.item.feedbacks.topic_mode_heat_fb}
              onChange={props.handlerChange(...path, 'feedbacks')}
              placeholder='topic_mode_heat_fb'
              required={!props.item.feedbacks.topic_mode_heat_fb}
            />
          </div>
          <div className='params-item__field'>
            <span className='params-field__title'>Вентиляция</span>
            <DefaultInput
              name='topic_mode_fan'
              value={props.item.conditioner.operating_mode.topic_mode_fan}
              onChange={props.handlerChange(...path, 'conditioner', 'operating_mode')}
              placeholder='topic_mode_fan'
            />
          </div>
          <div className='params-item__field'>
            <span className='params-field__title'>Фидбэк вентиляции</span>
            <DefaultInput
              name='topic_mode_fan_fb'
              value={props.item.feedbacks.topic_mode_fan_fb}
              onChange={props.handlerChange(...path, 'feedbacks')}
              placeholder='topic_mode_fan_fb'
            />
          </div>
          <div className='params-item__field'>
            <span className='params-field__title'>Осушение</span>
            <DefaultInput
              name='topic_mode_dry'
              value={props.item.conditioner.operating_mode.topic_mode_dry}
              onChange={props.handlerChange(...path, 'conditioner', 'operating_mode')}
              placeholder='topic_mode_dry'
            />
          </div>
          <div className='params-item__field'>
            <span className='params-field__title'>Фидбэк осушения</span>
            <DefaultInput
              name='topic_mode_dry_fb'
              value={props.item.feedbacks.topic_mode_dry_fb}
              onChange={props.handlerChange(...path, 'feedbacks')}
              placeholder='topic_mode_dry_fb'
            />
          </div>
        </div>
      }
    </div>
  );
}