import React, { Component } from 'react';

import axios from 'axios';

import * as configs from '../../../etc/config.json';
import { _refreshToken } from '../Refresh/Refresh';

import PreLoader from '../PreLoader/PreLoader';
import ApartConfigure from './ApartConfigure';
import DisplayAparts from './DisplayAparts';
import ApartHeader from './ApartHeader';
import ApartSearch from './ApartSearch';

import './styles.css';

const URL = `${configs.default.hostApiServer}:${configs.default.portApiServer}`;

class Aparts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      editApart: undefined,
      searchField: '',
      page: 1,
      floors: null,
      aparts: null
    };
  }

  componentDidMount() {
    const path = `${URL}/admin/apartments?sort=aptnumber`;
    const callback = res => {
      this.setState({ ...this.state, aparts: res.data._items });
      const floorsPath = `${URL}/admin/floors`;
      const floorsCallback = res => {
        this.setState({ ...this.state, floors: res.data._items, loading: false });
      };

      this.createGetRequestToServer(floorsPath, floorsCallback);
    };

    this.createGetRequestToServer(path, callback);
  }

  componentDidUpdate(prevProps, prevState) {
    const path = `${URL}/admin/apartments?sort=aptnumber${(this.state.searchField !== '' && !isNaN(+this.state.searchField)) ? `&where={"aptnumber": ${+this.state.searchField}}` : ''}`;
    const callback = res => {
      this.setState({ ...this.state, aparts: res.data._items });
    };

    prevState.searchField !== this.state.searchField && this.createGetRequestToServer(path, callback);
  }

  handlerUpdateBoard = () => {
    const path = `${URL}/admin/apartments?sort=aptnumber`;
    const callback = res => {
      this.setState({ ...this.state, aparts: res.data._items, editApart: undefined });
      this.stopLoading();
    };

    this.createGetRequestToServer(path, callback);

  }

  handlerResponseError = (error, callback) => {
    if (!navigator.onLine) return alert('Проверьте интернет соединение');

    switch (error.response.status) {
      case 401: {
        if (error.response.data._error.message === 'token expired' ||
          error.response.data._error.message === 'user not found') {

            _refreshToken(
              this.props.client_id,
              this.props.login,
              this.props.password,
              this.props.changeAccessToken,
              callback
            )}
        break;
      }

      case 422: {
        alert(JSON.stringify(error.response.data._issues, null, '\t')).replace(/\{|\}/gm, '');
        break;
      }

      default: alert('Неизвестная ошибка, повторите позже');
    }

    this.stopLoading();
  }

  createAxiosRequest = _etag => {
    const axiosParams = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.access_token,
        'Cache-Control': 'no-cache'
      }
    };

    if (!!_etag) axiosParams.headers['If-Match'] = _etag;

    return axios.create(axiosParams);
  };

  createGetRequestToServer = (path, ...callbacks) => {
    return this.createAxiosRequest().get(path).then(callbacks[0]).catch(err => this.handlerResponseError(err, this.createGetRequestToServer.bind(this, path, ...callbacks)));
  }

  createDeleteRequestToServer = (path, _etag, ...callbacks) => {
    return this.createAxiosRequest(_etag).delete(path).then(callbacks[0]).catch();
  }

  scrolledToBottom = () => {
    const path = `${URL}/admin/apartments?sort=aptnumber&page=${this.state.page + 1}`;
    const callback = res => {
      if (res.data._items.length === 0) return;

      let page = this.state.page + 1;

      this.setState({
        ...this.state,
        aparts: [ ...this.state.aparts, ...res.data._items ],
        page
      });
    };

    this.createGetRequestToServer(path, callback);
  }

  handlerReturnToHome = () => {
    this.props.changeMain("home");
  }

  handlerReturnFromEdit = () => {
    this.setState({ ...this.state, editApart: undefined })
  }

  handlerAddApart = apart => {
    apart === null && this.setState({ ...this.state, editApart: null });
  }

  handlerChangeSearchInput = e => {
    this.setState({ ...this.state, searchField: e.target.value });
  }

  handlerChangeApart = id => {
    const path = `${URL}/admin/apartments?where={"_id": "${id}"}`;
    const configureState = {};

    this.startLoading();

    // get Apart information
    const callbackAparts = resAparts => {
      const apart = resAparts.data._items[0];
      const path = `${URL}/admin/rooms?where={"apartment_id": "${id}"}`;

      configureState.apart = apart;

      // get Rooms info
      const callbackRooms = resRooms => {

        if (resRooms.data._items.length === 0) configureState.rooms = [];

        const path = `${URL}/admin/endpoints?where={"apartment_id": "${id}"}`;

        configureState.rooms = resRooms.data._items;

        // get Endpoints info
        const callbackEndp = resEndp => {
          let globalFlag = false;

          resEndp.data._items.forEach((endp, i) => {
            if (endp.global_endpoint && !globalFlag) {
              configureState.rooms.unshift({ devicesEndpoints: endp });
              globalFlag = true;
            }

            configureState.rooms.find(room => {
              if (room._id === undefined || endp.room_id === undefined) return;
              if (room._id === endp.room_id) return room.devicesEndpoints = endp;
            });
          });

          this.setState({ ...this.state, editApart: configureState, loading: false });
        }


        this.createGetRequestToServer(path, callbackEndp);
      }

      this.createGetRequestToServer(path, callbackRooms);
    };

    this.createGetRequestToServer(path, callbackAparts);
  }

  handlerDeleteApart = (item, index) => {
    if(confirm('Вы действительно хотите удалить апартамент?')) {
      const apartPath = `${URL}/admin/apartments/${item._id}`;
      const apartCallback = res => {
        const thisAparts = JSON.parse(JSON.stringify(this.state.aparts));
        this.setState({ ...this.state, aparts: thisAparts.filter((item, i) => i !== index ) });
      };

      this.createDeleteRequestToServer(apartPath, item._etag, apartCallback);
    }
  }

  startLoading = () => {
    this.setState({ ...this.state, loading: true });
    return true;
  }

  stopLoading = () => {
    this.setState({ ...this.state, loading: false });
    return true;
  }

  handlerCancel = () => {
    this.setState({ ...this.state, editApart: undefined });
  }

  render() {
    return(
      <div className='app-main__aparts'>
        {this.state.loading ?
          <PreLoader onLoad={() => {}} />
          :
          <React.Fragment>
            <ApartHeader
              ulData={this.props.ulData}
              editApart={this.state.editApart}
              handlerAddApart={this.handlerAddApart}
              handlerReturnToHome={this.handlerReturnToHome}
              handlerReturnFromEdit={this.handlerReturnFromEdit}
            />
            { this.state.editApart === undefined &&
              <ApartSearch
                searchValue={this.state.searchField}
                onChange={this.handlerChangeSearchInput}
              />
            }
            { this.state.editApart !== undefined ?
              <ApartConfigure
                client_id={this.props.client_id}
                user_id={this.props.user_id}
                username={this.props.username}
                access_token={this.props.access_token}
                login={this.props.LOGIN}
                password={this.props.PASSWORD}
                changeAccessToken={this.props.changeAccessToken}
                initialState={this.state.editApart}
                handlerUpdateBoard={this.handlerUpdateBoard}
                startLoading={this.startLoading}
                stopLoading={this.stopLoading}
              />
              : <DisplayAparts
                  floors={this.state.floors}
                  aparts={this.state.aparts}
                  pages={this.state.pages}
                  scrolledToBottom={this.scrolledToBottom}
                  handlerChangeApart={this.handlerChangeApart}
                  handlerDeleteApart={this.handlerDeleteApart}
                />
            }
          </React.Fragment>
        }
      </div>
    );
  }
}

export default Aparts;