import React from 'react';

import DefaultInput from '../../../../../../DumbComponents/DefaultInput';

// import './styles.css';

export default props => {
  const path = ['rooms', props.roomIndex, 'devicesEndpoints', 'sockets', props.itemIndex];

  return (
      <div className='params__item params__item_scenes'>
        <div className='params-item__field'>
          <span className='params-field__title'>Название:</span>
          <DefaultInput
            name='title'
            value={props.item.title}
            onChange={props.handlerChange(...path)}
            placeholder='title'
            required={!props.item.title}
          />
        </div>

        <div className='params-item__field'>
          <span className='params-field__title'>Канал:</span>
          <DefaultInput
            name='topic_switch'
            value={props.item.topic_switch}
            onChange={props.handlerChange(...path)}
            placeholder='topic_switch'
            required={!props.item.topic_switch}
          />
        </div>
        <div className='params-item__field'>
          <span className='params-field__title'>Фидбэк:</span>
          <DefaultInput
            name='topic_switch_fb'
            value={props.item.feedbacks.topic_switch_fb}
            onChange={props.handlerChange(...path, 'feedbacks')}
            placeholder='topic_switch_fb'
            required={!props.item.feedbacks.topic_switch_fb}
          />
        </div>
      </div>
  );
}