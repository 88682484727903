import React from 'react';

import DefaultInput from '../../../../../DumbComponents/DefaultInput';

import ClimateParams from './ClimateParams';

// import './styles.css';

const climateInitState = {
  feedbacks: {
    topic_setpoint_fb: '',
    topic_temperature_fb: '',
    topic_power_fb: '',
    topic_manual_auto_fb: '',
    topic_fan_auto_fb: '',
    topic_fan_min_fb: '',
    topic_fan_med_fb: '',
    topic_fan_max_fb: '',
    topic_mode_heat_fb: '',
    topic_mode_cool_fb: '',
    topic_mode_fan_fb: '',
    topic_mode_dry_fb: '',
    topic_mode_min_fb: '',
    topic_mode_med_fb: '',
    topic_mode_max_fb: '',
  },
}

export default props => {
  const selected = !!props.items;
  const path = ['rooms', props.roomIndex, 'devicesEndpoints'];

  const handlerClick = () => {
    !selected && props.handlerChange(...path)(null, 'climate', climateInitState );
    selected && props.handlerChange(...path)(null, 'climate', { clear: true });
  }

  return (
    <div className='room__block'>
      <div className='room__checkbox room__checkbox_climate'>
        <label><input onChange={() => handlerClick()} type='checkbox' checked={selected} />Климат</label>
      </div>
      { selected && 
        <div className='params params_climate'>

          <ClimateParams item={props.items} roomIndex={props.roomIndex} handlerChange={props.handlerChange} />
        </div>
      }
    </div>
  );
}