import axios from 'axios';
import qs from 'qs';
import { hostApiServer, portApiServer, hostloginServer, portLoginServer } from '../etc/config.json';

// const apiPrefix = 'http://188.225.35.65'
// // const apiPrefix = 'http://localhost'
// const port = '5000'
// const port = '8187'

export default {

    login(login, password) {
        const basicAuth = 'Basic ' + btoa(login + ':' + password);
        // console.log(basicAuth);

        const request = axios.create({
            headers: {
                "Authorization": basicAuth
            }
        });
        return request.get(`${hostloginServer}:${portLoginServer}/getclient?installation_id=123`);
    },

    logOut(login, password) {
        const basicAuth = 'Basic ' + btoa(login + ':' + password);
        // console.log(basicAuth);

        const request = axios.create({
            headers: {
                "Authorization": basicAuth
            }
        });
        return request.get(`${hostloginServer}:${portLoginServer}/logout`);
    },
    createImageId(data, access_token, typeuser) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                'Accept': 'application/json',
                // 'Content-Type': 'multipart/form-data',
                "Cache-Control": "no-store, no-cache, must-revalidate"
            }
        });
        // console.log('createImageId', data);

        return request.post(`${hostApiServer}:${portApiServer}/images/`, data);

    },
    getImage(imageId, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
                "Cache-Control": "no-store, no-cache, must-revalidate"
            }
        });

        return request.get(`${hostApiServer}:${portApiServer}/images/${imageId}`);

    },
    getRealClientId(ClientId, access_token, typeuser) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "Content-Type": "application/json; charset=utf-8",
                'Accept': 'application/json',
                "Cache-Control": "no-store, no-cache, must-revalidate"
            }
        });
        if (typeuser === "admin" || typeuser === "operator") {
            return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/clients/${ClientId}`);
        } else {
            return request.get(`${hostApiServer}:${portApiServer}/clients/${ClientId}`);
        }

    },
    // refreshToken(client_id, refresh_token) {
    //     const request = axios.create({
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    //         }
    //     });

    //     const data = qs.stringify({
    //         "client_id": client_id,
    //         "grant_type": "refresh_token",
    //         "refresh_token": refresh_token
    //     });
    //     /*         let serialize = function (obj) {
    //          let str = [];
    //          for (let p in obj)
    //              if (obj.hasOwnProperty(p)) {
    //                  str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    //              }
    //          return str.join("&");
    //      }
    //      let newData = serialize(refreshTokenData); */
    //     return request.post(`${hostApiServer}:${portApiServer}/oauth/token`, data);
    // },
    refreshToken(client_id, login, password) {
        const request = axios.create({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
            }
        });

        const data = qs.stringify({
            "client_id": client_id,
            "grant_type": "password",
            "username": login,
            "password": password
        });

        return request.post(`${hostApiServer}:${portApiServer}/oauth/token`, data);
    },


    //Пользователи
    getAccounts(access_token, typeuser, mainName, page, where, search) {
        if (page === undefined) {
            page = 1
        }
        if (search === undefined) {
            search = false
        }

        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "Content-Type": "application/json; charset=utf-8",
                'Accept': 'application/json',
                "Cache-Control": "no-store, no-cache, must-revalidate"
            }
        });
        if (typeuser === "admin" || typeuser === "operator") {

            if (mainName === "apart") {
                if (where !== undefined) {
                    if (search === true) {

                        return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/accounts?where={
                            "$and":
                            [
                                {"role":{"$regex": "user"}},
                                {"$or":
                                    [
                                        {"firstname":{"$regex": "(?i)${where}"}},
                                        {"lastname":{"$regex": "(?i)${where}"}},
                                        {"middlename":{"$regex": "(?i)${where}"}},
                                        {"phone":{"$regex": "${where}"}}
                                    ]
                                }
                            ]}&page=${page}`);

                    } else {
                        return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/accounts?where={ "${where[0]}":"${where[1]}", "role":{"$regex": "user"} }&page=${page}`);
                    }
                }
                return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/accounts?where={"role":{"$regex": "user"}}&page=${page}`);
            } else if (mainName === "lk") {
                if (where !== undefined) {
                    if (search === true) {
                        return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/accounts?where={
                             "$and":
                             [
                                 {"role":{"$regex": "operator"}},
                                 {"$or":
                                     [
                                         {"firstname":{"$regex": "(?i)${where}"}},
                                         {"lastname":{"$regex": "(?i)${where}"}},
                                         {"middlename":{"$regex": "(?i)${where}"}},
                                         {"phone":{"$regex": "${where}"}}
                                     ]
                                 }
                             ]}&page=${page}`);

                    } else {
                        return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/accounts?where={ "${where[0]}":"${where[1]}" }, "role":{"$regex": "operator"}}&page=${page}`);

                    }
                }
                return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/accounts?where={"role":{"$regex": "operator"}}&page=${page}`);
            } else {
                if (where !== undefined) {
                    if (search === true) {
                        return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/accounts?where={ "firstname":{"$regex": "${where}"},"lastname":{"$regex": "${where}"},"middlename":{"$regex": "${where}"}}`);
                    }
                    return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/accounts?where={ "${where[0]}":"${where[1]}" }`);
                }
            }
        }
        else {
            return request.get(`${hostApiServer}:${portApiServer}/accounts?where={ "${where[0]}":"${where[1]}" }`);;
        }



    },

    getAccount(id, access_token, typeuser) {

        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "Content-Type": "application/json; charset=utf-8",
                'Accept': 'application/json',
                "Cache-Control": "no-store, no-cache, must-revalidate"
            }
        });
        if (typeuser === "admin" || typeuser === "operator") {
            return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/accounts/${id}`);
        } else {
            return request.get(`${hostApiServer}:${portApiServer}/accounts/${id}`);
        }
    },

    createAccount(data, typeuser, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "content-type": "application/json",
                "Cache-Control": "no-store, no-cache, must-revalidate"

            }
        });
        if (typeuser === "admin" || typeuser === "operator") {
            return request.post(`${hostApiServer}:${portApiServer}/${typeuser}/accounts`, data);
        } else {
            return false;
        }

    },

    deleteAccounts(id, etag, typeuser, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "If-Match": etag
            }
        });
        return request.delete(`${hostApiServer}:${portApiServer}/${typeuser}/accounts/${id}`);
    },

    editAccount(id, etag, typeuser, data, access_token) {
        // console.log('data edit ', data);
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "If-Match": etag
            }
        });
        return request.patch(`${hostApiServer}:${portApiServer}/${typeuser}/accounts/${id}`, data);
    },

    getApartments(access_token, typeuser) {

        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "Content-Type": "application/json; charset=utf-8",
                "Cache-Control": "no-store, no-cache, must-revalidate"
            }
        });
        if (typeuser === "admin" || typeuser === "operator") {
            // console.log('request.get(`${hostApiServer}:${portApiServer}/${typeuser}/apartments`)', request.get(`${hostApiServer}:${portApiServer}/${typeuser}/apartments`));
            return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/apartments`);
        } else {
            return request.get(`${hostApiServer}:${portApiServer}/apartments/`);
        }

    },

    getAptnumber(apt_id, typeuser, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "Content-Type": "application/json",
                "Cache-Control": "no-store, no-cache, must-revalidate"

            }
        });
        if (typeuser === "admin" || typeuser === "operator") {
            return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/apartments/${apt_id}?sort=-_created&max_results=9999`);
        } else {
            return request.get(`${hostApiServer}:${portApiServer}/apartments/${apt_id}?sort=-_created&max_results=9999`);
        }

    },
    //Сервисы
    getService(id, access_token, typeuser) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "content-type": "application/json",
                "Cache-Control": "no-store, no-cache, must-revalidate"

            }
        });
        if (typeuser === "admin" || typeuser === "operator") {
            return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/services/${id}`);
        } else {
            return request.get(`${hostApiServer}:${portApiServer}/services/${id}`);
        }
    },
    getOnlineServices(access_token, typeuser, page, where, search) {
        if (page === undefined) {
            page = 1
        }
        if (search === undefined) {
            search = false
        }
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "content-type": "application/json",
                "Cache-Control": "no-store, no-cache, must-revalidate"
            }
        });

        if (typeuser === "admin" || typeuser === "operator") {


            if (where !== undefined) {
                if (search === true) {

                    return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/services?where={
                         
                                "$or":
                                    [
                                        {"title":{"$regex": "(?i)${where}"}},
                                        {"description":{"$regex": "(?i)${where}"}},
                                        {"email":{"$regex": "(?i)${where}"}},
                                        {"phone_number":{"$regex": "${where}"}}
                                    ]
                                
                            }&page=${page}`);

                } else {
                    return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/services?where={"${where[0]}":"${where[1]}"}&page=${page}`);
                }
            }
            return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/services?page=${page}`);
        }
        else {
            return request.get(`${hostApiServer}:${portApiServer}/services?page=${page}`);
        }



    },

    getOnlineCategoryServices(id, access_token, typeuser) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "content-type": "application/json",
                "Cache-Control": "no-store, no-cache, must-revalidate"

            }
        });
        if (typeuser === "admin" || typeuser === "operator") {
            return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/servicecategories/${id}`);
        } else {
            return request.get(`${hostApiServer}:${portApiServer}/servicecategories/${id}`);
        }
    },

    getOnlineAllCategoryServices(access_token, typeuser) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "content-type": "application/json",
                "Cache-Control": "no-store, no-cache, must-revalidate"

            }
        });
        if (typeuser === "admin" || typeuser === "operator") {
            return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/servicecategories/`);
        } else {
            return request.get(`${hostApiServer}:${portApiServer}/servicecategories/`);
        }
    },

    deleteOnlineServices(id, etag, typeuser, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "If-Match": etag
            }
        });
        if (typeuser === "admin" || typeuser === "operator") {
            return request.delete(`${hostApiServer}:${portApiServer}/${typeuser}/services/${id}`);
        } else {
            return false;
        }
    },

    editOnlineServices(id, etag, typeuser, data, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "If-Match": etag
            }
        });
        if (typeuser === "admin" || typeuser === "operator") {
            return request.patch(`${hostApiServer}:${portApiServer}/${typeuser}/services/${id}`, data);
        } else {
            return false;
        }
    },
    createOnlineServices(data, typeuser, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "content-type": "application/json",
                "Cache-Control": "no-store, no-cache, must-revalidate"

            }
        });
        if (typeuser === "admin" || typeuser === "operator") {
            return request.post(`${hostApiServer}:${portApiServer}/${typeuser}/services`, data);
        } else {
            return false;
        }

    },
    createOnlineServicesCategory(data, typeuser, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "content-type": "application/json",
                "Cache-Control": "no-store, no-cache, must-revalidate"

            }
        });
        if (typeuser === "admin" || typeuser === "operator") {
            return request.post(`${hostApiServer}:${portApiServer}/${typeuser}/servicecategories`, data);
        } else {
            return false;
        }

    },
    editOnlineServicesCategory(id, etag, typeuser, data, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "If-Match": etag
            }
        });
        return request.patch(`${hostApiServer}:${portApiServer}/${typeuser}/servicecategories/${id}`, data);
    },
    deleteOnlineCategoryServices(id, etag, typeuser, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "If-Match": etag
            }
        });
        if (typeuser === "admin" || typeuser === "operator") {
            return request.delete(`${hostApiServer}:${portApiServer}/${typeuser}/servicecategories/${id}`);
        } else {
            return false;
        }

    },
    // Заявки

    getServices(typeuser, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "content-type": "application/json",
                "Cache-Control": "no-store, no-cache, must-revalidate"

            }
        });
        if (typeuser === "admin" || typeuser === "operator") {
            return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/services?sort=-_created&max_results=9999`);
        } else {
            return request.get(`${hostApiServer}:${portApiServer}/services?sort=-_created&max_results=9999`);

        }
    },

    getServicerequests(typeuser, access_token, page) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "content-type": "application/json",
                "Cache-Control": "no-store, no-cache, must-revalidate"

            }
        });
        if (typeuser === "admin" || typeuser === "operator") {
            return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/servicerequests/?sort=-_created&page=${page}`);
        } else {
            return request.get(`${hostApiServer}:${portApiServer}/servicerequests/?sort=-_created&page=${page}`);
        }

    },

    getServicerequest(id, typeuser, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "content-type": "application/json",
                "Cache-Control": "no-store, no-cache, must-revalidate"

            }
        });

        return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/servicerequests/${id}`);
    },

    editServicerequest(id, etag, typeuser, data, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "If-Match": etag
            }
        });
        return request.patch(`${hostApiServer}:${portApiServer}/${typeuser}/servicerequests/${id}`, data);
    },

    //Рассылка
    getNotificationsType(id, typeuser, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "content-type": "application/json",
                "Cache-Control": "no-store, no-cache, must-revalidate"

            }
        });
        return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/notificationcategories/${id}`);
    },


    getNotificationsTypes(typeuser, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "content-type": "application/json",
                "Cache-Control": "no-store, no-cache, must-revalidate"

            }
        });
        return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/notificationcategories`);
    },

    createNotificationsTypes(typeuser, data, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "content-type": "application/json",
                "Cache-Control": "no-store, no-cache, must-revalidate"

            }
        });
        return request.post(`${hostApiServer}:${portApiServer}/${typeuser}/notificationcategories`, data);
    },
    deleteNotificationCategory(id, etag, typeuser, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "If-Match": etag
            }
        });
        return request.delete(`${hostApiServer}:${portApiServer}/${typeuser}/notificationcategories/${id}`);
    },

    editNotificationCategory(id, data, etag, typeuser, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "If-Match": etag
            }
        });
        return request.patch(`${hostApiServer}:${portApiServer}/${typeuser}/notificationcategories/${id}`, data);
    },

    getNotificationsList(typeuser, active, access_token, where, search) {
        if (search === undefined) {
            search = false
        }
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "content-type": "application/json",
                "Cache-Control": "no-store, no-cache, must-revalidate"

            }
        });
        if (typeuser === "admin" || typeuser === "operator") {


            if (where !== undefined) {
                if (search === true) {
                    return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/notifications?where={
                     "$and":
                     [
                         {"active": ${active}},
                         {"$or":
                                [
                                    {"title":{"$regex": "(?i)${where}"}},
                                    {"text":{"$regex": "(?i)${where}"}}
                                ]
                         }
                     ]}`);


                }
            }
            return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/notifications?where={"active":${active}}`);
        }
        else {
            return request.get(`${hostApiServer}:${portApiServer}/notifications?where={"active":${active}}`);
        }
    },
    getNotification(typeuser, id, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "content-type": "application/json",
                "Cache-Control": "no-store, no-cache, must-revalidate"

            }
        });
        return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/notifications/${id}`);
    },
    getQuestionsList(typeuser, active, access_token, where, search) {
        if (search === undefined) {
            search = false
        }
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "content-type": "application/json",
                "Cache-Control": "no-store, no-cache, must-revalidate"

            }
        });
        if (typeuser === "admin" || typeuser === "operator") {


            if (where !== undefined) {
                if (search === true) {

                    return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/questions?where={
                        "$and":
                        [
                            {"active": ${active}},
                            {"$or":
                                   [
                                       {"title":{"$regex": "(?i)${where}"}},
                                       {"text":{"$regex": "(?i)${where}"}}
                                   ]
                            }
                        ]}`);


                }
            }
            return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/questions?where={"active":${active}}`);
        }
        else {
            return request.get(`${hostApiServer}:${portApiServer}/questions?where={"active":${active}}`);
        }

    },
    getQuestion(typeuser, id, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "content-type": "application/json",
                "Cache-Control": "no-store, no-cache, must-revalidate"

            }
        });
        return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/questions/${id}`);
    },
    createNewNotifications(typeUser, data, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "content-type": "application/json",
                "Cache-Control": "no-store, no-cache, must-revalidate"

            }
        });
        return request.post(`${hostApiServer}:${portApiServer}/${typeUser}/notifications`, data);

    },

    createNewQuestions(typeUser, data, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "content-type": "application/json",
                "Cache-Control": "no-store, no-cache, must-revalidate"

            }
        });
        return request.post(`${hostApiServer}:${portApiServer}/${typeUser}/questions`, data);

    },

    deleteNotification(id, etag, typeuser, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "If-Match": etag
            }
        });
        return request.delete(`${hostApiServer}:${portApiServer}/${typeuser}/notifications/${id}`);
    },


    deleteQuestions(id, etag, typeuser, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "If-Match": etag
            }
        });
        return request.delete(`${hostApiServer}:${portApiServer}/${typeuser}/questions/${id}`);
    },

    editNotification(id, etag, typeuser, data, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "If-Match": etag
            }
        });
        return request.patch(`${hostApiServer}:${portApiServer}/${typeuser}/notifications/${id}`, data);
    },

    editQuestions(id, etag, typeuser, data, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "If-Match": etag
            }
        });
        return request.patch(`${hostApiServer}:${portApiServer}/${typeuser}/questions/${id}`, data);
    },

    //Pass

    getPassRequests(typeuser, access_token, page) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "content-type": "application/json",
                "Cache-Control": "no-store, no-cache, must-revalidate"

            }
        });
        return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/passrequests/?sort=-_created&page=${page}`);
    },

    editPassRequest(typeuser, id, etag, access_token, data) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "If-Match": etag

            }
        });
        const dataToSend = {
            status: data
        }
        return request.patch(`${hostApiServer}:${portApiServer}/${typeuser}/passrequests/${id}`, dataToSend);
    },

    getUsers(typeuser, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "content-type": "application/json",
                "Cache-Control": "no-store, no-cache, must-revalidate"

            }
        });
        if (typeuser === "user") {
            return request.get(`${hostApiServer}:${portApiServer}/users`);
        } else {
            return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/users`);
        }

    },

    getUser(typeuser, id, access_token) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "content-type": "application/json",
                "Cache-Control": "no-store, no-cache, must-revalidate"

            }
        });
        if (typeuser === "user") {
            return request.get(`${hostApiServer}:${portApiServer}/users/${id}`);
        } else {
            return request.get(`${hostApiServer}:${portApiServer}/${typeuser}/users/${id}`);
        }

    },

    createUser(typeuser, access_token, sendPasswordToAdmin, data) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "content-type": "application/json",
                "Cache-Control": "no-store, no-cache, must-revalidate"
            }
        });
        if (typeuser === "admin" || typeuser === "operator") {
            return request.post(`${hostApiServer}:${portApiServer}/${typeuser}/users?sendpasstoadmin=${sendPasswordToAdmin}`, data);

        } else {
            return false;
        }

    },

    editUser(id, typeuser, etag, access_token, data) {
        const request = axios.create({
            headers: {
                "Authorization": "Bearer " + access_token,
                "If-Match": etag
            }
        });
        if (typeuser === "admin" || typeuser === "operator") {
            return request.patch(`${hostApiServer}:${portApiServer}/${typeuser}/users/${id}`, data);

        } else {
            return false;
        }

    },

    // Личный кабинет


}