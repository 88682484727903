import React from 'react';

import DefaultInput from '../../../../../../DumbComponents/DefaultInput';

import ParamsWithDimmer from './ParamsWithDimmer';

// import './styles.css';

export default props => {
  const path = ['rooms', props.roomIndex, 'devicesEndpoints', 'light', props.itemIndex];

  return (
    <div className='params__item params__item_lights'>
      <div className='params-item__field'>
        <span className='params-field__title'>Название:</span>
        <DefaultInput
          placeholder="title"
          name='title'
          value={props.item.title}
          onChange={props.handlerChange(...path)}
          placeholder='title'
          required={!props.item.title}
        />
      </div>

      <div className='params-item__field'>
        <span className='params-field__title'>Устройство:</span>
        <DefaultInput
          placeholder="topic_switch"
          name='topic_switch'
          value={props.item.topic_switch}
          onChange={props.handlerChange(...path)}
          placeholder='topic_switch'
          required={!props.item.topic_switch}
        />
      </div>
      <div className='params-item__field'>
        <span className='params-field__title'>Фидбэк:</span>
        <DefaultInput
          placeholder="topic_switch_fb"
          name='topic_switch_fb'
          value={props.item.feedbacks.topic_switch_fb}
          onChange={props.handlerChange(...path, 'feedbacks')}
          placeholder='topic_switch_fb'
          required={!props.item.feedbacks.topic_switch_fb}
        />
      </div>
      <div className='dimmer'>
        <div className='room__checkbox room__checkbox_dimmer'>
          <label>
            <input
              type="checkbox"
              onChange={() => props.handlerChange(...path)(null, 'dimmable', !props.item.dimmable)}
              checked={props.item.dimmable}
            />Диммер</label>
          </div>
        { props.item.dimmable &&
          <ParamsWithDimmer
            roomIndex={props.roomIndex}
            itemIndex={props.itemIndex}
            item={props.item}
            handlerChange={props.handlerChange}
          /> }
      </div>
    </div>
  );
}