import React from 'react';

import DefaultInput from '../../../../../../../../DumbComponents/DefaultInput';

import './styles.css';

const fanInitState = {
  topic_fan_auto: '',
  topic_fan_min: '',
  topic_fan_med: '',
  topic_fan_max: '',
};

export default props => {
  const selected = !!props.item.conditioner.fan;
  const path = ['rooms', props.roomIndex, 'devicesEndpoints', 'climate'];

  const handlerClick = () => {
    !selected && props.handlerChange(...path, 'conditioner')(null, 'fan', fanInitState );
    selected && props.handlerChange(...path, 'conditioner')(null, 'fan', { clear: true });
  }

  return (
    <div className='params-item_conditioner-ventilation'>
      <div className='room__checkbox room__checkbox_fan'>
        <label><input type='checkbox' onChange={() => handlerClick()} checked={selected} />Вентиляция</label>
      </div>
      {selected && <div>
          <div className='params-item__field'>
            <span className='params-field__title'>Топик автоматического режима</span>
            <DefaultInput
              name='topic_fan_auto'
              value={props.item.conditioner.fan.topic_fan_auto}
              onChange={props.handlerChange(...path, 'conditioner', 'fan')}
              placeholder='topic_fan_auto'
              required={!props.item.conditioner.fan.topic_fan_auto}
            />
          </div>
          <div className='params-item__field'>
            <span className='params-field__title'>Фидбэк авто</span>
            <DefaultInput
              name='topic_fan_auto_fb'
              value={props.item.feedbacks.topic_fan_auto_fb}
              onChange={props.handlerChange(...path, 'feedbacks')}
              placeholder='topic_fan_auto_fb'
              required={!props.item.feedbacks.topic_fan_auto_fb}
            />
          </div>
          <div className='params-item__field'>
            <span className='params-field__title'>Топик минимальной скорости</span>
            <DefaultInput
              name='topic_fan_min'
              value={props.item.conditioner.fan.topic_fan_min}
              onChange={props.handlerChange(...path, 'conditioner', 'fan')}
              placeholder='topic_fan_min'
              required={!props.item.conditioner.fan.topic_fan_min}
            />
          </div>
          <div className='params-item__field'>
            <span className='params-field__title'>Фидбэк минимальной скорости</span>
            <DefaultInput
              name='topic_fan_min_fb'
              value={props.item.feedbacks.topic_fan_min_fb}
              onChange={props.handlerChange(...path, 'feedbacks')}
              placeholder='topic_fan_min_fb'
              required={!props.item.feedbacks.topic_fan_min_fb}
            />
          </div>
          <div className='params-item__field'>
            <span className='params-field__title'>Топик средней скорости</span>
            <DefaultInput
              name='topic_fan_med'
              value={props.item.conditioner.fan.topic_fan_med}
              onChange={props.handlerChange(...path, 'conditioner', 'fan')}
              placeholder='topic_fan_med'
              required={!props.item.conditioner.fan.topic_fan_med}
            />
          </div>
          <div className='params-item__field'>
            <span className='params-field__title'>Фидбэк средней скорости</span>
            <DefaultInput
              name='topic_fan_med_fb'
              value={props.item.feedbacks.topic_fan_med_fb}
              onChange={props.handlerChange(...path, 'feedbacks')}
              placeholder='topic_fan_med_fb'
              required={!props.item.feedbacks.topic_fan_med_fb}
            />
          </div>
          <div className='params-item__field'>
            <span className='params-field__title'>Топик максимальной скорости</span>
            <DefaultInput
              name='topic_fan_max'
              value={props.item.conditioner.fan.topic_fan_max}
              onChange={props.handlerChange(...path, 'conditioner', 'fan')}
              placeholder='topic_fan_max'
              required={!props.item.conditioner.fan.topic_fan_max}
            />
          </div>
          <div className='params-item__field'>
            <span className='params-field__title'>Фидбэк максимальной скорости</span>
            <DefaultInput
              name='topic_fan_max_fb'
              value={props.item.feedbacks.topic_fan_max_fb}
              onChange={props.handlerChange(...path, 'feedbacks')}
              placeholder='topic_fan_max_fb'
              required={!props.item.feedbacks.topic_fan_max_fb}
            />
          </div>
        </div>
      }
    </div>
  );
}