import React, { Component } from 'react';

import { connect } from 'react-redux';
import { _refreshToken } from '../Refresh/Refresh';
import dispatch from '../../../store/dispatch.js';
import api from '../../../api';

import MailingMenu from './MailingMenu/MailingMenu.js';
import MailingMain from './MailingMain/MailingMain.js';
import MailingAddTypeNotification from './MailingAddTypeNotification/MailingAddTypeNotification.js';
import MailingAddAnswers from './MailingAddAnswers/MailingAddAnswers.js';
import PreLoader from '../PreLoader/PreLoader.js';

import './Mailing.css';
import { async } from 'q';

class Mailing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stopUpdate: false,
            // stopDidUpdate: false,
            answerArray: undefined
        }
    }

    getCategoryId() {
        for (let i = 0; i < this.props.notificationCategoriesList._items.length; i++) {
            switch (this.props.notificationCategoriesList._items[i].title) {
                case this.props.state_create_notifacation_types:
                    return this.props.notificationCategoriesList._items[i]._id;
                default:
                    break;
            }
        }
    }

    getDataTimePublication() {
        let dateForSend = new Date(this.props.state_create_notifacation_date + ' ' + this.props.state_create_notifacation_time + ':00');
        if (this.props.statusSendDataNotificationOnServer === "create" && this.props.state_create_notifacation_status_notifacation === false) {
            dateForSend = new Date();
        }



        dateForSend.setTimezoneOffset(0);


        let dateForSendFormat = dateForSend.getUTCFullYear() + '-' + ('0' + (dateForSend.getMonth() + 1)).slice(-2) + '-' + ('0' + dateForSend.getDate()).slice(-2);
        let timeForSendFormat = ('0' + (dateForSend.getHours())).slice(-2) + ':' + ('0' + (dateForSend.getMinutes())).slice(-2);

        //---------------------------------------------------------------------------------------------

        return dateForSendFormat + ' ' + timeForSendFormat + ':00+00:00';

    }

    getDataTimeEndPublication() {
        let dateForSend = new Date(this.props.state_create_notifacation_date_end + ' ' + this.props.state_create_notifacation_time_end + ':00');
        if (this.props.state_create_notifacation_date_end === "" || this.props.state_create_notifacation_date_end === undefined) {
            return null
        }

        dateForSend.setTimezoneOffset(0);


        let dateForSendFormat = dateForSend.getUTCFullYear() + '-' + ('0' + (dateForSend.getMonth() + 1)).slice(-2) + '-' + ('0' + dateForSend.getDate()).slice(-2);
        let timeForSendFormat = ('0' + (dateForSend.getHours())).slice(-2) + ':' + ('0' + (dateForSend.getMinutes())).slice(-2);

        //---------------------------------------------------------------------------------------------

        return dateForSendFormat + ' ' + timeForSendFormat + ':00+00:00';

    }

    getDataDateNewForSend() {
        //---------------------------------------------------------------------------------------------

        let dateForSend = new Date();
        // dateForSend.setTimezoneOffset(0);

        //---------------------------------------------------------------------------------------------

        return dateForSend.getUTCFullYear() + '-' + ('0' + (dateForSend.getMonth() + 1)).slice(-2) + '-' + ('0' + dateForSend.getDate()).slice(-2);

    }

    getDataTimeNewForSend() {
        //---------------------------------------------------------------------------------------------

        let dateForSend = new Date();
        // dateForSend.setTimezoneOffset(0);
        //---------------------------------------------------------------------------------------------

        // return dateForSend.getUTCFullYear() + '-' + ('0' + (dateForSend.getMonth() + 1)).slice(-2) + '-' + ('0' + dateForSend.getDate()).slice(-2); 
        return ('0' + (dateForSend.getHours())).slice(-2) + ':' + ('0' + (dateForSend.getMinutes())).slice(-2);
    }

    createNewNotification() {

        if (this.props.state_open_notifacation_status_questions === true) {
            this.props.changeStateCreateNotifacationDateEnd("");
            this.props.changeStateCreateNotifacationTimeEnd("");
            this.props.changeStateStatusQuestionsEnd(false);
            this.props.changeStateCreateQuestionsAnswerOptions(["Да", "Нет", "Не знаю", "Воздержусь"]);
            // this.props.changeStateWindowOpenQuestion(!this.props.stateWindowOpenQuestion);
        } else {
            this.props.changeStateCreateNotifacationTypes(undefined);
        }
        this.props.changeStateCreateQuestionsTitle(undefined);
        this.props.changeStateCreateNotifacationDate(this.getDataDateNewForSend()); //
        this.props.changeStateCreateNotifacationTime(this.getDataTimeNewForSend()); //
        this.props.changeStateCreateNotifacationMessage(undefined);
        this.props.changeStateCreateNotifacationSatusNotification(false);
        this.props.changeStateEditNotifacationId(undefined);
        this.props.changeStateEditNotifacationEtag(undefined);
        this.props.changeStatusSendDataNotificationOnServer('create');
        this.props.changeStateWindowOpenNewMailing(!this.props.stateWindowOpenNewMailing);
    }

    sendDataNotificationOnServer() {
        if (this.props.state_create_notifacation_message === "" || this.props.state_create_notifacation_message === undefined) {
            // this.props.changeStateDangerInputCreateNotificationMessage(true);
        } if (this.props.state_create_notifacation_types === "" || this.props.state_create_notifacation_types === undefined) {
            this.props.changeStateDangerInputCreateNotificationTypeNotification(true);
        } if (this.props.state_create_notifacation_date === "" || this.props.state_create_notifacation_date === undefined) {
            this.props.changeStateDangerInputCreateNotificationDate(true);
        } if (this.props.state_create_notifacation_time === "" || this.props.state_create_notifacation_time === undefined) {
            this.props.changeStateDangerInputCreateNotificationTime(true);
        }
        if (this.props.state_open_notifacation_status_questions === true) {
            this.props.changeStateStatusQuestionsEnd(false);
            this.sendDataQuestion();
            // this.props.changeStateWindowOpenQuestion(!this.props.stateWindowOpenQuestion);
        } else {
            if (this.props.state_create_notifacation_message !== undefined && this.props.state_create_notifacation_types !== undefined && this.props.state_create_notifacation_date !== undefined && this.props.state_create_notifacation_time !== undefined && this.props.state_create_notifacation_message !== "" && this.props.state_create_notifacation_types !== "" && this.props.state_create_notifacation_date !== "" && this.props.state_create_notifacation_time !== "") {
                this.sendDataNotification();
            } else {
                console.log('Поля не заполнены');
                return;
            }

        }

    }
    sendDataNotification = async () => {
        const dataOnServer = {
            title: this.props.state_create_questions_title,
            text: this.props.state_create_notifacation_message,
            category_id: this.getCategoryId(),
            datetime_publication: this.getDataTimePublication(),
            active: !this.props.state_create_notifacation_status_notifacation
        }

        console.log('dataOnServer: ', dataOnServer);

        if (this.props.statusSendDataNotificationOnServer === "create") {
            await api.createNewNotifications(this.props.LOGIN_TYPE_USER, dataOnServer, this.props.access_token)
                .then(async (response) => {
                    await api.getNotificationsList(this.props.LOGIN_TYPE_USER, true, this.props.access_token)
                        .then((req) => {
                            this.props.changeMailingDataActive(req.data);
                            console.log(req);
                        })
                        .catch(
                            err => {
                                console.log(err.response);
                                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                    _refreshToken(
                                        this.props.client_id,
                                        this.props.LOGIN,
                                        this.props.PASSWORD,
                                        this.props.changeAccessToken,
                                        this.sendDataNotification.bind(this)
                                    )

                                }
                            }
                        );
                    await api.getNotificationsList(this.props.LOGIN_TYPE_USER, false, this.props.access_token)
                        .then((req) => {
                            this.props.changeMailingDataNotActive(req.data);
                            console.log(req);
                        })
                        .catch(
                            err => {
                                console.log(err.response);
                                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                                    _refreshToken(
                                        this.props.client_id,
                                        this.props.LOGIN,
                                        this.props.PASSWORD,
                                        this.props.changeAccessToken,
                                        this.sendDataNotification.bind(this)
                                    )
                                }
                            }
                        );
                    console.log(response);
                    this.backTo(this.props.mainName);
                    return;
                })
                .catch((err) => {
                    console.log(err.response);
                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                        _refreshToken(
                            this.props.client_id,
                            this.props.LOGIN,
                            this.props.PASSWORD,
                            this.props.changeAccessToken,
                            this.sendDataNotification.bind(this)
                        )
                    }
                });
            // this.props.getInitialState();
            // return;
        } else if (this.props.statusSendDataNotificationOnServer === "edit") {
            await api.getNotification(this.props.LOGIN_TYPE_USER, this.props.state_edit_notifacation_id, this.props.access_token)
                .then(async (res) => {
                    console.log('res.data', res.data);

                    await api.editNotification(this.props.state_edit_notifacation_id, res.data._etag, this.props.LOGIN_TYPE_USER, dataOnServer, this.props.access_token)
                        .then(async (response) => {
                            await api.getNotificationsList(this.props.LOGIN_TYPE_USER, true, this.props.access_token)
                                .then((req) => {
                                    this.props.changeMailingDataActive(req.data);
                                    console.log(req);
                                })
                                .catch((err) => {
                                    console.log(err);
                                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                                        _refreshToken(
                                            this.props.client_id,
                                            this.props.LOGIN,
                                            this.props.PASSWORD,
                                            this.props.changeAccessToken,
                                            this.sendDataNotification.bind(this)
                                        )
                                    }
                                });
                            await api.getNotificationsList(this.props.LOGIN_TYPE_USER, false, this.props.access_token)
                                .then((req) => {
                                    this.props.changeMailingDataNotActive(req.data);
                                    console.log(req);
                                })
                                .catch((err) => {
                                    console.log(err);
                                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                        _refreshToken(
                                            this.props.client_id,
                                            this.props.LOGIN,
                                            this.props.PASSWORD,
                                            this.props.changeAccessToken,
                                            this.sendDataNotification.bind(this)
                                        )
                                    }
                                });
                            console.log(response);
                            this.backTo(this.props.mainName);
                            return;
                        })
                        .catch((err) => {
                            console.log(err);
                            console.log(err.response);
                            if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                _refreshToken(
                                    this.props.client_id,
                                    this.props.LOGIN,
                                    this.props.PASSWORD,
                                    this.props.changeAccessToken,
                                    this.sendDataNotification.bind(this)
                                )
                            }
                        });
                    // this.props.getInitialState();
                    // return;
                })
                .catch((err) => {
                    console.log(err);
                    console.log(err.response);
                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                        _refreshToken(
                            this.props.client_id,
                            this.props.LOGIN,
                            this.props.PASSWORD,
                            this.props.changeAccessToken,
                            this.sendDataNotification.bind(this)
                        )
                    }
                });
        }
    }
    sendDataQuestion() {
        let dataOnServer = {
            title: this.props.state_create_questions_title,
            text: this.props.state_create_notifacation_message,
            datetime_publication: this.getDataTimePublication(),
            answer_options: this.props.state_create_questions_answer_options,
            color: ["#28354d", "#3b4e71"],
            // active_till: this.getDataTimeEndPublication(),
            active: !this.props.state_create_notifacation_status_notifacation
        }
        if (this.getDataTimeEndPublication() !== null) {
            dataOnServer = {
                title: this.props.state_create_questions_title,
                text: this.props.state_create_notifacation_message,
                datetime_publication: this.getDataTimePublication(),
                color: ["#28354d", "#3b4e71"],
                answer_options: this.props.state_create_questions_answer_options,
                active_till: this.getDataTimeEndPublication(),
                active: !this.props.state_create_notifacation_status_notifacation
            }
        }

        console.log('dataOnServer: ', dataOnServer);

        if (this.props.statusSendDataNotificationOnServer === "create") {
            api.createNewQuestions(this.props.LOGIN_TYPE_USER, dataOnServer, this.props.access_token)
                .then((response) => {
                    api.getQuestionsList(this.props.LOGIN_TYPE_USER, true, this.props.access_token)
                        .then((req) => {
                            this.props.changeQuestionsDataActive(req.data);
                            console.log(req);
                        })
                        .catch(
                            err => {
                                console.log(err.response);
                                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                    _refreshToken(
                                        this.props.client_id,
                                        this.props.LOGIN,
                                        this.props.PASSWORD,
                                        this.props.changeAccessToken,
                                        this.sendDataQuestion.bind(this)
                                    )

                                }
                            }
                        );
                    api.getNotificationsList(this.props.LOGIN_TYPE_USER, false, this.props.access_token)
                        .then((req) => {
                            this.props.changeQuestionsDataNotActive(req.data);
                            console.log(req);
                        })
                        .catch(
                            err => {
                                console.log(err.response);
                                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                                    _refreshToken(
                                        this.props.client_id,
                                        this.props.LOGIN,
                                        this.props.PASSWORD,
                                        this.props.changeAccessToken,
                                        this.sendDataQuestion.bind(this)
                                    )
                                }
                            }
                        );
                    console.log(response);
                    this.backTo(this.props.mainName);
                    return;
                })
                .catch((err) => {
                    console.log(err.response);
                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                        _refreshToken(
                            this.props.client_id,
                            this.props.LOGIN,
                            this.props.PASSWORD,
                            this.props.changeAccessToken,
                            this.sendDataQuestion.bind(this)
                        )
                    }
                });
            // this.props.getInitialState();
            // return;
        } else if (this.props.statusSendDataNotificationOnServer === "edit") {
            api.getQuestion(this.props.LOGIN_TYPE_USER, this.props.state_edit_notifacation_id, this.props.access_token)
                .then((res) => {
                    console.log('res.data', res.data);

                    api.editQuestions(this.props.state_edit_notifacation_id, res.data._etag, this.props.LOGIN_TYPE_USER, dataOnServer, this.props.access_token)
                        .then((response) => {
                            api.getQuestionsList(this.props.LOGIN_TYPE_USER, true, this.props.access_token)
                                .then((req) => {
                                    this.props.changeQuestionsDataActive(req.data);
                                    console.log(req);
                                })
                                .catch((err) => {
                                    console.log(err);
                                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                                        _refreshToken(
                                            this.props.client_id,
                                            this.props.LOGIN,
                                            this.props.PASSWORD,
                                            this.props.changeAccessToken,
                                            this.sendDataQuestion.bind(this)
                                        )
                                    }
                                });
                            api.getQuestionsList(this.props.LOGIN_TYPE_USER, false, this.props.access_token)
                                .then((req) => {
                                    this.props.changeQuestionsDataNotActive(req.data);
                                    console.log(req);
                                })
                                .catch((err) => {
                                    console.log(err);
                                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                        _refreshToken(
                                            this.props.client_id,
                                            this.props.LOGIN,
                                            this.props.PASSWORD,
                                            this.props.changeAccessToken,
                                            this.sendDataQuestion.bind(this)
                                        )
                                    }
                                });
                            console.log(response);
                            this.backTo(this.props.mainName);
                            return;
                        })
                        .catch((err) => {
                            console.log(err);
                            console.log(err.response);
                            if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                _refreshToken(
                                    this.props.client_id,
                                    this.props.LOGIN,
                                    this.props.PASSWORD,
                                    this.props.changeAccessToken,
                                    this.sendDataQuestion.bind(this)
                                )
                            }
                        });
                    // this.props.getInitialState();
                    // return;
                })
                .catch((err) => {
                    console.log(err);
                    console.log(err.response);
                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                        _refreshToken(
                            this.props.client_id,
                            this.props.LOGIN,
                            this.props.PASSWORD,
                            this.props.changeAccessToken,
                            this.sendDataQuestion.bind(this)
                        )
                    }
                });
        }
    }
    backTo(value) {
        this.props.changeStateSearchMailing(undefined);
        switch (value) {
            case "notification":

                if (this.props.stateWindowOpenNewMailing === true) {

                    this.props.changeStateStatusCustomAnswers(false);
                    this.props.changeStateWindowOpenNewMailing(!this.props.stateWindowOpenNewMailing);

                    return;
                } else if (this.props.stateWindowOpenMailing === true) {
                    this.props.changeStateWindowOpenMailing(!this.props.stateWindowOpenMailing);
                    return;
                } else {
                    this.props.changeMain("home");
                }
                break;

            default:
                break;
        }
    }

    addTypeNotification() {
        this.props.changeAddTypeNotificationStatus(!this.props.addTypeNotificationStatus);
        this.props.changeStateCreateNotifacationSatusNotification(false);
        this.props.changeStateStatusQuestionsEnd(false);
    }

    addDateNotification() {
        this.props.changeAddTypeNotificationStatus(false);
        this.props.changeStateCreateNotifacationSatusNotification(!this.props.state_create_notifacation_status_notifacation);
        // this.props.changeStateStatusQuestionsEnd(false);
    }

    addDateNotificationEnd() {
        this.props.changeAddTypeNotificationStatus(false);
        // this.props.changeStateCreateNotifacationSatusNotification(false);
        this.props.changeStateStatusQuestionsEnd(!this.props.state_status_questions_end);
    }
    addCustomAnswers() {
        // this.props.changeAddTypeNotificationStatus(false);
        // this.props.changeStateStatusQuestionsEnd(false);
        this.props.changeStateStatusCustomAnswers(!this.props.state_status_custom_answers);

    }

    changeStateOpenNotifacationSatusQuestions(result) {
        this.props.changeStateOpenNotifacationSatusQuestions(result);
        this.props.changeStateWindowOpenMailing(false);
        this.props.changeStateWindowOpenNewMailing(false);


    }
    openGraphic() {

        console.log(this.props.state_create_questions_statistics);

        this.props.changeChartDataGraphicOpen(true);
        this.props.changeChartDataGraphic(this.props.state_create_questions_statistics);
    }

    render() {
        const svg = [
            <svg
                width="20px"
                height="25px"
            >
                <path
                    fillRule="evenodd"
                    fill="rgb(112, 112, 112)"
                    d="M18.461,25.000 L0.537,25.000 C0.240,25.000 -0.000,24.756 -0.000,24.456 L-0.000,2.576 C-0.000,2.275 0.240,2.032 0.537,2.032 L11.016,2.032 C11.312,2.032 11.553,2.275 11.553,2.576 C11.553,2.876 11.312,3.119 11.016,3.119 L1.074,3.119 L1.074,23.912 L17.924,23.912 L17.924,6.437 C17.924,6.137 18.164,5.893 18.461,5.893 C18.757,5.893 18.998,6.137 18.998,6.437 L18.998,24.456 C18.998,24.756 18.757,25.000 18.461,25.000 ZM17.126,4.775 C17.126,4.775 17.125,4.775 17.125,4.776 C17.125,4.776 17.125,4.776 17.125,4.776 L8.608,13.400 C8.540,13.468 8.455,13.517 8.363,13.542 L5.547,14.282 C5.503,14.294 5.457,14.299 5.412,14.299 C5.272,14.299 5.135,14.243 5.033,14.140 C4.898,14.004 4.845,13.806 4.893,13.619 L5.624,10.768 C5.648,10.674 5.696,10.589 5.764,10.520 L14.281,1.896 C14.281,1.896 14.282,1.896 14.282,1.896 C14.282,1.896 14.282,1.896 14.282,1.895 L15.997,0.159 C16.097,0.057 16.234,-0.000 16.376,-0.000 C16.519,-0.000 16.655,0.057 16.756,0.159 L18.840,2.270 C19.050,2.482 19.050,2.827 18.840,3.039 L17.126,4.775 ZM6.627,11.185 L6.162,12.997 L7.952,12.526 L15.986,4.391 L14.661,3.049 L6.627,11.185 ZM16.376,1.313 L15.421,2.280 L16.746,3.622 L17.701,2.654 L16.376,1.313 Z"
                />
            </svg>,
            <svg
                width="9px"
                height="16px"
            >
                <path
                    fillRule="evenodd"
                    fill="rgb(255, 255, 255)"
                    d="M7.986,0.155 C8.102,0.051 8.247,-0.000 8.407,-0.000 C8.566,-0.000 8.711,0.051 8.827,0.155 C9.058,0.362 9.058,0.698 8.827,0.905 L1.438,7.501 L8.827,14.096 C9.058,14.303 9.058,14.640 8.827,14.846 C8.595,15.053 8.218,15.053 7.986,14.846 L0.178,7.876 C-0.054,7.669 -0.054,7.333 0.178,7.126 L7.986,0.155 Z"
                />
            </svg>,
            <svg
                width="25px"
                height="25px"
            >
                <path
                    fillRule="evenodd"
                    fill="rgb(255, 255, 255)"
                    d="M21.337,21.337 C18.926,23.747 15.723,25.000 12.488,25.000 C10.474,25.000 8.444,24.514 6.589,23.515 C4.923,24.715 3.295,24.974 2.190,24.974 C1.778,24.974 1.440,24.937 1.202,24.900 C0.695,24.820 0.303,24.424 0.224,23.916 C0.145,23.409 0.404,22.918 0.864,22.690 C1.820,22.220 2.465,21.279 2.867,20.470 C-1.230,15.539 -0.912,8.229 3.655,3.663 C6.018,1.300 9.152,-0.000 12.493,-0.000 C15.834,-0.000 18.969,1.300 21.331,3.663 C23.694,6.025 25.000,9.160 25.000,12.500 C25.000,15.840 23.700,18.975 21.337,21.337 ZM20.316,4.672 C16.003,0.354 8.978,0.354 4.659,4.672 C0.525,8.805 0.325,15.465 4.194,19.836 C4.294,19.952 4.352,20.095 4.368,20.238 C4.405,20.386 4.395,20.544 4.331,20.698 C3.935,21.601 3.258,22.764 2.185,23.546 L2.190,23.546 C3.121,23.546 4.527,23.314 5.959,22.204 C5.975,22.188 5.996,22.172 6.018,22.162 C6.245,21.934 6.599,21.882 6.885,22.051 C11.230,24.604 16.754,23.895 20.322,20.328 C24.641,16.009 24.641,8.990 20.316,4.672 ZM17.018,13.377 C16.533,13.377 16.140,12.985 16.140,12.500 C16.140,12.015 16.533,11.623 17.018,11.623 C17.503,11.623 17.895,12.015 17.895,12.500 C17.895,12.985 17.503,13.377 17.018,13.377 ZM12.493,13.377 C12.008,13.377 11.616,12.985 11.616,12.500 C11.616,12.015 12.008,11.623 12.493,11.623 C12.978,11.623 13.371,12.015 13.371,12.500 C13.371,12.985 12.978,13.377 12.493,13.377 ZM7.968,13.377 C7.484,13.377 7.091,12.985 7.091,12.500 C7.091,12.015 7.484,11.623 7.968,11.623 C8.453,11.623 8.846,12.015 8.846,12.500 C8.846,12.985 8.453,13.377 7.968,13.377 Z"
                />
            </svg>,
            <svg
                width="24px"
                height="20px"
            >
                <path
                    fillRule="evenodd"
                    fill="rgb(112, 112, 112)"
                    d="M0.002,10.003 C0.031,10.481 0.360,10.884 0.835,11.021 L9.635,13.592 L9.958,19.455 C9.973,19.687 10.135,19.891 10.365,19.967 C10.429,19.990 10.497,20.000 10.561,20.000 C10.732,20.000 10.894,19.934 11.012,19.806 L14.123,16.477 L18.533,19.427 C18.729,19.560 18.959,19.631 19.199,19.631 C19.753,19.631 20.233,19.252 20.351,18.721 L24.011,0.692 C24.055,0.484 23.971,0.266 23.805,0.133 C23.633,-0.004 23.403,-0.037 23.197,0.043 L0.707,8.890 C0.252,9.089 -0.028,9.524 0.002,10.003 ZM11.075,18.001 L10.874,14.303 L13.128,15.809 L11.075,18.001 ZM19.179,18.451 L11.154,13.081 L22.408,2.558 L19.179,18.451 ZM21.174,2.094 L10.046,12.498 L1.256,9.932 L21.174,2.094 Z"
                />
            </svg>,
            <svg
                width="14.5px"
                height="14.5px"
            // id="boxcheck"
            >
                <path
                    fillRule="evenodd"
                    stroke="rgb(184, 184, 184)"
                    strokeWidth="1px"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    opacity="0.2"
                    fill="none"
                    d="M0.500,0.500 L13.500,0.500 L13.500,13.500 L0.500,13.500 L0.500,0.500 Z"
                />
            </svg>,
            <svg
                width="7px"
                height="6px"
                id="check"
            >
                <path
                    fillRule="evenodd"
                    fill="#1098D8"
                    d="M2.227,4.746 L0.557,2.866 L-0.000,3.493 L2.227,6.000 L7.000,0.627 L6.443,0.000 L2.227,4.746 Z"
                />
            </svg>
        ]
        if (!this.props.isLoadMailingMain) {
            return (
                <PreLoader onLoad={this.getgetNotificationsListFromBase.bind(this)} />
            )
        }
        return (
            <div className="mailing container_main backcolor">
                <div className="container_header header_h">
                    <div className="svg_box">
                        {svg[3]}
                    </div>
                    <div className="svg_box margin-left hover_svg" onClick={() => this.backTo(this.props.mainName)}>
                        {svg[1]}
                    </div>
                </div>

                <ul className="accounts_header height_inherit flex flex-end">
                    <li
                        className={this.props.state_open_notifacation_status_questions === false ? "mailing-menu-header-li mailing-menu-header-li-active" : "mailing-menu-header-li"}
                        onClick={() => this.changeStateOpenNotifacationSatusQuestions(false)}
                    >
                        <p className="padding-left10 color-not-active">
                            Уведомления
                            </p>
                        <div className="svg_box">
                            <p className="right_column_header_li_num">{this.props.mailingDataActive !== undefined && this.props.mailingDataNotActive !== undefined ? this.props.mailingDataActive._items.length + this.props.mailingDataNotActive._items.length : ""}</p>
                        </div>

                    </li>
                    <li
                        className={this.props.state_open_notifacation_status_questions === true ? "mailing-menu-header-li mailing-menu-header-li-active" : "mailing-menu-header-li"}
                        onClick={() => this.changeStateOpenNotifacationSatusQuestions(true)}
                    >
                        <p className="padding-left10 color-not-active">
                            Голосования
                            </p>
                        <div className="svg_box">
                            <p className="right_column_header_li_num">{this.props.questionsDataNotActive !== undefined && this.props.questionsDataActive !== undefined ? this.props.questionsDataActive._items.length + this.props.questionsDataNotActive._items.length : ""}</p>
                        </div>

                    </li>
                </ul>

                {this.props.stateWindowOpenNewMailing === false && this.props.stateWindowOpenMailing === false ?
                    <div className="flex flex1 h100">
                        <div className="col-lg60 h100 padding-right15 padding-top0 flex flex1">
                            <MailingMain />
                        </div>
                        <div className="col-lg40 padding-left15 flex flex_flow_column padding-top0">
                            <MailingMenu />
                            <div className="h15 flex align-flex-end">
                                <div className="accounts_button flex center w100" onClick={() => this.createNewNotification()}>
                                    <span className="flex center">
                                        <svg width="20px"
                                            height="20px">
                                            <path
                                                fillRule="evenodd"
                                                fill="rgb(255, 255, 255)"
                                                d="M10.000,20.000 C4.486,20.000 -0.000,15.514 -0.000,10.000 C-0.000,4.486 4.486,-0.000 10.000,-0.000 C15.514,-0.000 20.000,4.486 20.000,10.000 C20.000,15.514 15.514,20.000 10.000,20.000 ZM10.000,0.769 C4.910,0.769 0.769,4.910 0.769,10.000 C0.769,15.090 4.910,19.231 10.000,19.231 C15.090,19.231 19.231,15.090 19.231,10.000 C19.231,4.910 15.090,0.769 10.000,0.769 ZM14.808,10.385 L10.385,10.385 L10.385,15.000 C10.385,15.213 10.212,15.385 10.000,15.385 C9.788,15.385 9.615,15.213 9.615,15.000 L9.615,10.385 L5.192,10.385 C4.980,10.385 4.808,10.213 4.808,10.000 C4.808,9.787 4.980,9.615 5.192,9.615 L9.615,9.615 L9.615,5.385 C9.615,5.172 9.788,5.000 10.000,5.000 C10.212,5.000 10.385,5.172 10.385,5.385 L10.385,9.615 L14.808,9.615 C15.020,9.615 15.192,9.787 15.192,10.000 C15.192,10.213 15.020,10.385 14.808,10.385 Z"
                                            >
                                            </path>
                                        </svg>
                                        {this.props.state_open_notifacation_status_questions === true ?
                                            <a className="accounts_button_a">Добавить голосование</a>
                                            :
                                            <a className="accounts_button_a">Добавить уведомление</a>
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    : this.props.stateWindowOpenNewMailing === true ?
                        <div className="flex h90">
                            <div className="w50 padding-right15 padding25 overflow-auto">

                                <div>
                                    <p className="label">Название</p>
                                    <div>
                                        <input className="input_user_edit w100" type="text" value={this.props.state_create_questions_title} onChange={(e) => {
                                            this.props.changeStateCreateQuestionsTitle(e.target.value);
                                        }
                                        } />

                                    </div>


                                </div>
                                {this.props.state_open_notifacation_status_questions === false ?
                                    <div className="margin-top20">
                                        <p className="label">Тип уведомления</p>
                                        {
                                            this.props.notificationCategoriesList !== undefined ?
                                                <nav className="select_nav">
                                                    <ul className="user_type_ul">
                                                        <li className={this.props.stateDangerInputCreateNotificationTypeNotification === false ? "user_type_li select_li z-index-3" : "user_type_li select_li z-index-3 danger_input"}><a className="user_type_a target text_white">{this.props.state_create_notifacation_types === "" || this.props.state_create_notifacation_types === undefined ? "Тип заявки" : this.props.state_create_notifacation_types}</a>
                                                            <ul>
                                                                {this.props.notificationCategoriesList !== undefined && this.props.notificationCategoriesList !== "" && this.props.notificationCategoriesList !== null ? this.props.notificationCategoriesList._items.map(typeNotifications => <li className="user_type_li select_li min-width250" key={typeNotifications._id} ><a className="user_type_a_bottom" onClick={() => {
                                                                    this.props.changeStateCreateNotifacationTypes(typeNotifications.title);
                                                                    this.props.changeStateDangerInputCreateNotificationTypeNotification(false);
                                                                }} >{typeNotifications.title}</a></li>) : null}
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </nav>
                                                :
                                                null
                                        }
                                    </div>
                                    : null
                                }

                                <div className="flex align-flex-end margin-top10">
                                    <div className="svg-box relative align-flex-end" onClick={() => this.addDateNotification()}>
                                        {svg[4]}
                                        {this.props.state_create_notifacation_status_notifacation === true ? svg[5] : null}
                                    </div>
                                    <p className="text_white flex center padding-left10">установить время публикации</p>
                                </div>
                                {this.props.state_create_notifacation_status_notifacation === true ?
                                    <div className="margin-top20">
                                        <p className="label">Установить время</p>
                                        <div className="flex">
                                            <input className={this.props.stateDangerInputCreateNotificationDate === false ? "input-date" : "input-date danger_input"} type="date" value={this.props.state_create_notifacation_date} onChange={(e) => {
                                                this.props.changeStateCreateNotifacationDate(e.target.value);
                                                this.props.changeStateDangerInputCreateNotificationDate(false);
                                            }
                                            } />
                                            <p className="text_white padding-left10 padding-right10 flex center">в</p>
                                            <input
                                                className={this.props.stateDangerInputCreateNotificationTime === false ? "input-time" : "input-time danger_input"}
                                                type="time"
                                                name="start-time"
                                                value={this.props.state_create_notifacation_time}
                                                onChange={(e) => {
                                                    this.props.changeStateCreateNotifacationTime(e.target.value);
                                                    this.props.changeStateDangerInputCreateNotificationTime(false);
                                                }
                                                } />
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                {this.props.state_open_notifacation_status_questions === true ?
                                    <div>
                                        <div>
                                            <div className="flex align-flex-end margin-top10">
                                                <div className="svg-box relative align-flex-end" onClick={() => this.addDateNotificationEnd()}>
                                                    {svg[4]}
                                                    {this.props.state_status_questions_end === true ? svg[5] : null}
                                                </div>
                                                <p className="text_white flex center padding-left10">установить время окончания</p>
                                            </div>

                                            {this.props.state_status_questions_end === true ?
                                                <div className="margin-top20">
                                                    <p className="label">Окончание</p>
                                                    <div className="flex">
                                                        <input className="input-date" type="date" value={this.props.state_create_notifacation_date_end} onChange={(e) => {
                                                            this.props.changeStateCreateNotifacationDateEnd(e.target.value);
                                                        }
                                                        } />
                                                        <p className="text_white padding-left10 padding-right10 flex center">в</p>
                                                        <input
                                                            className="input-time"
                                                            type="time"
                                                            name="end-time"
                                                            value={this.props.state_create_notifacation_time_end}
                                                            onChange={(e) => {
                                                                this.props.changeStateCreateNotifacationTimeEnd(e.target.value);
                                                            }
                                                            } />
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                        <div>
                                            <div className="flex align-flex-end margin-top10">
                                                <div className="svg-box relative align-flex-end" onClick={() => this.addCustomAnswers()}>
                                                    {svg[4]}
                                                    {this.props.state_status_custom_answers === true ? svg[5] : null}
                                                </div>
                                                <p className="text_white flex center padding-left10">указать варианты ответов</p>
                                            </div>
                                            {this.props.state_status_custom_answers === true ?
                                                <div className="margin-top20">
                                                    <p className="label">Варианты</p>
                                                    <div className="flex">
                                                        <MailingAddAnswers answerArray={this.props.state_create_questions_answer_options} />
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                    :
                                    null
                                }

                                {
                                    this.props.LOGIN_TYPE_USER === "admin" && this.props.state_open_notifacation_status_questions === false
                                        ?
                                        <div className="w100 margin-top20 flex">
                                            <a className="w100 button_accept flex center text_white margin-top20" onClick={() => this.addTypeNotification()}>{this.props.addTypeNotificationStatus === false ? "Добавить тип уведомления" : "Скрыть"}</a>
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.props.addTypeNotificationStatus === true && this.props.LOGIN_TYPE_USER === "admin" && this.props.state_open_notifacation_status_questions === false
                                        ?
                                        <MailingAddTypeNotification />
                                        :
                                        null
                                }

                                <div className="w100 margin-top20 flex">
                                    <div className="padding-right15 w30">
                                        <a className="w100 button_cancel flex center text_white" onClick={() => this.backTo(this.props.mainName)}>отмена</a>
                                    </div>
                                    <div className="w70">
                                        <a className="w100 button_accept flex center text_white" onClick={() => this.sendDataNotificationOnServer()}>{this.props.statusSendDataNotificationOnServer === "create" ? "отправить уведомление" : "изменить уведомление"}</a>
                                    </div>
                                </div>
                            </div>
                            <div className="w50 padding-right15 padding25">
                                <p className="label">Текст уведомления</p>
                                <textarea maxLength="600" className={this.props.stateDangerInputCreateNotificationMessage === false ? "w100 mailing-texarea text_white" : "w100 mailing-texarea text_white danger_input"} value={this.props.state_create_notifacation_message} onChange={(e) => {
                                    this.props.changeStateCreateNotifacationMessage(e.target.value);
                                    this.props.changeStateDangerInputCreateNotificationMessage(false);
                                }} />

                            </div>

                        </div>
                        : <div className="flex h90">
                            <div className="w50 padding-right15 padding25">

                                <div>
                                    <p className="label">Название</p>


                                    <p className="text_white max-w-250px user-select-text margin-top10">{this.props.state_create_questions_title === "" || this.props.state_create_questions_title === undefined ? "Название" : this.props.state_create_questions_title}</p>

                                </div>
                                {this.props.state_open_notifacation_status_questions === false ?

                                    <div className="margin-top20">
                                        <p className="label">Тип уведомления</p>

                                        <p className="text_white max-w-250px user-select-text margin-top10">{this.props.state_create_notifacation_types === "" || this.props.state_create_notifacation_types === undefined ? "Тип заявки" : this.props.state_create_notifacation_types}</p>

                                    </div>
                                    : null
                                }



                                <div className="margin-top20">
                                    <p className="label">Дата и Время публикации</p>
                                    <div className="flex">
                                        <p className="text_white max-w-250px user-select-text margin-top10 padding-right10">{this.props.state_create_notifacation_date}</p>
                                        <p className="text_white margin-top10 padding-right10">в</p>
                                        <p className="text_white max-w-250px user-select-text margin-top10 padding-right10">{this.props.state_create_notifacation_time}</p>

                                    </div>
                                </div>
                                {this.props.state_open_notifacation_status_questions === true ?

                                    <div>
                                        <div className="margin-top20">
                                            <p className="label">Дата и Время окончания</p>
                                            <div className="flex">
                                                <p className="text_white max-w-250px user-select-text margin-top10 padding-right10">{this.props.state_create_notifacation_date_end === "" ? "Бессрочно" : this.props.state_create_notifacation_date_end}</p>
                                                <p className="text_white margin-top10 padding-right10">{this.props.state_create_notifacation_time_end === "" ? "" : "в"}</p>
                                                <p className="text_white max-w-250px user-select-text margin-top10 padding-right10">{this.props.state_create_notifacation_time_end === "" ? "" : this.props.state_create_notifacation_time_end}</p>

                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                {this.props.state_open_notifacation_status_questions === true && this.props.state_create_questions_statistics !== undefined ?
                                    <div className="margin-top20">

                                        <p className="label">Варианты ответов и кол-во голосов</p>

                                        {this.props.state_create_questions_statistics.map(answer =>

                                            <div key={this.props.state_edit_notifacation_id + answer.title}>

                                                <p className="text_white margin-top10 padding-right10 answer-elem">{answer.title}</p>
                                                <p className="text_white margin-top10 padding-right10 answer-elem"> - </p>
                                                <p className="text_white margin-top10 padding-right10 answer-elem">{answer.quantity} голосов</p>
                                            </div>
                                        )}
                                    </div>
                                    :
                                    null
                                }

                                <div className="w100 margin-top20 flex">
                                    <div className="padding-right15 w30">
                                        <a className="w100 button_cancel flex center text_white" onClick={() => this.backTo(this.props.mainName)}>Назад</a>
                                    </div>

                                </div>
                            </div>
                            <div className="w50 padding-right15 padding25 flex flex-space-between column">
                                <div>
                                    <p className="label">Текст уведомления</p>
                                    <p className="text_white max-w-250px user-select-text margin-top10">{this.props.state_create_notifacation_message}</p>
                                </div>
                                {this.props.state_open_notifacation_status_questions === true && this.props.state_create_questions_statistics !== undefined ?
                                    <div className="flex flex-end">
                                        <div className="w70">
                                            <a className="w100 button_accept flex center text_white" onClick={() => this.openGraphic()}>Посмотреть график</a>
                                        </div>
                                    </div>
                                    :
                                    null}
                            </div>

                        </div>}
            </div>

        );
    };

    getgetNotificationsListFromBase = async () => {
        await api.getNotificationsTypes(this.props.LOGIN_TYPE_USER, this.props.access_token)
            .then(async (response) => {
                console.log("response: ", response);
                this.props.changeNotificationCategoriesList(response.data);
                await api.getNotificationsList(this.props.LOGIN_TYPE_USER, true, this.props.access_token, this.state.where, this.state.search)
                    .then(async (response) => {
                        console.log("response: ", response);
                        this.props.changeMailingDataActive(response.data);
                        // this.setState({
                        //     isLoad: true
                        // });
                        await api.getNotificationsList(this.props.LOGIN_TYPE_USER, false, this.props.access_token, this.state.where, this.state.search)
                            .then(async (response) => {
                                console.log("response: ", response);
                                this.props.changeMailingDataNotActive(response.data);
                                // this.setState({
                                //     isLoad: true
                                // });
                                await api.getQuestionsList(this.props.LOGIN_TYPE_USER, true, this.props.access_token, this.state.where, this.state.search)
                                    .then(async (response) => {
                                        console.log("response: ", response);
                                        this.props.changeQuestionsDataActive(response.data);

                                        await api.getQuestionsList(this.props.LOGIN_TYPE_USER, false, this.props.access_token, this.state.where, this.state.search)
                                            .then((response) => {
                                                console.log("response: ", response);
                                                this.props.changeQuestionsDataNotActive(response.data);
                                                // this.setState({ isLoad: true })
                                                this.props.changeIsLoadMailingMain(true); //

                                            })
                                            .catch((err) => {
                                                console.log(err);
                                                console.log(err.response);
                                                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                                                    _refreshToken(
                                                        this.props.client_id,
                                                        this.props.LOGIN,
                                                        this.props.PASSWORD,
                                                        this.props.changeAccessToken,
                                                        this.getgetNotificationsListFromBase.bind(this)
                                                    )
                                                }
                                            });

                                        //
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                        console.log(err.response);
                                        if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                            _refreshToken(
                                                this.props.client_id,
                                                this.props.LOGIN,
                                                this.props.PASSWORD,
                                                this.props.changeAccessToken,
                                                this.getgetNotificationsListFromBase.bind(this)
                                            )

                                        }
                                    });
                            })
                            .catch((err) => {
                                console.log(err);
                                console.log(err.response);
                                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                                    _refreshToken(
                                        this.props.client_id,
                                        this.props.LOGIN,
                                        this.props.PASSWORD,
                                        this.props.changeAccessToken,
                                        this.getgetNotificationsListFromBase.bind(this)
                                    )
                                }
                            });
                    })
                    .catch((err) => {
                        console.log(err);
                        console.log(err.response);
                        if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                            _refreshToken(
                                this.props.client_id,
                                this.props.LOGIN,
                                this.props.PASSWORD,
                                this.props.changeAccessToken,
                                this.getgetNotificationsListFromBase.bind(this)
                            )

                        }
                    });
            })
            .catch((err) => {
                console.log(err);
                console.log(err.response);
                if(err.response !== undefined) {
                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                        _refreshToken(
                            this.props.client_id,
                            this.props.LOGIN,
                            this.props.PASSWORD, -
                            this.props.changeAccessToken,
                            this.getgetNotificationsListFromBase.bind(this)
                        )
                    }
                }
                
            });








    }
};


export default connect(dispatch.mapStateToProps, dispatch.mapDispatchToProps)(Mailing);