import React, { Component } from 'react';

import { connect } from 'react-redux';
import { _refreshToken } from '../../Refresh/Refresh';

import Accounts_item from '../Accounts_item/Accounts_item.js';

import api from '../../../../api';

import dispath from '../../../../store/dispatch.js';
import PreLoader from '../../PreLoader/PreLoader.js';
import './Accounts_main.css';

class Accounts_main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoad: false,
            where: undefined,
            search: false
        };
        this.props.changeStateAccountPage(1);
    }

    getAccountsApi = async () => {
        await api.getApartments(this.props.access_token, this.props.LOGIN_TYPE_USER)
            .then(
                (req) => {
                    // console.log('App_Main getApartmentsApi');
                    // console.log('req.data', req.data);

                    if (req.data._items !== undefined, req.data._items !== 0, req.data._items !== null, req.data._items !== "") {
                        this.props.changeApartmentsData(req.data);
                        api.getAccounts(this.props.access_token, this.props.LOGIN_TYPE_USER, this.props.mainName, 1, this.state.where, this.state.search)
                            .then(
                                (response) => {
                                    // console.log(response);
                                    // this.props.changeAccountData(response.data._items);
                                    let items = response.data._items;
                                    let apartmentsInfo = req.data._items;
                                    
                                    if (items.length === 0) {
                                        this.props.changeAccountData([]);
                                        this.setState({
                                            isLoad: true
                                        });
                                        return
                                    }
                                    items.forEach((item, i) => {

                                        apartmentsInfo.forEach((apartment) => {
                                            // console.log(apartment);
                                            if (item.apartment_id === apartment._id) {
                                                item.apartment_num = apartment.aptnumber;
                                                item.apartment_size = apartment.area;

                                            }
                                        });
                                        if (i === items.length - 1) {
                                            // console.log('items', items);

                                            this.props.changeAccountData(items);
                                            this.setState({
                                                isLoad: true
                                            });

                                        }
                                    });
                                })
                            .catch((err) => {
                                console.log(err);
                                console.log(err.response);

                                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                    _refreshToken(
                                        this.props.client_id,
                                        this.props.LOGIN,
                                        this.props.PASSWORD,
                                        this.props.changeAccessToken,
                                        this.getAccountsApi.bind(this)
                                    )
                                }
                            });
                    } else {
                        return;
                    }

                }
            )
            .catch(
                (err) => {
                    console.log('App_Main getApartmentsApi ERR');
                    console.log(err);
                    console.log(err.response);
                    
                        if (err.response.status === 401) {
                            _refreshToken(
                                this.props.client_id,
                                this.props.LOGIN,
                                this.props.PASSWORD,
                                this.props.changeAccessToken,
                                this.getAccountsApi.bind(this)
                            )
    
                        }
                    
                    
                }
            )


    }



    onScrollList(event) {
        const scrollBottom = event.target.scrollTop +
            event.target.offsetHeight == event.target.scrollHeight;

        if (scrollBottom) {
            this.loadContent(); //API method
        }
    }

    loadContent = () => {
        api.getAccounts(this.props.access_token, this.props.LOGIN_TYPE_USER, this.props.mainName, 1)
            .then(
                (response) => {
                    if (this.props.state_account_page === Math.ceil(response.data._meta.total / 25)) {
                        return;
                    } else if (response.data._meta.total > 25 && this.props.state_account_page !== Math.ceil(response.data._meta.total / 25)) {

                        this.props.changeStateAccountPage(this.props.state_account_page + 1);

                        api.getAccounts(this.props.access_token, this.props.LOGIN_TYPE_USER, this.props.mainName, this.props.state_account_page)
                            .then(
                                (res) => {
                                    let items = res.data._items;
                                    let apartmentsInfo = this.props.apartmentsData._items;

                                    items.forEach((item, i) => {
                                        apartmentsInfo.forEach((apartment) => {

                                            if (item.apartment_id === apartment._id) {
                                                item.apartment_num = apartment.aptnumber;
                                                item.apartment_size = apartment.area;
                                                // console.log(item);
                                            }
                                        });
                                        if (i === items.length - 1) {
                                            this.props.changeAccountData(this.props.accountData.concat(items));

                                        }
                                    });

                                }
                            )
                            .catch((err) => {
                                console.log(err);
                                console.log(err.response);

                                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                    _refreshToken(
                                        this.props.client_id,
                                        this.props.LOGIN,
                                        this.props.PASSWORD,
                                        this.props.changeAccessToken,
                                        this.loadContent.bind(this)
                                    )

                                }
                            });
                    }

                })
            .catch((err) => {
                console.log(err);
                console.log(err.response);

                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                    _refreshToken(
                        this.props.client_id,
                        this.props.LOGIN,
                        this.props.PASSWORD,
                        this.props.changeAccessToken,
                        this.loadContent.bind(this)
                    )
                }
            });

    }
    changeStateSearchAccounts = async (text) => {

        await this.setState({
            where: text,
            search: true
        })
        // console.log(this.state.where);
        this.props.changeStateSearchAccounts(text);
        this.getAccountsApi();
    }

    render() {
        if (!this.state.isLoad) {
            return (
                <PreLoader onLoad={this.getAccountsApi.bind(this)} />
            )
        }
        return (

            <div className="accounts_main">
                <div className="accounts_main_search_container">
                    <input
                        className="input_user_edit w50"
                        value={this.props.state_search_accounts === undefined ? "" : this.props.state_search_accounts}
                        type="text"
                        placeholder="Поиск по жильцам"
                        onChange={(event) => {
                            this.changeStateSearchAccounts(event.target.value);
                        }}

                    />
                </div>
                <div className="accounts_main_titles">
                    <ul className="accounts_main_titles_ul flex label">
                        <li className="accounts_main_titles_li w100">Имя Фамилия</li>
                        <li className="accounts_main_titles_li w100">Апартамент</li>
                        <li className="accounts_main_titles_li w100">Телефон</li>
                        <li className="accounts_main_titles_li w50"></li>
                    </ul>
                </div>
                <div
                    className="scroll h90"
                    onScroll={event => this.onScrollList(event)}
                >
                    {
                        this.props.accountData !== undefined
                            ?
                            this.props.accountData.map(
                                data_items => <Accounts_item key={data_items.apartment_id + data_items._id} data_items={data_items} />
                            )
                            :
                            null
                    }
                </div>
            </div>

        );
    };
};

export default connect(dispath.mapStateToProps, dispath.mapDispatchToProps)(Accounts_main);