import React, { Component } from 'react';

import { connect } from 'react-redux';
import { _refreshToken } from '../../Refresh/Refresh';
import MailingMainMessage from './MailingMainMessage/MailingMainMessage.js';

import api from '../../../../api';

import dispath from '../../../../store/dispatch.js';
// import PreLoader from '../../PreLoader/PreLoader.js';

import './MailingMain.css';
import { async } from 'q';

class MailingMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _onLoadState: false,
            isLoad: false,
            where: undefined,
            search: false
        };


        this.getgetNotificationsListFromBase()
    }

    getgetNotificationsListFromBase = async () => {
        await api.getNotificationsTypes(this.props.LOGIN_TYPE_USER, this.props.access_token)
            .then(async (response) => {
                // console.log("response: ", response);
                this.props.changeNotificationCategoriesList(response.data);
                await api.getNotificationsList(this.props.LOGIN_TYPE_USER, true, this.props.access_token, this.state.where, this.state.search)
                    .then(async (response) => {
                        // console.log("response: ", response);
                        this.props.changeMailingDataActive(response.data);
                        // this.setState({
                        //     isLoad: true
                        // });
                        await api.getNotificationsList(this.props.LOGIN_TYPE_USER, false, this.props.access_token, this.state.where, this.state.search)
                            .then(async (response) => {
                                // console.log("response: ", response);
                                this.props.changeMailingDataNotActive(response.data);
                                // this.setState({
                                //     isLoad: true
                                // });
                                await api.getQuestionsList(this.props.LOGIN_TYPE_USER, true, this.props.access_token, this.state.where, this.state.search)
                                    .then(async (response) => {
                                        // console.log("response: ", response);
                                        this.props.changeQuestionsDataActive(response.data);

                                        await api.getQuestionsList(this.props.LOGIN_TYPE_USER, false, this.props.access_token, this.state.where, this.state.search)
                                            .then((response) => {
                                                // console.log("response: ", response);
                                                this.props.changeQuestionsDataNotActive(response.data);
                                                // this.setState({ isLoad: true })
                                                this.props.changeIsLoadMailingMain(true);

                                            })
                                            .catch((err) => {
                                                console.log(err);
                                                console.log(err.response);
                                                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                                                    _refreshToken(
                                                        this.props.client_id,
                                                        this.props.LOGIN,
                                                        this.props.PASSWORD,
                                                        this.props.changeAccessToken,
                                                        this.getgetNotificationsListFromBase.bind(this)
                                                    )
                                                }
                                            });

                                        //
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                        console.log(err.response);
                                        if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                            _refreshToken(
                                                this.props.client_id,
                                                this.props.LOGIN,
                                                this.props.PASSWORD,
                                                this.props.changeAccessToken,
                                                this.getgetNotificationsListFromBase.bind(this)
                                            )

                                        }
                                    });
                            })
                            .catch((err) => {
                                console.log(err);
                                console.log(err.response);
                                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                                    _refreshToken(
                                        this.props.client_id,
                                        this.props.LOGIN,
                                        this.props.PASSWORD,
                                        this.props.changeAccessToken,
                                        this.getgetNotificationsListFromBase.bind(this)
                                    )
                                }
                            });
                    })
                    .catch((err) => {
                        console.log(err);
                        console.log(err.response);
                        if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                            _refreshToken(
                                this.props.client_id,
                                this.props.LOGIN,
                                this.props.PASSWORD,
                                this.props.changeAccessToken,
                                this.getgetNotificationsListFromBase.bind(this)
                            )

                        }
                    });
            })
            .catch((err) => {
                console.log(err);
                console.log(err.response);
                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                    _refreshToken(
                        this.props.client_id,
                        this.props.LOGIN,
                        this.props.PASSWORD, -
                        this.props.changeAccessToken,
                        this.getgetNotificationsListFromBase.bind(this)
                    )
                }
            });








    }

    componentDidUpdate() {

        if (this.props.state_open_notifacation_status_questions === true) {
            if (this.props.state_open_notifacation_status_notifacation === true) {
                this.props.changeMailingData(this.props.questionsDataActive);
                return;
            } else {
                this.props.changeMailingData(this.props.questionsDataNotActive);
                return;
            }
        } else {
            if (this.props.state_open_notifacation_status_notifacation === true) {
                this.props.changeMailingData(this.props.mailingDataActive);
                return;
            } else if (this.props.state_open_notifacation_status_notifacation === false) {
                this.props.changeMailingData(this.props.mailingDataNotActive);
                return;
            }
        }

    }
    changeStateSearchMailing = async (text) => {

        await this.setState({
            where: text,
            search: true
        })
        // console.log(this.state.where);
        this.props.changeStateSearchMailing(text);
        this.getgetNotificationsListFromBase();
    }
    render() {
        // if (!this.state.isLoad) {
        //     return (
        //         <PreLoader onLoad={this.getgetNotificationsListFromBase.bind(this)} _onLoadState={this.state._onLoadState} />
        //     )
        // } else if (this.state.isLoad) {
            return (
                <div className="mailing-main">
                    <div className="mailing_main_search_container">
                        <input
                            className="input_user_edit w100"
                            value={this.props.state_search_mailing === undefined ? "" : this.props.state_search_mailing}
                            type="text"
                            placeholder={this.props.state_open_notifacation_status_questions === true ? "Поиск по голосованиям" : "Поиск по уведомлениям"}
                            onChange={(event) => {
                                this.changeStateSearchMailing(event.target.value);
                            }}

                        />
                    </div>
                    <div className="scroll-clock">
                        {
                            this.props.mailingData !== undefined && this.props.mailingData !== null && this.props.mailingData !== "" ? this.props.mailingData._items.map(dataMailingMainMessage => <MailingMainMessage dataMailingMainMessage={dataMailingMainMessage} key={dataMailingMainMessage._id} />).reverse() : null
                        }
                    </div>
                </div>
            );
        }

    };
// };

export default connect(dispath.mapStateToProps, dispath.mapDispatchToProps)(MailingMain);