import React, { Component } from 'react';
import axios from 'axios';

import * as configs from '../../../../etc/config.json';

import { _refreshToken } from '../../Refresh/Refresh';

import ApartInfo from './ApartInfo';
import Room from './Room';
import ConfigureButtons from './ConfigureButtons';

import { initNotGlobalRoomState, initGlobalRoomState, findField } from './utils';
import { validationFields } from './validation';

import './styles.css';

let rootUrl = `${configs.default.hostApiServer}:${configs.default.portApiServer}`;

class ApartConfigure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deletedItems: [],

      apart: {
        aptnumber: '',
        floor_id: ''
      },
      rooms: [
        { ...JSON.parse(JSON.stringify(initGlobalRoomState)) },
      ]
    };
  }

  componentDidMount() {
    window.onbeforeunload
    if (this.props.initialState === null) return;

    if (typeof this.props.initialState === 'object') {
      const loadedState = JSON.parse(JSON.stringify(this.props.initialState));
      let haveGlobalEndpoint = false;

      loadedState.rooms.forEach((item, i) => {

        if (item.devicesEndpoints === undefined || Object.keys(item.devicesEndpoints).length === 0 &&
          item.devicesEndpoints.constructor === Object) {
            item.devicesEndpoints = { ...JSON.parse(JSON.stringify(initNotGlobalRoomState.devicesEndpoints)) };
        } else {
          if (item.devicesEndpoints.global_endpoint === true && i !== 0 && !haveGlobalEndpoint) {
            loadedState.rooms.splice(0, 0, loadedState.rooms.splice(i, 1)[0]);
            haveGlobalEndpoint = true;
          } else if (item.devicesEndpoints.global_endpoint === true && i === 0) {
            haveGlobalEndpoint = true;
          }
        }

        if (item.devicesEndpoints.global_endpoint === false) {
          Object.keys(initNotGlobalRoomState.devicesEndpoints).forEach(key => {
            item.devicesEndpoints[key] === undefined ? item.devicesEndpoints[key] = initNotGlobalRoomState.devicesEndpoints[key] : '';
          })
        } else {
          Object.keys(initGlobalRoomState.devicesEndpoints).forEach(key => {
            item.devicesEndpoints[key] === undefined ? item.devicesEndpoints[key] = initGlobalRoomState.devicesEndpoints[key] : '';
          })
        }
      });

      if (!haveGlobalEndpoint) {
        loadedState.rooms.unshift(JSON.parse(JSON.stringify(initGlobalRoomState)));
      }

      this.setState(loadedState);

      // alert before reload or turn back, comment this for turn on live reloading!!!!!!
      window.onbeforeunload = function(e) {
        const message = "Вы уверены, что данные сохранены?";

        e.returnValue = message;

        return message;
      };
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = undefined;
  }

  // Создаёт axios с заголовками
  createRequest = _etag => {
    const axiosParams = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.access_token,
        'Cache-Control': 'no-cache'
      }
    };

    if (!!_etag) axiosParams.headers['If-Match'] = _etag;

    return axios.create(axiosParams);
  };

  // Обработка изменений в полях апартов / добавление вложенных устройств
  handlerChange = (...args) => (e, name, obj) => {
    const newState = JSON.parse(JSON.stringify(this.state));
    const depthField = [ ...args ];
    const target = e !== null ? e.target.name : name;
    const value = e !== null ? e.target.value : obj;
    let titleOf;

    if (target === 'title' && args.length > 2) {
      titleOf = args.indexOf('heated_floors') !== -1 ? 'heated_floors' :
        args.indexOf('ventiltaion') !== -1 ? 'ventiltaion' : args[3];
    }

    findField(newState, depthField)(target, value, titleOf);

    this.setState(newState);
  }

  // Добавление / удаление комнат
  handlerAddRoom = () => {
    const newState = { ...this.state };

    newState.rooms = [ ...newState.rooms, JSON.parse(JSON.stringify(initNotGlobalRoomState))];
    this.setState(newState);
  }

  handlerRemoveRoom = index => {
    const newState = { ...this.state };
    if (confirm('Хотите удалить комнату?')) {
      const deletedItem = newState.rooms.filter((item, i) => i === index);
      if ( deletedItem[0]._id !== undefined ) newState.deletedItems = [ ...newState.deletedItems, deletedItem[0] ];
      newState.rooms = newState.rooms.filter((item, i) => i !== index);
    }

    this.setState(newState);
  }
  // Добавление / удаление устройств/ендпоинтов/
  handlerAddGroup = fi => (group, obj) => {
    const newState = { ...this.state };
    newState.rooms[fi].devicesEndpoints[group].push(JSON.parse(JSON.stringify(obj)));
    this.setState(newState);
  }

  handlerRemoveGroup = fi => (group, si) => {
    const newState = { ...this.state };
    if (typeof si === 'number')
      newState.rooms[fi].devicesEndpoints[group] =
        newState.rooms[fi].devicesEndpoints[group].filter((item, i) => i !== si);
    if (si === 'all') newState.rooms[fi].devicesEndpoints[group] = [];
    this.setState(newState);
  }

  // Изменение позиции комнаты
  handlerChangeRoomPosition = from => to => {
    const newState = { ...this.state };
    newState.rooms.splice(to, 0, newState.rooms.splice(from,1)[0]);
    return this.setState(newState);
  }

  // Обработка ошибок сервера
  handlerResponseError = (error, callback) => {
    if (!navigator.onLine) return alert('Проверьте интернет соединение');

    switch (error.response.status) {
      case 401: {
        if (error.response.data._error.message === 'token expired' ||
          error.response.data._error.message === 'user not found') {

            _refreshToken(
              this.props.client_id,
              this.props.login,
              this.props.password,
              this.props.changeAccessToken,
              callback
            )}
        break;
      }

      case 412: {
        alert('Данные были изменены');
      }

      case 422: {
        alert(JSON.stringify(error.response.data._issues, null, '\t')).replace(/\{|\}/gm, '');
        break;
      }

      default: alert('Неизвестная ошибка, повторите позже');
    }

    this.props.stopLoading();
  }

  checkDeleteRooms = () => {
    if (this.state.deletedItems.length === 0) return true;

    this.state.deletedItems.forEach(item => {
      const roomPath = `${rootUrl}/admin/rooms/${item._id}`;
      const endpointPath = `${rootUrl}/admin/endpoints/${item.devicesEndpoints._id}`

      this.createRequest(item._etag).delete(roomPath).catch( err => this.handlerResponseError(err, this.checkDeleteRooms.bind(this)) );
      this.createRequest(item.devicesEndpoints._etag).delete(endpointPath).catch( err => this.handlerResponseError(err, this.checkDeleteRooms.bind(this)) );
    })

    return true;
  }

  // Обновить данные ендпоинтов на сервере
  putOnlyEndpoints = (roomEndpoints, roomId, apartId, checkIsEnd) => {
    const endpointData = JSON.parse(JSON.stringify(roomEndpoints));
    const metaInfoEndpoint = { _id: roomEndpoints._id, _etag: roomEndpoints._etag };
    const endpointsFields = ['short_sn', 'device_url', 'global_endpoint', 'master_off', 'light', 'climate', 'sockets',
      'blinds', 'light_scenes', 'security_sensors', 'windows', 'room_id', 'apartment_id'];

    // If room endpoint without _id. This endpoint create.
    if (metaInfoEndpoint._id === undefined || metaInfoEndpoint._etag === undefined) {
      return this.postOnlyEndpoints({ data: { _id: roomId } }, roomEndpoints, apartId, checkIsEnd);
    }

    for (let key in endpointData) {
      if ((Array.isArray(endpointData[key]) && endpointData[key].length === 0) ||
          endpointData[key] === null || endpointsFields.indexOf(key) === -1) {
        delete endpointData[key];
      }
    }

    this.createRequest(metaInfoEndpoint._etag).put(`${rootUrl}/admin/endpoints/${metaInfoEndpoint._id}`, endpointData).then(() => {
        checkIsEnd() && this.checkDeleteRooms() && this.props.handlerUpdateBoard();
      })
      .catch( err => this.handlerResponseError(err, this.putOnlyEndpoints.bind(this, roomEndpoints, roomId, apartId, checkIsEnd)));
  }

  // Обновить данные комнат на сервере
  putRoomsAndEndpoints = async (rooms, apartId) => {
    const endpointsItems = rooms.length;
    let counter = 0;
    const checkIsEnd = () => {

      if (endpointsItems === 0) return true;
      if (counter + 1 === endpointsItems) return true;
      ++counter;

      return false;
    }

    await rooms.forEach(room => {
      const roomEndpoints = JSON.parse(JSON.stringify(room.devicesEndpoints));
      const metaInfoRoom = { _id: room._id, _etag: room._etag };
      const roomFields = ["title", "active", "apartment_id"];

      if (room.devicesEndpoints.global_endpoint)
        return this.putOnlyEndpoints(roomEndpoints, metaInfoRoom._id, apartId, checkIsEnd);

      if (metaInfoRoom._id === undefined || metaInfoRoom._etag === undefined) {
        return this.postRoomsAndEndpoints([ room ], apartId);
      }

      delete room.devicesEndpoints;

      for (let field in room) {
        if (roomFields.indexOf(field) === -1) delete room[field];
      }

      this.createRequest(metaInfoRoom._etag).put(`${rootUrl}/admin/rooms/${metaInfoRoom._id}`, room).then(() => this.putOnlyEndpoints(roomEndpoints, metaInfoRoom._id, apartId, checkIsEnd))
        .catch( err => this.handlerResponseError(err, this.putRoomsAndEndpoints.bind(this, rooms, apartId)) );
    });
  }

  // Обновить данные по апартам, комнатам и ендпоинтам
  handlerPutApart = () => {

    let endpointsItems, counterEndpoints = 0;

    if (!validationFields(this.state)) return alert('Заполните все поля!');

    this.props.startLoading();

    const apartData = JSON.parse(JSON.stringify(this.state.apart));
    const metaInfoApart = { _id: this.state.apart._id, _etag: this.state.apart._etag };
    const apartFields = ['aptnumber', 'floor_id'];

    for (let field in apartData) {
      if (apartFields.indexOf(field) === -1) delete apartData[field];
    }

    this.createRequest(metaInfoApart._etag).put(`${rootUrl}/admin/apartments/${metaInfoApart._id}`, apartData)
      .then(res => {
        const rooms = JSON.parse(JSON.stringify(this.state.rooms));

        this.putRoomsAndEndpoints(rooms, metaInfoApart._id);
      })
      .catch(err => this.handlerResponseError(err, this.handlerPutApart));
  }

  // Отправка ендпоинтов на сервер
  postOnlyEndpoints = (roomResponse, roomEndpoints, apartId, checkIsEnd) => {
    roomEndpoints['apartment_id'] = apartId;
    roomResponse !== null && (roomEndpoints['room_id'] = roomResponse.data._id);

    for (let key in roomEndpoints) {
      if ((Array.isArray(roomEndpoints[key]) && roomEndpoints[key].length === 0) ||
          roomEndpoints[key] === null) {
        delete roomEndpoints[key];
      }
    }

    this.createRequest().post(`${rootUrl}/admin/endpoints`, roomEndpoints).then(() => checkIsEnd() && this.props.handlerUpdateBoard() )
      .catch(err => this.handlerResponseError(err, this.postOnlyEndpoints.bind(this, roomResponse, roomEndpoints, apartId, checkIsEnd)));
  }

  // Отправка комнат на сервер
  postRoomsAndEndpoints = async (rooms, apartId) => {
    const endpointsItems = rooms.length;
    let counter = 0;
    const checkIsEnd = () => {

      if (endpointsItems === 0) return true;
      if (counter + 1 === endpointsItems) return true;
      ++counter;

      return false;
    };

    await rooms.forEach(room => {
      const roomEndpoints = { ...JSON.parse(JSON.stringify(room.devicesEndpoints)) };

      if (room.devicesEndpoints.global_endpoint === true) return this.postOnlyEndpoints(null, roomEndpoints, apartId, checkIsEnd);

      {
        room['apartment_id'] = apartId,
        delete room.devicesEndpoints
      };

      this.createRequest().post(`${rootUrl}/admin/rooms`, room).then(resRoom => this.postOnlyEndpoints(resRoom, roomEndpoints, apartId, checkIsEnd))
        .catch( err => {console.log('error', err); this.handlerResponseError(err, this.postRoomsAndEndpoints.bind(this, rooms, apartId))} );
    });
  }

  // Отправка апарта на сервер
  handlerPostApart = () => {
    if (!validationFields(this.state)) return alert('Заполните все поля!');

    this.props.startLoading();

    this.createRequest().post(`${rootUrl}/admin/apartments`, this.state.apart)
      .then(res => {
        const rooms = JSON.parse(JSON.stringify(this.state.rooms));

        this.postRoomsAndEndpoints(rooms, res.data._id);
      })
      .catch(err => this.handlerResponseError(err, this.handlerPostApart));
  }

  // Скачать JSON файл
  downloadJSON = () => {
    const dowloadingState = JSON.parse(JSON.stringify(this.state));

    delete dowloadingState.deletedItems;

    let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(dowloadingState));
    let downloadAnchorNode = document.createElement('a');
    const configureName = this.state.apart.aptnumber ? `configs_${this.state.apart.aptnumber}` : 'configs';

    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", configureName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  loadJSON = jsonFile => {
    jsonFile.text().then(data => {
      confirm(`Заполнить данными из файла: ${jsonFile.name}?`) && this.setState(JSON.parse(data));
    })
      .catch(err => console.log(err));
  }

  render() {
    return (
      <div className='main__apart-configure'>
        <ApartInfo
          request={this.createRequest()}
          apart={this.state.apart}
          handlerChange={this.handlerChange}
          handlerResponseError={this.handlerResponseError}
        />
        <div className='apart-configure__rooms'>
          <Room
            index={0}
            roomInfo={this.state.rooms[0]}
            handlerRemove={this.handlerRemoveRoom}
            handlerAddGroup={this.handlerAddGroup}
            handlerRemoveGroup={this.handlerRemoveGroup}
            handlerChange={this.handlerChange}
            handlerChangeRoomPosition={this.handlerChangeRoomPosition}
          />
          {this.state.rooms.map(
            (item, i) =>
              <React.Fragment key={i}>
                {
                  (i >= 1) && <Room
                    index={i}
                    rooms={this.state.rooms.length}
                    roomInfo={item}
                    handlerRemove={this.handlerRemoveRoom}
                    handlerAddGroup={this.handlerAddGroup}
                    handlerRemoveGroup={this.handlerRemoveGroup}
                    handlerChange={this.handlerChange}
                    handlerChangeRoomPosition={this.handlerChangeRoomPosition}
                  />
                }
              </React.Fragment>
          )}
        </div>

        <ConfigureButtons
          state={this.state}
          initialState={this.props.initialState}
          handlerAddRoom={this.handlerAddRoom}
          handlerPostApart={this.handlerPostApart}
          handlerPutApart={this.handlerPutApart}
          downloadJSON={this.downloadJSON}
          loadJSON={this.loadJSON}
        />
      </div>
    );
  }
}

export default ApartConfigure;