import React from 'react';

import Conditioner from './Conditioner';
import Temperature from './Temperature';
import Ventilation from './Ventilation';
import WarmFloor from './WarmFloor';

// import './styles.css';

export default props => {
  return (
    <div>
      <Temperature item={props.item} roomIndex={props.roomIndex} handlerChange={props.handlerChange} />
      <Conditioner item={props.item} roomIndex={props.roomIndex} handlerChange={props.handlerChange} />
      <WarmFloor items={props.item} roomIndex={props.roomIndex} handlerChange={props.handlerChange} />
      <Ventilation items={props.item} roomIndex={props.roomIndex} handlerChange={props.handlerChange} />
    </div>
  );
}