import React from 'react';

import DefaultInput from '../../../../../../../../DumbComponents/DefaultInput';

import './styles.css';

const automationInitState = {
  topic_manual_auto: '',
};

export default props => {
  const selected = !!props.item.conditioner.automation_mode;
  const path = ['rooms', props.roomIndex, 'devicesEndpoints', 'climate'];

  const handlerClick = () => {
    !selected && props.handlerChange(...path, 'conditioner')(null, 'automation_mode', automationInitState );
    selected && props.handlerChange(...path, 'conditioner')(null, 'automation_mode', { clear: true });
  }

  return (
    <div className='params-item_conditioner-mode'>
      <div className='room__checkbox room__checkbox_conditioner-mode'>
        <label><input type='checkbox' onChange={() => handlerClick()} checked={selected} />Ручной / авто режимы</label>
      </div>
      {selected &&
        <div>
          <div className='params-item__field'>
            <span className='params-field__title'>Топик переключателя</span>
            <DefaultInput
              name='topic_manual_auto'
              value={props.item.conditioner.automation_mode.topic_manual_auto}
              onChange={props.handlerChange(...path, 'conditioner', 'automation_mode')}
              placeholder='topic_manual_auto'
              required={!props.item.conditioner.automation_mode.topic_manual_auto}
            />
          </div>
          <div className='params-item__field'>
            <span className='params-field__title'>Фидбэк переключателя</span>
            <DefaultInput
              name='topic_manual_auto_fb'
              value={props.item.feedbacks.topic_manual_auto_fb}
              onChange={props.handlerChange(...path, 'feedbacks')}
              placeholder='topic_manual_auto_fb'
              required={!props.item.feedbacks.topic_manual_auto_fb}
            />
          </div>
        </div>
      }
    </div>
  );
}