import React, { Component } from 'react';

import { connect } from 'react-redux';

import dispatch from '../../../../store/dispatch.js';

import api from '../../../../api';

import './Account.css';

import passport_img from './passport.png';
import empty_avatar from './empty_avatar.jpg';

class Account extends Component {
    componentWillMount() {
        if (this.props.user_photo_for_save !== undefined) {
            this.getImageApi();
        }

    }
    getImageApi = () => {
        // console.log(this.props.user_photo_for_save);
        api.getImage(this.props.user_photo_for_save, this.props.access_token)
            .then(request => {
                // console.log(request);
                this.setState({
                    isLoad: true
                })
                let img_file = `data:${request.data.image.content_type};base64, ${request.data.image.file}`
                // console.log(img_file);

                this.props.changeStateAccountUserPhoto(img_file);
            })
            .catch(
                error => {
                    console.log(error);
                    if (error.response.status === 401 && error.response.data._error.message === 'token expired' || error.response.status === 401 && error.response.data._error.message === 'user not found') {

                        _refreshToken(
                            this.props.client_id,
                            this.props.LOGIN,
                            this.props.PASSWORD,
                            this.props.changeAccessToken,
                            this.getImageApi.bind(this)
                        )
                    }
                }
            )
    }
    render() {

        return (
            <div className="account flex" >
                <div className="col-lg3 flex_flow_column">
                    <span className="label">Фото</span><br />
                    <div className="account_photo flex1">
                        <img className="account_photo_img" src={this.props.state_account_user_photo !== undefined ? this.props.state_account_user_photo : empty_avatar} alt="Фото" onClick={() => this.props.state_account_user_photo !== undefined ? this.props.changeImgOpen(!this.props.imgOpen, this.props.state_account_user_photo) : alert("Нет фото")}></img>
                    </div>
                    <div className="account_back_button flex1">
                        <a className="account_back_button_a flex center text_white" onClick={() => this.props.changeStateWindowAccounts(!this.props.stateWindowAccounts.accountOpen)}>к списку</a>
                    </div>
                </div>
                <div className="col-lg3 flex_flow_column">
                    <div className="account_name">
                        <span className="label">Ф. И. О.</span>
                        <p className="text_white max-w-250px user-select-text margin-top10">{this.props.state_account_name}</p>
                    </div>
                    <div className="account_type margin-top20">
                        <span className="label">Тип пользователя</span>
                        <p className="text_white max-w-250px user-select-text margin-top10">{this.props.state_account_type}</p>
                    </div>
                    <div className="account_type margin-top20">
                        <span className="label">Телефон</span>
                        <p className="text_white max-w-250px user-select-text margin-top10">{this.props.state_account_tel}</p>
                    </div>
                    <div className="account_email margin-top20">
                        <span className="label">E-mail</span>
                        <p className="text_white max-w-250px user-select-text margin-top10">{this.props.state_account_email}</p>
                    </div>
                    {this.props.state_account_numApart !== undefined ?
                        <div className="account_numapart margin-top20">
                            <span className="label">Номер апартамента</span>
                            <p className="text_white max-w-250px user-select-text margin-top10">{this.props.state_account_numApart}</p>
                        </div>
                        : null}

                </div>
                <div className="col-lg3 flex_flow_column">
                    <div className="account_pasport">
                        <div className="account_pasport_items">
                            <span className="label">Кадастровый паспорт</span>
                            <img className="account_passport_img margin-top10" src={this.props.state_account_passportApart !== undefined ? this.props.state_account_passportApart : passport_img} alt="Паспорт" onClick={() => this.props.state_account_passportApart !== undefined ? this.props.changeImgOpen(!this.props.imgOpen, this.props.state_account_passportApart) : alert("Нет паспорта")}></img>
                        </div>
                    </div>
                </div>

            </div>
        );
    };
}

export default connect(dispatch.mapStateToProps, dispatch.mapDispatchToProps)(Account);