import React, { Component } from 'react';

import { connect } from 'react-redux';
import { _refreshToken } from '../../Refresh/Refresh';
import dispatch from '../../../../store/dispatch.js';

import api from '../../../../api';

import './PassItem.css';

class PassItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dateUpdate: undefined,
            dateComming: undefined,
            plate: undefined,
            user: undefined,
            apartId: undefined,
            apartNum: undefined,
            status: undefined,
            id: undefined,
            etag: undefined
        };

    }

    componentWillMount() {
        this.setState({
            dateComming: this.getTime(this.props.passDataItem.datetime) + ' ' + this.getDate(this.props.passDataItem.datetime),
            dateUpdate: this.getTime(this.props.passDataItem._created) + ' ' + this.getDate(this.props.passDataItem._created),
            plate: this.props.passDataItem.plate,
            status: this.props.passDataItem.status,
            id: this.props.passDataItem._id,
            etag: this.props.passDataItem._etag
        })
        this.getAccountsApi();
    }
    getAccountsApi = () => {
        api.getAccounts(this.props.access_token, this.props.LOGIN_TYPE_USER, this.props.mainName, 1, ["user", this.props.passDataItem.owner_id])
            .then(
                (req) => {
                    // console.log(req);
                    if (req.data !== undefined) {
                        if (req.data._items[0].apartment_id !== undefined) {
                            this.setState({ apartId: req.data._items[0].apartment_id });
                            this.getApartment();
                        } if (req.data._items[0].apartment_id === undefined) {
                            this.setState({ apartNum: "Нет данных" });
                        }
                        this.setState({ user: `${req.data._items[0].firstname} ${req.data._items[0].middlename} ${req.data._items[0].lastname}` });
                        return;
                    } else {
                        this.setState({ user: "Нет данных" });
                        return;
                    }
                }
            )
            .catch(
                (err) => {
                    console.log(err);
                    console.log(err.response);
                    console.log(err.response.status);
                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                        _refreshToken(
                            this.props.client_id,
                            this.props.LOGIN,
                            this.props.PASSWORD,
                            this.props.changeAccessToken,
                            this.getAccountsApi.bind(this)
                        )
                    } else {

                        switch (err.response.status) {
                            case 401:
                                // alert('Данные не верны!')
                                break;
                            case 404:
                                this.setState({ user: `Нет данных` });
                                this.setState({ apartNum: "Нет данных" });
                                break;
                            case 500:
                                alert('Внутренняя ошибка сервера!')
                                break;
                            default:
                                break;
                        }
                    }
                }
            )
    }
    getApartment() {
        if (this.state.apartId !== undefined) {
            api.getAptnumber(this.state.apartId, this.props.LOGIN_TYPE_USER, this.props.access_token)
                .then(
                    (req) => {
                        // console.log(req);
                        if (req.data !== undefined) {
                            this.setState({ apartNum: req.data.aptnumber });
                            return;
                        } else {
                            this.setState({ apartNum: "Нет данных" });
                            return;
                        }
                    }
                )
                .catch(
                    (err) => {
                        console.log(err);
                        console.log(err.response);
                        console.log(err.response.status);
                        if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                            _refreshToken(
                                this.props.client_id,
                                this.props.LOGIN,
                                this.props.PASSWORD,
                                this.props.changeAccessToken,
                                this.getApartment.bind(this)
                            )
                        } else {

                            switch (err.response.status) {
                                case 401:
                                    // alert('Данные не верны!')
                                    break;
                                case 404:
                                    this.setState({ user: `Нет данных` });
                                    this.setState({ apartNum: "Нет данных" });
                                    break;
                                case 500:
                                    alert('Внутренняя ошибка сервера!')
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                )
        }

    }

    getDate(date) {
        return ('0' + new Date(date).getDate()).slice(-2) + '.' + ('0' + new Date(date).getMonth()).slice(-2) + '.' + new Date(date).getUTCFullYear();
    }

    getTime(date) {
        return (('0' + (new Date(date).getHours())).slice(-2) + ':' + ('0' + (new Date(date).getMinutes())).slice(-2));
    }

    clickOpenItem() {

        // console.log('click: ', this.state);
        this.props.changePassItemDateUpdate(this.state.dateUpdate);
        this.props.changePassItemDateComming(this.state.dateComming);
        this.props.changePassItemPlate(this.state.plate);
        this.props.changePassItemUser(this.state.user);
        this.props.changePassItemApartId(this.state.apartId);
        this.props.changePassItemApartNum(this.state.apartNum);
        this.props.changePassItemDateStatus(this.state.status);
        this.props.changePassItemId(this.state.id);
        this.props.changePassItemEtag(this.state.etag);

        this.props.changeStateWindowOpenPassItem(true);

    }

    render() {
        // console.log('passDataItem: ', this.props.passDataItem);

        return (
            <div
                className="pass-item bottom-horizontal-border padding25 flex w100"
                onClick={() => this.clickOpenItem()}
            >
                <div className="w30 flex center-horizontal">
                    <div className="">
                        <p className="date_gray margin-bottom30">{this.getTime(this.props.passDataItem.datetime) + ' ' + this.getDate(this.props.passDataItem.datetime)}</p>
                        <p className="text_white">{this.props.passDataItem.plate}</p>
                    </div>
                </div>
                <div className="w70 flex center-horizontal left-vertikal-border padding-left25">
                    <div className="">
                        <p className="text_white margin-bottom30">{this.state.user}</p>
                        <p className="text_white">{this.state.apartNum}</p>
                    </div>
                </div>
            </div>
        );
    };
};

export default connect(dispatch.mapStateToProps, dispatch.mapDispatchToProps)(PassItem);