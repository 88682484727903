        /**
         * Set the timezone offset from the local timezone offset.
         * Argument "minutes" => -UTC±XX:XX, default = local offset.
         * Property "timezoneOffset" => last-set offset.
         * Return => milliseconds.
         */
        export const setTimezoneOffset = Date.prototype.setTimezoneOffset = function (minutes) {
            var _minutes;
            if (this.timezoneOffset == _minutes) {
                _minutes = this.getTimezoneOffset();
            } else {
                _minutes = this.timezoneOffset;
            }
            if (arguments.length) {
                this.timezoneOffset = minutes;
            } else {
                this.timezoneOffset = minutes = this.getTimezoneOffset();
            }
            return this.setTime(this.getTime() + (_minutes - minutes) * 6e4);
        };