import React from 'react';

import DefaultInput from '../../../../../../DumbComponents/DefaultInput';

// import './styles.css';

export default props => {
  const path = ['rooms', props.roomIndex, 'devicesEndpoints', 'blinds', props.itemIndex];

  return (
    <div className='params__item params__item_blinds'>
      <div className='params-item__field'>
        <span className='params-field__title'>Название:</span>
        <DefaultInput
          name='title'
          value={props.item.title}
          onChange={props.handlerChange(...path)}
          placeholder='title'
          required={!props.item.title}
        />
      </div>

      <div className='params-item__field'>
        <span className='params-field__title'>Открыть:</span>
        <DefaultInput
          name="topic_open"
          placeholder="topic_open"
          value={props.item.topic_open}
          onChange={props.handlerChange(...path)}
          placeholder='topic_open'
          required={!props.item.topic_open}
        />
      </div>
      <div className='params-item__field'>
        <span className='params-field__title'>Остановить:</span>
        <DefaultInput
          name="topic_stop"
          placeholder="topic_stop"
          value={props.item.topic_stop}
          onChange={props.handlerChange(...path)}
          placeholder='topic_stop'
          required={!props.item.topic_stop}
        />
      </div>
      <div className='params-item__field'>
        <span className='params-field__title'>Закрыть:</span>
        <DefaultInput
          name="topic_close"
          placeholder="topic_close"
          value={props.item.topic_close}
          onChange={props.handlerChange(...path)}
          placeholder='topic_close'
          required={!props.item.topic_close}
        />
      </div>
    </div>
  );
}