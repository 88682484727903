import React from 'react';

import DefaultInput from '../../../../../../../../DumbComponents/DefaultInput';

// import './styles.css';

export default props => {
  const path = ['rooms', props.roomIndex, 'devicesEndpoints', 'climate', 'heated_floors', props.itemIndex];

  return (
    <div>
      <div>
        <span>Контур {props.itemIndex + 1}</span>
        {
          props.itemsLength !== 1 &&
          <span
            className='params-field__button-remove'
            onClick={
              () => props.handlerChange(...path.slice(0, -2))(null, 'heated_floors', { clear: true, index: props.itemIndex })
            }>
              Удалить контур
          </span>
        }
      </div>
      <div>
        <div className='params-item__field'>
          <span className='params-field__title'>Название</span>
          <DefaultInput
            name='title'
            value={props.item.title}
            placeholder='title'
            onChange={props.handlerChange(...path)}
            required={!props.item.title}
          />
        </div>

        <div className='params-item__field'>
          <span className='params-field__title'>Топик</span>
          <DefaultInput
            name='topic_switch'
            value={props.item.topic_switch}
            placeholder='topic_switch'
            onChange={props.handlerChange(...path)}
            required={!props.item.topic_switch}
          />
        </div>
        <div className='params-item__field'>
          <span className='params-field__title'>Фидбэк</span>
          <DefaultInput
            name='topic_switch_fb'
            value={props.item.feedbacks.topic_switch_fb}
            placeholder='topic_switch_fb'
            onChange={props.handlerChange(...path, 'feedbacks')}
            required={!props.item.feedbacks.topic_switch_fb}
          />
        </div>
      </div>
    </div>
  );
}