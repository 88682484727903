import React, { useState } from 'react';

import DefaultInput from '../../../../../DumbComponents/DefaultInput';

import './styles.css';

export default props => {
  const path = ['rooms', props.roomIndex, 'devicesEndpoints'];
  const selectedMasterOff = !!props.info.master_off;
  const masterOffInitState = {
    topic_call: ''
  };

  const handlerClick = () => {
    !selectedMasterOff && props.handlerChange(...path)(null, 'master_off', masterOffInitState );
    selectedMasterOff && props.handlerChange(...path)(null, 'master_off', { clear: true });
  }

  return (
    <div className='endpoint__info'>
      <div className='endpoint-info__item'>
        <div className='endpoint-info__title'>Серийный номер Wiren Board:</div>
        <DefaultInput
          name='short_sn'
          placeholder='short_sn'
          onChange={props.handlerChange(...path)}
          value={props.info.short_sn}
          required={!props.info.short_sn}
        />
      </div>
      <div className='endpoint-info__item'>
        <div className='endpoint-info__title'>Путь к устройству:</div>
        <DefaultInput
          name='device_url'
          placeholder='device_url'
          onChange={props.handlerChange(...path)}
          value={props.info.device_url}
          required={!props.info.device_url}
        />
      </div>
      { props.roomIndex === 0 && 
        <div className='endpoint-info__item'>
          <label className='endpoint-info__title endpoint-info__title_global_true'>
            Глобальное управление апартаментом: <input
              type='checkbox'
              name='global_endpoint'
              onChange={() => props.handlerChange(...path)(null, 'global_endpoint', !props.info.global_endpoint)}
              checked={props.info.global_endpoint}
              disabled
            />
          </label>
        </div>
      }
      { props.roomIndex === 0 &&
        <div className='endpoint-info__item'>
          <div className='room__checkbox room__checkbox_master-off'>
            <label><input type='checkbox' onChange={() => handlerClick()} checked={selectedMasterOff} />Master off</label>
          </div>
        </div>
      }
      { selectedMasterOff &&
        <div className='params-item__field'>
          <span className='params-field__title'>Команда вызова</span>
          <DefaultInput
            name='topic_call'
            value={props.info.master_off.topic_call}
            onChange={props.handlerChange(...path, 'master_off')}
            placeholder='topic_call'
            required={!props.info.master_off.topic_call}
          />
        </div>
      }
    </div>
  );
}