import React, { useState } from 'react';

import LightParams from './LightParams';

// import './styles.css';

const lightInitState = {
  title: '',
  name: '',
  dimmable: false,
  topic_switch: '',
  feedbacks: {
    topic_switch_fb: '',
  }
}
  // topic_value: '',
  // dimmer_max_value: '',
  //   topic_value_fb: ''

export default props => {
  const selected = props.items.length !== 0;
  const path = ['rooms', props.roomIndex, 'devicesEndpoints'];

  const handlerClick = () => {
    !selected && props.handlerChange(...path)(null, 'light', JSON.parse(JSON.stringify(lightInitState)));
    selected && props.handlerChange(...path)(null, 'light', { clear: true });
  }

  return (
    <div className='room__block'>
      <div className='room__checkbox room__checkbox_lights'>
        <label><input onChange={() => handlerClick()} type='checkbox' checked={selected} />Освещение</label>
      </div>
      { selected &&
          (
            <div className='params params_lights'>
              {props.items.map((item, i) =>
                <div key={i}>
                  <LightParams key={i} roomIndex={props.roomIndex} itemIndex={i} item={item} handlerChange={props.handlerChange} />
                  {props.items.length > 1 && <div className='params__button-remove' onClick={() => props.handlerRemoveGroup('light', i)}>Удалить освещение</div>}
                </div>)}
              <div
                className='room__button_add'
                onClick={() => props.handlerAddGroup('light', lightInitState)}
              >Добавить освещение</div>
            </div>
          )
      }
    </div>
  );
}