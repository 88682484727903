import React, { Component } from 'react';

import swal from 'sweetalert';

import api from '../../../../../api';

import { connect } from 'react-redux';
import { _refreshToken } from '../../../Refresh/Refresh';
import dispath from '../../../../../store/dispatch.js';

import './LkOperatorsListItem.css';

class LkOperatorsListItem extends Component {

    constructor(props) {
        super(props);
        this.props.changeAccountsMainStateHiden(false);
        this.state = {
            // apartment_num: undefined,
            // apartment_size: undefined,
            // apartment_pasport: undefined,
            user_photo: undefined // получать из базы
        }
        // this.props.data_items.apartment_id !== undefined
        // ?
        // api.getAptnumber(this.props.data_items.apartment_id, this.props.access_token)
        //     .then(
        //         (req) => {
        //             // console.log(req);

        //             if (this.state.apartment_num === req.data.aptnumber && this.state.apartment_size === req.data.area) {
        //                 return;
        //             } else if (this.state.apartment_num === req.data.aptnumber) {
        //                 this.setState({
        //                     apartment_size: req.data.area,
        //                     // apartment_pasport: undefined,
        //                     // user_photo: undefined
        //                 });
        //             } else if (this.state.apartment_size === req.data.area) {
        //                 this.setState({
        //                     // apartment_num: req.data.aptnumber,
        //                     // apartment_pasport: undefined,
        //                     // user_photo: undefined
        //                 });

        //             } else {
        //                 this.setState({
        //                     // apartment_num: req.data.aptnumber,
        //                     // apartment_size: req.data.area,
        //                     // apartment_pasport: undefined,
        //                     // user_photo: undefined
        //                 });
        //             }
        //         }
        //     )
        //     .catch((error) => {
        //         console.log(error);
        //     })
        // :
        // null;
    }

    componentWillMount() {
        // this.props.data_items.apartment_id !== undefined
        //     ?
        //     api.getAptnumber(this.props.data_items.apartment_id, this.props.access_token)
        //         .then(
        //             (req) => {
        //                 this.setState({
        //                     apartment_num: req.data.aptnumber,
        //                     apartment_size: req.data.area,
        //                     // apartment_pasport: undefined,
        //                     // user_photo: undefined
        //                 })
        //             }
        //         )
        //         .catch((error) => {
        //             console.log(error);
        //         })
        //     :
        //     null;
    }

    // changeApartmentDataItem() {
    //     this.props.changeApartmentNum(this.state.apartment_num);
    //     this.props.changeApartmentSize(this.state.apartment_size);
    //     // this.props.changeApartmentPasport(undefined);
    //     // this.props.changeUserPhoto(undefined);
    // }

    openItem(event) {
        // console.log('this.state', this.state);

        // this.changeApartmentDataItem();
        // this.props.changeApartmentNum(this.state.apartment_num);
        // this.props.changeApartmentSize(this.state.apartment_size);
        if (event.target.id !== "editSvg" && event.target.id !== "editPath" && event.target.id !== "deleteSvg" && event.target.id !== "deletePath" && event.target.id !== "deleteDiv" && event.target.id !== "editDiv") {
            this.props.changeMain("apart");
            this.props.changeStateAccountName(`${this.props.data_items.firstname} ${this.props.data_items.middlename} ${this.props.data_items.lastname}`);
            this.props.changeStateAccountType(this.props.data_items.role);
            this.props.changeStateAccountTel(this.props.data_items.phone);
            this.props.changeStateAccountEmail(this.props.data_items.email);
            this.props.changeUserPhotoForSave(this.props.data_items.image);
            // this.props.changeStateAccountNumApart(this.state.apartment_num);

            // this.props.changeStateAccountSizeApart(this.state.apartment_size);
            this.props.changeStateAccountPassportApart(this.props.apartment_pasport);
            this.props.changeStateAccountUserPhoto(this.props.user_photo);
            this.props.changeStateWindowAccounts(!this.props.stateWindowAccounts.accountOpen); //personalInfo
            return;
        } else {
            return;
        }
    }

    editItem(data_items) {
        this.props.changeStateCreateUserPhoto({
            imagePreviewUrl: this.props.data_items.image
        });
        // this.changeApartmentDataItem();
        this.props.changesStateWindowOpenNewOperator(true);
        this.props.changeStatusSendDataAccountOnServer('edit');
        this.props.changeStateEditAccountId(data_items._id);
        this.props.changeStateEditAccountEtag(data_items._etag);

        this.props.changeStateWindowAccountEdit(!this.props.stateWindowAccounts.accountEdit);
        this.props.changeMain("apart");
        this.props.changeStateCreateUserFirstName(data_items.firstname);
        this.props.changeStateCreateUserSecondName(data_items.lastname);
        this.props.changeStateCreateUserThirdName(data_items.middlename);
        this.props.changeStateCreateUserTel(data_items.phone);
        this.props.changeStateCreateUserTypeUser(data_items.role[0]);
        this.props.changeStateCreateUserUserEmail(data_items.email);
        this.props.changeUserPhotoForSave(data_items.image);
        // this.props.changeStateCreateUserPhoto(data_items.name);
        // this.props.changeStateCreateUserImgPassport(data_items.name);
        // this.props.changeStateCreateUserNumApart(this.state.apartment_num);
        // this.props.changeStateCreateUserSizeApart(this.state.apartment_size);
    }

    deletElem(result) {
        if (result === true) {
            this.deletElemApi();
            return;
        } else {
            return;
        }
    }
    deletElemApi = () => {
        api.deleteAccounts(this.props.data_items._id, this.props.data_items._etag, this.props.LOGIN_TYPE_USER, this.props.access_token)
            .then((response) => {
                // console.log(response);
                api.getAccounts(this.props.access_token, this.props.LOGIN_TYPE_USER, this.props.mainName, 1)
                    .then((req) => {
                        this.props.changedDataOperators(req.data._items);
                        this.props.changeStateAccountPage(1);
                        // console.log(req);
                    })
                    .catch(
                        err => {
                            console.log(err);
                            if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                _refreshToken(
                                    this.props.client_id,
                                    this.props.LOGIN,
                                    this.props.PASSWORD,
                                    this.props.changeAccessToken,
                                    this.deletElemApi.bind(this)
                                )

                            }
                        }
                    );
                // console.log(response);
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                    _refreshToken(
                        this.props.client_id,
                        this.props.LOGIN,
                        this.props.PASSWORD,
                        this.props.changeAccessToken,
                        this.deletElemApi.bind(this)
                    )
                }
            });
    }
    deletItem() {
        swal("Действительно хотите удалить?", {
            dangerMode: true,
            buttons: true,
        })
            .catch(swal.noop)
            .then((result) => {
                this.deletElem(result);
            });
    }

    componentDidUpdate() {
        this.props.changeAccountsMainStateHiden(true);
    }

    render() {

        const svgData = [
            <svg
                id="deleteSvg"
                width="11px"
                height="11px">
                <path
                    id="deletePath"
                    fillRule="evenodd"
                    fill="rgb(92, 92, 92)"
                    d="M10.718,1.260 C10.348,0.891 9.749,0.891 9.379,1.260 L5.989,4.648 L2.600,1.260 C2.230,0.891 1.630,0.891 1.260,1.260 C0.890,1.630 0.890,2.230 1.260,2.599 L4.649,5.987 L1.260,9.375 C0.890,9.744 0.890,10.344 1.260,10.714 C1.445,10.899 1.687,10.991 1.930,10.991 C2.172,10.991 2.414,10.899 2.599,10.714 L5.989,7.326 L9.379,10.714 C9.564,10.899 9.806,10.991 10.048,10.991 C10.291,10.991 10.533,10.899 10.718,10.714 C11.088,10.344 11.088,9.745 10.718,9.375 L7.329,5.987 L10.718,2.599 C11.088,2.230 11.088,1.630 10.718,1.260 Z"
                />
            </svg>,
            <svg
                id="editSvg"
                width="12px"
                height="12px">
                <path
                    id="editPath"
                    fillRule="evenodd"
                    fill="rgb(92, 92, 92)"
                    d="M11.582,12.000 L0.428,12.000 C0.204,12.000 0.010,11.803 0.010,11.574 C0.010,11.346 0.194,11.149 0.428,11.149 L11.572,11.149 C11.806,11.149 12.000,11.346 12.000,11.574 C12.000,11.803 11.816,12.000 11.582,12.000 ZM2.689,9.706 C2.608,9.789 2.506,9.830 2.394,9.830 L0.418,9.841 C0.306,9.841 0.204,9.799 0.122,9.716 C0.041,9.633 0.000,9.519 0.000,9.405 L0.010,7.380 C0.010,7.266 0.061,7.162 0.132,7.079 L5.592,1.525 L6.957,0.124 C7.131,-0.042 7.395,-0.042 7.558,0.124 L9.514,2.138 C9.677,2.314 9.677,2.584 9.514,2.750 L2.689,9.706 ZM0.856,7.567 L0.845,8.969 L2.231,8.958 L7.263,3.840 L5.888,2.439 L0.856,7.567 ZM7.263,1.037 L6.489,1.826 L7.864,3.228 L8.638,2.439 L7.263,1.037 Z"
                />
            </svg>

        ]
        return (
            <div className="accounts_item flex border_bottom" onClick={(enent) => this.openItem(enent)}>
                <div className="accounts_item_name flex center-horizontal w100 margin-right">
                    <p className="text_white">{this.props.data_items.firstname} {this.props.data_items.lastname} </p>
                </div>
                {/* <div className="accounts_item_numapart flex center-horizontal w100 margin-right">
                    <p className="text_white">{this.state.apartment_num}</p>
                </div> */}
                <div className="accounts_item_tel w100 flex center-horizontal margin-right">
                    <p className="text_white ">{this.props.data_items.phone}</p>
                </div>
                <div className="accounts_item_controller flex w50 flex-end">
                    <div id="deleteDiv" className="delete_svg svg_box hover_svg" onClick={() => this.deletItem()}>
                        {svgData[0]}
                    </div>
                    <div id="editDiv" className="edit_svg svg_box hover_svg" onClick={() => this.editItem(this.props.data_items)} >
                        {svgData[1]}
                    </div>
                </div>
            </div>
        );
    };
};

export default connect(dispath.mapStateToProps, dispath.mapDispatchToProps)(LkOperatorsListItem);