import React from 'react';

import DefaultInput from '../../../../../DumbComponents/DefaultInput';

import './styles.css';

export default props => {
  const createSelectOptions = () => {
    const returnedArray = [];

    for (let i = 0; i !== props.rooms; i++) {
      if (i !== 0) {
        returnedArray.push(
          <option key={i} value={i}>{i}</option>
        );
      }
    }

    return returnedArray;
  }

  return (
    <div className='room__info'>
      <div className='room-info__room-number'>
        <span className='params-field__title'>Номер комнаты</span>
        <select onChange={e => props.handlerChangeRoomPosition(props.roomIndex)(+e.target.value)} value={props.roomIndex}>
          { createSelectOptions() }
        </select>
      </div>
      <div className='room-info__field'>
        <span className='params-field__title'>Название комнаты</span>
        <DefaultInput
          name='title'
          placeholder='title'
          onChange={props.handlerChange('rooms', props.roomIndex)}
          value={props.roomInfo.title}
          required={!props.roomInfo.title}
        />
      </div>
      { props.rooms > 1 &&
        <div className='params__button-remove' onClick={() => props.handlerRemove(props.roomIndex)}>Удалить комнату</div> }
    </div>
  );
}