import constants from '../constants';

export default {

  changeUserImg(NewUserImg) {
    return {
      type: constants.ACTION_CHANGE_USER_IMG,
      payload: NewUserImg
    }
  },

  changeLeftColumnImg(NewLeftColumnImg) {
    return {
      type: constants.ACTION_CHANGE_LEFT_COLUMN_IMG,
      payload: NewLeftColumnImg
    }
  },

  changeLkImageluserFile(NewLeftColumnImg) {
    return {
      type: constants.ACTION_CHANGE_LK_USER_IMG_FILE,
      payload: NewLeftColumnImg
    }
  },

  changeUserData(NewUserData) {
    return {
      type: constants.ACTION_CHANGE_USER_DATA,
      payload: NewUserData
    }
  },

  changeLogin(NewLogin) {
    return {
      type: constants.ACTION_CHANGE_LOGIN,
      payload: NewLogin
    }
  },

  changePassword(NewPassword) {
    return {
      type: constants.ACTION_CHANGE_PASSWORD,
      payload: NewPassword
    }
  },

  changeUserMenuOpenState(NewUserMenuOpenState) {
    return {
      type: constants.ACTION_CHANGE_USER_MENU_OPEN_STATE,
      payload: NewUserMenuOpenState
    }
  },

  changeStatusLogin(NewStatusLogin) {
    return {
      type: constants.ACTION_CHANGE_STATUS_LOGIN,
      payload: NewStatusLogin
    }
  },

  changeStateDangerInputLogin(NewStateDangerInputLogin) {
    return {
      type: constants.ACTION_CHANGE_STATE_DANGER_INPUT_LOGIN,
      payload: NewStateDangerInputLogin
    }
  },

  changeStateDangerInputPassword(NewStateDangerInputPassword) {
    return {
      type: constants.ACTION_CHANGE_STATE_DANGER_INPUT_PASSWORD,
      payload: NewStateDangerInputPassword
    }
  },

  changeAccessToken(NewAccessToken) {
    return {
      type: constants.ACTION_CHANGE_ACCESS_TOKEN,
      payload: NewAccessToken
    }
  },

  changeAccountId(NewAccountId) {
    return {
      type: constants.ACTION_CHANGE_ACCOUNT_ID,
      payload: NewAccountId
    }
  },

  changeClientId(NewClientId) {
    return {
      type: constants.ACTION_CHANGE_CLIENT_ID,
      payload: NewClientId
    }
  },

  changeRealClientId(NewRealClientId) {
    return {
      type: constants.ACTION_CHANGE_REAL_CLIENT_ID,
      payload: NewRealClientId
    }
  },

  changeStateSearchAccounts(NewStateSearchAccounts) {
    return {
      type: constants.ACTION_CHANGE_STATE_SEARCH_ACCOUNTS,
      payload: NewStateSearchAccounts
    }
  },

  changeStateSearchMailing(NewStateSearchMailing) {
    return {
      type: constants.ACTION_CHANGE_STATE_SEARCH_MAILING,
      payload: NewStateSearchMailing
    }
  },

  changeStateSearchServices(NewStateSearchServices) {
    return {
      type: constants.ACTION_CHANGE_STATE_SEARCH_SERVICES,
      payload: NewStateSearchServices
    }
  },

  changeExpires(NewExpires) {
    return {
      type: constants.ACTION_CHANGE_EXPIRES,
      payload: NewExpires
    }
  },

  changeRefreshToken(NewRefreshToken) {
    return {
      type: constants.ACTION_CHANGE_REFRESH_TOKEN,
      payload: NewRefreshToken
    }
  },

  changeUserName(NewUserName) {
    return {
      type: constants.ACTION_CHANGE_USER_NAME,
      payload: NewUserName
    }
  },

  changeUserId(NewUserId) {
    return {
      type: constants.ACTION_CHANGE_USER_ID,
      payload: NewUserId
    }
  },


  changeLoginTypeUser(NewTypeUser) {
    return {
      type: constants.ACTION_LOGIN_TYPE_USER,
      payload: NewTypeUser
    }
  },

  changeMain(NewMain) {
    return {
      type: constants.ACTION_CHANGE_MAIN,
      payload: NewMain
    }
  },

  changeImgOpen(NewImgOpenState, NewImg) {
    return {
      type: constants.ACTION_CHANGE_IMG_OPEN,
      payload: { NewImgOpenState: NewImgOpenState, NewAttachment: NewImg }
    }
  },

  getInitialState() {
    return {
      type: constants.ACTION_GET_INITIAL_STATE
    }
  },

  changeServicerequests(NewServicerequests) {
    return {
      type: constants.ACTION_CHANGE_SERVICE_REQUESTS,
      payload: NewServicerequests
    }
  },

  changeStateRequestPage(NewStateRequestPage) {
    return {
      type: constants.ACTION_CHANGE_STATE_REQUEST_PAGE,
      payload: NewStateRequestPage
    }
  },

  changeServicerequestData(NewServicerequestData) {
    return {
      type: constants.ACTION_CHANGE_SERVICE_REQUEST_DATA,
      payload: NewServicerequestData
    }
  },

  changeStateWindowServicerequests(NewRequestOpen) {
    return {
      type: constants.ACTION_CHANGE_STATE_WINDOW_SERVICE_REQUEST,
      payload: NewRequestOpen
    }
  },

  changeAccountData(NewAccountData) {
    return {
      type: constants.ACTION_CHANGE_ACCOUNT_DATA,
      payload: NewAccountData
    }
  },

  changeStateAccountPage(NewStateAccountPage) {
    return {
      type: constants.ACTION_CHANGE_STATE_ACCOUNT_PAGE,
      payload: NewStateAccountPage
    }
  },
  //
  changeStateAccountName(NewStateAccountName) {
    return {
      type: constants.ACTION_CHANGE_STATE_ACCOUNT_NAME,
      payload: NewStateAccountName
    }
  },
  changeStateAccountType(NewStateAccountType) {
    return {
      type: constants.ACTION_CHANGE_STATE_ACCOUNT_TYPE,
      payload: NewStateAccountType
    }
  },
  changeStateAccountTel(NewStateAccountTel) {
    return {
      type: constants.ACTION_CHANGE_STATE_ACCOUNT_TEL,
      payload: NewStateAccountTel
    }
  },
  changeStateAccountEmail(NewStateAccountEmail) {
    return {
      type: constants.ACTION_CHANGE_STATE_ACCOUNT_EMAIL,
      payload: NewStateAccountEmail
    }
  },
  changeStateAccountNumApart(NewStateAccountNumApart) {
    return {
      type: constants.ACTION_CHANGE_STATE_ACCOUNT_NUM_APART,
      payload: NewStateAccountNumApart
    }
  },
  changeStateAccountSizeApart(NewStateAccountSizeApart) {
    return {
      type: constants.ACTION_CHANGE_STATE_ACCOUNT_SIZE_APART,
      payload: NewStateAccountSizeApart
    }
  },
  changeStateAccountUserPhoto(NewStateAccountUserPhoto) {
    return {
      type: constants.ACTION_CHANGE_STATE_ACCOUNT_USER_PHOTO,
      payload: NewStateAccountUserPhoto
    }
  },
  changeStateAccountPassportApart(NewStateAccountPassportApart) {
    return {
      type: constants.ACTION_CHANGE_STATE_ACCOUNT_PASSPORT_APART,
      payload: NewStateAccountPassportApart
    }
  },
  //

  changeStateWindowAccounts(NewStateWindowAccounts, NewpersonalInfo) {
    return {
      type: constants.ACTION_CHANGE_STATE_WINDOW_ACCOUNTS,
      payload: { NewStateWindowAccounts: NewStateWindowAccounts, NewpersonalInfo: NewpersonalInfo }
    }
  },

  changeStateWindowAccountEdit(NewStateWindowAccountEdit) {
    return {
      type: constants.ACTION_CHANGE_STATE_WINDOW_ACCOUNT_EDIT,
      payload: NewStateWindowAccountEdit
    }
  },

  //изменение state на редактировании/создании пользователя

  changeApartmentNum(NewApartmentNum) {
    return {
      type: constants.ACTION_CHANGE_APARTMENT_NUM,
      payload: NewApartmentNum
    }
  },

  changeApartmentSize(NewApartmentSize) {
    return {
      type: constants.ACTION_CHANGE_APARTMENT_SIZE,
      payload: NewApartmentSize
    }
  },

  changeApartmentPasport(NewApartmentPasport) {
    return {
      type: constants.ACTION_CHANGE_APARTMENT_PASPORT,
      payload: NewApartmentPasport
    }
  },

  changeUserPhoto(NewUserPhoto) {
    return {
      type: constants.ACTION_CHANGE_USER_PHOTO,
      payload: NewUserPhoto
    }
  },
  changeUserPhotoForSave(NewUserPhoto) {
    return {
      type: constants.ACTION_CHANGE_USER_PHOTO_FOR_SAVE,
      payload: NewUserPhoto
    }
  },

  changeServicesPhotoForSave(NewServicesPhoto) {
    return {
      type: constants.ACTION_CHANGE_SERVICES_PHOTO_FOR_SAVE,
      payload: NewServicesPhoto
    }
  },
  changeServicesCategoryPhotoForSave(NewServicesategoryPhoto) {
    return {
      type: constants.ACTION_CHANGE_SERVICES_CATEGORY_PHOTO_FOR_SAVE,
      payload: NewServicesategoryPhoto
    }
  },


  changeStatusSendDataAccountOnServer(NewDataAccountOnServer) {
    return {
      type: constants.ACTION_CHANGE_STATUS_SEND_ACCOUNT_ON_SERVER,
      payload: NewDataAccountOnServer
    }
  },


  changeStateEditAccountId(NewStateEditAccountId) {
    return {
      type: constants.ACTION_CHANGE_STATE_EDIT_ACCOUNT_ID,
      payload: NewStateEditAccountId
    }
  },

  changeStateEditAccountEtag(NewStateEditAccountEtag) {
    return {
      type: constants.ACTION_CHANGE_STATE_EDIT_ACCOUNT_ETAG,
      payload: NewStateEditAccountEtag
    }
  },

  changeStateCreateUserFirstName(NewUserFirstName) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_USER_FIRSTNAME,
      payload: NewUserFirstName
    }
  },

  changeStateCreateUserSecondName(NewUserSecondName) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_USER_SECONDNAME,
      payload: NewUserSecondName
    }
  },
  changeStateCreateUserThirdName(NewUserThirdName) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_USER_THIRDNAME,
      payload: NewUserThirdName
    }
  },
  changeStateCreateUserTel(NewUserTel) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_USER_TEL,
      payload: NewUserTel
    }
  },
  changeStateCreateUserTypeUser(NewUserTypeUser) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_USER_TYPE_USER,
      payload: NewUserTypeUser
    }
  },
  changeStateCreateUserUserEmail(NewUserEmail) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_USER_EMAIL,
      payload: NewUserEmail
    }
  },
  changeStateCreateUserPhoto(NewUserPhoto) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_USER_PHOTO,
      payload: NewUserPhoto
    }
  },

  changeStateCreateUserImgPassport(NewUserImgPassport) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_USER_IMG_PASSPORT,
      payload: NewUserImgPassport
    }
  },
  changeStateCreateUserNumApart(NewUserNumApart) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_USER_NUM_APART,
      payload: NewUserNumApart
    }
  },
  changeStateCreateUserSizeApart(NewUserSizeApart) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_USER_SIZE_APART,
      payload: NewUserSizeApart
    }
  },
  // Изменение state на подсветку инпутов
  changeStateDangerInputCreateUserFirstName(NewStateDangerInputUserFirstName) {
    return {
      type: constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_FIRSTNAME,
      payload: NewStateDangerInputUserFirstName
    }
  },

  changeStateDangerInputCreateUserSecondName(NewStateDangerInputUserSecondName) {
    return {
      type: constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_SECONDNAME,
      payload: NewStateDangerInputUserSecondName
    }
  },
  changeStateDangerInputCreateUserThirdName(NewStateDangerInputUserThirdName) {
    return {
      type: constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_THIRDNAME,
      payload: NewStateDangerInputUserThirdName
    }
  },
  changeStateDangerInputCreateUserTel(NewStateDangerInputUserTel) {
    return {
      type: constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_TEL,
      payload: NewStateDangerInputUserTel
    }
  },
  changeStateDangerInputCreateUserTypeUser(NewStateDangerInputUserTypeUser) {
    return {
      type: constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_TYPE_USER,
      payload: NewStateDangerInputUserTypeUser
    }
  },
  changeStateDangerInputCreateUserUserEmail(NewStateDangerInputUserEmail) {
    return {
      type: constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_EMAIL,
      payload: NewStateDangerInputUserEmail
    }
  },
  changeStateDangerInputCreateUserPhoto(NewStateDangerInputUserPhoto) {
    return {
      type: constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_PHOTO,
      payload: NewStateDangerInputUserPhoto
    }
  },
  changeStateDangerInputCreateUserImgPassport(NewStateDangerInputUserImgPassport) {
    return {
      type: constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_IMG_PASSPORT,
      payload: NewStateDangerInputUserImgPassport
    }
  },
  changeStateDangerInputCreateUserNumApart(NewStateDangerInputUserNumApart) {
    return {
      type: constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_NUM_APART,
      payload: NewStateDangerInputUserNumApart
    }
  },
  changeStateDangerInputCreateUserSizeApart(NewStateDangerInputUserSizeApart) {
    return {
      type: constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_SIZE_APART,
      payload: NewStateDangerInputUserSizeApart
    }
  },

  // Сервисы

  changeServices(NewServices) {
    return {
      type: constants.ACTION_CHANGE_SERVICES,
      payload: NewServices
    }
  },
  changeServicesData(NewServicesData) {
    return {
      type: constants.ACTION_CHANGE_SERVICES_DATA,
      payload: NewServicesData
    }
  },
  changeStatusSendDataServicesOnServer(NewDataServicesOnServer) {
    return {
      type: constants.ACTION_CHANGE_STATUS_SEND_SERVICES_ON_SERVER,
      payload: NewDataServicesOnServer
    }
  },
  changeOnlineServicesData(NewOnlineServicesData) {
    return {
      type: constants.ACTION_CHANGE_ONLINE_SERVICES_DATA,
      payload: NewOnlineServicesData
    }
  },
  changeOnlineAllServicesCategoryData(NewOnlineServicesCategoryData) {
    return {
      type: constants.ACTION_CHANGE_ONLINE_SERVICES_CATEGORY_DATA,
      payload: NewOnlineServicesCategoryData
    }
  },
  changeOnlineServicesCategory(NewOnlineServicesCategory) {
    return {
      type: constants.ACTION_CHANGE_ONLINE_SERVICES_CATEGORY,
      payload: NewOnlineServicesCategory
    }
  },

  changeOnlineServicesDataForOpenItem(NewServicesDataForOpenItem) {
    return {
      type: constants.ACTION_CHANGE_ONLINE_SERVICES_DATA_FOR_OPEN,
      payload: NewServicesDataForOpenItem
    }
  },
  changeServicesCategoryForOpenItem(NewDataForOpenItem) {
    return {
      type: constants.ACTION_CHANGE_ONLINE_SERVICES_CATEGORY_DATA_FOR_OPEN,
      payload: NewDataForOpenItem
    }
  },
  changeStateWindowServices(NewStateWindowServices, NewServicesInfo) {
    return {
      type: constants.ACTION_CHANGE_STATE_WINDOW_SERVICES,
      payload: { NewStateWindowServices: NewStateWindowServices, NewServicesInfo: NewServicesInfo }
    }
  },
  changeStateWindowServicesEdit(NewStateWindowServicesEdit) {
    return {
      type: constants.ACTION_CHANGE_STATE_WINDOW_SERVICES_EDIT,
      payload: NewStateWindowServicesEdit
    }
  },

  changeStateWindowServicesCategory(NewStateWindowServicesCategory) {
    return {
      type: constants.ACTION_CHANGE_STATE_WINDOW_SERVICES_CATEGORY,
      payload: NewStateWindowServicesCategory
    }
  },

  changeStateWindowServicesCategoryEdit(NewStateWindowServicesCategoryEdit) {
    return {
      type: constants.ACTION_CHANGE_STATE_WINDOW_SERVICES_CATEGORY_EDIT,
      payload: NewStateWindowServicesCategoryEdit
    }
  },

  changeStateServicesPage(NewStateServicesPage) {
    return {
      type: constants.ACTION_CHANGE_STATE_SERVICES_PAGE,
      payload: NewStateServicesPage
    }
  },
  changeStateServicesCategoryPage(NewStateServicesCategoryPage) {
    return {
      type: constants.ACTION_CHANGE_STATE_SERVICES_CATEGORY_PAGE,
      payload: NewStateServicesCategoryPage
    }
  },
  changeStateEditServicesTitle(NewStateEditServicesTitle) {
    return {
      type: constants.ACTION_CHANGE_STATE_EDIT_SEVRICE_TITLE,
      payload: NewStateEditServicesTitle
    }
  },
  changeStateEditServicesCategoryTitle(NewStateEditServicesCategoryTitle) {
    return {
      type: constants.ACTION_CHANGE_STATE_EDIT_SEVRICE_CATEGORY_TITLE,
      payload: NewStateEditServicesCategoryTitle
    }
  },
  changeStateEditServicesCategoryActive(NewStateEditServicesCategoryActive) {
    return {
      type: constants.ACTION_CHANGE_STATE_EDIT_SEVRICE_CATEGORY_ACTIVE,
      payload: NewStateEditServicesCategoryActive
    }
  },
  changeStateCreateServicePhoto(NewServicePhoto) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_SEVRICE_PHOTO,
      payload: NewServicePhoto
    }
  },
  changeStateCreateServiceCategoryPhoto(NewServiceCategoryPhoto) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_SEVRICE_CATEGORY_PHOTO,
      payload: NewServiceCategoryPhoto
    }
  },
  changeStateEditServicesId(NewStateEditServicesId) {
    return {
      type: constants.ACTION_CHANGE_STATE_EDIT_SEVRICE_ID,
      payload: NewStateEditServicesId
    }
  },

  changeStateEditServicesEtag(NewStateEditServiceEtag) {
    return {
      type: constants.ACTION_CHANGE_STATE_EDIT_SEVRICE_ETAG,
      payload: NewStateEditServiceEtag
    }
  },

  changeStateServicesCategoryEtag(NewStateServiceCategoryEtag) {
    return {
      type: constants.ACTION_CHANGE_STATE_SEVRICE_CATEGORY_ETAG,
      payload: NewStateServiceCategoryEtag
    }
  },

  changeStateCreateServicesCategoryId(NewStateCreateServicesCategoryId) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_SEVRICE_CATEGORY_ID,
      payload: NewStateCreateServicesCategoryId
    }
  },

  changeStateCreateServicesCategoryName(NewStateCreateServicesCategoryName) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_SEVRICE_CATEGORY_NAME,
      payload: NewStateCreateServicesCategoryName
    }
  },

  changeStateCreateServicesDescription(NewStateCreateServicesDescription) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_SEVRICE_DESCRIPTION,
      payload: NewStateCreateServicesDescription
    }
  },
  changeStateCreateServicesShortinfo(NewStateCreateServicesShortinfo) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_SEVRICE_SHORTINFO,
      payload: NewStateCreateServicesShortinfo
    }
  },
  changeStateCreateServicesPhone(NewStateCreateServicesPhone) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_SEVRICE_PHONE,
      payload: NewStateCreateServicesPhone
    }
  },
  changeStateCreateServicesLinkCite(NewStateCreateServicesLinkCite) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_SEVRICE_LINK,
      payload: NewStateCreateServicesLinkCite
    }
  },
  changeStateCreateServicesEmail(NewStateCreateServicesEmail) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_SEVRICE_EMAIL,
      payload: NewStateCreateServicesEmail
    }
  },

  changeStateCreateServicesOrder(NewStateCreateServicesOrder) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_SEVRICE_ORDER,
      payload: NewStateCreateServicesOrder
    }
  },

  changeStateCreateServicesDelivery(NewStateCreateServicesDelivery) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_SEVRICE_DELIVERY,
      payload: NewStateCreateServicesDelivery
    }
  },

  changeStateCreateServicesPay(NewStateCreateServicesPay) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_SEVRICE_PAY,
      payload: NewStateCreateServicesPay
    }
  },


  //Изменение состояния для Mailing
  changeChartDataGraphic(NewChartDataGraphic) {
    return {
      type: constants.ACTION_CHANGE_DATA_GRAPHIC,
      payload: NewChartDataGraphic
    }
  },
  changeChartDataGraphicOpen(NewChartDataGraphicOpen) {
    return {
      type: constants.ACTION_CHANGE_DATA_GRAPHIC_OPEN,
      payload: NewChartDataGraphicOpen
    }
  },
  changeStateAddTypeNotification(NewStateAddTypeNotification) {
    return {
      type: constants.ACTION_CHANGE_STATE_ADD_TYPE_NOTIFICATION,
      payload: NewStateAddTypeNotification
    }
  },

  changeStateEditNotificationCategory(NewStateEditNotificationCategory) {
    return {
      type: constants.ACTION_CHANGE_STATE_EDIT_NOTIFICATION_CATEGORY,
      payload: NewStateEditNotificationCategory
    }
  },

  changeStateStatusEditNotificationCategory(NewStateStatusEditNotificationCategory) {
    return {
      type: constants.ACTION_CHANGE_STATE_STATUS_EDIT_NOTIFICATION_CATEGORY,
      payload: NewStateStatusEditNotificationCategory
    }
  },

  changeStateEditNotificationCategoryTitle(NewStateEditNotificationCategoryTitle) {
    return {
      type: constants.ACTION_CHANGE_STATE_EDIT_NOTIFICATION_CATEGORY_TITLE,
      payload: NewStateEditNotificationCategoryTitle
    }
  },

  changeStateEditNotificationCategoryList(NewStateEditNotificationCategoryList) {
    return {
      type: constants.ACTION_CHANGE_STATE_EDIT_NOTIFICATION_CATEGORY_LIST,
      payload: NewStateEditNotificationCategoryList
    }
  },

  changeStateDangerInputAddTypeNotification(NewStateDangerInputAddTypeNotification) {
    return {
      type: constants.ACTION_CHANGE_STATE_DANGER_INPUT_ADD_TYPE_NOTIFICATION,
      payload: NewStateDangerInputAddTypeNotification
    }
  },

  changeAddTypeNotificationStatus(NewAddTypeNotificationStatus) {
    return {
      type: constants.ACTION_CHANGE_ADD_TYPE_NOTIFICATION_STATUS,
      payload: NewAddTypeNotificationStatus
    }
  },

  changeStateWindowOpenMailing(NewstateWindowOpenMailing) {
    return {
      type: constants.ACTION_CHANGE_STATE_WINDOW_OPEN_MAILING,
      payload: NewstateWindowOpenMailing
    }
  },

  changeStateWindowOpenNewMailing(NewstateWindowOpenNewMailing) {
    return {
      type: constants.ACTION_CHANGE_STATE_WINDOW_OPEN_NEW_MAILING,
      payload: NewstateWindowOpenNewMailing
    }
  },

  changeStateWindowOpenQuestion(NewstateWindowOpenQuestion) {
    return {
      type: constants.ACTION_CHANGE_STATE_WINDOW_OPEN_QUESTION,
      payload: NewstateWindowOpenQuestion
    }
  },

  changeMailingData(NewMailingData) {
    return {
      type: constants.ACTION_CHANGE_MAILING_DATA,
      payload: NewMailingData
    }
  },

  changeMailingDataActive(NewMailingDataActive) {
    return {
      type: constants.ACTION_CHANGE_MAILING_DATA_ACTIVE,
      payload: NewMailingDataActive
    }
  },

  changeMailingDataNotActive(NewMailingDataNotActive) {
    return {
      type: constants.ACTION_CHANGE_MAILING_DATA_NOT_ACTIVE,
      payload: NewMailingDataNotActive
    }
  },

  changeQuestionsDataActive(NewQuestionsDataActive) {
    return {
      type: constants.ACTION_CHANGE_QUESTIONS_DATA_ACTIVE,
      payload: NewQuestionsDataActive
    }
  },

  changeQuestionsDataNotActive(NewQuestionsDataNotActive) {
    return {
      type: constants.ACTION_CHANGE_QUESTIONS_DATA_NOT_ACTIVE,
      payload: NewQuestionsDataNotActive
    }
  },

  changeNotificationCategoriesList(NewNotificationCategoriesList) {
    return {
      type: constants.ACTION_CHANGE_NOTIFICATION_CATERIES_LIST,
      payload: NewNotificationCategoriesList
    }
  },

  changeActiveColorTypeOfNotification(NewActiveColorTypeOfNotification) {
    return {
      type: constants.ACTION_CHANGE_ACTIVE_COLOR_TYPE_OF_NOTIFICATION,
      payload: NewActiveColorTypeOfNotification
    }
  },

  changeStateCreateNotifacationTypes(NewStateCreateNotifacationTypes) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_NOTIFICATION_TYPES,
      payload: NewStateCreateNotifacationTypes
    }
  },

  changeStateCreateNotifacationDate(NewStateCreateNotifacationDate) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_NOTIFICATION_DATE,
      payload: NewStateCreateNotifacationDate
    }
  },

  changeStateCreateNotifacationTime(NewStateCreateNotifacationTime) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_NOTIFICATION_TIME,
      payload: NewStateCreateNotifacationTime
    }
  },

  changeStateCreateNotifacationDateEnd(NewStateCreateNotifacationDateEnd) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_NOTIFICATION_DATE_END,
      payload: NewStateCreateNotifacationDateEnd
    }
  },

  changeStateCreateNotifacationTimeEnd(NewStateCreateNotifacationTimeEnd) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_NOTIFICATION_TIME_END,
      payload: NewStateCreateNotifacationTimeEnd
    }
  },

  changeStateCreateNotifacationMessage(NewStateCreateNotifacationMessage) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_NOTIFICATION_MESSAGE,
      payload: NewStateCreateNotifacationMessage
    }
  },
  changeStateCreateQuestionsTitle(NewStateCreateQuestionsTitle) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_QUESTION_TITLE,
      payload: NewStateCreateQuestionsTitle
    }
  },

  changeStateCreateQuestionsAnswerOptions(NewStateCreateQuestionsAnswerOptions) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_QUESTION_ANSWER_OPTIONS,
      payload: NewStateCreateQuestionsAnswerOptions
    }
  },

  changeStateCreateQuestionsAnswerOption(NewStateCreateQuestionsAnswerOption) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_QUESTION_ANSWER_OPTION,
      payload: NewStateCreateQuestionsAnswerOption
    }
  },

  changeStateCreateQuestionsStatistics(NewStateCreateQuestionsStatistics) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_QUESTION_STATISTICS,
      payload: NewStateCreateQuestionsStatistics
    }
  },

  changeStateCreateQuestionsAnswersTotal(NewStateCreateQuestionsAnswersTotal) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_QUESTION_ANSWER_TOTAL,
      payload: NewStateCreateQuestionsAnswersTotal
    }
  },

  changeStateCreateQuestionsMultipleAnswers(NewStateCreateQuestionsMultipleAnswers) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_QUESTION_MULTIPLE_ANSWERS,
      payload: NewStateCreateQuestionsMultipleAnswers
    }
  },

  changeStateCreateQuestionsActiveTill(NewStateCreateQuestionsActiveTill) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_QUESTION_ACTIVE_TILL,
      payload: NewStateCreateQuestionsActiveTill
    }
  },

  changeStateCreateNotifacationSatusNotification(NewStateCreateNotifacationStatusNotification) {
    return {
      type: constants.ACTION_CHANGE_STATE_CREATE_NOTIFICATION_STATUS_NOTIFICATION,
      payload: NewStateCreateNotifacationStatusNotification
    }
  },

  changeStateOpenNotifacationSatusNotification(NewStateOpenNotifacationStatusNotification) {
    return {
      type: constants.ACTION_CHANGE_STATE_OPEN_NOTIFICATION_STATUS_NOTIFICATION,
      payload: NewStateOpenNotifacationStatusNotification
    }
  },
  changeStateOpenNotifacationSatusQuestions(NewStateOpenNotifacationStatusQuestions) {
    return {
      type: constants.ACTION_CHANGE_STATE_OPEN_NOTIFICATION_STATUS_QUESTIONS,
      payload: NewStateOpenNotifacationStatusQuestions
    }
  },

  changeStateStatusQuestionsEnd(NewStateStatusQuestionsEnd) {
    return {
      type: constants.ACTION_CHANGE_STATE_OPEN_NOTIFICATION_STATUS_QUESTIONS_END,
      payload: NewStateStatusQuestionsEnd
    }
  },

  changeStateStatusCustomAnswers(NewStateStatusCustomAnswers) {
    return {
      type: constants.ACTION_CHANGE_STATE_STATUS_CUSTOM_ANSWERS,
      payload: NewStateStatusCustomAnswers
    }
  },

  changeStateOpenNotifacationStatusPrivatePublication(NewStateOpenNotifacationStatusPrivatePublication) {
    return {
      type: constants.ACTION_CHANGE_STATE_OPEN_NOTIFICATION_STATUS_PRIVATE_PUBLICATION,
      payload: NewStateOpenNotifacationStatusPrivatePublication
    }
  },

  changeStateCreateNotifacationListUsersOnSearch(NewStateCreateNotifacationListUsersOnSearch) {
    return {
      type: constants.ACTION_CHANGE_STATE_OPEN_NOTIFICATION_LIST_USERS_ON_SEARCH,
      payload: NewStateCreateNotifacationListUsersOnSearch
    }
  },

  changeStatusSendDataNotificationOnServer(NewStatusSendDataNotificationOnServer) {
    return {
      type: constants.ACTION_CHANGE_STATUS_SEND_DATA_NOTIFICATION_ON_SERVER,
      payload: NewStatusSendDataNotificationOnServer
    }
  },

  changeStateEditNotifacationId(NewStateEditNotifacationId) {
    return {
      type: constants.ACTION_CHANGE_STATE_EDIT_NOTIFICATION_ID,
      payload: NewStateEditNotifacationId
    }
  },

  changeStateEditNotifacationEtag(NewStateEditNotifacationEtag) {
    return {
      type: constants.ACTION_CHANGE_STATE_EDIT_NOTIFICATION_ETAG,
      payload: NewStateEditNotifacationEtag
    }
  },

  changeStateEditQuestionsId(NewStateEditQuestionsId) {
    return {
      type: constants.ACTION_CHANGE_STATE_EDIT_QUESTIONS_ID,
      payload: NewStateEditQuestionsId
    }
  },

  changeStateEditQuestionsEtag(NewStateEditQuestionsEtag) {
    return {
      type: constants.ACTION_CHANGE_STATE_EDIT_QUESTIONS_ETAG,
      payload: NewStateEditQuestionsEtag
    }
  },

  // Подсветка заполнения инпутов в Рассылке

  changeStateDangerInputCreateNotificationMessage(NewState) {
    return {
      type: constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_NOTIFICATION_MESSAGE,
      payload: NewState
    }
  },
  changeStateDangerInputCreateNotificationTypeNotification(NewState) {
    return {
      type: constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_NOTIFICATION_TYPE_NOTIFICATION,
      payload: NewState
    }
  },
  changeStateDangerInputCreateNotificationDate(NewState) {
    return {
      type: constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_NOTIFICATION_DATE,
      payload: NewState
    }
  },
  changeStateDangerInputCreateNotificationTime(NewState) {
    return {
      type: constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_NOTIFICATION_TIME,
      payload: NewState
    }
  },

  // Apartments

  changeApartmentsData(NewApartmentsData) {
    return {
      type: constants.ACTION_CHANGE_APARTMENTS_DATA,
      payload: NewApartmentsData
    }
  },

  // Pass

  changeStateWindowOpenPassItem(NewStateWindowOpenPassItem) {
    return {
      type: constants.ACTION_CHANGE_STATE_WINDOW_OPEN_PASS_ITEM,
      payload: NewStateWindowOpenPassItem
    }
  },

  changePassData(NewPassData) {
    return {
      type: constants.ACTION_CHANGE_PASS_DATA,
      payload: NewPassData
    }
  },

  changePassItemDateComming(NewPassItemDateComming) {
    return {
      type: constants.ACTION_CHANGE_PASS_ITEM_DATA_COMMING,
      payload: NewPassItemDateComming
    }
  },

  changePassItemDateUpdate(NewPassItemDateUpdate) {
    return {
      type: constants.ACTION_CHANGE_PASS_ITEM_DATA_UPDATE,
      payload: NewPassItemDateUpdate
    }
  },

  changePassItemPlate(NewPassItemPlate) {
    return {
      type: constants.ACTION_CHANGE_PASS_ITEM_PLATE,
      payload: NewPassItemPlate
    }
  },

  changePassItemUser(NewPassItemUser) {
    return {
      type: constants.ACTION_CHANGE_PASS_ITEM_USER,
      payload: NewPassItemUser
    }
  },
  changePassItemApartId(NewPassItemApartId) {
    return {
      type: constants.ACTION_CHANGE_PASS_ITEM_APART_ID,
      payload: NewPassItemApartId
    }
  },
  changePassItemApartNum(NewPassItemApartNum) {
    return {
      type: constants.ACTION_CHANGE_PASS_ITEM_APART_NUM,
      payload: NewPassItemApartNum
    }
  },

  changePassItemDateStatus(NewPassItemStatus) {
    return {
      type: constants.ACTION_CHANGE_PASS_ITEM_STATUS,
      payload: NewPassItemStatus
    }
  },

  changePassItemId(NewPassItemId) {
    return {
      type: constants.ACTION_CHANGE_PASS_ITEM_ID,
      payload: NewPassItemId
    }
  },

  changePassItemEtag(NewPassItemEtag) {
    return {
      type: constants.ACTION_CHANGE_PASS_ITEM_ETAG,
      payload: NewPassItemEtag
    }
  },

  changeAccountsMainStateHiden(NewAccountsMainStateHiden) {
    return {
      type: constants.ACTION_CHANGE_ACCOUNTS_MAIN_STATE_HIDEN,
      payload: NewAccountsMainStateHiden
    }
  },

  // Личный кабинет

  changesStateWindowOpenNewOperator(NewStateWindowOpenNewOperator) {
    return {
      type: constants.ACTION_CHANGE_STATE_WINDOW_OPEN_NEW_OPERATOR,
      payload: NewStateWindowOpenNewOperator
    }
  },

  changesStateWindowOpenEditOperator(NewStateWindowOpenEditOperator) {
    return {
      type: constants.ACTION_CHANGE_STATE_WINDOW_EDIT_OPERATOR,
      payload: NewStateWindowOpenEditOperator
    }
  },

  changesStateLkNameuser(NewStateLkNameuser) {
    return {
      type: constants.ACTION_CHANGE_STATE_LK_NAMEUSER,
      payload: NewStateLkNameuser
    }
  },

  changesStateLkEmailuser(NewStateLkEmailuser) {
    return {
      type: constants.ACTION_CHANGE_STATE_LK_EMAILUSER,
      payload: NewStateLkEmailuser
    }
  },

  changesStateLkImageluser(NewStateLkImageuser) {
    return {
      type: constants.ACTION_CHANGE_STATE_LK_IMAGEUSER,
      payload: NewStateLkImageuser
    }
  },

  changesStateLkEmailuserChangeStatus(NewStateLkEmailuserChangeStatus) {
    return {
      type: constants.ACTION_CHANGE_STATE_LK_EMAILUSER_CHANGE_STATUS,
      payload: NewStateLkEmailuserChangeStatus
    }
  },

  changesStateLkListoperatorsOpen(NewStateLkListoperatorsOpen) {
    return {
      type: constants.ACTION_CHANGE_STATE_LK_LISTOPERATORS_OPEN,
      payload: NewStateLkListoperatorsOpen
    }
  },

  changesStateLkPhotouser(NewStateLkPhotouser) {
    return {
      type: constants.ACTION_CHANGE_STATE_LK_PHOTOUSER,
      payload: NewStateLkPhotouser
    }
  },

  changedDataOperators(NewDataOperators) {
    return {
      type: constants.ACTION_CHANGE_DATA_OPERATORS,
      payload: NewDataOperators
    }
  },

  changeStatePassPage(NewStatePassPage) {
    return {
      type: constants.ACTION_CHANGE_STATE_PASS_PAGE,
      payload: NewStatePassPage
    }
  },

  changeStateDangerInputEmailuserChangeStatus(NewStateDangerInputEmailuserChangStatus) {
    return {
      type: constants.ACTION_CHANGE_STATE_DANGER_INPUT_EMAILUSER_CHANGE_STATUS,
      payload: NewStateDangerInputEmailuserChangStatus
    }
  },

  changeIsLoadMailingMain(NewIsLoadMailingMain) {
    return {
      type: constants.ACTION_CHANGE_IS_LOAD_MAILING_MAIN,
      payload: NewIsLoadMailingMain
    }
  },
  changeIsLoadMailingMenu(NewIsLoadMailingMenu) {
    return {
      type: constants.ACTION_CHANGE_IS_LOAD_MAILING_MENU,
      payload: NewIsLoadMailingMenu
    }
  }
}
