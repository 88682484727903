import React, { Component } from 'react';
import axios from 'axios';
import * as configs from '../../../../../etc/config.json';

import ApartFields from './ApartFields';

class ApartInfo extends Component {
  constructor(props) {
    super(props),
    this.state = {
      floors: null
    }
  }

  componentDidMount = () => {
    this.getFloors('floors').then(items => {

      this.setState({
        ...this.state,
        floors: [ ...items.data._items ]
      })
    }).catch( err => this.props.handlerResponseError(err, this.getFloors.bind(this, 'floors')) );
  }

  getFloors = getPath => {
    let url = `${configs.default.hostApiServer}:${configs.default.portApiServer}`;
    return this.props.request.get(`${url}/admin/${getPath}`);
  }


  render() {
    return (
      <ApartFields
        apart={this.props.apart}
        handlerChange={this.props.handlerChange}
        floorsList={this.state.floors}
      />
    );
  }
}

export default ApartInfo;