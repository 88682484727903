import React from 'react';

import TextareaJSON from './TextareaJSON';

import './styles.css';

export default props => {
  return (
    <div className='apart-configure__buttons'>
      <div
        className='apart-configure__button apart-configure__add-room'
        onClick={props.handlerAddRoom}
      >Добавить комнату</div>

      <TextareaJSON data={JSON.parse(JSON.stringify(props.state))} />

      <div className='apart-configure__json-buttons'>
        <div
          className='apart-configure__button apart-configure__button_download'
          onClick={props.downloadJSON}
        >Скачать JSON</div>

        <div className='apart-configure__button' onClick={() => document.getElementById('json-actions').click()}>Загрузить JSON</div>
        <input
          id='json-actions'
          type='file'
          accept='application/json'
          onChange={ data => data !== undefined && props.loadJSON(data.target.files[0]) }
        />
      </div>

      <div
        className='apart-configure__button apart-configure__post-apart'
        onClick={props.initialState !== undefined && props.initialState !== null ? props.handlerPutApart : props.handlerPostApart}
      >{ props.initialState !== undefined && props.initialState !== null ? 'Сохранить' : 'Отправить' }</div>
    </div>
  )
}