import React, { useState } from 'react';

export default props => {
    const { name, value, onChange, className = '', placeholder, required } = props;
    const [text, setText] = useState(value);

    function update(event) {
        setText(event.target.value);
        if (typeof onChange === "function") {
            onChange(event);
        }
    }

    return (
        <input
            type="text"
            className={`input ${className}`}
            name={name}
            value={value}
            onChange={update}
            onClick={e => e.stopPropagation()}
            placeholder={placeholder}
            required={required}
        />
    );
}