import React from 'react';

import Mode from './Mode';
import OperationMode from './OperationMode';
import Ventilation from './Ventilation';

import DefaultInput from '../../../../../../../DumbComponents/DefaultInput';

import './styles.css';

const conditionerInitState = {
  power: {
    topic_power: '',
  },
};

export default props => {
  const selected = !!props.item.conditioner;
  const path = ['rooms', props.roomIndex, 'devicesEndpoints', 'climate'];

  const handlerClick = () => {
    !selected && props.handlerChange(...path)(null, 'conditioner', conditionerInitState );
    selected && props.handlerChange(...path)(null, 'conditioner', { clear: true });
  }

  return (
    <div className='params-item_conditioner'>
      <div className='room__checkbox room__checkbox_conditioner'>
        <label><input type='checkbox' onChange={() => handlerClick()} checked={selected} />Кондиционер</label>
      </div>

      {selected &&
        <div>
          <div className='params-item__field'>
            <span className='params-field__title'>Топик включения</span>
            <DefaultInput
              name='topic_power'
              value={props.item.conditioner.power.topic_power}
              onChange={props.handlerChange(...path, 'conditioner', 'power')}
              placeholder='topic_power'
              required={!props.item.conditioner.power.topic_power}
            />
          </div>
          <div className='params-item__field'>
            <span className='params-field__title'>Фидбэк включения</span>
            <DefaultInput
              name='topic_power_fb'
              value={props.item.feedbacks.topic_power_fb}
              onChange={props.handlerChange(...path, 'feedbacks')}
              placeholder='topic_power_fb'
              required={!props.item.feedbacks.topic_power_fb}
            />
          </div>
          <Mode item={props.item} roomIndex={props.roomIndex} handlerChange={props.handlerChange} />
          <Ventilation item={props.item} roomIndex={props.roomIndex} handlerChange={props.handlerChange} />
          <OperationMode item={props.item} roomIndex={props.roomIndex} handlerChange={props.handlerChange} />
        </div>
      }
    </div>
  );
}