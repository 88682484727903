import React from 'react';

import CurtainsParams from './CurtainsParams';

// import './styles.css';

const blindsInitState = {
  title: '',
  name: '',
  topic_open: '',
  topic_close: '',
  topic_stop: ''
}

export default props => {
  const selected = (props.items.length !== 0);
  const path = ['rooms', props.roomIndex, 'devicesEndpoints'];

  const handlerClick = () => {
    !selected && props.handlerChange(...path)(null, 'blinds', blindsInitState );
    selected && props.handlerChange(...path)(null, 'blinds', { clear: true });
  }

  return (
    <div className='room__block'>
      <div className='room__checkbox room__checkbox_curtains'>
        <label><input onChange={() => handlerClick()} type='checkbox' checked={selected} />Шторы</label>
      </div>
      { selected &&
          (
            <div className='params params_blinds'>
              {props.items.map((item, i) =>
                <div key={i} >
                  <CurtainsParams key={i} roomIndex={props.roomIndex} itemIndex={i} item={item} handlerChange={props.handlerChange} />
                  { props.items.length > 1 && <div className='params__button-remove' onClick={() => props.handlerRemoveGroup('blinds', i)}>Удалить шторы</div> }
                </div>)}
              <div
                className='room__button_add'
                onClick={() => props.handlerAddGroup('blinds', blindsInitState)}
              >Добавить шторы</div>
            </div>
          )
      }
    </div>
  );
}