import React from 'react';

import SocketParam from './SocketParam';

// import './styles.css';

const socketsInitState = {
  title: '',
  name: '',
  topic_switch: '',
  feedbacks: {
    topic_switch_fb: ''
  }
};

export default props => {
  const selected = props.items.length !== 0;
  const path = ['rooms', props.roomIndex, 'devicesEndpoints'];

  const handlerClick = () => {
    !selected && props.handlerChange(...path)(null, 'sockets', socketsInitState );
    selected && props.handlerChange(...path)(null, 'sockets', { clear: true });
  }

  return (
    <div className='room__block'>
      <div className='room__checkbox room__checkbox_sockets'>
        <label><input onChange={() => handlerClick()} type='checkbox' checked={selected} />Розетки</label>
      </div>
      { selected &&
          (
            <div className='params params_scenes'>
              {props.items.map((item, i) =>
                <div key={i} >
                  <SocketParam key={i} roomIndex={props.roomIndex} itemIndex={i} item={item} handlerChange={props.handlerChange} />
                  { props.items.length > 1 && <div className='params__button-remove' onClick={() => props.handlerRemoveGroup('sockets', i)}>Удалить розетку</div> }
                </div>)}
              <div
                className='room__button_add'
                onClick={() => props.handlerAddGroup('sockets', socketsInitState)}
              >Добавить розетку</div>
            </div>
          )
      }
    </div>
  );
}