import React, { Component } from "react";

import { connect } from "react-redux";
import { _refreshToken } from "./App_Main/Refresh/Refresh";
import dispatch from "../store/dispatch.js";

import cookieAction from "../cookieAction";

import App_left_column from "./App_left-column/App_left_column.js";
import App_right_column from "./App_right-column/App_right_column.js";
import App_Main from "./App_Main/App_Main.js";
import Img from "./Img/Img.js";
import OpenGraphic from "./OpenGraphic/OpenGraphic.js";

import api from "../api/index.js";

class MainComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {
        this.getAccountApi();
    }

    shouldComponentUpdate(nextProps, nextState) {
        // if (nextProps.) {

        // }
        return true
    }
    render() {
        return (
            <div className="flex-container">
                {this.props.stateWindowServicerequests.imgOpen ===
                    true ? (
                        <Img
                            imgOpen={
                                this.props.stateWindowServicerequests
                                    .imgOpen
                            }
                            imgSrc={
                                this.props.stateWindowServicerequests.imgSrc
                            }
                            changeImgOpen={this.props.changeImgOpen}
                        />
                    ) : null}
                {this.props.chartDataGraphicOpen ===
                    true ? (
                        <OpenGraphic chartData={this.props.chartDataGraphic} changeChartDataGraphicOpen={this.props.changeChartDataGraphicOpen} />
                    ) : null}
                <App_left_column />
                <App_Main />
                <App_right_column />
            </div>
        )
    }
    getAccountApi = async () => {
        // console.log("getAccountApi", cookieAction.getCookie("AI"));
        const accountId = cookieAction.getCookie("AI");
        const access_token = cookieAction.getCookie("AT");
        
        if (accountId !== undefined && !this.state.isLoadPhoto) {
            await api
                .getAccount(
                    accountId,
                    access_token,
                    this.props.LOGIN_TYPE_USER
                )
                .then(async getAccount => {
                    // console.log("getAccount", getAccount.data);
                    this.props.changeUserData(getAccount.data);
                    await api.getImage(getAccount.data.image, this.props.access_token)
                        .then(getImage => {
                            // console.log("getImage: ", getImage.data);

                            let img_file = `data:${
                                getImage.data.image.content_type
                                };base64, ${getImage.data.image.file}`;
                            // console.log('img_file', img_file);

                            this.props.changeLeftColumnImg(img_file);
                            this.setState({
                                isLoadPhoto: true
                            });
                        })
                        .catch(error => {
                            console.log(error);
                            if (error.response.status === 401) {
                                _refreshToken(
                                    this.props.client_id,
                                    this.props.LOGIN,
                                    this.props.PASSWORD,
                                    this.props.changeAccessToken,
                                    this.getAccountApi.bind(this)
                                );
                            } else {
                                // console.log('====================================')
                                // console.log('getImage: ', error.response)
                                // console.log('====================================')
                            }
                        });

                })
                .catch(err => {
                    console.log(err);
                    console.log(err.response);
                    if (
                        err.response.status === 401
                    ) {
                       console.log('====================================')
                       console.log(this.props.LOGIN,
                        this.props.PASSWORD)
                       console.log('====================================')
                        _refreshToken(
                            this.props.client_id,
                            this.props.LOGIN,
                            this.props.PASSWORD,
                            this.props.changeAccessToken,
                            this.getAccountApi.bind(this)
                        );
                    }
                });
        }

    };
}



export default connect(
    dispatch.mapStateToProps,
    dispatch.mapDispatchToProps
)(MainComponent);