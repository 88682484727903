import React, { Component } from 'react';

import { connect } from 'react-redux';
import { _refreshToken } from '../../Refresh/Refresh';
import dispath from '../../../../store/dispatch.js';
import api from '../../../../api';

import './LkOperatorsList.css';

import LkOperatorsListItem from "./LkOperatorsListItem/LkOperatorsListItem.js";

class LkOperatorsList extends Component {

    onScrollList(event) {
        const scrollBottom = event.target.scrollTop +
            event.target.offsetHeight == event.target.scrollHeight;

        if (scrollBottom) {
            this.loadContent(); //API method
        }
    }

    loadContent() {
        api.getAccounts(this.props.access_token, this.props.LOGIN_TYPE_USER, this.props.mainName, 1)
            .then(
                (response) => {
                    if (this.props.state_account_page === Math.ceil(response.data._meta.total / 25)) {
                        return;
                    } else if (response.data._meta.total > 25 && this.props.state_account_page !== Math.ceil(response.data._meta.total / 25)) {
                        this.props.changeStateAccountPage(this.props.state_account_page + 1);
                       
                        api.getAccounts(this.props.access_token, this.props.LOGIN_TYPE_USER, this.props.mainName, this.props.state_account_page)
                            .then(
                                (res) => {
                                    this.props.changeAccountData(this.props.accountData.concat(res.data._items));
                                }
                            )
                            .catch(
                                (err) => {
                                    console.log(err.response);
                                    if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
                                        _refreshToken(
                                            this.props.client_id,
                                            this.props.LOGIN,
                                            this.props.PASSWORD,
                                            this.props.changeAccessToken,
                                            this.loadContent.bind(this)
                                        )
                            
                                    }
                                }
                            )
                    }
                })
            .catch((err) => {
                console.log(err.response);
                if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

                    _refreshToken(
                        this.props.client_id,
                        this.props.LOGIN,
                        this.props.PASSWORD,
                        this.props.changeAccessToken,
                        this.loadContent.bind(this)
                    )
                }
            });
    }

    render() {
        return (
            <div className="lk-operators-list h100">
               <div className="accounts_main_titles">
                    <ul className="accounts_main_titles_ul flex label">
                        <li className="accounts_main_titles_li w100">Имя Фамилия</li>
                        {/* <li className="accounts_main_titles_li w100">Апартамент</li> */}
                        <li className="accounts_main_titles_li w100">Телефон</li>
                        <li className="accounts_main_titles_li w50"></li>
                    </ul>
                </div>
                <div
                    className="scroll operarors-scrol-elem flex_column_full"
                    onScroll={event => this.onScrollList(event)}
                >
                    {
                        this.props.dataOperators !== undefined
                        ?
                        this.props.dataOperators.map(
                            data_items => <LkOperatorsListItem key={data_items.apartment_id + data_items._id} data_items={data_items} />
                        )
                        :
                        null
                    }
                </div>
            </div>
        );
    };
};

export default connect(dispath.mapStateToProps, dispath.mapDispatchToProps)(LkOperatorsList);