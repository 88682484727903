import React from 'react';

import SecurityParams from './SecurityParams';

// import './styles.css';
// security_sensors
const securInitState = {
  'title': '',
  'name': '',
  'topic_type': [],
  'topic_feedback': ''
};

export default props => {
  const selected = props.items.length !== 0;
  const path = ['rooms', props.roomIndex, 'devicesEndpoints'];

  const handlerClick = () => {
    !selected && props.handlerChange(...path)(null, 'security_sensors', JSON.parse(JSON.stringify(securInitState)));
    selected && props.handlerChange(...path)(null, 'security_sensors', { clear: true });
  }

  return (
    <div className='room__block'>
      <div className='room__checkbox room__checkbox_security-sensors'>
        <label><input onChange={() => handlerClick()} type='checkbox' checked={selected} />Безопасность</label>
      </div>
      { selected &&
          (
            <div className='params params_security-sensors'>
              { props.items.map((item, i) =>
                <div key={i} >
                  <SecurityParams roomIndex={props.roomIndex} itemIndex={i} item={item} handlerChange={props.handlerChange} />
                  { props.items.length > 1 && <div className='params__button-remove' onClick={() => props.handlerRemoveGroup('security_sensors', i)}>Удалить датчик</div> }
                </div>) }
              <div
                className='room__button_add'
                onClick={() => props.handlerAddGroup('security_sensors', securInitState)}
              >Добавить датчик безопасности</div>
            </div>
          )
      }
    </div>
  );
}