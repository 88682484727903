import constants from '../constants';
import { initialState } from './initialState.js';

export const rootReducer = (state = initialState, action) => {
  switch (action.type) {

    case constants.ACTION_CHANGE_USER_IMG:
      return { ...state, userImg: action.payload }

    case constants.ACTION_CHANGE_LEFT_COLUMN_IMG:
      return { ...state, userLeftColumnImg: action.payload }

    case constants.ACTION_CHANGE_LK_USER_IMG_FILE:
      return { ...state, userLkUserImgFile: action.payload }

    case constants.ACTION_CHANGE_USER_DATA:
      return { ...state, userData: action.payload }

    case constants.ACTION_CHANGE_SERVICE_REQUEST_DATA:
      return { ...state, servicerequestData: action.payload }

    case constants.ACTION_CHANGE_USER_MENU_OPEN_STATE:
      return { ...state, userMenuOpenState: action.payload }

    case constants.ACTION_CHANGE_LOGIN:
      return { ...state, LOGIN: action.payload }

    case constants.ACTION_CHANGE_PASSWORD:
      return { ...state, PASSWORD: action.payload }

    case constants.ACTION_CHANGE_STATE_DANGER_INPUT_LOGIN:
      return { ...state, state_danger_input_login: action.payload }

    case constants.ACTION_CHANGE_STATE_DANGER_INPUT_PASSWORD:
      return { ...state, state_danger_input_password: action.payload }

    case constants.ACTION_CHANGE_STATUS_LOGIN:
      return { ...state, STATUS_LOGIN: action.payload }

    case constants.ACTION_CHANGE_ACCESS_TOKEN:
      return { ...state, access_token: action.payload }

    case constants.ACTION_CHANGE_ACCOUNT_ID:
      return { ...state, account_id: action.payload }
    case constants.ACTION_CHANGE_CLIENT_ID:
      return { ...state, client_id: action.payload }
    case constants.ACTION_CHANGE_REAL_CLIENT_ID:
      return { ...state, real_client_id: action.payload }
    case constants.ACTION_CHANGE_STATE_SEARCH_ACCOUNTS:
      return { ...state, state_search_accounts: action.payload }
    case constants.ACTION_CHANGE_STATE_SEARCH_MAILING:
      return { ...state, state_search_mailing: action.payload }
    case constants.ACTION_CHANGE_STATE_SEARCH_SERVICES:
      return { ...state, state_search_services: action.payload }
    case constants.ACTION_CHANGE_EXPIRES:
      return { ...state, expires: action.payload }
    case constants.ACTION_CHANGE_REFRESH_TOKEN:
      return { ...state, refresh_token: action.payload }
    case constants.ACTION_CHANGE_USER_NAME:
      return { ...state, username: action.payload }
    case constants.ACTION_CHANGE_USER_ID:
      return { ...state, user_id: action.payload }

    case constants.ACTION_LOGIN_TYPE_USER:
      return { ...state, LOGIN_TYPE_USER: action.payload }

    //
    case constants.ACTION_CHANGE_STATE_ACCOUNT_PAGE:
      return { ...state, state_account_page: action.payload }

    case constants.ACTION_CHANGE_STATE_ACCOUNT_NAME:
      return { ...state, state_account_name: action.payload }

    case constants.ACTION_CHANGE_STATE_ACCOUNT_TYPE:
      return { ...state, state_account_type: action.payload }

    case constants.ACTION_CHANGE_STATE_ACCOUNT_TEL:
      return { ...state, state_account_tel: action.payload }

    case constants.ACTION_CHANGE_STATE_ACCOUNT_EMAIL:
      return { ...state, state_account_email: action.payload }

    case constants.ACTION_CHANGE_STATE_ACCOUNT_NUM_APART:
      return { ...state, state_account_numApart: action.payload }

    case constants.ACTION_CHANGE_STATE_ACCOUNT_SIZE_APART:
      return { ...state, state_account_sizeApart: action.payload }

    case constants.ACTION_CHANGE_STATE_ACCOUNT_USER_PHOTO:
      return { ...state, state_account_user_photo: action.payload }

    case constants.ACTION_CHANGE_STATE_ACCOUNT_PASSPORT_APART:
      return { ...state, state_account_passportApart: action.payload }
    //

    case constants.ACTION_CHANGE_MAIN:
      return { ...state, mainName: action.payload }

    case constants.ACTION_CHANGE_IMG_OPEN:
      return { ...state, stateWindowServicerequests: { requestOpen: state.stateWindowServicerequests.requestOpen, imgOpen: action.payload.NewImgOpenState, imgSrc: action.payload.NewAttachment } }

    case constants.ACTION_CHANGE_SERVICE_REQUESTS:
      return { ...state, servicerequests: action.payload }

    case constants.ACTION_CHANGE_SERVICE_REQUEST:
      return { ...state, servicerequestData: action.payload }

    case constants.ACTION_CHANGE_STATE_WINDOW_SERVICE_REQUEST:
      return { ...state, stateWindowServicerequests: { requestOpen: action.payload, imgOpen: state.stateWindowServicerequests.imgOpen, imgSrc: state.stateWindowServicerequests.imgSrc } }

    case constants.ACTION_CHANGE_ACCOUNT_DATA:
      return { ...state, accountData: action.payload }

    case constants.ACTION_CHANGE_STATE_WINDOW_ACCOUNTS:
      return { ...state, stateWindowAccounts: { accountOpen: action.payload.NewStateWindowAccounts, accountDataPersonal: action.payload.NewpersonalInfo, accountEdit: state.stateWindowAccounts.accountEdit } }

    case constants.ACTION_CHANGE_STATE_WINDOW_ACCOUNT_EDIT:
      return { ...state, stateWindowAccounts: { accountOpen: state.stateWindowAccounts.accountOpen, accountDataPersonal: state.stateWindowAccounts.accountDataPersonal, accountEdit: action.payload } }

    //

    case constants.ACTION_CHANGE_STATUS_SEND_ACCOUNT_ON_SERVER:
      return { ...state, statusSendDataAccountOnServer: action.payload }

    case constants.ACTION_CHANGE_STATUS_SEND_SERVICES_ON_SERVER:
      return { ...state, statusSendDataServicesOnServer: action.payload }

    case constants.ACTION_CHANGE_SERVICES_PHOTO_FOR_SAVE:
      return { ...state, services_photo_for_save: action.payload }

    case constants.ACTION_CHANGE_SERVICES_CATEGORY_PHOTO_FOR_SAVE:
      return { ...state, services_category_photo_for_save: action.payload }



    case constants.ACTION_CHANGE_STATE_EDIT_ACCOUNT_ID:
      return { ...state, stateEditAccountId: action.payload }

    case constants.ACTION_CHANGE_STATE_EDIT_ACCOUNT_ETAG:
      return { ...state, stateEditAccountEtag: action.payload }

    //
    case constants.ACTION_CHANGE_APARTMENT_NUM:
      return { ...state, apartment_num: action.payload }

    case constants.ACTION_CHANGE_APARTMENT_SIZE:
      return { ...state, apartment_size: action.payload }

    case constants.ACTION_CHANGE_APARTMENT_PASPORT:
      return { ...state, apartment_pasport: action.payload }

    case constants.ACTION_CHANGE_USER_PHOTO:
      return { ...state, user_photo: action.payload }

    case constants.ACTION_CHANGE_USER_PHOTO_FOR_SAVE:
      return { ...state, user_photo_for_save: action.payload }



    case constants.ACTION_CHANGE_STATE_CREATE_USER_SECONDNAME:
      return { ...state, state_create_user_secondName: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_USER_FIRSTNAME:
      return { ...state, state_create_user_firstName: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_USER_THIRDNAME:
      return { ...state, state_create_user_thirdName: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_USER_TEL:
      return { ...state, state_create_user_tel: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_USER_TYPE_USER:
      return { ...state, state_create_user_typeUser: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_USER_EMAIL:
      return { ...state, state_create_user_email: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_USER_PHOTO:
      return { ...state, state_create_user_photo: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_USER_IMG_PASSPORT:
      return { ...state, state_create_user_imgPassport: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_USER_NUM_APART:
      return { ...state, state_create_user_numApart: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_USER_SIZE_APART:
      return { ...state, state_create_user_sizeApart: action.payload }

    //

    case constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_FIRSTNAME:
      return { ...state, state_danger_input_user_firstName: action.payload }

    case constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_SECONDNAME:
      return { ...state, state_danger_input_user_secondName: action.payload }

    case constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_THIRDNAME:
      return { ...state, state_danger_input_user_thirdName: action.payload }

    case constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_TEL:
      return { ...state, state_danger_input_user_tel: action.payload }

    case constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_TYPE_USER:
      return { ...state, state_danger_input_user_typeUser: action.payload }

    case constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_EMAIL:
      return { ...state, state_danger_input_user_email: action.payload }

    case constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_PHOTO:
      return { ...state, state_danger_input_user_photo: action.payload }

    case constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_IMG_PASSPORT:
      return { ...state, state_danger_input_user_imgPassport: action.payload }

    case constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_NUM_APART:
      return { ...state, state_danger_input_user_numApart: action.payload }

    case constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_USER_SIZE_APART:
      return { ...state, state_danger_input_user_sizeApart: action.payload }


    case constants.ACTION_CHANGE_ADD_TYPE_NOTIFICATION_STATUS:
      return { ...state, addTypeNotificationStatus: action.payload }

    case constants.ACTION_CHANGE_STATE_ADD_TYPE_NOTIFICATION:
      return { ...state, state_add_type_notifacation: action.payload }

    case constants.ACTION_CHANGE_STATE_EDIT_NOTIFICATION_CATEGORY:
      return { ...state, state_edit_notification_category: action.payload }

    case constants.ACTION_CHANGE_STATE_STATUS_EDIT_NOTIFICATION_CATEGORY:
      return { ...state, state_status_edit_notification_category: action.payload }

    case constants.ACTION_CHANGE_STATE_EDIT_NOTIFICATION_CATEGORY_TITLE:
      return { ...state, state_edit_notification_category_title: action.payload }

    case constants.ACTION_CHANGE_STATE_EDIT_NOTIFICATION_CATEGORY_LIST:
      return { ...state, state_edit_notification_category_list: action.payload }

    case constants.ACTION_CHANGE_DATA_GRAPHIC:
      return { ...state, chartDataGraphic: action.payload }

    case constants.ACTION_CHANGE_DATA_GRAPHIC_OPEN:
      return { ...state, chartDataGraphicOpen: action.payload }

    case constants.ACTION_CHANGE_STATE_DANGER_INPUT_ADD_TYPE_NOTIFICATION:
      return { ...state, state_danger_input_add_type_notifacation: action.payload }

    case constants.ACTION_CHANGE_STATE_WINDOW_OPEN_MAILING:
      return { ...state, stateWindowOpenMailing: action.payload }

    case constants.ACTION_CHANGE_STATE_WINDOW_OPEN_NEW_MAILING:
      return { ...state, stateWindowOpenNewMailing: action.payload }

    case constants.ACTION_CHANGE_STATE_WINDOW_OPEN_QUESTION:
      return { ...state, stateWindowOpenQuestion: action.payload }

    case constants.ACTION_CHANGE_MAILING_DATA:
      return { ...state, mailingData: action.payload }

    case constants.ACTION_CHANGE_MAILING_DATA_ACTIVE:
      return { ...state, mailingDataActive: action.payload }

    case constants.ACTION_CHANGE_MAILING_DATA_NOT_ACTIVE:
      return { ...state, mailingDataNotActive: action.payload }

    case constants.ACTION_CHANGE_QUESTIONS_DATA_ACTIVE:
      return { ...state, questionsDataActive: action.payload }

    case constants.ACTION_CHANGE_QUESTIONS_DATA_NOT_ACTIVE:
      return { ...state, questionsDataNotActive: action.payload }

    case constants.ACTION_CHANGE_NOTIFICATION_CATERIES_LIST:
      return { ...state, notificationCategoriesList: action.payload }


    case constants.ACTION_CHANGE_ACTIVE_COLOR_TYPE_OF_NOTIFICATION:
      return { ...state, state_active_color_type_of_notifacation: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_NOTIFICATION_TYPES:
      return { ...state, state_create_notifacation_types: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_NOTIFICATION_DATE:
      return { ...state, state_create_notifacation_date: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_NOTIFICATION_TIME:
      return { ...state, state_create_notifacation_time: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_NOTIFICATION_DATE_END:
      return { ...state, state_create_notifacation_date_end: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_NOTIFICATION_TIME_END:
      return { ...state, state_create_notifacation_time_end: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_NOTIFICATION_MESSAGE:
      return { ...state, state_create_notifacation_message: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_QUESTION_TITLE:
      return { ...state, state_create_questions_title: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_QUESTION_ANSWER_OPTIONS:
      return { ...state, state_create_questions_answer_options: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_QUESTION_ANSWER_OPTION:
      return { ...state, state_create_questions_answer_option: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_QUESTION_STATISTICS:
      return { ...state, state_create_questions_statistics: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_QUESTION_ANSWER_TOTAL:
      return { ...state, state_create_questions_answer_total: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_QUESTION_MULTIPLE_ANSWERS:
      return { ...state, state_create_questions_multiple_answers: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_QUESTION_ACTIVE_TILL:
      return { ...state, state_create_questions_active_till: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_NOTIFICATION_STATUS_NOTIFICATION:
      return { ...state, state_create_notifacation_status_notifacation: action.payload }

    case constants.ACTION_CHANGE_STATE_OPEN_NOTIFICATION_STATUS_NOTIFICATION:
      return { ...state, state_open_notifacation_status_notifacation: action.payload }

    case constants.ACTION_CHANGE_STATE_OPEN_NOTIFICATION_STATUS_QUESTIONS:
      return { ...state, state_open_notifacation_status_questions: action.payload }

    case constants.ACTION_CHANGE_STATE_OPEN_NOTIFICATION_STATUS_QUESTIONS_END:
      return { ...state, state_status_questions_end: action.payload }

    case constants.ACTION_CHANGE_STATE_STATUS_CUSTOM_ANSWERS:
      return { ...state, state_status_custom_answers: action.payload }

    case constants.ACTION_CHANGE_STATE_OPEN_NOTIFICATION_STATUS_PRIVATE_PUBLICATION:
      return { ...state, state_create_notifacation_status_private_publication: action.payload }

    case constants.ACTION_CHANGE_STATE_OPEN_NOTIFICATION_LIST_USERS_ON_SEARCH:
      return { ...state, state_create_notifacation_list_users_on_search: action.payload }

    case constants.ACTION_CHANGE_STATUS_SEND_DATA_NOTIFICATION_ON_SERVER:
      return { ...state, statusSendDataNotificationOnServer: action.payload }

    case constants.ACTION_CHANGE_STATE_EDIT_NOTIFICATION_ID:
      return { ...state, state_edit_notifacation_id: action.payload }

    case constants.ACTION_CHANGE_STATE_EDIT_NOTIFICATION_ETAG:
      return { ...state, state_edit_notifacation_etag: action.payload }

    case constants.ACTION_CHANGE_STATE_EDIT_QUESTIONS_ID:
      return { ...state, state_edit_questions_id: action.payload }

    case constants.ACTION_CHANGE_STATE_EDIT_QUESTIONS_ETAG:
      return { ...state, state_edit_questions_etag: action.payload }

    // Подсветка заполнения инпутов в Рассылке
    case constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_NOTIFICATION_MESSAGE:
      return { ...state, stateDangerInputCreateNotificationMessage: action.payload }

    case constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_NOTIFICATION_TYPE_NOTIFICATION:
      return { ...state, stateDangerInputCreateNotificationTypeNotification: action.payload }

    case constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_NOTIFICATION_DATE:
      return { ...state, stateDangerInputCreateNotificationDate: action.payload }

    case constants.ACTION_CHANGE_STATE_DANGER_INPUT_CREATE_NOTIFICATION_TIME:
      return { ...state, stateDangerInputCreateNotificationTime: action.payload }

    // Апартаменты

    case constants.ACTION_CHANGE_APARTMENTS_DATA:
      return { ...state, apartmentsData: action.payload }

    // Пропуск

    case constants.ACTION_CHANGE_STATE_WINDOW_OPEN_PASS_ITEM:
      return { ...state, stateWindowOpenPassItem: action.payload }

    case constants.ACTION_CHANGE_PASS_DATA:
      return { ...state, passData: action.payload }

    case constants.ACTION_CHANGE_PASS_ITEM_USER:
      return { ...state, passItemUser: action.payload }

    case constants.ACTION_CHANGE_PASS_ITEM_DATA_UPDATE:
      return { ...state, passItemDateUpdate: action.payload }

    case constants.ACTION_CHANGE_PASS_ITEM_PLATE:
      return { ...state, passItemPlate: action.payload }

    case constants.ACTION_CHANGE_PASS_ITEM_STATUS:
      return { ...state, passItemStatus: action.payload }

    case constants.ACTION_CHANGE_PASS_ITEM_DATA_COMMING:
      return { ...state, passItemDateComming: action.payload }

    case constants.ACTION_CHANGE_PASS_ITEM_APART_ID:
      return { ...state, passItemApartId: action.payload }

    case constants.ACTION_CHANGE_PASS_ITEM_APART_NUM:
      return { ...state, passItemApartNum: action.payload }

    case constants.ACTION_CHANGE_PASS_ITEM_ID:
      return { ...state, passItemId: action.payload }

    case constants.ACTION_CHANGE_PASS_ITEM_ETAG:
      return { ...state, passItemEtag: action.payload }

    case constants.ACTION_CHANGE_SERVICES:
      return { ...state, services: action.payload }

    case constants.ACTION_CHANGE_SERVICES_DATA:
      return { ...state, servicesData: action.payload }

    case constants.ACTION_CHANGE_ONLINE_SERVICES_DATA:
      return { ...state, onlineServicesData: action.payload }

    case constants.ACTION_CHANGE_ONLINE_SERVICES_CATEGORY:
      return { ...state, onlineServicesCategory: action.payload }

    case constants.ACTION_CHANGE_ONLINE_SERVICES_CATEGORY_DATA:
      return { ...state, onlineServicesCategoryData: action.payload }

    case constants.ACTION_CHANGE_ONLINE_SERVICES_DATA_FOR_OPEN:
      return { ...state, stateDataForOpenItem: action.payload }

    case constants.ACTION_CHANGE_ONLINE_SERVICES_CATEGORY_DATA_FOR_OPEN:
      return { ...state, stateSevicesCategoryDataForOpenItem: action.payload }

    case constants.ACTION_CHANGE_ACCOUNT_DATA:
      return { ...state, accountData: action.payload }

    case constants.ACTION_CHANGE_STATE_WINDOW_SERVICES:
      return { ...state, stateWindowServices: { servicesOpen: action.payload.NewStateWindowServices, servicesData: action.payload.NewServicesInfo, servicesEdit: state.stateWindowServices.servicesEdit, servicesCategory: state.stateWindowServices.servicesCategory, servicesCategoryEdit: state.stateWindowServices.servicesCategoryEdit } }

    case constants.ACTION_CHANGE_STATE_WINDOW_SERVICES_EDIT:
      return { ...state, stateWindowServices: { servicesOpen: state.stateWindowServices.servicesOpen, servicesData: state.stateWindowServices.servicesData, servicesEdit: action.payload, servicesCategory: state.stateWindowServices.servicesCategory, servicesCategoryEdit: state.stateWindowServices.servicesCategoryEdit } }

    case constants.ACTION_CHANGE_STATE_WINDOW_SERVICES_CATEGORY:
      return { ...state, stateWindowServices: { servicesOpen: state.stateWindowServices.servicesOpen, servicesData: state.stateWindowServices.servicesData, servicesEdit: state.stateWindowServices.servicesEdit, servicesCategory: action.payload, servicesCategoryEdit: state.stateWindowServices.servicesCategoryEdit } }

    case constants.ACTION_CHANGE_STATE_WINDOW_SERVICES_CATEGORY_EDIT:
      return { ...state, stateWindowServices: { servicesOpen: state.stateWindowServices.servicesOpen, servicesData: state.stateWindowServices.servicesData, servicesEdit: state.stateWindowServices.servicesEdit, servicesCategory: state.stateWindowServices.servicesCategory, servicesCategoryEdit: action.payload } }

    case constants.ACTION_CHANGE_STATE_SERVICES_PAGE:
      return { ...state, state_services_page: action.payload }

    case constants.ACTION_CHANGE_STATE_SERVICES_CATEGORY_PAGE:
      return { ...state, state_services_category_page: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_SEVRICE_PHOTO:
      return { ...state, state_services_photo: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_SEVRICE_CATEGORY_PHOTO:
      return { ...state, state_services_category_photo: action.payload }

    case constants.ACTION_CHANGE_ACCOUNTS_MAIN_STATE_HIDEN:
      return { ...state, accounts_main_state_hiden: action.payload }

    case constants.ACTION_CHANGE_STATE_REQUEST_PAGE:
      return { ...state, state_request_page: action.payload }

    case constants.ACTION_CHANGE_STATE_EDIT_SEVRICE_TITLE:
      return { ...state, state_services_title: action.payload }

    case constants.ACTION_CHANGE_STATE_EDIT_SEVRICE_CATEGORY_ACTIVE:
      return { ...state, state_services_category_active: action.payload }

    case constants.ACTION_CHANGE_STATE_EDIT_SEVRICE_CATEGORY_TITLE:
      return { ...state, state_services_category_title: action.payload }

    case constants.ACTION_CHANGE_STATE_EDIT_SEVRICE_ID:
      return { ...state, state_services_id: action.payload }

    case constants.ACTION_CHANGE_STATE_EDIT_SEVRICE_ETAG:
      return { ...state, state_services_etag: action.payload }

    case constants.ACTION_CHANGE_STATE_SEVRICE_CATEGORY_ETAG:
      return { ...state, state_services_category_etag: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_SEVRICE_CATEGORY_ID:
      return { ...state, state_services_category_id: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_SEVRICE_CATEGORY_NAME:
      return { ...state, state_services_category_name: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_SEVRICE_DESCRIPTION:
      return { ...state, state_services_description: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_SEVRICE_SHORTINFO:
      return { ...state, state_services_shortinfo: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_SEVRICE_PHONE:
      return { ...state, state_services_phone: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_SEVRICE_LINK:
      return { ...state, state_services_link: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_SEVRICE_EMAIL:
      return { ...state, state_services_email: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_SEVRICE_ORDER:
      return { ...state, state_services_order: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_SEVRICE_DELIVERY:
      return { ...state, state_services_delivery: action.payload }

    case constants.ACTION_CHANGE_STATE_CREATE_SEVRICE_PAY:
      return { ...state, state_services_pay: action.payload }

    // Личный кабинет

    case constants.ACTION_CHANGE_STATE_WINDOW_OPEN_NEW_OPERATOR:
      return { ...state, stateWindowOpenNewOperator: action.payload }

    case constants.ACTION_CHANGE_STATE_WINDOW_EDIT_OPERATOR:
      return { ...state, stateWindowOpenEditOperator: action.payload }

    case constants.ACTION_CHANGE_STATE_LK_NAMEUSER:
      return { ...state, state_lk_nameuser: action.payload }

    case constants.ACTION_CHANGE_STATE_LK_EMAILUSER:
      return { ...state, state_lk_emailuser: action.payload }

    case constants.ACTION_CHANGE_STATE_LK_IMAGEUSER:
      return { ...state, state_lk_imageuser: action.payload }

    case constants.ACTION_CHANGE_STATE_LK_EMAILUSER_CHANGE_STATUS:
      return { ...state, state_lk_emailuser_change_status: action.payload }

    case constants.ACTION_CHANGE_STATE_DANGER_INPUT_EMAILUSER_CHANGE_STATUS:
      return { ...state, state_danger_input_lk_email: action.payload }

    case constants.ACTION_CHANGE_STATE_LK_LISTOPERATORS_OPEN:
      return { ...state, state_lk_listoperators_open: action.payload }

    case constants.ACTION_CHANGE_STATE_LK_PHOTOUSER:
      return { ...state, state_lk_photouser: action.payload }

    case constants.ACTION_CHANGE_DATA_OPERATORS:
      return { ...state, dataOperators: action.payload }

    case constants.ACTION_CHANGE_STATE_PASS_PAGE:
      return { ...state, state_pass_page: action.payload }

    case constants.ACTION_CHANGE_IS_LOAD_MAILING_MAIN:
      return { ...state, isLoadMailingMain: action.payload }

    case constants.ACTION_CHANGE_IS_LOAD_MAILING_MENU:
      return { ...state, isLoadMailingMenu: action.payload }

    //

    case constants.ACTION_GET_INITIAL_STATE:
      return initialState
  }
  return state;
};