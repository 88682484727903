import React, { Component } from "react";

import { connect } from "react-redux";
import { _refreshToken } from "../../Refresh/Refresh";
import dispath from "../../../../store/dispatch.js";


// import api from "../../../../api/index.js";
class MailingAddAnswers extends Component {
	constructor(props) {
		super(props);

		this.state = {
			answerArray: this.props.answerArray
		};
	}
	deleteInput(index) {
		let newArray = this.state.answerArray;
		// console.log(newArray);

		newArray.splice(index, 1);
		// console.log(newArray);
		this.setState({
			answerArray: newArray
		})
		this.props.changeStateCreateQuestionsAnswerOptions(newArray);
	}
	addNewInput() {
		let newArray = this.state.answerArray;
		newArray.push("");
		this.setState({
			answerArray: newArray
		})
		this.props.changeStateCreateQuestionsAnswerOptions(newArray);
	}
	changeStateCreateQuestionsAnswerOption(answer, index) {
		let newArray = this.state.answerArray;
		newArray[index] = answer;

		this.setState({
			answerArray: newArray
		})
		this.props.changeStateCreateQuestionsAnswerOptions(newArray);
	}

	render() {
		// console.log(this.state.answerArray)
		return (
			<div className="w100 inputs-answers overflow-auto">
				<div className="w80">
					{this.state.answerArray !== undefined ?


						this.state.answerArray.map((answer, index) =>
							<div className="container-input-answer" key={"index" + index}>

								<input className="input_user_edit w100" type="text"
									// value={answer}
									value={this.state.answerArray[index]}

									onChange={(e) => {
										this.changeStateCreateQuestionsAnswerOption(e.target.value, index);
								
									}}
								/>
								<div className="w20 flex flex-end align-flex-start delete-answer">
									<div className="w100">
										<a className="w100 button_accept flex center text_white" onClick={() => this.deleteInput(index)}>-</a>
									</div>
								</div>
							</div>

						)

						: null}

				</div>
				<div className="w80 margin-top10 flex flex-start align-flex-start add-answer">
					<div className="w20">
						<a className="w100 button_accept flex center text_white" onClick={() => this.addNewInput()}>+</a>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(dispath.mapStateToProps, dispath.mapDispatchToProps)(MailingAddAnswers);
