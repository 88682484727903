import React from 'react';

import DefaultInput from '../../../../../../../DumbComponents/DefaultInput';

import './styles.css';

const temperatureInitState = {
  topic_setpoint_up: '',
  topic_setpoint_down: '',
  topic_setpoint: '',
};

export default props => {
  const selected = !!props.item.temperature;
  const path = ['rooms', props.roomIndex, 'devicesEndpoints', 'climate'];

  const handlerClick = () => {
    !selected && props.handlerChange(...path)(null, 'temperature', temperatureInitState );
    selected && props.handlerChange(...path)(null, 'temperature', { clear: true });
  }

  return (
    <div className='params-item_temperature'>
      <div className='room__checkbox room__checkbox_temperature'>
        <label><input type='checkbox' onChange={() => handlerClick()} checked={selected} />Температура</label>
      </div>
      { selected &&
        <div>
          <div className='params-item__field'>
            <span className='params-field__title'>Топик</span>
            <DefaultInput
              name='topic_setpoint'
              value={props.item.temperature.topic_setpoint}
              placeholder='topic_setpoint'
              onChange={props.handlerChange(...path, 'temperature')}
              required={!props.item.temperature.topic_setpoint &&
                (!props.item.temperature.topic_setpoint_up && !props.item.temperature.topic_setpoint_down)}
            />
          </div>
          <div className='params-item__field'>
            <span className='params-field__title'>Топик повышения</span>
            <DefaultInput
              name='topic_setpoint_up'
              value={props.item.temperature.topic_setpoint_up}
              placeholder='topic_setpoint_up'
              onChange={props.handlerChange(...path, 'temperature')}
              required={
                !props.item.temperature.topic_setpoint_up &&
                  !(!!props.item.temperature.topic_setpoint && !props.item.temperature.topic_setpoint_down)
              }
            />
          </div>
          <div className='params-item__field'>
            <span className='params-field__title'>Топик понижения</span>
            <DefaultInput
              name='topic_setpoint_down'
              value={props.item.temperature.topic_setpoint_down}
              placeholder='topic_setpoint_down'
              onChange={props.handlerChange(...path, 'temperature')}
              required={
                !props.item.temperature.topic_setpoint_down &&
                  !(!!props.item.temperature.topic_setpoint && !props.item.temperature.topic_setpoint_up)
              }
            />
          </div>
          <div className='params-item__field'>
            <span className='params-field__title'>Топик фидбэка</span>
            <DefaultInput
              name='topic_setpoint_fb'
              value={props.item.feedbacks.topic_setpoint_fb}
              placeholder='topic_setpoint_fb'
              onChange={props.handlerChange(...path, 'feedbacks')}
              required={!props.item.feedbacks.topic_setpoint_fb}
            />
          </div>
          <div className='params-item__field'>
            <span className='params-field__title'>Топик текущего фидбэка</span>
            <DefaultInput
              name='topic_temperature_fb'
              value={props.item.feedbacks.topic_temperature_fb}
              placeholder='topic_temperature_fb'
              onChange={props.handlerChange(...path, 'feedbacks')}
              required={!props.item.feedbacks.topic_temperature_fb}
            />
          </div>
        </div>
      }
    </div>
  );
}