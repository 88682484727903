import cyrillicToTranslit from 'cyrillic-to-translit-js';

export const initNotGlobalRoomState = {
  title: '',
  devicesEndpoints: {
    short_sn: '',
    device_url: '',
    global_endpoint: false,
    light: [],
    climate: null,
    sockets: [],
    blinds: [],
    light_scenes: [],
    security_sensors: [],
    windows: []
  }
};

export const initGlobalRoomState = {
  devicesEndpoints: {
    short_sn: '',
    device_url: '',
    global_endpoint: true,
    master_off: null,
    light_scenes: [],
    security_sensors: []
  }
};

/**
* Рекурсивно находит поле в объекте obj
*
* @param { object } obj - корректируемый объект
* @param { array } depth - путь (глубина)
* @param { string } target - корректируемое поле
* @param { any } value - значение, в зависимости от которого происходит удаление либо запись.
* @param { string } titleOf - в случае, если target === 'title' необходим для формирования значения.
*
* @return undefined
*
**/

export const findField = (obj, depth) => (target, value, titleOf) => {

  if (depth.length === 0) {

    // clear statment
    if (value.clear) {

      if (typeof value.index === 'number') {
        obj[target] = JSON.parse(JSON.stringify(obj[target].filter(
          (item, i) => i !== value.index
        )));
        return;
      }

      if (Array.isArray(obj[target]) && (target !== 'ventiltaion' && target !== 'heated_floors')) {
        confirm(`Вы уверены что хотите удалить блок`) ? obj[target] = [] : null;
      } else if (confirm(`Вы уверены что хотите удалить блок`) ) {
        if(target === 'climate') obj[target] = null
          else delete obj[target];
      }

      return;
    }

    // check this is dimmer?
    if (target === 'dimmable') {
      if (value === false) {
        delete obj.topic_value;
        delete obj.dimmer_max_value;
        delete obj.feedbacks.topic_value_fb;
      } else {
        obj['topic_value'] = '';
        obj['dimmer_max_value'] = '';
        obj.feedbacks['topic_value_fb'] = '';
      }

    }

    // write statements
    if (target === 'title' && titleOf !== undefined) {
      value === '' ? obj['name'] = '' :
        obj['name'] = `${titleOf}_${cyrillicToTranslit().transform(value, '_')}`;
    }

    (typeof value === 'object' && Array.isArray(obj[target])) ?
      obj[target] = [ ...obj[target], JSON.parse(JSON.stringify(value)) ] :
        obj[target] = value;

    return;
  }

  return findField(obj[depth.shift()], depth)(target, value, titleOf);
}