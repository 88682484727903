import React, { Component } from "react";

import { connect } from "react-redux";
import { _refreshToken } from "../../Refresh/Refresh";
import dispath from "../../../../store/dispatch.js";
import { slugify } from "transliteration";

import "./MailingAddTypeNotification.css";
import api from "../../../../api/index.js";
class MailingAddTypeNotification extends Component {
	constructor(props) {
		super(props);

		this.state = {
			colorArray: [
				['#373564', '#3e4d92'],
				['#4e9b9e', '#69babd'],
				["#495a6f", "#6b84a3"],
				['rgb(171, 74, 49)', 'rgb(255, 86, 0)'],
				['rgb(100, 99, 98)', 'rgb(134, 104, 181)'],
				['#373564', '#3e4d22'],
				['#4e9b9e', '#69ba3d'],
				["#81251a", "#a72d1f"],
				['rgb(110, 58, 121)', 'rgb(134, 104, 181)'],
				['rgb(100, 99, 98)', 'rgb(134, 104, 77)']
			],
			colorStyle: {
				background: `linear-gradient(45deg, #4e9b9e, #69babd)`
			}

		};
	}
	changeColorTypeOfNotification(color) {
		// console.log(color);
		return { background: `linear-gradient(-45deg, ${color[0]}, ${color[1]})` }
	}
	changeActiveColorTypeOfNotification(color) {

		this.setState({
			colorStyle: {
				background: `linear-gradient(-45deg, ${color[0]}, ${color[1]})`
			}
		})
		this.props.changeActiveColorTypeOfNotification(color);
		// console.log(this.props.state_status_edit_notification_category);
		if (this.props.state_status_edit_notification_category === true) {
			// console.log(this.props.categoryIndex);

			let newList = this.props.notificationCategoriesList;
			newList._items[this.props.categoryIndex].color = color;

			this.props.changeNotificationCategoriesList(newList);
		}

	}
	saveTypeNotification() {
		slugify.config({ lowercase: false, separator: "_" });

		if (
			this.props.state_add_type_notifacation !== undefined &&
			this.props.state_add_type_notifacation !== null &&
			this.props.state_add_type_notifacation !== ""
		) {
			const dataToSend = {
				title: this.props.state_add_type_notifacation,
				type: slugify(this.props.state_add_type_notifacation),
				color: this.props.state_active_color_type_of_notifacation
			};
			// console.log(dataToSend);

			api.createNotificationsTypes(this.props.LOGIN_TYPE_USER, dataToSend, this.props.access_token)
				.then(
					req => {
						api.getNotificationsTypes(this.props.LOGIN_TYPE_USER, this.props.access_token)
							.then(
								(response) => {
									this.props.changeNotificationCategoriesList(response.data);
									// console.log("response: ", response);
									this.props.changeAddTypeNotificationStatus(!this.props.addTypeNotificationStatus);
								}
							)
							.catch((err) => {
								console.log(err);
								if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {
									_refreshToken(
										this.props.client_id,
										this.props.LOGIN,
										this.props.PASSWORD,
										this.props.changeAccessToken,
										this.saveTypeNotification.bind(this)
									)

								}
							});
						console.log(req);
						this.props.changeStateAddTypeNotification("");

					}
				)
				.catch((err) => {
					console.log(err);
					if (err.response.status === 401 && err.response.data._error.message === 'token expired' || err.response.status === 401 && err.response.data._error.message === 'user not found') {

						_refreshToken(
							this.props.client_id,
							this.props.LOGIN,
							this.props.PASSWORD,
							this.props.changeAccessToken,
							this.saveTypeNotification.bind(this)
						)
					}
				});
		} else {
			this.props.changeStateDangerInputAddTypeNotification(true);
			return;
		}
	}

	render() {
		return (
			this.props.state_status_edit_notification_category === false ?

				<div className="margin-top20 w100">
					<p className="label">Добавить новый тип уведомлений</p>
					<div className="type-notification">
						<div className="flex">
							<input className={this.props.state_danger_input_add_type_notifacation === false
								? "input-date w100"
								: "input-date w100 danger_input"
							}
								type="text"
								value={this.props.state_add_type_notifacation}
								maxLength="20"
								onChange={e => {
									this.props.changeStateAddTypeNotification(
										e.target.value
									);
									this.props.changeStateDangerInputAddTypeNotification(
										false
									);
								}}
								onBlur={e => {
									this.props.changeStateAddTypeNotification(
										e.target.value
									);
									this.props.changeStateDangerInputAddTypeNotification(
										false
									);
								}}
								placeholder="Тип уведомления"
							/>
						</div>
						<div className="flex margin-top10 w100">
							<div className="custom-radios">
								{this.state.colorArray.map(color =>

									<div key={color}>
										<div className="custom-radios-label">
											<span className={this.props.state_active_color_type_of_notifacation === color ? "border-white" : ""} style={this.changeColorTypeOfNotification(color)} onClick={() => this.changeActiveColorTypeOfNotification(color)}>
											</span>
										</div>
									</div>
								)}





							</div>
						</div>
						<a
							className="w100 button_accept flex center text_white margin-top20"
							onClick={() => this.saveTypeNotification()}
						>
							Сохранить тип
					</a>
					</div>
				</div>
				:
				<div className="margin-top20 w100">
					<div className="flex margin-top10 w100">
						<div className="custom-radios">
							{this.state.colorArray.map(color =>

								<div key={color}>
									<div className="custom-radios-label">
										<span className={this.props.state_active_color_type_of_notifacation === color ? "border-white" : ""} style={this.changeColorTypeOfNotification(color)} onClick={() => this.changeActiveColorTypeOfNotification(color)}>
										</span>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>



		);
	}
}

export default connect(dispath.mapStateToProps, dispath.mapDispatchToProps)(MailingAddTypeNotification);
